import {useEffect, useMemo, useState} from "react";
import {useSnackbar} from "notistack";
import {addDealFile, deleteAgreementFile, getAgreementsByDeal} from "api";
import {DocumentType} from "types/deal";
import {AlertTypes} from "types/alertTypes";
import {useMixpanel} from "utils/MixpanelContext";
import configuration from "utils/configuration";
import useUploadDocument from "utils/hooks/useUploadDocument";
import {
  getDocumentLatestUpdate,
  getDocumentName,
  isDocumentUploadedByClient,
} from "utils/intakeHelper";
import {useClientOnboarding} from "../context/ClientOnboardingProvider";
import useCustomerPortalAuthUser from "./useCustomerPortalAuthUser";

export interface FilesFilterTotals {
  all: number;
  outstanding: number;
  inReview: number;
  approved: number;
}
export const filesFilterTypesList = {
  outstanding: 'Outstanding',
  inReview: 'In review',
  approved: 'Approved'
};
export type FilesFilterTypes = keyof typeof filesFilterTypesList;

const useOnboardingFiles = () => {
  const [{deal: {deal}}] = useClientOnboarding();
  const authUser = useCustomerPortalAuthUser();
  const mixpanel = useMixpanel();
  const {enqueueSnackbar} = useSnackbar();

  const [selectedFilter, setSelectedFilter] =
    useState<FilesFilterTypes>("outstanding");
  const [fileAgreements, setFileAgreements] = useState<DocumentType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteFile = async (fileId: number) => {
    try {
      const selectedFile = fileAgreements.find(f => f.id === fileId);
      let newList;

      if (selectedFile && selectedFile.status === "Temporary") {
        newList = fileAgreements.filter(f => f.id !== fileId);
      } else {
        const {data} = await deleteAgreementFile(fileId);
        newList = data;
        const deletedFile = data.find(f => f.id === fileId);
        if (deletedFile) {
          const placeholder = {
            ...deletedFile,
            id: deletedFile.id * -1,
            description: "Your document was deleted and the request went to the “Outstanding” section.",
            status: "Temporary",
          } as DocumentType;
          newList = [...data, placeholder];
        }
      }

      setFileAgreements(newList);
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Failed to delete file.", {
        variant: AlertTypes.Error,
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    }
  }

  const {onUpload} = useUploadDocument({
    documentType: "",
    onFileUploaded: ({file}, {documentName, setLoading, id}) => {
      if (!deal) return;

      const sizeInKb = file.size / 1024;
      const maxSize = 5120; // 5MB
      if (sizeInKb > maxSize) {
        enqueueSnackbar("File is too big (max 5MB)", {
          variant: AlertTypes.Error,
          autoHideDuration: configuration.autoHideErrorDuration,
        });
        mixpanel.track("document_size_error", {
          deal_type: deal?.deal_type,
          client_type: deal?.participants.find(p => p.user_id === authUser?.id)?.role,
          document_type: documentName,
          file_size: sizeInKb.toFixed(2) + 'Kb'
        });
        return;
      }

      setLoading(true);
      addDealFile(deal.id, {
        agreements: [
          {
            document_type: documentName,
            id,
            file,
          },
        ],
      })
        .then(({data}) => {
          setLoading(false);
          setFileAgreements(data as DocumentType[]);
          mixpanel.track(`intake_file_uploaded`, {
            transactionType: deal.deal_type,
            documentType: documentName,
          });
          enqueueSnackbar("Your document was uploaded", {
            variant: AlertTypes.Success
          })
        })
        .catch((e) => {
          setLoading(false);
          enqueueSnackbar("There was an error uploading your file", {
            variant: AlertTypes.Error,
            autoHideDuration: configuration.autoHideErrorDuration,
          });
        });
    },
  });

  const {filteredFiles, totals} = useMemo(() => {
    const filteredFiles: DocumentType[] = [];
    const totals = {
      all: 0,
      outstanding: 0,
      inReview: 0,
      approved: 0,
    } as FilesFilterTotals;

    fileAgreements.forEach(f => {
      let byClient;
      switch (f.status) {
        case "Rejected":
        case "Missing":
          totals.outstanding++;
          if (selectedFilter === 'outstanding') {
            filteredFiles.push(f);
          }
          break;
        case "Pending":
          byClient = isDocumentUploadedByClient(f);
          byClient && totals.inReview++;

          if (selectedFilter === "inReview" && byClient) {
            filteredFiles.push(f);
          }
          break;
        case "Temporary":
          if (selectedFilter === "inReview") {
            filteredFiles.push(f);
          }
          break;
        case "Approved":
          if (!f.storage_id) break;
            
          byClient = isDocumentUploadedByClient(f);
          byClient && totals.approved++;

          if (selectedFilter === "approved" && byClient) {
            filteredFiles.push(f);
          }
          break;
      }
    });

    totals.all = totals.approved + totals.inReview + totals.outstanding;

    filteredFiles.sort((a, b) => {
      switch (selectedFilter) {
        case "inReview":
        case "approved":
          return (
            new Date(getDocumentLatestUpdate(b)?.updated_at || 0).getTime() -
            new Date(getDocumentLatestUpdate(a)?.updated_at || 0).getTime()
          );
        case "outstanding":
          return getDocumentName(a).localeCompare(getDocumentName(b));
      }
    });

    return {filteredFiles, totals};
  }, [fileAgreements, selectedFilter]);

  useEffect(() => {
    if (deal && authUser) {
      setIsLoading(true);
      getAgreementsByDeal(deal.id)
        .then(({data}) => {
          setIsLoading(false);
          setFileAgreements(data);
        })
        .catch((e) => {
          setIsLoading(false);
          enqueueSnackbar("There was an error loading the file requirements", {
            variant: AlertTypes.Error,
            autoHideDuration: configuration.autoHideErrorDuration,
          });
        });
    }
  }, [deal, authUser]);

  const filter = {
    totals,
    selectedFilter,
    setSelectedFilter,
  };

  return [filteredFiles, isLoading, onUpload, handleDeleteFile, filter] as const;
}

export default useOnboardingFiles;