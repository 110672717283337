import {FC, useState} from "react";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import {
  BodyRegular,
  BodySmall,
  H2,
  H3,
} from "components/CommonDashboard/Typography";
import TextField from "components/CommonDashboard/TextField";
import Button from "components/CommonDashboard/Button";
import {SaveIcon} from "components/CommonDashboard/Icons";
import Colors from "components/CommonDashboard/Styling/Colors";
import PasswordHint from "components/CommonDashboard/PasswordHint";
import useUserSecurityForm from "../../hooks/useUserSecurityForm";
import useCustomerPortalAuthUser from "../../hooks/useCustomerPortalAuthUser";
import SecurityMFA from "./SecurityMFA";

const SecurityContent: FC = () => {
  const user = useCustomerPortalAuthUser();
  const {values, controlFor, handleSubmit, isSubmitting} =
    useUserSecurityForm();
  const [showMFA, setShowMFA] = useState(false);

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap="2.4rem" maxWidth="lg">
        <H2>Security</H2>
        <H3>Update password</H3>

        <TextField {...controlFor("current_pass")} label="Current password" />

        <TextField
          {...controlFor("new_pass")}
          label="New password"
          placeholder="Type your new password"
        />
        <Stack gap="0.6rem">
          <PasswordHint
            active={!!values.new_pass.match("(?=.{12,})")}
            hint="Must be at least 12 characters"
          />
          <PasswordHint
            active={!!values.new_pass.match("(?=.*[0-9])")}
            hint="Must contain at least 1 number"
          />
          <PasswordHint
            active={!!values.new_pass.match("(?=.*[A-Z])")}
            hint="Must contain at least 1 uppercase letter"
          />
          <PasswordHint
            active={!!values.new_pass.match("(?=.*[a-z])")}
            hint="Must contain at least 1 lowercase letter"
          />
          <PasswordHint
            active={!values.new_pass.match(/^\s+|\s+$/g) && Boolean(values.new_pass)}
            hint="No spaces at the beginning or end"
          />
        </Stack>

        <TextField
          {...controlFor("confirm_new_pass")}
          label="Confirm new password"
          placeholder="Type your new password"
        />

        <ButtonRow>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            type="submit"
            label={{text: "Save changes"}}
            endIcon={<SaveIcon color={Colors.WHITE} />}
            typeOf="CTA"
          />
        </ButtonRow>

        <H3 mt="2.4rem">Multi-factor authentication</H3>
        <Stack direction="column" gap="1.6rem">
          <BodyRegular>
            {Boolean(user?.google_verify) ? "Enabled" : "Not enabled yet."}
          </BodyRegular>
          <BodySmall color={Colors.DARK_GREY}>
            Multi-factor authentication adds an extra layer of security to your
            account by requiring more than just a password to log in.
          </BodySmall>
        </Stack>

        <ButtonRow>
          <Button
            onClick={() => setShowMFA(true)}
            label={{text: "Enable Multi-Factor Authentication"}}
            typeOf="CTA"
            disabled={Boolean(user?.google_verify)}
          />
        </ButtonRow>
      </Stack>

      <SecurityMFA startMFAConfig={showMFA} onFinish={() => setShowMFA(false)}  />
    </form>
  );
};

const ButtonRow = styled(Stack)(({theme}) => ({
  marginTop: "2.4rem",
  alignItems: "end",
  [theme.breakpoints.down("mobile")]: {
    marginTop: 0,
    alignItems: "initial",
  },
}));

export default SecurityContent;
