import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "components/CommonDashboard/Button";
import {
    BuildingIcon,
    PlusIcon,
    SecurityIcon,
    SettingsIcon,
} from "components/CommonDashboard/Icons";
import Colors from "components/CommonDashboard/Styling/Colors";
import { BodySmaller, ButtonLargeText, ButtonSmallText } from "components/CommonDashboard/Typography";
import HeaderMenu from "components/CommonDashboard/HeaderMenu";
import { pages } from "../pages/paths";
import { Deal } from "types/deal";

interface HeaderMenuInterface {
    firstName: string,
    deals: Deal[]
}

const PortalHeaderMenu: FC<HeaderMenuInterface> = ({ firstName, deals }) => {
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const [pathname, setPathname] = useState(history.location.pathname);

    const handleSettings = () => {
        history.push(pages.USER_SETTINGS.route());
    }

    const handleTransactions = () => {
        history.push(pages.DEALS.route());
    }

    const handleSecurity = () => {
        history.push(pages.USER_SECURITY.route());
    }

    const handlerDealNavigation = (dealId: number) => () => {
        history.push(pages.DEAL_STATUS.route(dealId));
    }

    const isSelected = (path: string) => pathname === path;

    useEffect(() => {
        let unregister = history.listen(loc => setPathname(loc.pathname))
        return () => unregister();
    }, []);

    return (
        <HeaderMenu name={isMobile ? undefined : `Hi, ${firstName}`}>
            <StyledItem onClick={handleTransactions} selected={isSelected(pages.DEALS.route())}>
                <ListItemIcon>
                    <BuildingIcon color={Colors.DARK_GREY} />
                </ListItemIcon>
                <ButtonLargeText data-testid="my-transactions-btn" color={Colors.DARK_GREY}>My Transactions</ButtonLargeText>
            </StyledItem>
            {deals && deals.slice(0,2).map((deal) =>
                <StyledItem data-testid="deal-item" key={deal.id} onClick={handlerDealNavigation(deal.id)} selected={isSelected(pages.DEAL_STATUS.route(deal.id))}>
                    <ListItemText>
                        <ButtonSmallText color={Colors.DARK_GREY}>{deal.address.address}, {deal.address.city}, {deal.address.state}</ButtonSmallText>
                        <BodySmaller color={Colors.DARK_GREY}>{deal.deal_number} - {deal.deal_type}</BodySmaller>
                    </ListItemText>
                </StyledItem>
            )}
            {deals.length > 2 &&
                <Stack px={1.6} py={0.8}>
                    <Button
                        typeOf="secondary"
                        size="small"
                        startIcon={<PlusIcon size="small" color={Colors.BLACK} />}
                        label={{text: "See all transactions"}}
                        onClick={handleTransactions}
                        testId="see-all-transactions-button"
                    />
                </Stack>
            }
            <StyledItem onClick={handleSettings} selected={isSelected(pages.USER_SETTINGS.route())}>
                <ListItemIcon>
                    <SettingsIcon color={Colors.DARK_GREY} />
                </ListItemIcon>
                <ButtonLargeText color={Colors.DARK_GREY}>Settings</ButtonLargeText>
            </StyledItem>
            <StyledItem onClick={handleSecurity} selected={isSelected(pages.USER_SECURITY.route())}>
                <ListItemIcon>
                    <SecurityIcon color={Colors.DARK_GREY} />
                </ListItemIcon>
                <ButtonLargeText color={Colors.DARK_GREY}>Security</ButtonLargeText>
            </StyledItem>
        </HeaderMenu>
    )
}

const StyledItem = styled(MenuItem)(() => ({
    padding: '1.6rem',
    minWidth: '22rem',
    '&:hover, &.Mui-selected': {
        backgroundColor: Colors.CORAL_20,
        'p': {
            color: Colors.BLACK
        },
        '& svg path': {
            fill: Colors.BLACK,
        }
    },
}))

export default PortalHeaderMenu;