import {OnboardingStepStatus, OnboardingStepStatus as StepStatus} from "../types";
import {updateOnboardingTask} from "api";
import Colors from "components/CommonDashboard/Styling/Colors";
import {
  ArrowRightIcon,
  CheckCircleIcon,
  CloudUploadIcon,
  IDVIcon,
  RetainerIcon,
  SurveyIcon,
  UmbrellaIcon,
} from "components/CommonDashboard/Icons";
import {BodyRegular} from "components/CommonDashboard/Typography";
import {
  ClientIntakeStates,
  IDVStates,
  OnboardingStates,
  OnboardingTask,
  OnboardingTaskStatus,
} from "types/intakeForm";
import {DocumentType} from "types/deal";
import {pages} from "../paths";
import MovingStep from "./MovingStep";

export interface getStatusProps {
    OnbState: OnboardingStates;
    intState?: ClientIntakeStates;
    idvState?: IDVStates;
    idvFeature: boolean;
    task?: OnboardingTask;
    agreements?: DocumentType[];
}
interface getButtonProps {
    stepStatus: StepStatus;
    task?: OnboardingTask;
    refreshDeal?: () => void;
}

const insurelyLink = "https://insurely.ca/deeded-ref3/";

export const OnboardingSteps = [
    {
        title: "Agree to Lawyer Retainer",
        icon: <RetainerIcon />,
        description: "Our network of friendly and experienced real estate lawyers operate independently of our platform. In order for the lawyer to be able to represent you, you’ll need to agree to the lawyer’s retainer.",
        link: pages.RETAINER.route,
        testId: "agree-to-lawyer-retainer-step",
        getButtonProps: (props: getButtonProps) => ({
            label: { text: 'Continue' },
            endIcon: <ArrowRightIcon color={Colors.WHITE} />,
            testId: 'step-cta-button',
        }),
        getStatus: ({ OnbState }: getStatusProps) => {
            const { Accessed, InRetainer, Created } = OnboardingStates;
            if ([Accessed, InRetainer, Created].includes(OnbState))
                return StepStatus.NotStarted;
            return StepStatus.Completed;
        },
    },
    {
        title: "Identity Verification",
        icon: <IDVIcon />,
        description: "Our network of friendly and experienced real estate lawyers operate independently of our platform. In order for the lawyer to be able to represent you, you’ll need to agree to the lawyer’s retainer.",
        link: pages.IDV.route,
        getButtonProps: (props: getButtonProps) => ({
            label: { text: 'Start ID verification' },
            endIcon: <ArrowRightIcon color={Colors.WHITE} />
        }),
        getStatus: ({ idvState, OnbState, idvFeature }: getStatusProps) => {
            const { InIDV, Completed } = OnboardingStates;
            if (!idvFeature)
                return StepStatus.NotShow;
            if (OnbState === InIDV && idvState && idvState !== IDVStates.Created)
                return StepStatus.InProgress;
            if (OnbState === InIDV)
                return StepStatus.NotStarted;
            if (OnbState === Completed)
                return StepStatus.Completed;
            return StepStatus.Disabled;
        },
    },
    {
        title: "Complete Onboarding Questionnaire",
        icon: <SurveyIcon />,
        description: "Answer a short questionnaire to help us gather the key information necessary for us to start your closing.",
        link: pages.SURVEY.route,
        testId: "compete-onboarding-questionnaire-step",
        getButtonProps: (props: getButtonProps) => ({
            label: { text: props.stepStatus !== StepStatus.InProgress ? 'Start questionnaire' : 'Continue questionnaire' },
            endIcon: <ArrowRightIcon color={Colors.WHITE} />,
            testId: 'step-cta-button',
        }),
        getStatus: ({ OnbState, intState }: getStatusProps) => {
            if (!intState)
                return StepStatus.NotShow;
            if (OnbState !== OnboardingStates.Completed)
                return StepStatus.Disabled;
            if (intState === ClientIntakeStates.Created)
                return StepStatus.NotStarted;
            if (intState === ClientIntakeStates.InProgress)
                return StepStatus.InProgress;
            return StepStatus.Completed;
        },
    },
    {
        alwaysShowButtons: true,
        title: "Upload documents",
        icon: <CloudUploadIcon />,
        link: pages.FILES.route,
        description: "We’ll need a few files uploaded depending on your answers to the survey questions.",
        testId: "upload-documents-step",
        getButtonProps: ({stepStatus}: getButtonProps) => {
          let label = "Get started";
          switch (stepStatus) {
            case OnboardingStepStatus.Completed:
              label = "View Documents";
              break;
            case OnboardingStepStatus.InProgress:
              label = "Continue";
              break;
          }
          return {
            label: {text: label},
            endIcon: <ArrowRightIcon color={Colors.WHITE} />,
            testId: 'step-cta-button'
          }
        },
        getStatus: ({ OnbState, intState, agreements }: getStatusProps) => {
            if (OnbState !== OnboardingStates.Completed)
                return StepStatus.Disabled;
            if (intState && intState !== ClientIntakeStates.Completed)
                return StepStatus.Disabled;
            if (agreements && agreements?.findIndex(a => ["Rejected", "Missing"].includes(a.status)) === -1)
                return StepStatus.Completed;
            if (agreements && agreements?.findIndex(a => a.status !== "Missing") > -1)
                return StepStatus.InProgress;
            return StepStatus.NotStarted;
        },
    },
    {
        title: "Arrange or Update Home Insurance",
        icon: <UmbrellaIcon />,
        link: pages.FILES.route,
        alwaysShowButtons: true,
        description: (
          <>
            <BodyRegular mb="1.2rem" color={Colors.DARK_GREY}>
              Proof of home insurance will be required prior to closing your
              transaction. If you have a current home insurance policy, you'll need
              to contact your insurer or broker to update it with your new lender's
              information and provide us proof of insurance. We'll let you know the
              exact lender's name a few days prior to closing.
            </BodyRegular>
            <BodyRegular color={Colors.DARK_GREY}>
              If you do not have insurance or you're interested in getting a
              competitive quote, we've partnered with{" "}
              <BodyRegular
                color={Colors.PRIMARY}
                component="a"
                href={insurelyLink}
                target="_blank"
              >
                Insurely
              </BodyRegular>
              , a top-notch digital insurance broker who can provide you with a
              competitive quote in seconds. Best part is that we'll work together
              with Insurely to get proof of insurance, save you time. Click below to
              get the best rates out there.
            </BodyRegular>
          </>
        ),
        testId: "arrange-or-update-insurance-step",
        getButtonProps: (props: getButtonProps) => ({
            component: "a",
            href: insurelyLink,
            target: "_blank",
            label: { text: 'Get your free quote with Insurely' },
            endIcon: <ArrowRightIcon color={Colors.WHITE} />,
            testId: 'get-your-free-quote-with-insurely-button',
            onClick: undefined,
            disabled: false,
        }),
        getSecButtonProps: ({ task, refreshDeal }: getButtonProps) => {
            if (!task || task.status === OnboardingTaskStatus.Completed) return;
            return {
                label: { text: 'I already have a Home Insurance' },
                endIcon: <CheckCircleIcon color={Colors.DARK_GREY} />,
                testId: 'i-have-insurance-button',
                onClick: async () => {
                    await updateOnboardingTask(task.id, OnboardingTaskStatus.Completed);
                    if (refreshDeal) refreshDeal();
                },
                disabled: false,
            }
        },
        getStatus: ({ task }: getStatusProps) => {
            switch (task?.status || "") {
              case OnboardingTaskStatus.Completed:
                return StepStatus.Completed;
              case OnboardingTaskStatus.InProgress:
                return StepStatus.InProgress;
              case OnboardingTaskStatus.NotStarted:
                return StepStatus.Disabled;
              default:
                return StepStatus.NotShow;
            }
        },
    },
    MovingStep
];


export default OnboardingSteps;