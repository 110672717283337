import Stack from "@mui/material/Stack";
import { Header } from "components/Common/Wrapper/Header/Header";
import Colors from "components/CommonDashboard/Styling/Colors";
import { FC } from "react";
import ScheduleCall from "../ScheduleCall";
import ReviewsPanel from "../ReviewsPanel";
import BottomPanel from "../ContactPanel";
import Footer from "components/CommonDashboard/Footer/Footer";
import { styled } from "@mui/material/styles";

export const Separator = () => <Stack style={{ borderTop: `1px solid ${Colors.PRIMARY_100}` }} />;

export const QuoteContainer: FC = ({ children }) => {
    return (
        <Stack sx={{ backgroundColor: Colors.PRIMARY_25 }}>
            <Header title="" fixed={false} maxWidth='120rem' />
            <MainContainer>
                <Stack sx={{ gap: '4.8rem' }}>
                    {children}
                </Stack>
                <ScheduleCall />
                <Separator />
                <ReviewsPanel />
                <BottomPanel />
            </MainContainer>
            <Footer />
        </Stack >
    );
};


const MainContainer = styled(Stack)(({ theme }) => ({
    maxWidth: '120rem',
    alignSelf: 'center',
    flexFlow: 'column',
    gap: '4.8rem',
    padding: '2.4rem',
    [theme.breakpoints.down('mobile')]: {
        flexFlow: 'column',
        padding: '4.8rem 1.6rem',
        gap: '2.4rem',
    }
}));
