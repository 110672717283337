import React, {SyntheticEvent, useContext} from "react";
import {useDispatch} from "react-redux";
import {Box, styled, Typography, useTheme} from "@mui/material";
import constants from "styles/constants";
import DeededCheckbox from "v2/components/DeededCheckbox";
import DeededButton from "v2/components/DeededButton";
import DeededRadioButton from "v2/components/DeededRadioButton";
import useWindowWidth from "utils/hooks/useWindowWidth";
import useTypedSelector from "utils/hooks/useTypedSelector";
import {addErrorAC} from "redux/reducers/ErrorReducer";
import FormField from "../Common/FormField";
import {FormContext} from "../Common/FormContext";
import HasBrokerFields from "./HasBrokerFields";

const FormFields = () => {
  const theme = useTheme();
  const { sm } = theme.breakpoints.values;
  const {values, handleChange, errors, handleSubmit} = useContext(
    FormContext,
  );
  const dispatch = useDispatch();
  const isSubmittingBankForm = useTypedSelector(
    (state) => state.LandingReducer.isSubmittingBankForm,
  );

  const windowWidth = useWindowWidth();
  const handleChangeCheckbox = (e: {target: {checked: boolean}}) => {
    handleChange({
      target: {
        name: "isConsentedTerms",
        value: e.target.checked,
      },
    });
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "634px",
            marginBottom: "56px",
            [theme.breakpoints.down("md")]: {
              width: "536px",
              marginBottom: "48px",
            },
            [theme.breakpoints.down("sm")]: {
              width: "90%",
              marginBottom: "32px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "24px",
              [theme.breakpoints.down("sm")]: {
                marginBottom: "18px",
              },
            }}
          >
            <FormField
              name={"firstName"}
              label={"Your First Name"}
            />
            <FormField
              name={"lastName"}
              label={"Your Last Name"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
              },
            }}
          >
            <FormField name={"email"} label={"Your Email"} />
            <FormField
              name={"phoneNumber"}
              label={"Your Phone Number"}
            />
          </Box>
        </Box>
        <TitleTypography
          sx={{
            width: "633px",
            marginBottom: "40px",
            [theme.breakpoints.down("lg")]: {
              width: "536px",
              fontSize: "18px",
            },
            [theme.breakpoints.down("sm")]: {
              width: "90%",
            },
          }}
        >
          Tell us about the property that we will be securing your reverse
          mortgage for
        </TitleTypography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "634px",
            marginBottom: "48px",
            [theme.breakpoints.down("md")]: {
              width: "536px",
            },
            [theme.breakpoints.down("sm")]: {
              width: "90%",
              marginBottom: "32px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "24px",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
              },
            }}
          >
            <FormField
              name={"streetAddress"}
              label={"Street Address"}
            />
            <FormField name={"city"} label={"City"} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              [theme.breakpoints.down("sm")]: {
                marginBottom: "24px",
              },
            }}
          >
            <FormField name={"province"} label={"Province"} />
            <FormField name={"postalCode"} label={"Postal Code"} />
            {windowWidth >= sm && (
              <FormField
                name={"closingDate"}
                label={"Closing Date"}
              />
            )}
          </Box>
          {windowWidth < sm && (
            <Box>
              <FormField
                name={"closingDate"}
                label={"Closing Date"}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              color: constants.colors.title,
              marginRight: "24px",
              [theme.breakpoints.down("sm")]: {
                marginRight: "0px",
              },
            }}
          >
            How do you prefer to be contacted?
          </Typography>
          <Box
            sx={{
              "& .MuiFormControlLabel-root": {
                "& .MuiRadio-root": {
                  span: {
                    " svg": {
                      width: "16px",
                      height: "16px",
                    },
                  },
                },
              },
            }}
          >
            <DeededRadioButton
              sx={{
                svg: {
                  height: "16px",
                  width: "16px",
                },
              }}
              onChange={() => {
                handleChange({
                  target: {
                    name: "preferred",
                    value: "phone",
                  },
                });
              }}
              checked={values.preferred === "phone"}
              label={"By Phone"}
            />
            <DeededRadioButton
              onChange={() => {
                handleChange({
                  target: {
                    name: "preferred",
                    value: "email",
                  },
                });
              }}
              checked={values.preferred === "email"}
              label={"By Email"}
            />
          </Box>
        </Box>

        <HasBrokerFields />
        
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "40px",
            [theme.breakpoints.down("sm")]: {
              width: "90%",
            },
          }}
        >
          <DeededCheckbox
            checked={values.isConsentedTerms}
            name={"isConsentedTerms"}
            onChange={
              handleChangeCheckbox as unknown as (
                event: SyntheticEvent<Element, Event>,
                checked: boolean,
              ) => void
            }
          />
          <Typography
            onClick={() => {
              handleChange({
                target: {
                  name: "isConsentedTerms",
                  value: !values.isConsentedTerms,
                },
              });
            }}
            sx={{
              cursor: "pointer",
              fontSize: "14px",
              color: constants.colors.title,
            }}
          >
            I consent to Deeded’s{" "}
            <LinkSpan
              onClick={(e) => {
                e.stopPropagation();
                window
                  .open("https://deeded.ca/privacy-policy/", "_blank")!
                  .focus();
              }}
            >
              Privacy Policy
            </LinkSpan>{" "}
            and{" "}
            <LinkSpan
              onClick={(e) => {
                e.stopPropagation();
                window.open("https://deeded.ca/terms/", "_blank")!.focus();
              }}
            >
              Terms of Use
            </LinkSpan>
          </Typography>
        </Box>
        <DeededButton
          onClick={() => {
            if (values.preferred.length === 0 && !isSubmittingBankForm) {
              dispatch(
                addErrorAC("Please choose how do You prefer to be contacted"),
              );
            }
            if (!values.isConsentedTerms && !isSubmittingBankForm) {
              dispatch(
                addErrorAC(
                  "Please consent to the Privacy Policy and Terms of Use",
                ),
              );
            }
          }}
          sx={{
            height: "34px",
            width: "186px",
            [theme.breakpoints.down("sm")]: {
              height: "34px",
              width: "186px",
            },
          }}
          type={isSubmittingBankForm ? "button" : "submit"}
        >
          {isSubmittingBankForm ? "Loading..." : "Submit"}
        </DeededButton>
      </Form>
    </>
  );
};

const TitleTypography = styled(Typography)({
  fontSize: "24px",
  fontWeight: "500",
  color: constants.colors.equitableBankFromTitle,
  textAlign: "center",
});
const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});
const LinkSpan = styled("span")({
  color: constants.colors.red,
});
export default FormFields;
