export enum TransactionTypeEnum {
  Buying = "Buying",
  Selling = "Selling",
  Refinancing = "Refinancing",
}

export enum PropertyEnum {
  Ontario = "Ontario",
  Alberta = "Alberta",
  BritishColumbia = "British Columbia",
}
