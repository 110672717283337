import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useSelector, useDispatch} from "react-redux";
import {userLogin, userMtfLogin} from "redux/actions/auth";
import {LoginDisplay} from "./LoginDisplay";
import {useDidUpdateEffect} from "components/Dashboard/ClientIntakeEditCard/components/hooks-helpers";
import {clearAllCalendarSettings} from "components/Dashboard/Calendar/utils/clearAllCalendarSettings";
import {useMixpanel} from "utils/MixpanelContext";
import useUser from "utils/hooks/useUser";
import storage from "utils/storage";
import configuration from "utils/configuration";
import {resolveJwtTokenVerified} from "utils/resolveJwtTokenVerified";
import { AlertTypes } from "../../../types/alertTypes";

export const MISSING_DOCUMENTS_QUERY = "?missing-documents";

function LoginV2() {
  // State
  const [userEmail, setUserEmail] = useState("");
  const [password, setUserPassword] = useState("");
  const [sixDigitsCode, setSixDigitsCode] = useState("");
  const [userEmailError, setUserEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isMtfAuthShowed, setIsMtfAuthShowed] = useState(false);
  const [verifyToken, setVerifyToken] = useState("");
  // router
  const history = useHistory();
  const dispatch = useDispatch();
  // notistack
  const {enqueueSnackbar} = useSnackbar();

  // mixpanel
  const mixpanel = useMixpanel();
  const {user: currentUser} = useUser();
  const deals = useSelector((state) => state.DealsReducer.deals);
  const loading = useSelector((state) => state.AuthReducer.authLoading);
  const error = useSelector((state) => state.AuthReducer.error);

  useEffect(() => {
    const token = resolveJwtTokenVerified();
    if (token !== null && token !== 'expired') {
      history.replace('/');
    }
  }, []);

  useEffect(() => {
    clearAllCalendarSettings(dispatch);
  }, [dispatch]);
  useDidUpdateEffect(() => {
    if (error?.length === 0) return;
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: configuration.autoHideErrorDuration,
    });
  }, [error]);

  const onUserLogin = (user) => {
    if (isMtfAuthShowed) {
      dispatch(userMtfLogin(user));
    } else {
      dispatch(userLogin(user, setIsMtfAuthShowed, setVerifyToken));
    }
  };
  const clearError = () => dispatch({type: "REMOVE_ERROR"});

  useDidUpdateEffect(() => {
    const userID = localStorage.getItem("user_id");
    let token = localStorage.getItem(`token_${userID}`);

    if (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      clearError();
    }
    const onDefaultPostLoginBehavior = () => {
      if (storage.targetLink) {
        const link = storage.targetLink;
        return history.replace(link);
      }
      history.push("/");
    }
    if (token) {
      dispatch({type: "SALE_RESET"});
      dispatch({type: "MORTGAGE_RESET"});
      dispatch({type: "PURCHASE_RESET"});
      // Send data for mixpanel analytics
      const {id, role, email, full_name} = currentUser;
      const mixPayload = {
        role,
        email,
        full_name,
      };
      mixpanel.identify(id);
      mixpanel.people.set({
        $email: email,
        $name: full_name,
        role,
        id,
      });
      mixpanel.track(`${role}_login`, mixPayload);
      onDefaultPostLoginBehavior();
    }
  }, [currentUser, deals]);

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (!userEmail.length && !password.length) {
      setUserEmailError(true);
      setPasswordError(true);
      return;
    }
    const user = {
      email: isMtfAuthShowed ? undefined : userEmail,
      password: isMtfAuthShowed ? undefined : password,
      code: isMtfAuthShowed ? sixDigitsCode : undefined,
      login_token: isMtfAuthShowed ? verifyToken : undefined,
    };
    if (error?.length !== 0) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }

    enqueueSnackbar("Processing login", {
      variant: AlertTypes.Info,
      autoHideDuration: 1500,
    });
    onUserLogin(user);
  };
  const onEmailInputBlur = (e) => {
    let regExp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regExp.test(String(e.target.value).toLowerCase())) {
      setUserEmailError(true);
    }
  };
  const onPasswordInputBlur = (e) => {
    if (e.target.value.length <= 0) {
      setPasswordError(true);
    }
  };
  return (
    <>
      <LoginDisplay
        onFormSubmit={onFormSubmit}
        onFormChange={() => {
          clearError();
          setUserEmailError(false);
          setPasswordError(false);
        }}
        onEmailInputChange={(e) => setUserEmail(e.target.value)}
        onEmailInputBlur={onEmailInputBlur}
        onPasswordInputChange={(e) => setUserPassword(e.target.value)}
        onPasswordInputBlur={onPasswordInputBlur}
        setSixDigitsCode={setSixDigitsCode}
        sixDigitsCode={sixDigitsCode}
        hasUserEmailError={userEmailError}
        hasUserPasswordError={passwordError}
        isLoading={loading}
        isMtfAuthShowed={isMtfAuthShowed}
      />
    </>
  );
}

export default LoginV2;
