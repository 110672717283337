import Stack from "@mui/material/Stack";
import Button from "components/CommonDashboard/Button";
import { PhoneIcon } from "components/CommonDashboard/Icons";
import Colors from "components/CommonDashboard/Styling/Colors";
import { BodyRegular } from "components/CommonDashboard/Typography";
import configuration from "utils/configuration";
import { styled } from "@mui/material/styles";


const ScheduleCall = () => <RootContainer>
    <BodyRegular alignSelf='center'>Have a question?  We're always here to help.</BodyRegular>
    <Button
        component='a'
        href={configuration.callScheduleUrl}
        target="_blank"
        typeOf="primary"
        label={{ text: "Schedule a call" }}
        startIcon={<PhoneIcon color={Colors.WHITE} />}
    />
</RootContainer>


const RootContainer = styled(Stack)(({ theme }) => ({
    gap: '2.4rem',
    alignItems: 'center',
    maxWidth: '120rem',
    [theme.breakpoints.down('mobile')]: {
        gap: '1.6rem',
        alignItems: 'stretch',
        padding: '0 2.4rem'
    }
}));


export default ScheduleCall;