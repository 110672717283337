import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {configureStore, PayloadAction} from "@reduxjs/toolkit";
import {testApi} from "./reducers/testApi";
import {ActivityLogReducer} from "./reducers/activityLogReducer";
import {AuthReducer} from "./reducers/authReducer";
import {CalendarReducer} from "./reducers/calendarReducer";
import ConfigReducer from "./reducers/configReducer";
import {DealDetailsSurveyReducer} from "./reducers/dealDetailsSurveyReducer";
import {DealsReducer} from "./reducers/dealsReducer";
import ErrorReducer from "./reducers/ErrorReducer";
import {LandingReducer} from "./reducers/landingReducer";
import {LenderReducer} from "./reducers/lenderReducer";
import {MortgageReducer} from "./reducers/mortgageReducer";
import {ProfileReducer} from "./reducers/profileReducer";
import {PurchaseReducer} from "./reducers/purchaseReducer";
import {SaleReducer} from "./reducers/saleReducer";
import {SelectedFileMoveStorageReducer} from "./reducers/selectedFileMoveStorageReducer";
import {StorageReducer} from "./reducers/storageReducer";
import {SurveyReducer} from "./reducers/surveyReducer";
import {combineReducers} from "@reduxjs/toolkit";
import testReducer from "./reducers/test";
import {dealInfoApi} from "./reducers/dealInfoApi";
import {configDealNumbersAPI} from "./reducers/configDealNumbersApi";
import {calendarApi} from "./reducers/calendarApi";
import {profileApi} from "./reducers/profileApi";
import {lendingApi} from "./reducers/landingApi";
import FileTasksReducer from "./reducers/fileTasksSlice";

const appReducer = combineReducers({
  AuthReducer,
  DealsReducer,
  SurveyReducer,
  SaleReducer,
  PurchaseReducer,
  MortgageReducer,
  LenderReducer,
  StorageReducer,
  CalendarReducer,
  ConfigReducer,
  SelectedFileMoveStorageReducer,
  ErrorReducer,
  ProfileReducer,
  LandingReducer,
  ActivityLogReducer,
  DealDetailsSurveyReducer,
  [configDealNumbersAPI.reducerPath]: configDealNumbersAPI.reducer,
  [testApi.reducerPath]: testApi.reducer,
  test: testReducer,
  [dealInfoApi.reducerPath]: dealInfoApi.reducer,
  [calendarApi.reducerPath]: calendarApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [lendingApi.reducerPath]: lendingApi.reducer,
  FileTasksReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "AuthReducer",
    "DealsReducer",
    "StorageReducer",
    "PurchaseReducer",
    "CalendarReducer",
    "ActivityLogReducer",
    "ConfigReducer",
    "DealDetailsSurveyReducer",
    "SelectedFileMoveStorageReducer",
    "ErrorReducer",
    "ProfileReducer",
    "LandingReducer",
    "FileTasksReducer",
  ],
};

const persistedReducer = persistReducer(persistConfig, appReducer);

const rootReducer = (
  state: ReturnType<typeof persistedReducer> | undefined,
  action: PayloadAction<string>,
) => persistedReducer(state, action);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(testApi.middleware)
      .concat(dealInfoApi.middleware)
      .concat(configDealNumbersAPI.middleware)
      .concat(calendarApi.middleware)
      .concat(profileApi.middleware)
      .concat(lendingApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const persistor = persistStore(store);

export {store, persistor};
