import { H1 } from "components/CommonDashboard/Typography";
import { FC, useEffect, useState } from "react";
import { Deal } from "types/deal";
import { styled } from "@mui/material/styles";
import Colors from "components/CommonDashboard/Styling/Colors";
import DealCard from "./DealCard";
import Stack from "@mui/material/Stack";
import CircularLoader from "components/Common/Loader/CircularLoader";
import Pagination from "@mui/material/Pagination";
import useGetDeals from "customerPortal/hooks/useGetDeals";
import { BODY_SMALL } from "components/CommonDashboard/Styling/Styles";
import DealListEmptyState from "./DealListEmptyState";
import { DealFilter, DealToggleFilters } from "./DealRowFilters";

interface InitialDealsProps {
    deals: Deal[] | null
    total: number,
    perPage: number,
    loading?: boolean
}
interface DealListProps {
    initialActiveDeals: InitialDealsProps,
    initialCloseDeals: InitialDealsProps,
}

const DealList: FC<DealListProps> = ({ initialActiveDeals, initialCloseDeals }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [propFilter, setPropFilter] = useState<{ onboarding: boolean } | { showClosed: boolean }>({ onboarding: true });
    const [currInitialDeals, setCurrInitialDeals] = useState<InitialDealsProps>(initialActiveDeals);

    //Handle page changes
    const current = useGetDeals(true, currentPage, propFilter, '', 'id', currInitialDeals);

    const [selectedFilter, setSelectedFilter] = useState<keyof typeof DealFilter>(DealFilter.Active);
    useEffect(() => {
        setCurrentPage(0);
        if (selectedFilter === 'Active') {
            setPropFilter({ onboarding: true });
            return setCurrInitialDeals(initialActiveDeals);
        }

        setPropFilter({ showClosed: true });
        setCurrInitialDeals(initialCloseDeals);
    }, [selectedFilter])

    if (current.loading || current.deals === null) {
        return <CircularLoader containerHeight={"70vh"} />
    }
    
    return <>
        <H1 color={Colors.BLACK} data-testid="my-transactions-title">My transactions</H1>
        <DealToggleFilters
            selectedFilter={selectedFilter}
            handleFilter={(e, value) => value ? setSelectedFilter(value) : null}
            totalActive={initialActiveDeals.total}
            totalClosed={initialCloseDeals.total}
        />

        <DealCardList currentDeals={current} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </>
}
export default DealList;

interface DealCardListInterface {
    currentDeals: InitialDealsProps,
    currentPage: number,
    setCurrentPage: (val: number) => void
}

const DealCardList = ({ currentDeals, currentPage, setCurrentPage }: DealCardListInterface) => {
    if (!currentDeals?.deals || currentDeals?.deals.length === 0)
        return <DealListEmptyState />;

    return <>
        <DealsContainer>
            {currentDeals.deals && currentDeals.deals.map((deal) => <DealCard key={deal.id} deal={deal} />)}
        </DealsContainer>
        {Math.ceil(currentDeals.total / currentDeals.perPage) > 1 && (
            <DealsPagination
                count={Math.ceil(currentDeals.total / currentDeals.perPage)}
                variant="outlined"
                shape="rounded"
                page={currentPage || 1}
                onChange={(event, val) => {
                    if (!currentDeals.loading && val !== currentPage) {
                        setCurrentPage(val);
                    }
                }}
            />
        )}
    </>
}


const DealsContainer = styled(Stack)(({ theme }) => ({
    gap: '2.4rem',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'left',
    [theme.breakpoints.down("mobile")]: {
        flexDirection: 'column',
        flexWrap: 'no wrap',
    },
}));

const DealsPagination = styled(Pagination)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiPaginationItem-root": {
        ...BODY_SMALL
    },
    "& .Mui-selected": {
        backgroundColor: Colors.PRIMARY,
        border: "none",
    },
}));