import React, {FormEvent, useEffect} from "react";
import {Box, useTheme} from "@mui/material";
import SimpliiBanner from "./SimpliiBanner";
import SimpliiForm from "./SimpliiForm";
import {useGetSlugForPartnersBankQuery} from "../../../redux/reducers/landingApi";
import {useParams} from "react-router-dom";
import DeededLoader from "../../Svg/DeededLoader";
import NotFound from "../../Dashboard/404/NotFound";

const Simplii = () => {
  const params = useParams() as {slug: string};
  const {data, isLoading, refetch} = useGetSlugForPartnersBankQuery(
    params.slug,
  );
  const theme = useTheme();
  useEffect(() => {
    refetch();
  }, [refetch]);
  return (
    <>
      {isLoading ? (
        <DeededLoader />
      ) : (
        <>
          {!!data ? (
            <Box
              sx={{
                overflowY: "scroll",
                paddingBottom: "80px",
                [theme.breakpoints.down("md")]: {
                  paddingBottom: "56px",
                },
                "&::-webkit-scrollbar": {
                  width: "0px",
                },
              }}
            >
              <SimpliiBanner />
              <SimpliiForm />
            </Box>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
};

export default Simplii;
export const colors = {
  banner: "#f8f7f7",
  fromTitle: "#463239",
}