import { FC, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { createLead } from "api";
import configuration from "utils/configuration";
import { QuoteResult, QuoteTypes } from "types/quote";
import { quoteSurveyDataInterface, SurveyQuote } from "./components/SurveyQuote";
import QuoteSuccess from "./components/QuoteSuccess";
import { BodyRegular, H1, H3, LinkLarge } from "components/CommonDashboard/Typography";
import Loader from "./components/Loader";
import Colors from "components/CommonDashboard/Styling/Colors";
import { AlertIcon, HomeIcon } from "components/CommonDashboard/Icons";
import Button from "components/CommonDashboard/Button";
import useScrollTopEffect from "utils/hooks/useScrollTopEffect";

type QuoteStep = "Survey" | "Processing" | "Success" | "ThankYou" | "Error";
type ClientInfoType = {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
}

const QuotePanel: FC = () => {
    const history = useHistory();
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [step, setStep] = useState<QuoteStep>("Survey");
    const [prevQuote, setPrevQuote] = useState<QuoteResult>();
    const [currentQuote, setCurrentQuote] = useState<QuoteResult>();
    const [clientInfo, setClientInfo] = useState<ClientInfoType>();

    const submitForm = async (surveyData: Partial<quoteSurveyDataInterface>) => {
        const json = map(surveyData, query.get("referral") || null);
        setClientInfo(json.client);
        setStep("Processing");

        try {
            if (surveyData.nameH) {
                throw new Error('Failed validation');
            }

            const res = await createLead(json);
            setCurrentQuote({ ...res.data, input: json.quote, deal_sub_type: json.deal_sub_type });
            setStep("Success");
        } catch (e) {
            enqueueSnackbar(
                "There was an error submitting the form. Please try again. If the problem continues, contact us",
                {
                    variant: "error",
                    autoHideDuration: configuration.autoHideErrorDuration,
                },
            );
            setStep("Error");
        }
    };

    const handleSuccessNextStep = () => {
        if (["Sale", "Purchase", "Mortgage"].includes(currentQuote?.deal_type || "")) {
            return void history.replace(`/information/my-quote/upload/${currentQuote?.uuid}`);
        }

        // TODO implement behavior for other quote deal types
        console.warn("quote deal type not implemented");
    };

    const newDealType = ["Purchase", "Sale"].includes(prevQuote?.deal_type || "")
        ? prevQuote?.deal_type === "Sale"
            ? "purchase"
            : "sale"
        : undefined;

    const renderTitle = useMemo(() => {
        switch (step) {
            case "Success":
                return 'Your Quote';
            case "Survey":
            case "Processing":
            case "Error":
            default:
                return 'Get Your Quote';
        }
    }, [step]);

    const renderedStep = useMemo(() => {
        switch (step) {
            case "Survey":
                return (
                    <SurveyQuote
                        submitForm={submitForm}
                        referral={query.get("referral")}
                        dealType={newDealType || query.get("deal_type")}
                        userInfo={prevQuote?.contact}
                    />
                );
            case "Processing":
                return (
                    <StatusContainer>
                        <Loader />
                        <BodyRegular color={Colors.NEUTRAL_600}>Loading</BodyRegular>
                    </StatusContainer>
                );
            case "Success":
                return (
                    <QuoteSuccess
                        clientName={clientInfo?.first_name}
                        quote={currentQuote!}
                        onNextStep={handleSuccessNextStep}
                    />
                );
            case "Error":
            default:
                return (
                    <StatusContainer>
                        <Stack sx={{ backgroundColor: Colors.PRIMARY_100, padding: '2.4rem', borderRadius: '50%' }}>
                            <AlertIcon size={32} />
                        </Stack>
                        <H3 color={Colors.NEUTRAL_700}>We have encountered a problem</H3>
                        <Stack gap='1rem' alignItems='center'>
                            <BodyRegular color={Colors.NEUTRAL_600}>We are sorry, our system is currently having an issue receiving your information.</BodyRegular>
                            <BodyRegular color={Colors.NEUTRAL_600}>Please try again later or contact us at <LinkLarge component="a" href="mailto:hello@deeded.ca" color={Colors.PRIMARY_600}>hello@deeded.ca.</LinkLarge></BodyRegular>
                        </Stack>
                        <Button component='a' href={query.get("referral") ? `/partners/${query.get("referral")}` : configuration.webPageUrl}
                            typeOf="CTA" endIcon={<HomeIcon color={Colors.WHITE} />} label={{ text: "Back to home" }} />
                    </StatusContainer>
                );
        }
    }, [step]);

    useScrollTopEffect([step]);

    return (
        <>
            <H1 data-testid="title">{renderTitle}</H1>
            <Box display="flex" justifyContent="space-around" alignContent="center" height={'100%'}>
                <Box
                    className="quote_survey"
                    sx={{
                        [theme.breakpoints.up("xs")]: { width: "calc(100vw - 2rem)" }
                    }}
                >
                    {renderedStep}
                </Box>
            </Box>
        </>
    );
};

const map = (surveyData: Partial<quoteSurveyDataInterface>, referral: string | null) => {
    const { TitleTrn, ILA, Mortgage } = QuoteTypes;
    let deal_type = surveyData.dealType,
        deal_sub_type;
    if (surveyData.dealType === TitleTrn || surveyData.dealType === ILA) {
        deal_type = Mortgage;
        deal_sub_type = surveyData.dealType;
    }
    return {
        deal_type,
        deal_sub_type,
        closing_date: surveyData.closingDate,
        hcaptcha_token: surveyData.formCaptcha,
        address: {
            address: surveyData.address,
            city: surveyData.city,
            code: surveyData.postalCode,
            state: surveyData.province,
        },
        client: {
            first_name: surveyData.firstName,
            last_name: surveyData.lastName,
            email: surveyData.email,
            phone: surveyData.phone,
        },
        referral: {
            channel: surveyData["referralChannel-Comment"]
                ? 'Referral Channel: ' + surveyData["referralChannel-Comment"]
                : surveyData.referralChannel,
            referrer_name: surveyData.referrerName,
        },
        referring_slug: referral,
        quote: {
            mortgage: surveyData.mortgageAmount || surveyData.mortgageValue,
            price: surveyData.sellingPrice || surveyData.purchasePrice,
            is_first_time_homebuyer: surveyData.isFirstTimeHomeBuyer,
            has_mortgage: surveyData.hasMortgage,
        },
    };
};

export const StatusContainer = styled(Stack)(({ theme }) => ({
    justifyContent: 'center',
    padding: '4.8rem',
    backgroundColor: Colors.WHITE,
    borderRadius: '2.4rem',
    [theme.breakpoints.down("mobile")]: {
        minHeight: '50vh',
        padding: '4rem'
    },
    gap: '3.2rem',
    alignItems: 'center'
}));


export default QuotePanel;
