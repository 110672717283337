
import { useClientOnboarding } from "../../context/ClientOnboardingProvider";
import PortalContainer from "components/CommonDashboard/Container/PortalContainer";
import MyTeam from "customerPortal/components/MyTeam";
import TermsImg from "components/Svg/TermsImg";
import Retainer from "./Retainer";


const RetainerContainer = () => {
    const [{ deal: { deal, onboarding } }] = useClientOnboarding();

    if (!deal) return <></>;

    return <PortalContainer
        leftPanel={<Retainer deal={deal} onboarding={onboarding} />}
        rightPanel={<MyTeam
            deal={deal}
            MainImage={<TermsImg />}
        />}
    />
}
export default RetainerContainer;
