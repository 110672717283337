import {FC} from "react";
import {useHistory} from "react-router-dom";
import Colors from "components/CommonDashboard/Styling/Colors";
import {BodyRegular, H2} from "components/CommonDashboard/Typography";
import {useClientOnboarding} from "../../context/ClientOnboardingProvider";
import DealInfoHeader from "../../components/DealInfoHeader";
import useOnboardingFiles, {FilesFilterTypes} from "../../hooks/useOnboardingFiles";
import {pages} from "../paths";
import FilesList from "./FilesList";
import FilesFilter from "./FilesFilter";

const FilesContent: FC = () => {
  const history = useHistory();
  const [{deal: {deal}}] = useClientOnboarding();
  const [files, isLoading, onUpload, handleDelete, filter] = useOnboardingFiles();

  const handleBack = () => {
    history.push(pages.DEAL_STATUS.route(deal?.id));
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <DealInfoHeader deal={deal} onBack={handleBack} />
      <H2>
        Documents required for closing
      </H2>
      <BodyRegular color={Colors.DARK_GREY} data-testid="file-uploads-title">
        To smoothly complete your transaction, we need the specified documents.
        Please upload them promptly and check back periodically in case we
        require any additional documents throughout the closing process.
      </BodyRegular>
      <FilesFilter {...filter} />
      <FilesList
        files={files}
        onUpload={onUpload}
        onDelete={handleDelete}
        selectedFilter={filter.selectedFilter}
        setSelectedFilter={filter.setSelectedFilter}
      />
    </>
  );
};

export default FilesContent;
