import {FC, useEffect, useState} from "react";
import {Box} from "@mui/material";
import {pages} from "../paths";
import {SurveyQuestions} from "./SurveyQuestions";
import CircularLoader from "components/Common/Loader/CircularLoader";
import {OnboardingStates, State} from "types/intakeForm";
import useUpdateIntakeStage from "../../hooks/useUpdateIntakeStage";
import {useClientOnboarding} from "../../context/ClientOnboardingProvider";

const Survey: FC = () => {
  const [{deal: {onboarding, deal}}] = useClientOnboarding();
  const [updateIntakeStage] = useUpdateIntakeStage();

  const [questions, setQuestions] = useState<Object | null>(null);
  const [savedData, setSavedData] = useState<Object | null>(null);
  const [intake, setIntake] = useState<{id: number} | null>(null);

  const handleNextButton = () => {
    let state: State = OnboardingStates.Completed;
    updateIntakeStage && updateIntakeStage(state, pages.DEAL_STATUS.route, (deal?.id || '').toString())();
  };

  useEffect(() => {
    if (onboarding) {
      const intake =
        onboarding.intakes.length > 0 ? onboarding.intakes[0] : null;
      intake && setQuestions(intake.form_schema);
      intake?.content && setSavedData(intake.content);
      intake && setIntake(intake);
    }
  }, [onboarding?.id]);

  return questions ? (
    <>
      <Box className={"portal_questions_wrapper"} sx={{maxWidth: "128rem"}}>
        <SurveyQuestions
          questions={questions}
          intakeId={intake?.id || 0}
          savedData={savedData}
          handleSubmit={handleNextButton}
          transactionType={deal?.deal_type}
        />
      </Box>
    </>
  ) : (
    <CircularLoader containerHeight={"70vh"} />
  );
};

export default Survey;
