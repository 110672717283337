import {FC} from "react";
import PortalContainer from "components/CommonDashboard/Container/PortalContainer";
import UserProfileSecurity from "components/Svg/UserProfileSecurity";
import SideImage from "../../components/SideImage";
import SecurityContent from "./SecurityContent";

const UserSecurity: FC = () => {
    return <PortalContainer
        leftPanel={<SecurityContent />}
        rightPanel={<SideImage MainImage={<UserProfileSecurity />} />}
        hideRightPanelOnMobile
    />
}

export default UserSecurity;
