import { Wrapper } from "components/Common/Wrapper/Wrapper";
import { useMemo } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import useClientTokenVerification from "customerPortal/hooks/useClientTokenVerification";
import { useCustomerPortal } from "customerPortal/context/CustomerPortalProvider";
import CircularLoader from "components/Common/Loader/CircularLoader";
import { ONBOARDING_PATH, pages } from "../paths";
import Colors from "components/Common/componentStyling/Colors";
import OnboardingBaseWrapper from "../OnboardingBase/OnboardingBaseWrapper";
import PortalHeaderMenu from "customerPortal/components/PortalHeaderMenu";
import NextSteps from "../NextSteps";
import UserSettings from "../UserSettings";
import UserSecurity from "../UserSecurity";
import DealsView from "../DealsView";
import useGetDeals from "customerPortal/hooks/useGetDeals";

export const PortalBase = () => {
  const [state,] = useCustomerPortal();
  const location = useLocation();

  // Redux and Legacy Storage Hooks
  useClientTokenVerification();
  const activeDeals = useGetDeals(true, 1, { onboarding: true });
  const closeDeals = useGetDeals(true, 1, { showClosed: true });

  const stageName = useMemo(() => {
    const currentPage = Object.values(pages).find((page) =>
      location.pathname.includes(page.route()),
    );
    return currentPage?.title || "";
  }, [location]);

  const LogOutButton = useMemo(() => state.authUser?.user ? (
    <PortalHeaderMenu firstName={state.authUser.user.first_name} deals={activeDeals.deals || []} />
  ) : (
    <></>
  ), [state.authUser?.user, activeDeals.deals]);

  return (
    <Wrapper
      stageName={stageName}
      headerProps={{ rightComponent: LogOutButton }}
      rootProps={{ color: Colors.RED_10 }}
    >
      {(state.authUser?.user && !activeDeals.loading && activeDeals.deals !== null) ? (
        <Switch>
          {/* TODO add route to show all user deals here */}
          <Route path={pages.DEAL_STATUS.route()} exact component={NextSteps} />
          <Route path={pages.USER_SETTINGS.route()} exact component={UserSettings} />
          <Route path={pages.USER_SECURITY.route()} exact component={UserSecurity} />
          <Route path={pages.DEALS.route()} exact component={() => <DealsView activeDeals={activeDeals} closeDeals={closeDeals} />} />
          <Route path={`${ONBOARDING_PATH}/:onboarding_token`} component={OnboardingBaseWrapper} />
        </Switch>
      ) : (
        <CircularLoader containerHeight={"70vh"} />
      )}
    </Wrapper>
  );
};
