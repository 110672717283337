import {Box, Typography, useTheme} from "@mui/material";
import React, {useContext} from "react";
import {FormContext} from "../Common/FormContext";
import constants from "../../../styles/constants";
import DeededRadioButton from "../../../v2/components/DeededRadioButton";
import FormField from "../Common/FormField";

const HasBrokerFields = () => {
  const theme = useTheme();
  const {values, handleChange} = useContext(FormContext);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "12px",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              color: constants.colors.title,
              marginRight: "24px",
              whiteSpace: "nowrap",
              [theme.breakpoints.down("md")]: {
                whiteSpace: "normal",
                textAlign: "center",
                width: "90%",
              },
              [theme.breakpoints.down("sm")]: {
                marginRight: "0px",
              },
            }}
          >
            Are you working with a mortgage broker or an Equitable Bank mortgage
            specialist?
          </Typography>
          <Box
            sx={{
              display: "flex",
              "& .MuiFormControlLabel-root": {
                "& .MuiRadio-root": {
                  span: {
                    " svg": {
                      width: "16px",
                      height: "16px",
                    },
                  },
                },
              },
            }}
          >
            <DeededRadioButton
              sx={{
                svg: {
                  height: "16px",
                  width: "16px",
                },
              }}
              onChange={() => {
                handleChange({
                  target: {
                    name: "hasBroker",
                    value: true,
                  },
                });
              }}
              checked={values.hasBroker}
              label={"Yes"}
            />
            <DeededRadioButton
              onChange={() => {
                handleChange({
                  target: {
                    name: "hasBroker",
                    value: false,
                  },
                });
              }}
              checked={!values.hasBroker}
              label={"No"}
            />
          </Box>
        </Box>
        {values.hasBroker && (
          <Box
            sx={{
              marginBottom: "16px",
              width: "634px",
              [theme.breakpoints.down("md")]: {
                width: "536px",
              },
              [theme.breakpoints.down("sm")]: {
                width: "90%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "24px",
                [theme.breakpoints.down("sm")]: {
                  marginBottom: "18px",
                },
              }}
            >
              <FormField
                name={"brokerFirstName"}
                label={"Broker First Name"}
              />
              <FormField
                name={"brokerLastName"}
                label={"Broker Last Name"}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                },
              }}
            >
              <FormField
                name={"brokerEmailAddress"}
                label={"Broker Email"}
              />
              <FormField
                name={"brokerPhoneNumber"}
                label={"Broker Phone Number"}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
export default HasBrokerFields;
