import { FunctionFactory } from "survey-core";
import { parseISO, isWeekend } from "date-fns";

function isWeekday (params: any[]): any {
    if (!params) return false;

    const [ date ] = params;
    return !isWeekend(parseISO(date));
}

export const registerCustomFunctions = () => {
    FunctionFactory.Instance.register("isWeekday", isWeekday);
}