import { ClientOnboarding, IntakeRequest } from "types/intakeForm";
import {SET_DEAL_DATA, SET_DEAL_DOCUMENTS, SET_ONBOARDING_DATA, SET_ONBOARDING_LOADING} from "../actionTypes";
import { Deal } from "types/deal";

export type DealState = {
    deal: Deal | null,
    request: IntakeRequest | null,
    onboarding: ClientOnboarding | null,
    loading: boolean
}

export const defaultDealState: DealState = {
    deal: null,
    request: null,
    onboarding: null,
    loading: false,
}

export const dealReducer = (state: DealState, action: Record<string, any>): DealState => {
    const { type, payload } = action;
    switch (type) {
        case SET_ONBOARDING_DATA:
            return { ...state, request: payload?.onboarding, loading: false };
        case SET_ONBOARDING_LOADING:
            return { ...state, loading: payload };
        case SET_DEAL_DATA:
            return { ...state, deal: payload?.deal, request: payload?.intake_request, onboarding: payload?.onboarding };
        case SET_DEAL_DOCUMENTS:
            return state.deal ? { ...state, deal: { ...state.deal, agreements: payload } } : state;
        default:
            return state;
    }
}