import {FC} from "react";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import {H2, H3} from "components/CommonDashboard/Typography";
import TextField from "components/CommonDashboard/TextField";
import CheckboxField from "components/CommonDashboard/CheckboxField";
import SelectField from "components/CommonDashboard/SelectField";
import Button from "components/CommonDashboard/Button";
import {SaveIcon} from "components/CommonDashboard/Icons";
import {PhoneFormat} from "components/CommonDashboard/TextField/TextFieldFormatters";
import useUserSettingsForm from "../../hooks/useUserSettingsForm";
import {provincesByTimezone} from "../../../utils/reusableSelect";
import Colors from "../../../components/CommonDashboard/Styling/Colors";

const SettingsContent: FC = () => {
  const {values, controlFor, handleComm, handleSubmit, isSubmitting} = useUserSettingsForm();

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap="2.4rem" maxWidth="lg">
        <H2>Settings</H2>

        <H3>Personal Information</H3>
        <InputRow>
          <TextField {...controlFor("first_name")} label="First name" />
          <TextField
            {...controlFor("middle_name")}
            label="Middle name"
            observation="(optional)"
          />
          <TextField {...controlFor("last_name")} label="Last name" />
        </InputRow>

        <InputRow>
          <TextField {...controlFor("email")} label="Email" type="email" />
          <TextField
            {...controlFor("phone")}
            label="Phone number"
            InputProps={{
              inputComponent: PhoneFormat as any,
            }}
          />
          <SelectField
            {...controlFor("timezone")}
            label="Province"
            options={provincesByTimezone}
          />
        </InputRow>

        <H3>Notifications</H3>
        <Stack direction="row" gap="2.4rem">
          <CheckboxField
            onChange={(_e, chk) => handleComm("Email", chk)}
            checked={values.communication_method?.includes("Email")}
            label="Email"
          />
          <CheckboxField
            onChange={(_e, chk) => handleComm("Text", chk)}
            checked={values.communication_method?.includes("Text")}
            label="Text"
          />
        </Stack>

        <ButtonRow>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            type="submit"
            label={{text: "Save changes"}}
            endIcon={<SaveIcon color={Colors.WHITE} />}
            typeOf="CTA"
          />
        </ButtonRow>
      </Stack>
    </form>
  );
};

const InputRow = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "2.4rem",
  alignItems: "end",
  [theme.breakpoints.down("mobile")]: {
    flexDirection: "column",
    alignItems: "initial",
  }
}));

const ButtonRow = styled(Stack)(({ theme }) => ({
  marginTop: "2.4rem",
  alignItems: "end",
  [theme.breakpoints.down("mobile")]: {
    marginTop: 0,
    alignItems: "initial",
  }
}));

export default SettingsContent;
