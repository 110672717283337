import React from "react";
import {withSnackbar} from "utils/withSnackbar";
import {useRouteMatch} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {setAnswerTwo} from "../../../redux/actions/survey";
import "./SurveyQuestion.scss";
import Buttons from "../Buttons/Buttons";

const SurveyQuestion2 = () => {
  let match = useRouteMatch();
  const dispatch = useDispatch();
  const {answerTwo} = useSelector((state) => state.SurveyReducer);
  return (
    <div className="step__container">
      <div className="forwarding">
        <div className="flex">
          <h2 className="title">What could we do differently?</h2>
        </div>
        <div className="address__container">
          <div className="address__wrapper" style={{}}>
            <label htmlFor="address">
              <textarea
                style={{minHeight: "350px"}}
                value={answerTwo ?? ""}
                onChange={(e) => {
                  if (
                    e.target.value.length > 300 ||
                    e.target.value.split(" ").some((el) => el.length > 25)
                  )
                    return false;
                  dispatch(setAnswerTwo(e.target.value));
                }}
                name="address"
                placeholder="Your answer"
              ></textarea>
            </label>
          </div>
        </div>
      </div>
      <Buttons
        backBtn={false}
        back={`/survey/question1/${match.params.id}`}
        next={`/survey/question3/${match.params.id}`}
      />
    </div>
  );
};

export default withSnackbar(SurveyQuestion2);
