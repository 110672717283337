import { Stack, styled } from "@mui/material";
import Button, { ButtonProps } from "components/CommonDashboard/Button";
import Colors from "components/CommonDashboard/Styling/Colors";
import { BodySmall, H3, Overline } from "components/CommonDashboard/Typography";
import { useHistory } from "react-router-dom";
import { Deal, StageNumber } from "types/deal";
import { pages } from "../paths";


interface DealCardInterface {
    deal: Deal
}

const isAClosedDeal = (deal: Deal) => deal?.deal_stage?.number === StageNumber.Closed || deal?.deal_stage?.number === StageNumber.Survey;


const DealCard = ({ deal }: DealCardInterface) => {
    const history = useHistory();

    const handleClickOnDeal = (deal: Deal) => history.push(pages.DEAL_STATUS.route(deal.id));

    const labelProps: Pick<ButtonProps, 'label' | 'typeOf'> = !isAClosedDeal(deal) ? { label: { text: 'View details' }, typeOf: 'primary' } : { label: { text: 'View details' }, typeOf: 'secondary' };
    const isDisabled =  isAClosedDeal(deal) && (deal.client_onboardings || []).length === 0;

    return <DealCardContainer data-testid="deal-card">
        <Stack gap={'1.6rem'} alignItems={'center'}>
            <Stack gap='0.8rem' sx={{ backgroundColor: Colors.CORAL_50, padding: '.8rem', borderRadius: '3.6rem' }} flexDirection={'row'} alignItems='center'>
                <Overline data-testid="deal-type">{deal.deal_type}</Overline>
            </Stack>
            <Stack gap=".4rem">
                <H3 data-testid="deal-address-street" color={Colors.BLACK} textAlign='center'>{deal.address.address}</H3>
                <BodySmall data-testid="deal-address-details" color={Colors.DARK_GREY} textAlign='center'>{deal.address.city}, {deal.address.state} {deal.address.code}</BodySmall>
            </Stack>
        </Stack>
        <Button data-testid="see-status-button" disabled={isDisabled} fullWidth {...labelProps} onClick={() => handleClickOnDeal(deal)} />
    </DealCardContainer>
}

const DealCardContainer = styled(Stack)(({ theme }) => ({
    padding: '2.4rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1.6rem',
    width: '25.5rem',
    borderRadius: '1.6rem',
    backgroundColor: Colors.WHITE,
    alignSelf: 'stretch',
    [theme.breakpoints.down("mobile")]: {
        width: 'auto',
        flex: '1 0 0',
    },
}));

export default DealCard;
