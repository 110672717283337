import React from "react";

const CustomerPortalFiles = () => {
  return (
    <svg width="100%" height="316" viewBox="0 0 401 316" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_70_9120)">
        <path d="M73.4304 37.2043C72.6789 37.2872 71.9546 37.5339 71.3087 37.927C70.6628 38.3202 70.111 38.8501 69.6921 39.4796C69.2732 40.109 68.9973 40.8227 68.8841 41.5703C68.7708 42.3179 68.8228 43.0812 69.0364 43.8065L54.0823 54.4925L62.9961 57.9229L75.8303 47.2493C77.025 46.8097 78.0169 45.947 78.618 44.8249C79.2192 43.7027 79.3879 42.3991 79.0921 41.1609C78.7962 39.9227 78.0565 38.836 77.013 38.1068C75.9696 37.3776 74.6949 37.0565 73.4304 37.2043Z" fill="#FFB8B8" />
        <path d="M72.0398 53.3002L53.4905 71.9642C50.9484 74.5055 47.5963 76.076 44.0167 76.4027L21.096 78.494C20.2557 78.5741 19.4078 78.4851 18.6026 78.232C17.7973 77.9789 17.0509 77.567 16.4075 77.0206C15.5174 76.2756 14.8556 75.2946 14.4979 74.1904C14.1403 73.0861 14.1014 71.9034 14.3857 70.778C14.6701 69.6526 15.2661 68.6303 16.1053 67.8285C16.9445 67.0266 17.9929 66.4777 19.1301 66.2449L42.5461 61.259L63.8647 45.5444C64.1611 45.3776 64.4959 45.2912 64.836 45.2937C65.1761 45.2963 65.5096 45.3877 65.8034 45.5588L71.601 50.3081C71.9498 50.4993 72.23 50.7948 72.4023 51.1533C72.5746 51.5118 72.6303 51.9153 72.5616 52.307C72.494 52.6839 72.3118 53.0307 72.0398 53.3002Z" fill="#2F2E41" />
        <path d="M294.433 63.3305C294.571 62.0564 294.313 60.7707 293.695 59.6481C293.077 58.5255 292.128 57.6205 290.978 57.0561L175.539 0.634552C174.804 0.275403 174.007 0.0643592 173.191 0.0134775C172.375 -0.0374042 171.558 0.0728681 170.785 0.338C170.012 0.603132 169.298 1.01793 168.686 1.55872C168.073 2.0995 167.573 2.75567 167.214 3.48977L167.211 3.4941L123.914 92.0806C123.192 93.5644 123.087 95.2742 123.623 96.8352C124.159 98.3961 125.292 99.6808 126.774 100.408L242.213 156.829C243.697 157.552 245.407 157.657 246.968 157.121C248.529 156.585 249.813 155.451 250.54 153.97L293.837 65.3832C294.153 64.7387 294.355 64.0441 294.433 63.3305Z" fill="#F2F2F2" />
        <path d="M226.804 259.949H77.0159C75.1301 259.947 73.3222 259.196 71.9887 257.863C70.6553 256.53 69.9052 254.722 69.9031 252.836V49.4921C69.9052 47.6063 70.6553 45.7983 71.9887 44.4649C73.3222 43.1314 75.1301 42.3813 77.0159 42.3792H226.804C228.69 42.3813 230.498 43.1314 231.831 44.4649C233.165 45.7983 233.915 47.6063 233.917 49.4921V252.836C233.915 254.722 233.165 256.53 231.831 257.863C230.498 259.196 228.69 259.947 226.804 259.949Z" fill="white" />
        <path d="M116.745 103.014C122.142 103.014 126.517 98.6392 126.517 93.2421C126.517 87.8449 122.142 83.4696 116.745 83.4696C111.348 83.4696 106.972 87.8449 106.972 93.2421C106.972 98.6392 111.348 103.014 116.745 103.014Z" fill="#E6E6E6" />
        <path d="M101.85 119.951C103.231 120.736 104.676 121.402 106.17 121.942L106.434 122.034C109.443 123.09 112.61 123.628 115.799 123.624C116.44 123.624 117.058 123.602 117.636 123.558C119.041 123.472 120.438 123.279 121.814 122.982C122.569 122.817 123.322 122.619 124.05 122.396C124.226 122.344 124.389 122.293 124.568 122.237C127.537 121.274 130.326 119.829 132.824 117.959C132.624 117.579 132.404 117.211 132.166 116.853C130.882 114.891 129.128 113.28 127.064 112.165C125 111.051 122.691 110.469 120.345 110.472H115.202C112.489 110.473 109.833 111.253 107.551 112.72C105.269 114.187 103.457 116.279 102.33 118.747C102.15 119.14 101.99 119.542 101.85 119.951Z" fill="#E6E6E6" />
        <path d="M120.061 122.739C119.733 122.763 119.417 122.874 119.146 123.06C120.524 122.974 121.895 122.784 123.245 122.491L120.061 122.739Z" fill="#2F2E41" />
        <path d="M117.338 66.7227C111.043 66.7234 104.926 68.8017 99.9338 72.6351C94.9418 76.4685 91.3549 81.8426 89.7298 87.9232C88.1047 94.0038 88.5323 100.451 90.9463 106.263C93.3602 112.076 97.6254 116.929 103.08 120.07C104.528 120.908 106.048 121.616 107.622 122.184L107.889 122.277C110.925 123.342 114.12 123.885 117.337 123.881C117.96 123.881 118.582 123.861 119.194 123.814C120.611 123.727 122.019 123.533 123.407 123.233C124.183 123.063 124.945 122.863 125.665 122.642C125.839 122.59 126.004 122.539 126.184 122.482C132.653 120.375 138.158 116.03 141.71 110.227C145.262 104.425 146.627 97.5457 145.561 90.8265C144.495 84.1072 141.067 77.9885 135.894 73.5703C130.721 69.1521 124.141 66.7241 117.338 66.7227ZM133.92 116.711C131.454 118.614 128.679 120.077 125.716 121.037C125.557 121.088 125.392 121.14 125.227 121.191C124.543 121.402 123.823 121.587 123.083 121.747C121.77 122.033 120.437 122.219 119.097 122.302C118.515 122.343 117.924 122.364 117.338 122.364C114.292 122.366 111.268 121.853 108.393 120.847L108.141 120.759C106.576 120.197 105.067 119.487 103.635 118.64C98.6493 115.705 94.7361 111.247 92.471 105.923C90.2059 100.599 89.7083 94.6881 91.0513 89.06C92.3943 83.4319 95.507 78.383 99.9322 74.6551C104.357 70.9272 109.862 68.7171 115.636 68.3493C121.41 67.9815 127.151 69.4756 132.013 72.6119C136.875 75.7483 140.603 80.3615 142.65 85.7738C144.696 91.186 144.952 97.1118 143.381 102.681C141.809 108.249 138.493 113.167 133.92 116.711Z" fill="#CCCCCC" />
        <path d="M226.804 259.949H77.0159C75.1301 259.947 73.3222 259.196 71.9887 257.863C70.6553 256.53 69.9052 254.722 69.9031 252.836V49.4921C69.9052 47.6063 70.6553 45.7983 71.9887 44.4649C73.3222 43.1314 75.1301 42.3813 77.0159 42.3792H226.804C228.69 42.3813 230.498 43.1314 231.831 44.4649C233.165 45.7983 233.915 47.6063 233.917 49.4921V252.836C233.915 254.722 233.165 256.53 231.831 257.863C230.498 259.196 228.69 259.947 226.804 259.949ZM77.0159 43.216C75.352 43.2179 73.7568 43.8797 72.5802 45.0563C71.4036 46.2329 70.7418 47.8281 70.7399 49.4921V252.836C70.7418 254.5 71.4036 256.095 72.5802 257.272C73.7568 258.448 75.352 259.11 77.0159 259.112H226.804C228.468 259.11 230.063 258.448 231.24 257.272C232.416 256.095 233.078 254.5 233.08 252.836V49.4921C233.078 47.8281 232.416 46.2329 231.24 45.0563C230.063 43.8797 228.468 43.2179 226.804 43.216L77.0159 43.216Z" fill="#CCCCCC" />
        <path d="M197.807 95.1917H155.422C154.533 95.1917 153.68 94.8384 153.051 94.2096C152.422 93.5807 152.069 92.7278 152.069 91.8385C152.069 90.9492 152.422 90.0964 153.051 89.4675C153.68 88.8387 154.533 88.4854 155.422 88.4854H197.807C198.696 88.4854 199.549 88.8387 200.178 89.4675C200.807 90.0964 201.16 90.9492 201.16 91.8385C201.16 92.7278 200.807 93.5807 200.178 94.2096C199.549 94.8384 198.696 95.1917 197.807 95.1917Z" fill="#E6E6E6" />
        <path d="M211.639 106.508H155.422C154.533 106.508 153.68 106.155 153.051 105.526C152.422 104.898 152.069 104.045 152.069 103.155C152.069 102.266 152.422 101.413 153.051 100.784C153.68 100.156 154.533 99.8022 155.422 99.8022H211.639C212.528 99.8022 213.381 100.156 214.01 100.784C214.639 101.413 214.992 102.266 214.992 103.155C214.992 104.045 214.639 104.898 214.01 105.526C213.381 106.155 212.528 106.508 211.639 106.508Z" fill="#E6E6E6" />
        <path d="M197.745 149.913H92.1815C91.2922 149.913 90.4393 149.56 89.8105 148.931C89.1816 148.302 88.8284 147.449 88.8284 146.56C88.8284 145.671 89.1816 144.818 89.8105 144.189C90.4393 143.56 91.2922 143.207 92.1815 143.207H197.745C198.635 143.207 199.487 143.56 200.116 144.189C200.745 144.818 201.098 145.671 201.098 146.56C201.098 147.449 200.745 148.302 200.116 148.931C199.487 149.56 198.635 149.913 197.745 149.913Z" fill="#E6E6E6" />
        <path d="M211.577 161.23H92.1815C91.2922 161.23 90.4393 160.877 89.8105 160.248C89.1816 159.619 88.8284 158.766 88.8284 157.877C88.8284 156.988 89.1816 156.135 89.8105 155.506C90.4393 154.877 91.2922 154.524 92.1815 154.524H211.577C212.466 154.524 213.319 154.877 213.948 155.506C214.577 156.135 214.93 156.988 214.93 157.877C214.93 158.766 214.577 159.619 213.948 160.248C213.319 160.877 212.466 161.23 211.577 161.23Z" fill="#E6E6E6" />
        <path d="M197.745 187.24H92.1815C91.2922 187.24 90.4393 186.887 89.8105 186.258C89.1816 185.629 88.8284 184.776 88.8284 183.887C88.8284 182.998 89.1816 182.145 89.8105 181.516C90.4393 180.887 91.2922 180.534 92.1815 180.534H197.745C198.635 180.534 199.487 180.887 200.116 181.516C200.745 182.145 201.098 182.998 201.098 183.887C201.098 184.776 200.745 185.629 200.116 186.258C199.487 186.887 198.635 187.24 197.745 187.24Z" fill="#E6E6E6" />
        <path d="M211.577 198.557H92.1815C91.2922 198.557 90.4393 198.204 89.8105 197.575C89.1816 196.946 88.8284 196.093 88.8284 195.204C88.8284 194.315 89.1816 193.462 89.8105 192.833C90.4393 192.204 91.2922 191.851 92.1815 191.851H211.577C212.466 191.851 213.319 192.204 213.948 192.833C214.577 193.462 214.93 194.315 214.93 195.204C214.93 196.093 214.577 196.946 213.948 197.575C213.319 198.204 212.466 198.557 211.577 198.557Z" fill="#E6E6E6" />
        <path d="M197.745 224.568H92.1815C91.2922 224.568 90.4393 224.214 89.8105 223.585C89.1816 222.957 88.8284 222.104 88.8284 221.214C88.8284 220.325 89.1816 219.472 89.8105 218.843C90.4393 218.215 91.2922 217.861 92.1815 217.861H197.745C198.635 217.861 199.487 218.215 200.116 218.843C200.745 219.472 201.098 220.325 201.098 221.214C201.098 222.104 200.745 222.957 200.116 223.585C199.487 224.214 198.635 224.568 197.745 224.568Z" fill="#E6E6E6" />
        <path d="M211.577 235.884H92.1815C91.2922 235.884 90.4393 235.531 89.8105 234.902C89.1816 234.273 88.8284 233.42 88.8284 232.531C88.8284 231.642 89.1816 230.789 89.8105 230.16C90.4393 229.531 91.2922 229.178 92.1815 229.178H211.577C212.465 229.179 213.317 229.533 213.945 230.162C214.573 230.79 214.926 231.643 214.926 232.531C214.926 233.42 214.573 234.272 213.945 234.901C213.317 235.529 212.465 235.883 211.577 235.884Z" fill="#E6E6E6" />
        <path d="M289.289 121.451C289.427 120.177 289.17 118.891 288.552 117.768C287.934 116.646 286.985 115.741 285.834 115.176L170.395 58.7549C169.661 58.3957 168.863 58.1847 168.048 58.1338C167.232 58.0829 166.414 58.1932 165.641 58.4583C164.868 58.7235 164.155 59.1383 163.542 59.679C162.929 60.2198 162.429 60.876 162.07 61.6101L162.068 61.6144L118.771 150.201C118.048 151.685 117.943 153.395 118.479 154.956C119.015 156.516 120.149 157.801 121.63 158.528L237.069 214.95C238.553 215.672 240.263 215.777 241.824 215.241C243.385 214.705 244.67 213.572 245.396 212.09L288.694 123.503C289.01 122.859 289.212 122.164 289.289 121.451Z" fill="white" />
        <path d="M289.289 121.451C289.427 120.177 289.17 118.891 288.552 117.768C287.934 116.646 286.985 115.741 285.834 115.176L170.395 58.7549C169.661 58.3957 168.863 58.1847 168.048 58.1338C167.232 58.0829 166.414 58.1932 165.641 58.4583C164.868 58.7235 164.155 59.1383 163.542 59.679C162.929 60.2198 162.429 60.876 162.07 61.6101L162.068 61.6144L118.771 150.201C118.048 151.685 117.943 153.395 118.479 154.956C119.015 156.516 120.149 157.801 121.63 158.528L237.069 214.95C238.553 215.672 240.263 215.777 241.824 215.241C243.385 214.705 244.67 213.572 245.396 212.09L288.694 123.503C289.01 122.859 289.212 122.164 289.289 121.451ZM244.738 211.768C244.097 213.076 242.964 214.076 241.586 214.549C240.209 215.022 238.7 214.929 237.391 214.291L121.952 157.87C120.644 157.229 119.644 156.095 119.171 154.718C118.698 153.341 118.791 151.832 119.429 150.523L162.726 61.9361C163.367 60.6284 164.501 59.6284 165.878 59.1554C167.255 58.6824 168.764 58.775 170.073 59.4129L285.512 115.834C286.82 116.476 287.82 117.609 288.293 118.987C288.766 120.364 288.674 121.873 288.036 123.182L244.738 211.768Z" fill="#3F3D56" />
        <path d="M276.698 125.479L246.465 187.336C246.264 187.749 245.906 188.065 245.472 188.214C245.037 188.364 244.561 188.334 244.148 188.133L139.14 136.81C139.065 136.774 138.993 136.731 138.926 136.683C138.581 136.449 138.333 136.098 138.226 135.696C138.119 135.293 138.161 134.865 138.344 134.491L168.577 72.634C168.677 72.4296 168.816 72.2468 168.987 72.0961C169.157 71.9454 169.356 71.8297 169.571 71.7557C169.786 71.6818 170.014 71.6509 170.241 71.6649C170.468 71.6789 170.69 71.7375 170.895 71.8374L275.902 123.16C276.315 123.362 276.631 123.719 276.78 124.154C276.93 124.589 276.9 125.065 276.699 125.478L276.698 125.479Z" fill="#E6E6E6" />
        <path d="M184.509 122.337C192.908 122.337 199.717 115.529 199.717 107.13C199.717 98.7314 192.908 91.9228 184.509 91.9228C176.111 91.9228 169.302 98.7314 169.302 107.13C169.302 115.529 176.111 122.337 184.509 122.337Z" fill="#F45753" />
        <path d="M206.776 170.174L139.751 137.415C139.666 137.375 139.585 137.328 139.51 137.273L198.047 97.8578C198.407 97.6104 198.827 97.4631 199.263 97.4311C199.699 97.399 200.136 97.4833 200.529 97.6754C200.922 97.8674 201.257 98.1603 201.499 98.5241C201.742 98.8879 201.884 99.3096 201.91 99.7461L205.175 146.992L205.332 149.254L206.776 170.174Z" fill="#3F3D56" />
        <path opacity="0.2" d="M206.776 170.174L183.892 158.989L203.101 148.163L204.484 147.383L205.175 146.992L205.333 149.254L206.776 170.174Z" fill="black" />
        <path d="M244.095 188.414L186.124 160.08L205.332 149.254L206.715 148.473L231.747 134.363C233.388 133.439 235.739 134.48 236.272 136.249C236.308 136.369 236.335 136.492 236.355 136.615L244.095 188.414Z" fill="#3F3D56" />
        <path d="M65.2264 193.813L71.5317 193.813L74.5318 169.491L65.2249 169.491L65.2264 193.813Z" fill="#FFB8B8" />
        <path d="M63.6179 191.755L76.036 191.754C77.0753 191.754 78.1049 191.959 79.0651 192.356C80.0252 192.754 80.8976 193.337 81.6325 194.072C82.3674 194.807 82.9503 195.679 83.348 196.639C83.7457 197.599 83.9504 198.629 83.9504 199.668V199.925L63.6183 199.926L63.6179 191.755Z" fill="#2F2E41" />
        <path d="M16.2136 189.798L22.7468 191.076L30.7849 166.485L21.143 164.599L16.2136 189.798Z" fill="#FFB8B8" />
        <path d="M14.9645 187.339L27.8304 189.856L27.8309 189.856C28.9077 190.067 29.9324 190.487 30.8465 191.094C31.7607 191.701 32.5464 192.481 33.1588 193.392C33.7712 194.302 34.1983 195.324 34.4157 196.4C34.6331 197.475 34.6366 198.583 34.4259 199.659L34.3738 199.926L13.3086 195.805L14.9645 187.339Z" fill="#2F2E41" />
        <path d="M74.4003 109.914C74.1868 109.188 73.8169 108.519 73.3165 107.952C72.8162 107.385 72.1977 106.934 71.5045 106.632C70.8113 106.33 70.0603 106.184 69.3044 106.204C68.5486 106.223 67.8062 106.408 67.1296 106.746L53.9861 93.8981L52.1726 103.275L64.932 114.038C65.5744 115.137 66.5976 115.963 67.8078 116.358C69.018 116.753 70.3311 116.69 71.4982 116.182C72.6653 115.673 73.6053 114.754 74.1402 113.599C74.6751 112.444 74.7676 111.133 74.4003 109.914Z" fill="#FFB8B8" />
        <path d="M25.6361 43.7841C32.613 43.7841 38.2688 38.1282 38.2688 31.1514C38.2688 24.1745 32.613 18.5187 25.6361 18.5187C18.6593 18.5187 13.0034 24.1745 13.0034 31.1514C13.0034 38.1282 18.6593 43.7841 25.6361 43.7841Z" fill="#FFB8B8" />
        <path d="M22.9836 43.9775C23.6547 44.2712 25.0292 38.9229 24.4135 37.8532C23.4979 36.2624 23.552 36.2738 22.9442 35.2213C22.3363 34.1688 22.2003 32.7283 22.9875 31.8022C23.7746 30.8761 25.5962 30.999 25.9554 32.1601C25.7243 29.9547 27.9057 28.1819 30.0665 27.6837C32.2273 27.1855 34.5065 27.4948 36.6792 27.0515C39.2007 26.537 41.8242 24.4234 40.8182 21.6506C40.6456 21.1889 40.3871 20.7641 40.0564 20.3986C38.8941 19.0917 37.2681 18.5337 35.6784 17.9941C32.3712 16.8716 28.9735 15.735 25.4831 15.8589C22.6072 16.0092 19.8376 16.9947 17.5131 18.6947C15.1886 20.3946 13.41 22.7353 12.3951 25.4303C12.1545 26.1045 11.9682 26.7969 11.8378 27.5007C10.6398 33.9424 14.3806 40.2964 20.4562 42.7494L22.9836 43.9775Z" fill="#2F2E41" />
        <path d="M19.882 113.374C19.5598 113.179 19.3016 112.895 19.1392 112.555C18.9767 112.215 18.9171 111.836 18.9676 111.462L13.2672 83.2245L7.48891 61.5529C6.85456 59.1783 7.04627 56.6585 8.03236 54.407C9.01846 52.1556 10.7405 50.3061 12.916 49.1621L16.736 45.5477L25.0695 49.138L29.0294 53.1643C31.8696 54.5472 34.389 56.5091 36.4258 58.9239C38.4625 61.3386 39.9715 64.1528 40.8557 67.1856L47.2069 88.9189L61.8745 110.741C62.1194 111.104 62.2339 111.54 62.1994 111.977C62.1649 112.414 61.9834 112.827 61.6845 113.148C59.644 115.343 19.882 113.374 19.882 113.374Z" fill="#2F2E41" />
        <path d="M58.3098 111.368L36.6814 96.3803C33.7336 94.3234 31.5995 91.2988 30.6499 87.8319L24.5704 65.6335C24.3441 64.8203 24.283 63.97 24.3909 63.1328C24.4988 62.2956 24.7734 61.4886 25.1985 60.7593C25.7758 59.7524 26.6254 58.9287 27.6498 58.3829C28.6742 57.8371 29.8318 57.5913 30.9896 57.6738C32.1474 57.7563 33.2584 58.1638 34.1951 58.8493C35.1317 59.5349 35.856 60.4708 36.2846 61.5494L45.3008 83.7278L64.5114 101.959C64.7276 102.222 64.8714 102.536 64.9285 102.871C64.9857 103.207 64.9542 103.551 64.8373 103.87L61.1786 110.411C61.0516 110.788 60.8098 111.116 60.4871 111.348C60.1643 111.581 59.7769 111.706 59.3792 111.708C58.9963 111.707 58.6228 111.589 58.3098 111.368Z" fill="#2F2E41" />
        <path d="M13.3771 186.034C13.2003 185.781 13.0871 185.488 13.047 185.182C13.0069 184.876 13.0412 184.564 13.1469 184.274L23.1527 147.9L23.8923 112.259L57.5745 109.949L57.738 109.938L67.8829 128.325C71.0591 134.073 73.0035 140.42 73.5922 146.962L76.9355 183.598C76.986 184.096 76.8391 184.594 76.5265 184.985C76.2139 185.376 75.7604 185.628 75.2635 185.688L65.1075 186.654C64.6182 186.714 64.125 186.58 63.733 186.281C63.3411 185.982 63.0815 185.542 63.0096 185.054L58.8856 147.825L44.0471 122.753L39.0662 149.148L28.7471 185.599C28.6122 185.963 28.3698 186.277 28.0521 186.5C27.7344 186.723 27.3565 186.844 26.9684 186.847L14.9328 186.852C14.6264 186.853 14.3243 186.779 14.053 186.637C13.7818 186.494 13.5497 186.287 13.3771 186.034Z" fill="#2F2E41" />
        <path d="M271.281 305.664H17.4732C12.9732 305.659 8.65894 303.869 5.47695 300.687C2.29496 297.505 0.505083 293.191 0.5 288.691V149.305C0.505083 144.805 2.29496 140.491 5.47695 137.309C8.65894 134.127 12.9732 132.337 17.4732 132.332H48.5921C52.8144 132.328 56.8858 133.902 60.0079 136.745L93.7545 160.842C95.9546 162.853 98.828 163.966 101.808 163.964H272.857C275.107 163.964 277.335 164.411 279.41 165.28C281.485 166.148 283.367 167.421 284.947 169.023C286.526 170.626 287.771 172.526 288.609 174.614C289.447 176.702 289.862 178.935 289.829 181.185L288.253 288.939C288.169 293.391 286.347 297.634 283.175 300.76C280.003 303.886 275.734 305.646 271.281 305.664Z" fill="white" />
        <path d="M269.027 297.435H18.9749C16.804 297.432 14.7228 296.569 13.1878 295.034C11.6528 293.499 10.7893 291.418 10.7869 289.247V147.582C10.7893 145.411 11.6528 143.33 13.1878 141.795C14.7228 140.26 16.804 139.396 18.9749 139.394C20.8949 139.396 22.7358 140.16 24.0935 141.517C25.4512 142.875 26.2149 144.716 26.217 146.636V279.474C26.2193 281.509 27.0286 283.459 28.4672 284.898C29.9057 286.336 31.8562 287.146 33.8907 287.148H268.182C269.346 287.135 270.481 287.512 271.405 288.22C272.33 288.927 272.99 289.924 273.282 291.05C273.609 292.119 273.568 293.266 273.167 294.309C272.765 295.352 272.026 296.23 271.067 296.803C270.459 297.199 269.752 297.418 269.027 297.435Z" fill="#F2F2F2" />
        <path d="M146.276 229.402L145.618 228.743C145.482 228.616 145.402 228.439 145.395 228.252C145.389 228.065 145.458 227.884 145.585 227.747C145.596 227.736 145.607 227.725 145.618 227.715L151.394 221.934C151.457 221.866 151.533 221.812 151.618 221.773C151.702 221.735 151.793 221.714 151.885 221.711C151.978 221.708 152.07 221.723 152.157 221.756C152.243 221.788 152.322 221.838 152.39 221.901C152.401 221.911 152.412 221.922 152.423 221.934L158.199 227.71C158.266 227.775 158.32 227.853 158.358 227.939C158.395 228.025 158.415 228.117 158.417 228.211C158.419 228.305 158.402 228.399 158.367 228.486C158.333 228.573 158.282 228.653 158.217 228.72L158.199 228.738L157.54 229.397C157.475 229.464 157.397 229.518 157.311 229.556C157.225 229.593 157.132 229.613 157.039 229.615C156.945 229.617 156.851 229.6 156.764 229.565C156.677 229.531 156.597 229.48 156.529 229.414L156.512 229.397L153.097 225.796V234.339C153.097 234.433 153.079 234.525 153.044 234.611C153.009 234.698 152.958 234.776 152.892 234.843C152.827 234.909 152.749 234.962 152.663 234.998C152.577 235.035 152.485 235.054 152.392 235.054L152.387 235.054H151.435C151.246 235.054 151.064 234.979 150.93 234.845C150.796 234.711 150.72 234.529 150.72 234.339V225.807L147.305 229.407C147.173 229.543 146.992 229.62 146.802 229.623C146.613 229.625 146.43 229.552 146.294 229.42L146.276 229.402Z" fill="#F45753" />
        <path d="M151.898 216.682C154.216 216.682 156.483 217.37 158.41 218.658C160.338 219.946 161.84 221.776 162.727 223.918C163.615 226.06 163.847 228.417 163.394 230.691C162.942 232.965 161.826 235.053 160.186 236.693C158.547 238.332 156.458 239.448 154.185 239.901C151.911 240.353 149.554 240.121 147.412 239.233C145.27 238.346 143.44 236.844 142.152 234.916C140.864 232.989 140.176 230.722 140.176 228.404C140.179 225.296 141.415 222.316 143.612 220.118C145.81 217.921 148.79 216.685 151.898 216.682ZM151.898 215.139C149.274 215.139 146.71 215.917 144.528 217.375C142.347 218.832 140.647 220.904 139.643 223.328C138.639 225.752 138.376 228.419 138.888 230.992C139.4 233.565 140.663 235.928 142.518 237.784C144.373 239.639 146.737 240.902 149.31 241.414C151.883 241.926 154.55 241.663 156.974 240.659C159.398 239.655 161.47 237.955 162.927 235.774C164.385 233.592 165.163 231.027 165.163 228.404C165.163 224.886 163.765 221.512 161.278 219.024C158.79 216.537 155.416 215.139 151.898 215.139Z" fill="#3F3D56" />
        <path d="M271.281 305.664H17.4732C12.9732 305.659 8.65894 303.869 5.47695 300.687C2.29496 297.505 0.505083 293.191 0.5 288.691V149.305C0.505083 144.805 2.29496 140.491 5.47695 137.309C8.65894 134.127 12.9732 132.337 17.4732 132.332H48.5921C52.8144 132.328 56.8858 133.902 60.0079 136.745L93.7545 160.842C95.9546 162.853 98.828 163.966 101.808 163.964H272.857C275.107 163.964 277.335 164.411 279.41 165.28C281.485 166.148 283.367 167.421 284.947 169.023C286.526 170.626 287.771 172.526 288.609 174.614C289.447 176.702 289.862 178.935 289.829 181.185L288.253 288.939C288.169 293.391 286.347 297.634 283.175 300.76C280.003 303.886 275.734 305.646 271.281 305.664ZM17.4732 133.361C13.2459 133.365 9.19316 135.047 6.20403 138.036C3.21489 141.025 1.53348 145.078 1.52868 149.305V288.691C1.53348 292.918 3.21489 296.971 6.20403 299.96C9.19316 302.949 13.2459 304.631 17.4732 304.635H271.281C275.465 304.618 279.475 302.965 282.454 300.028C285.434 297.092 287.146 293.106 287.224 288.924L288.8 181.17C288.831 179.057 288.442 176.958 287.654 174.997C286.867 173.036 285.697 171.25 284.214 169.745C282.73 168.24 280.962 167.044 279.013 166.228C277.063 165.413 274.971 164.992 272.857 164.992H101.808C98.5821 164.995 95.4709 163.794 93.0841 161.623L59.3376 137.525C56.4022 134.843 52.5686 133.357 48.5921 133.361H17.4732Z" fill="#3F3D56" />
        <path d="M292.791 308.378H286.486L284.515 284.056L292.792 284.056L292.791 308.378Z" fill="#FFB8B8" />
        <path d="M294.399 314.49L274.067 314.49V314.233C274.067 313.193 274.272 312.164 274.67 311.204C275.067 310.244 275.65 309.371 276.385 308.637C277.12 307.902 277.992 307.319 278.952 306.921C279.913 306.523 280.942 306.319 281.981 306.319L294.4 306.319L294.399 314.49Z" fill="#2F2E41" />
        <path d="M363.256 308.378H356.95L353.95 284.056L363.257 284.056L363.256 308.378Z" fill="#FFB8B8" />
        <path d="M364.864 314.49L344.532 314.49V314.233C344.532 313.193 344.736 312.164 345.134 311.204C345.532 310.244 346.115 309.371 346.85 308.637C347.584 307.902 348.457 307.319 349.417 306.921C350.377 306.523 351.406 306.319 352.446 306.319L364.864 306.319L364.864 314.49Z" fill="#2F2E41" />
        <path d="M342.522 233.189C343.392 232.169 343.883 230.879 343.91 229.538C343.938 228.197 343.5 226.888 342.672 225.833C342.504 225.631 342.32 225.443 342.121 225.272L346.87 207.737L338.955 210.363L335.658 226.612C335.111 227.614 334.869 228.754 334.962 229.892C335.055 231.03 335.479 232.115 336.181 233.015C336.549 233.501 337.021 233.898 337.562 234.176C338.104 234.455 338.701 234.608 339.31 234.625C339.919 234.642 340.523 234.521 341.079 234.273C341.635 234.024 342.128 233.654 342.522 233.189Z" fill="#FFB8B8" />
        <path d="M342.217 222.402C342.118 222.402 342.019 222.395 341.921 222.38L337.796 221.758C337.475 221.703 337.17 221.578 336.903 221.393C336.635 221.207 336.411 220.966 336.247 220.685C335.889 220.086 335.761 219.379 335.885 218.693L341.031 189.951L337.541 171.485C336.868 167.931 338.604 164.342 341.579 163.136C342.462 162.775 343.423 162.649 344.369 162.771C345.315 162.892 346.213 163.256 346.976 163.828C347.799 164.451 348.482 165.241 348.98 166.146C349.478 167.05 349.781 168.049 349.868 169.078L352.338 192.623L352.308 192.725L344.358 220.628C344.244 221.116 343.972 221.554 343.586 221.875C343.2 222.195 342.719 222.381 342.217 222.402Z" fill="#F45753" />
        <path d="M340.999 152.96C347.976 152.96 353.632 147.305 353.632 140.328C353.632 133.351 347.976 127.695 340.999 127.695C334.023 127.695 328.367 133.351 328.367 140.328C328.367 147.305 334.023 152.96 340.999 152.96Z" fill="#FFB8B8" />
        <path d="M334.531 199.05C334.217 199.05 333.905 198.992 333.611 198.879L313.644 191.199C313.104 190.993 312.649 190.61 312.354 190.112C312.059 189.615 311.94 189.032 312.018 188.459L312.82 182.445C312.345 180.312 312.388 178.097 312.948 175.986C313.507 173.874 314.566 171.928 316.035 170.311L316.521 169.776L322.428 159.004C322.828 158.274 323.369 157.631 324.019 157.111C324.669 156.591 325.416 156.205 326.216 155.975C327.016 155.745 327.853 155.676 328.68 155.771C329.507 155.867 330.307 156.125 331.034 156.531L341.465 162.361C342.839 163.127 343.875 164.379 344.372 165.871C345.775 170.095 345.671 174.675 344.077 178.831L336.935 197.402C336.746 197.886 336.416 198.302 335.987 198.596C335.558 198.89 335.051 199.048 334.531 199.05Z" fill="#F45753" />
        <path d="M282.204 302.406L282.177 302.401C281.836 302.335 281.512 302.202 281.225 302.008C280.937 301.814 280.692 301.564 280.504 301.273C280.315 300.981 280.188 300.655 280.128 300.313C280.069 299.972 280.08 299.622 280.16 299.284L295.46 232.4C297.966 221.797 301.426 211.443 305.8 201.464L312.309 186.622L336.6 197.711L340.068 204.055C342.772 211.119 344.162 218.618 344.17 226.183L368.852 298.515C368.972 298.868 369.014 299.243 368.975 299.615C368.936 299.986 368.817 300.344 368.626 300.664C368.434 300.985 368.176 301.259 367.867 301.47C367.559 301.68 367.209 301.821 366.841 301.882L353.33 301.73C353.202 301.752 353.073 301.763 352.943 301.762C352.431 301.763 351.931 301.609 351.507 301.322C351.083 301.034 350.755 300.626 350.566 300.15L322.353 227.018C322.312 226.921 322.243 226.838 322.155 226.781C322.067 226.724 321.963 226.696 321.858 226.699C321.755 226.701 321.655 226.734 321.572 226.795C321.489 226.855 321.427 226.94 321.394 227.037L294.623 300.533C294.418 301.104 294.016 301.584 293.49 301.887C292.963 302.189 292.346 302.295 291.749 302.184L282.204 302.406Z" fill="#2F2E41" />
        <path d="M300.675 220.155C301.388 219.902 302.036 219.495 302.574 218.964C303.113 218.433 303.528 217.791 303.791 217.082C304.054 216.373 304.159 215.615 304.097 214.861C304.036 214.108 303.81 213.377 303.435 212.72L315.534 198.884L306.07 197.593L296.032 210.93C294.97 211.633 294.203 212.7 293.876 213.93C293.548 215.161 293.684 216.468 294.256 217.605C294.829 218.742 295.798 219.63 296.981 220.1C298.164 220.57 299.479 220.589 300.675 220.155Z" fill="#FFB8B8" />
        <path d="M306.379 210.408C305.958 210.408 305.543 210.304 305.171 210.107L300.914 207.836C300.601 207.669 300.327 207.44 300.107 207.162C299.887 206.885 299.727 206.564 299.637 206.222C299.547 205.88 299.528 205.522 299.583 205.172C299.637 204.823 299.763 204.488 299.953 204.189L315.678 179.409L318.405 160.71C318.546 159.743 318.882 158.815 319.392 157.982C319.902 157.149 320.575 156.429 321.372 155.864C322.169 155.299 323.072 154.901 324.027 154.695C324.981 154.49 325.968 154.48 326.927 154.666C327.885 154.853 328.796 155.232 329.604 155.781C330.412 156.33 331.1 157.037 331.627 157.859C332.154 158.682 332.508 159.603 332.668 160.566C332.829 161.53 332.792 162.516 332.56 163.464L326.93 186.513L308.382 209.454C308.14 209.753 307.835 209.994 307.489 210.159C307.142 210.324 306.763 210.409 306.379 210.408Z" fill="#F45753" />
        <path d="M329.629 129.905C331.09 125.113 339.532 122.975 346.549 124.963C353.566 126.951 358.608 132.164 359.781 137.26C362.38 148.546 348.825 157.706 351.282 168.993C352.907 176.46 362.307 183.521 372.894 185.226C361.226 187.667 346.985 185.522 336.165 179.692C329.431 176.064 323.782 170.589 323.867 165.067C323.919 161.723 326.046 158.766 328.647 156.212C331.248 153.658 334.362 151.387 336.749 148.721C339.136 146.054 340.79 142.862 340.093 139.477C339.397 136.092 335.857 132.562 331.095 131.578L329.629 129.905Z" fill="#2F2E41" />
        <path d="M399.986 315.437H204.022C203.886 315.437 203.755 315.382 203.659 315.286C203.562 315.189 203.508 315.059 203.508 314.922C203.508 314.786 203.562 314.655 203.659 314.559C203.755 314.462 203.886 314.408 204.022 314.408H399.986C400.122 314.408 400.253 314.462 400.349 314.559C400.446 314.655 400.5 314.786 400.5 314.922C400.5 315.059 400.446 315.189 400.349 315.286C400.253 315.382 400.122 315.437 399.986 315.437Z" fill="#CCCCCC" />
      </g>
      <defs>
        <clipPath id="clip0_70_9120">
          <rect width="400" height="315.437" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomerPortalFiles;
