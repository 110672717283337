import {createContext, Dispatch, ReactNode, useContext, useReducer} from "react";
import {dealReducer, DealState, defaultDealState} from "./reducer/deal";

interface ClientOnboardingProviderProps {
  children?: ReactNode;
}

interface State {
  deal: DealState;
}

const initialState = {
  deal: defaultDealState,
};

export const ClientOnboardingContext = createContext([
  initialState,
  () => {},
] as [State, Dispatch<Record<string, any>>]);

export const useClientOnboarding = () => useContext(ClientOnboardingContext);

export const ClientOnboardingProvider = ({
  children,
}: ClientOnboardingProviderProps) => {
  const [state, dispatch] = useReducer(clientOnboardingReducer, initialState);

  return (
    <ClientOnboardingContext.Provider value={[state, dispatch]}>
      {children}
    </ClientOnboardingContext.Provider>
  );
};

export const clientOnboardingReducer = (
  state: State,
  action: Record<string, any>,
) => ({
  deal: dealReducer(state.deal, action),
});
