
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import AICPALogo from "assets/img/AICPALogo.png";
import DeededLogo from "assets/img/deeded.svg";
import { BodySmall, H3, SubtitleSmall } from "components/CommonDashboard/Typography";
import Button from "components/CommonDashboard/Button";
import Colors from "components/CommonDashboard/Styling/Colors";
import { FacebookIcon, IconProps, InstagramIcon, LinkedinIcon, MailIcon, PhoneIcon, XTwitterIcon } from "components/CommonDashboard/Icons";
import { webLink } from "utils/openLink";
import { kebabCase } from "lodash";


const Footer = () => {
    return <Stack sx={{ paddingY: '4.8rem', backgroundColor: 'white', gap: '12rem' }}>
        <NavigationContainer>
            {FooterLinks.map((section) => <Stack key={section.title} sx={{ gap: '1.6rem' }}>
                <H3 color={Colors.NEUTRAL_700}>{section.title}</H3>
                {section.list.map((item) => <Button
                    key={item.label}
                    component='a'
                    href={item?.link || webLink(kebabCase(item.label))}
                    typeOf="link"
                    label={{ text: item.label }}
                    startIcon={item?.icon && item.icon({ color: Colors.NEUTRAL_600 })}
                />)}
            </Stack>
            )}
        </NavigationContainer>
        <AdditionalContainer>
            <DisclaimerContainer>
                <img src={AICPALogo} alt="Logo" width={91} height={90} />
                <Stack sx={{ gap: '1.6rem' }}>
                    <BodySmall color={Colors.NEUTRAL_700}>
                        Deeded is not a law firm. All legal advice that is received through Deeded's platform is provided by lawyers/law firms who operate independently of Deeded Inc.
                    </BodySmall>
                    <SubtitleSmall color={Colors.NEUTRAL_700}>© 2024 Deeded Inc.</SubtitleSmall>
                </Stack>
                <img src={DeededLogo} width={136} height={25} alt="deeded"/>
            </DisclaimerContainer>
            <SocialContainer>
                {SocialLinks.map((item) =>
                    <Button
                        key={item.link}
                        component='a'
                        href={item.link}
                        target="_blank"
                        typeOf="primary"
                        startIcon={item.icon({ color: 'white' })}
                    />
                )}
            </SocialContainer>
        </AdditionalContainer>
    </Stack>;
}

const SocialContainer = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    gap: '2.4rem',
    [theme.breakpoints.down('mobile')]: {
        display: 'none'
    }
}));

const NavigationContainer = styled(Stack)(({ theme }) => ({
    justifyContent: 'space-around',
    flexDirection: 'row',
    alignSelf: 'center',
    width: '120rem',
    [theme.breakpoints.down('mobile')]: {
        display: 'none'
    }
}));

const DisclaimerContainer = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    gap: '3.7rem',
    maxWidth: '72.2rem',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
        alignItems: 'center',
    }
}));

const AdditionalContainer = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    width: '120rem',
    alignSelf: 'center',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& img[alt="deeded"]': {
        display: 'none'
    },
    [theme.breakpoints.down('mobile')]: {
        width: '100%',
        padding: '2.4rem 1.6rem',
        '& p': {
            textAlign: 'center',
        },
        '& img': {
            display: 'initial'
        },
    }
}));


const SocialLinks = [
    { link: 'https://www.instagram.com/deeded.ca/', icon: InstagramIcon },
    { link: 'https://www.facebook.com/getdeeded', icon: FacebookIcon },
    { link: 'https://twitter.com/getdeeded', icon: XTwitterIcon },
    { link: 'https://ca.linkedin.com/company/deeded', icon: LinkedinIcon },
];


type PageLink = {
    title: string,
    list: Array<{
        label: string,
        link?: string,
        icon?: (props: IconProps) => JSX.Element
    }>
}
const FooterLinks: PageLink[] = [
    {
        title: "Who We Help",
        list: [
            { label: 'Consumers', },
            { label: 'Mortgage Pros', },
            { label: 'Real Estate Agents', },
            { label: 'Lenders and Proptechs', },
            { label: 'Lawyers', },
        ]
    },
    {
        title: "Company",
        list: [
            { label: 'About Us', },
            { label: 'Careers', },
            { label: 'Blog', },
        ]
    },
    {
        title: "Help",
        list: [
            { label: 'Contact Us', },
            { label: 'Privacy Policy', },
            { label: 'Terms of Use', },
            { label: 'Cookie Policy', },
        ]
    },
    {
        title: "Get in touch",
        list: [
            { label: 'hello@deeded.ca', icon: MailIcon, link: "mailto:hello@deeded.ca" },
            { label: '(855) 456-4335', icon: PhoneIcon, link: "tel:(855)456-4335"},
            { label: '(647) 797-3439', icon: PhoneIcon , link: "tel:(647)797-3439"},
        ]
    }
]


export default Footer;