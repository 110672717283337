import {useEffect} from "react";
import {PortalBase} from "customerPortal/pages/PortalBase";
import {Route, Switch} from "react-router-dom";
import {CUSTOMER_PORTAL_PATH} from "./paths";
import NotFound from "components/Dashboard/404/NotFound";
import {useHistory} from "react-router-dom";
import {resolveJwtTokenVerified} from "utils/resolveJwtTokenVerified";
import {CustomerPortalProvider} from "../context/CustomerPortalProvider";

const CustomerPortal = () => {
  const history = useHistory();
  useEffect(() => {
    window.addEventListener("focus", (e) => {
      if (resolveJwtTokenVerified() === "expired") {
        history.replace("/login");
      }
    });
  }, [history]);

  return (
    <CustomerPortalProvider>
      <Switch>
        <Route path={CUSTOMER_PORTAL_PATH} component={PortalBase} />
        <Route component={NotFound} />
      </Switch>
    </CustomerPortalProvider>
  );
};
export default CustomerPortal;
