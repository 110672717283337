import Button from "components/CommonDashboard/Button";
import { ArrowRightIcon, GoogleIcon, IconWrapper, StarIcon } from "components/CommonDashboard/Icons";
import { BodyRegular, H2, H3 } from "components/CommonDashboard/Typography";
import { webLink } from "utils/openLink";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Colors from "components/CommonDashboard/Styling/Colors";

const ReviewsPanel = () => {
    return <RightPanelContainer>
        <H2>500+ 5-Star Reviews Can't be Wrong</H2>
        <ReviewsContainer>
            {reviews.map((review, idx) => <BoxReview key={idx} {...review} />)}
        </ReviewsContainer>
        <ButtonContainer>
            <Button
                component='a'
                href={webLink('reviews')}
                target="_blank"
                typeOf="link"
                label={{ text: "See more reviews" }}
                endIcon={<ArrowRightIcon color={Colors.NEUTRAL_700} />}
            />
        </ButtonContainer>
    </RightPanelContainer>
}

const ReviewsContainer = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    gap: '2.4rem',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column'
    }
}));

const RightPanelContainer = styled(Stack)(({ theme }) => ({
    gap: '2.4rem',
    maxWidth: '120rem',
    padding: '4.8rem',
    borderRadius: '2.4rem',
    backgroundColor: Colors.PRIMARY_100,
    [theme.breakpoints.down('mobile')]: {
        width: '100%',
        padding: '4.8rem 1.6rem',
    }
}));

const ButtonContainer = styled(Stack)(({ theme }) => ({
    maxWidth: '21.1rem',
    [theme.breakpoints.down('mobile')]: {
        maxWidth: 'initial',
    }
}));

interface BoxReviewInterface {
    reviewer: string,
    stars: number,
    review: string
}


const BoxReview = ({ reviewer, stars, review }: BoxReviewInterface) => {

    return <Stack sx={{ gap: '2.4rem', padding: '2.4rem', backgroundColor: Colors.WHITE, borderRadius: '2.4rem', boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.05)', flex: '1 1 0px' }}>
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: '1.6rem' }}>
            <IconWrapper><GoogleIcon /></IconWrapper>
            <H3 sx={{ color: Colors.NEUTRAL_700 }}>{reviewer}</H3>
        </Stack>
        <Stack sx={{ flexDirection: 'row', gap: '8px' }}>
            {(new Array(stars).fill(0).map((val, idx) => <StarIcon key={idx} color={Colors.WARNING_600} />))}
        </Stack>
        <BodyRegular
            sx={{
                overflow: 'hidden',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                color: Colors.NEUTRAL_600
            }}
        >
            {review}
        </BodyRegular>
    </Stack>
}


const reviews: BoxReviewInterface[] = [
    {
        reviewer: "Claudia Stevenson",
        stars: 5,
        review: `Deeded is the only way I would proceed when it comes to getting a mortgage, the process was easy, fast and was totally completed around our schedule, no taking a break from work or family events. We received quick answers when we had questions and the process was completed before we knew it!!`
    },
    {
        reviewer: "Kennedy Uba",
        stars: 5,
        review: `They're the best in the business in terms of ensuring your deal is appropriately closed and work is done properly. They always ensure high level of professionalism and are readily available to provide clarifications where needed.`
    },
    {
        reviewer: "Jamal Syed",
        stars: 5,
        review: `Very professional, knowledgeable and friendly staff. Everyone I have worked with in Deeded was very cooperative and helpful. Their follow up with the client is extremely high level. Highly recommend using Deeded.`
    }
]

export default ReviewsPanel;