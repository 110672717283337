import React from "react";
import {withSnackbar} from "utils/withSnackbar";
import {useRouteMatch, useHistory} from "react-router-dom";
import "./GoogleReview.scss";
import useTypedSelector from "../../../utils/hooks/useTypedSelector";
import {Box, styled, Typography, useTheme} from "@mui/material";
import constants from "../../../styles/constants";
import amazonCard from "../../../assets/img/amazonCard.png";
import DeededButton from "../../../v2/components/DeededButton";
import {openLink} from "../../../utils/openLink";
import { GoogleURL } from "./survey";

const GoogleReview = ({clientData, enqueueSnackbar}) => {
  let history = useHistory();
  const theme = useTheme();
  const {surveyProvince} = useTypedSelector((state) => state.SurveyReducer);

  const handleSurverySubmit = () => {
    history.push("/login");
  };
  const getLink = () => GoogleURL[surveyProvince] ?? GoogleURL.ON;
  
  const handleReviewOnGoogleClick = () => {
    openLink(getLink());
    handleSurverySubmit();
  };
  return (
    <>
      <Box
        className="google-review__wrapper"
        sx={{
          padding: "60px 40px",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "700",
            color: constants.colors.intakeTitle,
            textTransform: "uppercase",
          }}
        >
          THANK YOU! ONE LAST THING...
        </Typography>
        <Box
          sx={{
            marginTop: "49px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BannerComponent />
          <PageTypography
            sx={{
              marginBottom: "24px",
              [theme.breakpoints.down("lg")]: {
                width: "95%",
              },
            }}
          >
            It would mean a lot to us if you could take another minute to review
            us on Google and share your experience in working with us.
          </PageTypography>
          <PageTypography
            sx={{
              width: "756px",
              marginBottom: "40px",
              [theme.breakpoints.down("lg")]: {
                width: "95%",
              },
            }}
          >
            In appreciation for your Google review, we will send you a{" "}
            <BolderSpan>$25 Amazon Gift card. </BolderSpan>
            Simply click below to enter your review, along with a brief comment
            about your experience with us.
          </PageTypography>
          <DeededButton
            onClick={handleReviewOnGoogleClick}
            sx={{
              width: "383px",
              marginBottom: "40px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "10px",
              },
            }}
            kind={"secondary"}
          >
            Simply click here to review us on Google
          </DeededButton>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            Thank you so much in advance for helping us out!
          </Typography>
        </Box>
      </Box>
    </>
  );
};
const BannerComponent = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "604px",
          height: "236px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          [theme.breakpoints.down("lg")]: {
            width: "95%",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
          }}
        >
          <GoogleReviewBackgroundSvg />
        </Box>
        <Box
          sx={{
            width: "220px",
            height: "137px",
            zIndex: "1",
            img: {
              width: "100%",
              height: "100%",
            },
          }}
        >
          <img src={amazonCard} alt="amazon_card" />
        </Box>
      </Box>
    </>
  );
};
const GoogleReviewBackgroundSvg = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 604 236"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M508.235 24C508.846 26.092 509.775 28.3308 512.269 29.9824C514.665 30.0191 516.278 30.1292 518.65 30.6614C517.696 29.23 518.088 28.1657 519.701 26.2572C516.058 26.1654 512.733 25.2479 508.235 24Z"
        fill="#F16764"
      />
      <path
        d="M278.547 21.8353C277.056 21.5417 275.711 20.8077 273.706 18.4037C272.484 19.2111 271.726 20.1654 271.212 21.6335C273.022 23.2667 274.268 24.1109 276.762 24.9734C276.884 23.7255 277.373 23.0281 278.547 21.817V21.8353Z"
        fill="#F16764"
      />
      <path
        d="M103.134 191.689C104.699 189.561 105.237 188.276 104.479 185.725C99.2956 184.624 96.3129 183.707 91.6433 181.872C91.3988 183.321 90.812 184.276 90.103 185.01C95.555 186.992 98.5866 188.717 103.134 191.689Z"
        fill="#F16764"
      />
      <path
        d="M581.031 113.818C582.596 111.689 583.133 110.405 582.376 107.854C577.193 106.753 574.21 105.835 569.54 104C569.296 105.45 568.709 106.404 568 107.138C573.452 109.12 576.484 110.845 581.031 113.818Z"
        fill="#F16764"
      />
      <path
        d="M150.923 52.7068C149.799 51.8443 149.114 50.8901 149.139 49.2568C144.029 50.3579 140.973 51.5324 136.597 53.4776C136.988 55.7714 137.917 56.9643 138.699 57.7534C142.635 54.9456 145.838 53.918 150.923 52.7252V52.7068Z"
        fill="#F16764"
      />
      <path
        d="M455.372 152.157C460.995 154.175 464.76 154.964 469.943 155.497C470.212 152.377 469.649 150.395 468.549 148.615C465.322 148.854 463.024 148.101 459.577 146.688C459.063 149.349 457.939 150.615 455.372 152.157Z"
        fill="#F16764"
      />
      <path
        d="M62.8962 141.332C68.2997 138.783 71.52 136.679 75.5613 133.39C73.5455 130.994 71.7464 129.99 69.7098 129.51C67.5965 131.96 65.4395 133.053 62.0028 134.492C63.5213 136.736 63.6214 138.427 62.8962 141.332Z"
        fill="#F16764"
      />
      <path
        d="M118 119.469C123.623 121.487 127.388 122.276 132.571 122.808C132.84 119.689 132.278 117.707 131.178 115.927C127.95 116.165 125.652 115.413 122.205 114C121.692 116.661 120.567 117.927 118 119.469Z"
        fill="#F16764"
      />
      <path
        d="M373.394 210.395C372.245 207.679 372.661 205.918 373.81 203.367C367.673 203.917 361.585 205 359.141 206.12C359.385 208.34 360.29 210.157 361.977 212.542C364.739 211.24 367.942 210.873 373.394 210.395Z"
        fill="#F16764"
      />
      <ellipse
        cx="317.976"
        cy="30.5825"
        rx="5.67656"
        ry="5.68349"
        fill="#DFDFDF"
      />
      <ellipse
        cx="476.186"
        cy="205.417"
        rx="5.67656"
        ry="5.68349"
        fill="#DFDFDF"
      />
      <ellipse
        cx="329.677"
        cy="197.683"
        rx="5.67656"
        ry="5.68349"
        fill="#DFDFDF"
      />
      <ellipse
        cx="425.561"
        cy="43.5734"
        rx="5.67656"
        ry="5.68349"
        fill="#DFDFDF"
      />
      <ellipse
        cx="95.6766"
        cy="25.6835"
        rx="5.67656"
        ry="5.68349"
        fill="#DFDFDF"
      />
    </svg>
  );
};
const PageTypography = styled(Typography)({
  color: constants.colors.intakeTitle,
  fontSize: "16px",
  width: "618px",
  textAlign: "center",
});
const BolderSpan = styled("span")({
  fontWeight: "700",
});
export default withSnackbar(GoogleReview);
