import {useEffect, useMemo, useCallback} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {validate as uuidValidate} from "uuid";
import {useSnackbar} from "notistack";
import {getExistingIntakeByToken, getIsIntakeRequestMigrated} from "api";
import {AlertTypes} from "types/alertTypes";
import {OnboardingStates} from "types/intakeForm";
import useUser from "utils/hooks/useUser";
import useCheckTokenExpiration from "utils/hooks/useCheckTokenExpiration";
import {isClient, shouldBeAskedTOS} from "utils/permissionRoles";
import {notifyError} from "utils/bugSnag";
import configuration from "utils/configuration";
import storage from "utils/storage";
import {ONBOARDING_PATH, pages} from "customerPortal/pages/paths";
import {SET_AUTH_USER} from "customerPortal/context/actionTypes";
import {useCustomerPortal} from "../context/CustomerPortalProvider";

const useClientTokenVerification = () => {
  const {enqueueSnackbar} = useSnackbar();
  const {search, pathname} = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const {user: currentUser} = useUser();
  const [, dispatchCustomerPortal] = useCustomerPortal();

  /**
   * If there is no active session, we will check the client's intake account and,
   * based on that, redirect them either to the login page or to set a new password.
   */
  const handleNoSession = useCallback(() => {
    // Code to support old urls after context refactor [DASH-1198]
    const clientId = query.get("client_id");
    if (clientId) {
      history.replace(`${ONBOARDING_PATH}/${clientId}`);
      return window.location.reload();
    }

    let token =
      pathname.split(`${ONBOARDING_PATH}/`).join("").split("/")[0] ?? null;

    if (!token) {
      return history.replace(`/`);
    }

    if (!uuidValidate(token)) {
      return verifyOldToken(token);
    }

    getExistingIntakeByToken(token).then((res) => {
      const {clientHasAccount, state} = res.data;
      if (
        state === OnboardingStates.Created ||
        state === OnboardingStates.Accessed
      ) {
        return history.push(`/customer/registration?client_id=${token}`);
      }
      if (clientHasAccount) {
        return history.push(`/login`);
      }
      history.push(`/reset-password?client_id=${token}`);
    }).catch((error) => {
      error?.response?.status === 404 && history.push("/not-found");
    });
  }, [history]);

  const refreshCalled = useCheckTokenExpiration(handleNoSession);

  const handleError = (error: any) => {
    console.log(error);
    if (error.response.status === 403 || error.response.status === 404) {
      return history.replace(`/not-found`);
    }
    notifyError(error);
    enqueueSnackbar("There was an error loading the data", {
      variant: AlertTypes.Error,
      autoHideDuration: configuration.autoHideErrorDuration,
    });
  };

  // This is the only code related to old V2 intakes, it's necessary to keep old URLs working
  const verifyOldToken = async (clientId: string) => {
      try {
          const res = await getIsIntakeRequestMigrated(clientId);

          if (res.data?.is_v1 && !currentUser.id) {
              storage.targetLink = "";
              return history.push(`/login`);
          }

          if (res.data?.is_v1 && currentUser.id) {
              history.replace(pages.DEALS.route());
          }

          if (res.data?.onboarding_uuid) {
              history.replace(`${ONBOARDING_PATH}/${res.data?.onboarding_uuid}`);
          }

          window.location.reload();
      } catch(e) {
          handleError(e);
      }
  };

  useEffect(() => {
    if (!refreshCalled) return;

    // Code to support old urls after context refactor [DASH-1198]
    const clientId = query.get("client_id");
    if (clientId) {
      if (!uuidValidate(clientId)) {
        return void verifyOldToken(clientId);
      } else {
        history.replace(`${ONBOARDING_PATH}/${clientId}`);
        return window.location.reload();
      }
    }

    if (!isClient(currentUser.role || ''))
      return history.replace('/');

    if (shouldBeAskedTOS(currentUser.role || "") && !currentUser.tos_signed_at) {
      const token =
          pathname.split(`${ONBOARDING_PATH}/`).join("").split("/")[0] ?? null;
      return history.push(`/accept_terms_of_use${token ? '?client_id='+token : ''}`);
    }

    dispatchCustomerPortal({type: SET_AUTH_USER, payload: currentUser});
  }, [refreshCalled]);
};

export default useClientTokenVerification;
