import { FC, ReactNode, useMemo, useState } from "react";
import { OnboardingStepStatus as StepStatus } from "../types";
import Stack from "@mui/material/Stack";
import Colors from "components/CommonDashboard/Styling/Colors";
import { BodyRegular, H3 } from "components/CommonDashboard/Typography";
import Accordion from "components/CommonDashboard/Accordion";
import Button, { ButtonProps } from "components/CommonDashboard/Button";
import { Deal } from "types/deal";
import { ClientOnboarding, OnboardingTask } from "types/intakeForm";
import { useHistory } from "react-router-dom";
import StatusPill from "customerPortal/components/StatusPill";
import OnboardingSteps from "./OnboardingSteps";
import { styled } from "@mui/material/styles";
import { isDocumentUploadedByClient } from "utils/intakeHelper";

export const Steps = ({ onboarding, deal, refresh }: { onboarding: ClientOnboarding; deal: Deal; refresh: () => void }) => {
    const [incompleteSteps, completedSteps] = useMemo(() => {
        const complete = [];
        const pending = [];
        for (let index = 0; index < OnboardingSteps.length; index++) {
            const step = OnboardingSteps[index];
            const { title, icon, description, getButtonProps, link, getSecButtonProps, alwaysShowButtons, testId, customFooter } = step;
            const task = onboarding.onboarding_tasks.find(t => t.label === title);
            const agreements = deal.agreements?.filter(a => a.status === "Missing" || isDocumentUploadedByClient(a))
            const status = step.getStatus({
                OnbState: onboarding.state,
                intState: onboarding.intake?.state,
                idvState: onboarding.user_idv?.state,
                idvFeature: deal.idv_feature,
                agreements,
                task,
            });
            if (status === StepStatus.NotShow)
                continue;
            const buttonProps = getButtonProps && getButtonProps({ stepStatus: status }) || null;
            const secondaryButtonProps = getSecButtonProps && getSecButtonProps({ stepStatus: status, task, refreshDeal: refresh, }) || null;

            if (status === StepStatus.Completed) {
                complete.push({ title, icon, description, status, buttonProps, secondaryButtonProps, link, alwaysShowButtons, testId, customFooter, task });
                continue;
            }
            pending.push({ title, icon, description, status, buttonProps, secondaryButtonProps, link, alwaysShowButtons, testId, customFooter, task });
        }
        return [pending, complete];
    }, [onboarding, deal.idv_feature]);

    return <Stack sx={{ gap: '2.4rem', flexDirection: 'column' }}>
        {incompleteSteps.map((step, idx) =>
            <AccordionStep key={idx} title={step.title} icon={step.icon} status={step.status} description={step.description} testId={step.testId}>
                {step.customFooter ?
                    step.customFooter({ task: step.task, refreshDeal: refresh, deal }) :
                    <FooterButtons step={step} onboarding={onboarding} />}
            </AccordionStep>
        )}
        {!!completedSteps.length && (<H3 data-testid='completed-steps-header'>Completed {'('}{completedSteps.length}{')'} </H3>)}
        {completedSteps.map((step, idx) =>
            <AccordionStep key={idx} title={step.title} icon={step.icon} status={step.status} description={step.description} testId={step.testId}>
                {Boolean(step.alwaysShowButtons) && <FooterButtons step={step} onboarding={onboarding} />}
            </AccordionStep>
        )}
    </Stack>;
};

interface Step {
    title: string;
    icon: JSX.Element;
    testId?: string;
    description: string | JSX.Element;
    status: StepStatus;
    buttonProps?: Partial<ButtonProps> | null;
    secondaryButtonProps?: Partial<ButtonProps> | null;
    link: (token?: string) => string;
    alwaysShowButtons: boolean | undefined;
    customFooter: (props: any) => ReactNode,
    task?: OnboardingTask,
}

interface FooterButtonsProps {
    onboarding: ClientOnboarding;
    step: Step;
}

const FooterButtons: FC<FooterButtonsProps> = ({step, onboarding}) => {
  const history = useHistory();

  return (
    <Stack direction="row" gap="1.6rem">
       {step.buttonProps && <Button
        sx={(theme) => ({
          marginTop: "2.4rem",
          [theme.breakpoints.down("mobile")]: {width: "100%"},
        })}
        typeOf="CTA"
        disabled={step.status === StepStatus.Disabled}
        onClick={() => history.push(step.link(onboarding.uuid))}
        {...step.buttonProps}
      />}
      {step.secondaryButtonProps && (
        <Button
          sx={(theme) => ({
            marginTop: "2.4rem",
            [theme.breakpoints.down("mobile")]: {width: "100%"},
          })}
          typeOf="secondary"
          disabled={step.status === StepStatus.Disabled}
          {...step.secondaryButtonProps}
        />
      )}
    </Stack>
  );
};

interface AccordionStepProps {
    title: string,
    icon: ReactNode,
    status: StepStatus,
    description: string | ReactNode,
    children?: ReactNode,
    testId?: string,
}
const AccordionStep: FC<AccordionStepProps> = (props) => {
    return <Accordion
        data-testid={props.testId}
        defaultExpanded={[StepStatus.NotStarted, StepStatus.InProgress].includes(props.status)}
        title={<TitleContainer>
            <Stack sx={{ padding: '1.2rem', backgroundColor: Colors.CORAL_20, borderRadius: '50%' }}>{props.icon}</Stack>
            <TitleWrapper>
                <H3 data-testid='accordion-step-title'>{props.title}</H3>
            </TitleWrapper>
            <StatusPill status={props.status} testId='status-pill'/>
        </TitleContainer>}
    >
        <Stack sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            {typeof props.description === "string" ? (
              <BodyRegular color={Colors.DARK_GREY}>
                  {props.description}
              </BodyRegular>
            ) : (
              props.description
            )}
            {props.children}
        </Stack>
    </Accordion>;
}

const TitleContainer = styled(Stack)(({ theme }) => ({
    gap: '3.7rem',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingRight: '3.7rem',
    [theme.breakpoints.down('mobile')]: {
        flexFlow: 'row wrap',
        gap: '1.6rem',
        justifyContent: 'space-between',
        paddingRight: '0',
    }
}))

const TitleWrapper = styled('div')(({ theme }) => ({
    flex: 1,
    [theme.breakpoints.down('mobile')]: {
        order: 3,
        flex: '1 0 100%',
        display: 'flex',
        height: '5.4rem',
        alignItems: 'center',
        paddingRight: '6.4rem'
    }
}))
