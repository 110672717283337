import { useEffect, useState, FC } from "react";
import { RetainerIntake } from "components/Dashboard/TermsOfConditions";
import { pages } from "../paths";
import { Stack } from "@mui/material";
import { ClientIntakeStates, ClientOnboarding, OnboardingStates } from "types/intakeForm";
import useUpdateIntakeStage from "../../hooks/useUpdateIntakeStage";
import DealInfoHeader from "customerPortal/components/DealInfoHeader";
import { Deal } from "types/deal";
import AlertPanel from "components/CommonDashboard/AlertPanel";
import { BodyRegular, H1 } from "components/CommonDashboard/Typography";
import Colors from "components/CommonDashboard/Styling/Colors";
import {  styled } from '@mui/material/styles';
import CheckboxField from "components/CommonDashboard/CheckboxField";
import Button from "components/CommonDashboard/Button";


interface RetainerProps {
    deal: Deal,
    onboarding: ClientOnboarding | null
}

const Retainer = ({ deal, onboarding }: RetainerProps) => {
    const [updateIntakeStage] = useUpdateIntakeStage();

    const [accept, setAccept] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleNextButton = () => {
        setLoading(true);
        //if onboarding has idv activated
        if (deal?.idv_feature) {
            return updateIntakeStage(OnboardingStates.InIDV, pages.IDV.route)();
        }
        //if idv is not activated
        let route;
        if (onboarding && onboarding.intakes.length > 0 && onboarding.intakes[0].state !== ClientIntakeStates.Completed) {
            route = pages.SURVEY.route;
        } else {
            route = pages.FILES.route;
        }
        updateIntakeStage && updateIntakeStage(OnboardingStates.Completed, route)()
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    if (!deal) return <></>;

    return <>
        <DealInfoHeader deal={deal} />
        <H1>{deal.deal_type} Retainer & Terms</H1>
        <BodyRegular color={Colors.NEUTRAL_500} data-testid="retainer-agreement-body-text">
            Accepting a retainer with one of our independent real estate lawyers establishes your
            relationship with the Lawyer and their Law Firm. By agreeing to the retainer, you're
            ensuring that you have a dedicated legal expert to provide the support you need.
        </BodyRegular>
        <AlertPanel title="Scroll to the bottom to accept." />
        <RetainerIntake
            dealType={deal.deal_type}
            province={deal.address.state}
        />
        <AcceptStack>
            <CheckboxField
                testId="agree-to-retainer-checkbox"
                label="By checking this box, I agree to the Retainer"
                checked={accept}
                onChange={() => setAccept(!accept)}
            />
            <Button
                testId="next-button"
                label={{ text: 'Accept', inputId: "ButtonId" }}
                disabled={!accept || loading}
                loading={loading}
                typeOf="CTA"
                onClick={handleNextButton}
            />
        </AcceptStack>
    </>
}


const AcceptStack = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
        gap: '2.4rem'
    }
}));

export default Retainer;
