import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getPartnerPage } from "api";
import { PartnerPageWithUser } from "types/partnerPage";

const usePartnerPage = () => {
    const [loading, setLoading] = useState(false);
    const [partnerPage, setPartnerPage] = useState<PartnerPageWithUser>();
    const history = useHistory();
    const { slug  } = useParams<{ slug: string }>();

    const refresh = async () => {
        if (loading) return;
        setLoading(true);

        try {
            const res = await getPartnerPage(slug || '');

            setPartnerPage(res.data);
        } catch (e) {
            console.log(e);
            history.push("/not-found");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refresh();
    }, []);

    return [partnerPage, loading, refresh] as const;
};

export default usePartnerPage;
