import React from "react";
import {withSnackbar} from "utils/withSnackbar";
import {useRouteMatch} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {setAnswerOne} from "../../../redux/actions/survey";
import "./SurveyQuestion.scss";
import Buttons from "../Buttons/Buttons";

const SurveyQuestion1 = () => {
  let match = useRouteMatch();

  const dispatch = useDispatch();
  const {answerOne} = useSelector((state) => state.SurveyReducer);

  return (
    <div className="step__container">
      <div className="forwarding">
        <div className="flex">
          <h2 className="title">What should we keep doing? </h2>
        </div>
        <div className="address__container">
          <div className="address__wrapper" style={{}}>
            <label htmlFor="address">
              <textarea
                style={{minHeight: "350px"}}
                value={answerOne ?? ""}
                onChange={(e) => {
                  if (
                    e.target.value.length > 300 ||
                    e.target.value.split(" ").some((el) => el.length > 25)
                  )
                    return false;
                  dispatch(setAnswerOne(e.target.value));
                }}
                name="address"
                placeholder="Your answer"
              ></textarea>
            </label>
          </div>
        </div>
      </div>
      <Buttons
        backBtn={false}
        back={`/survey/home/${match.params.id}`}
        next={`/survey/question2/${match.params.id}`}
      />
    </div>
  );
};

export default withSnackbar(SurveyQuestion1);
