import {ActionType} from "redux/actionTypes";
import {PropertyEnum, TransactionTypeEnum} from "types/landing";
import {pricingSubmit} from "../../api";
import {addErrorAC} from "./ErrorReducer";
import {Dispatch} from "redux";

export interface ThirdStepInfo {
  purchasePrice: string;
  sellingPrice: string;
  mortgageAmount: string;
  mortgageValue: string;
  isPropertyToronto: boolean;
  isFirstTimeHomebuyer: boolean;
}

interface FifthStepInfo {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

interface SixthStepInfo {
  street: string;
  city: string;
  date: string;
  postalCode: string;
}

interface LastStepInfo {
  checkboxes: Array<any>;
}

export interface LandingReducerState {
  profData: (unknown & {role?: string; company?: string}) | null;
  transactionType: TransactionTypeEnum;
  property: PropertyEnum;
  currentStep: number;
  thirdStepInfo: ThirdStepInfo;
  fifthStepInfo: FifthStepInfo;
  sixthStepInfo: SixthStepInfo;
  lastStepInfo: LastStepInfo;
  isSubmittingBankForm: boolean;
}

type LandingReducerActionType = {
  type:
    | "SET_TRANSACTION_TYPE"
    | "SET_PROPERTY"
    | "SET_CURRENT_STEP"
    | "SET_THIRD_STEP_INFO"
    | "SET_FIFTH_STEP_INFO"
    | "SET_SIXTH_STEP_INFO"
    | "SET_LAST_STEP_INFO"
    | "SET_PROF_DATA"
    | "SET_IS_SUBMITTING_BANK_FORM"
    | keyof typeof ActionType;
  payload: any;
};

const landingInitialState: LandingReducerState = {
  isSubmittingBankForm: false,
  profData: null,
  currentStep: 0,
  transactionType: TransactionTypeEnum.Buying,
  property: PropertyEnum.Ontario,
  thirdStepInfo: {
    purchasePrice: "",
    sellingPrice: "",
    mortgageAmount: "",
    mortgageValue: "",
    isPropertyToronto: false,
    isFirstTimeHomebuyer: false,
  },
  fifthStepInfo: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
  sixthStepInfo: {
    street: "",
    city: "",
    date: "",
    postalCode: "",
  },
  lastStepInfo: {
    checkboxes: [
      {
        label: "Referral from Real Estate or Mortgage Professional",
        name: "isRealOfMortgage",
        value: false,
      },
      {
        label: "Searched and found website",
        name: "isSearched",
        value: false,
      },
      {
        label: "Previous client, friend or family member",
        name: "isPrevious",
        value: false,
      },
    ],
  },
};

export const LandingReducer = (
  state: LandingReducerState = landingInitialState,
  action: LandingReducerActionType,
) => {
  switch (action.type) {
    case "SET_IS_SUBMITTING_BANK_FORM": {
      return {
        ...state,
        isSubmittingBankForm: action.payload,
      };
    }
    case "SET_PROF_DATA": {
      return {
        ...state,
        profData: action.payload,
      };
    }
    case "SET_TRANSACTION_TYPE": {
      return {
        ...state,
        transactionType: action.payload,
      };
    }

    case "SET_PROPERTY": {
      return {
        ...state,
        property: action.payload,
      };
    }

    case "SET_CURRENT_STEP": {
      return {
        ...state,
        currentStep: action.payload,
      };
    }

    case "SET_THIRD_STEP_INFO": {
      const copyThirdStepInfo: any = {...state.thirdStepInfo};
      action.payload.forEach((obj: any) => {
        copyThirdStepInfo[obj.name] = obj.value;
      });

      return {
        ...state,
        thirdStepInfo: {...state.thirdStepInfo, ...copyThirdStepInfo},
      };
    }

    case "SET_FIFTH_STEP_INFO": {
      const copyFifthStepInfo: any = {...action.payload};
      return {
        ...state,
        fifthStepInfo: {...state.fifthStepInfo, ...copyFifthStepInfo},
      };
    }

    case "SET_SIXTH_STEP_INFO": {
      const copySixthStepInfo: any = {...action.payload};
      return {
        ...state,
        sixthStepInfo: {...state.sixthStepInfo, ...copySixthStepInfo},
      };
    }

    case "SET_LAST_STEP_INFO": {
      return {
        ...state,
        lastStepInfo: {...state.lastStepInfo, ...action.payload},
      };
    }

    default:
      return state;
  }
};

export const setProfDataAC = (payload: unknown | null) => ({
  type: "SET_PROF_DATA",
  payload,
});
export const setTransactionType = (payload: TransactionTypeEnum) => ({
  type: "SET_TRANSACTION_TYPE",
  payload,
});

export const setProperty = (payload: PropertyEnum) => ({
  type: "SET_PROPERTY",
  payload,
});

export const setCurrentStep = (payload: number) => ({
  type: "SET_CURRENT_STEP",
  payload,
});

export const setThirdStepInfo = (payload: any) => ({
  type: "SET_THIRD_STEP_INFO",
  payload,
});

export const setFifthStepInfo = (payload: any) => ({
  type: "SET_FIFTH_STEP_INFO",
  payload,
});
export const setSixthStepInfo = (payload: any) => ({
  type: "SET_SIXTH_STEP_INFO",
  payload,
});

export const setLastStepInfo = (payload: any) => ({
  type: "SET_LAST_STEP_INFO",
  payload,
});
export const setIsSubmittingBankFormAC = (payload: boolean) => ({
  type: "SET_IS_SUBMITTING_BANK_FORM",
  payload,
});
export const pricingSubmitTC =
  (
    data: unknown,
    setIsShowedSuccessPopup?: (isShowedSuccessPopup: boolean) => void,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsSubmittingBankFormAC(true));
    try {
      const pricingSubmitResponse = await pricingSubmit(data);
      if (setIsShowedSuccessPopup) {
        setIsShowedSuccessPopup(true);
      }
      dispatch(setIsSubmittingBankFormAC(false));
    } catch (error) {
      dispatch(setIsSubmittingBankFormAC(false));
      const e = error as {response: {data: {error: string}}};
      dispatch(addErrorAC(e?.response.data.error));
    }
  };
