import { useMemo } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Colors from "components/CommonDashboard/Styling/Colors";
import { BodyRegular, H1, SubtitleSmall } from "components/CommonDashboard/Typography";
import HeartDealOverview from "components/Svg/HeartDealOverview";
import useCustomerPortalAuthUser from "customerPortal/hooks/useCustomerPortalAuthUser";
import { Deal } from "types/deal";
import DealInfoHeader from "../../components/DealInfoHeader";
import { Steps } from "./Steps";

interface MyNextStepsInterface {
    deal: Deal;
    refresh: () => void;
}

const DealOverview = ({ deal, refresh }: MyNextStepsInterface) => {
    const authUser = useCustomerPortalAuthUser();
    const onboarding = useMemo(() => deal.client_onboardings.filter(o => o.user_id === authUser?.id), [authUser])[0] || null;
    const isV1 = deal.intake_version === 1;

    if (!onboarding && !isV1)
        return <SubtitleSmall alignSelf={'center'}>No onboarding sent</SubtitleSmall>;

    return (
        <>
            <DealInfoHeader deal={deal} />
            <Box sx={{ height: "1px", backgroundColor: Colors.PRIMARY_100 }} />
            {isV1 ? (
                <Stack gap="2.4rem" pt="8rem" pb="4rem" px="2.4rem">
                    <HeartDealOverview />
                    <H1 textAlign="center" data-testid="intake-next-steps-title">Thank you for completing your transaction with us!</H1>
                    <BodyRegular textAlign="center" color={Colors.DARK_GREY}>
                        If you have any questions please contact our team.
                    </BodyRegular>
                </Stack>
            ) : (
                <>
                    <H1 data-testid="intake-next-steps-title">My next steps</H1>
                    <BodyRegular color={Colors.DARK_GREY}>
                        Ready to start your closing? We’ve outlined the key steps below.
                    </BodyRegular>
                    <Steps onboarding={onboarding} deal={deal} refresh={refresh} />
                </>
            )}
        </>
    );
};

export default DealOverview;
