import { CustomWidgetCollection, JsonObject, PanelModel } from "survey-core";
import { CustomWidgetAutoComplete } from "./customWidgetTypes";

const INPUT_NAME = "address-autocomplete";

const registerAddressAutocomplete = () => {
    CustomWidgetCollection.Instance.addCustomWidget(addressAutocomplete, INPUT_NAME);
};

const addressAutocomplete: CustomWidgetAutoComplete = {
    name: INPUT_NAME,
    widgetIsLoaded: () => true,
    isFit: (question) => question.getType() === INPUT_NAME,
    activatedByChanged: () => {
        JsonObject.metaData.addClass(INPUT_NAME, [], undefined, "text");
        JsonObject.metaData.addProperty(INPUT_NAME, {
            name: "provinces",
            category: "general",
        });
    },
    isDefaultRender: true,
    // add Google Maps Autocomplete to the rendered text field
    afterRender: function (question, el) {
        let text;
        if (el.tagName.toLowerCase() !== "input") {
            text = el.getElementsByTagName("input")[0];
        } else {
            text = el;
        }
        text.className = "sd-input sd-text";

        this.tryLoadLib(text, question);
    },
    getAddressComponent: (place, type, useShortName) => {
        for (let i = 0; i < place.address_components.length; i++) {
            const component = place.address_components[i];
            if (component.types[0] === type) {
                if (useShortName) {
                    return component.short_name;
                }
                return component.long_name;
            }
        }
    },
    getAddressComponentLevel: function (place, type, startLevel, useShortName = false) {
        let ret: string | undefined;
        for (let i = startLevel ? startLevel : 1; i < 6; i++) {
            ret = this.getAddressComponent(place, type + "_level_" + i, useShortName);
            if (ret) {
                return ret;
            }
        }
        return ret;
    },
    transferValue: function (value, parent, fieldName, thisQuestionName) {
        let targetName = thisQuestionName + "." + fieldName;
        let cq = parent.getQuestionByName(targetName);
        if (!cq) {
            targetName = thisQuestionName + fieldName;
            cq = parent.getQuestionByName(targetName);
        }
        if (!cq) {
            targetName = fieldName;
            cq = parent.getQuestionByName(targetName);
        }
        if (cq) {
            cq.value = value;
        }
    },
    tryLoadLib: function (input, question, retry = 1) {
        try {
            google.maps.places.Autocomplete;
        } catch (e) {
            console.info("Google Autocomplete not loaded.", e);
            if (retry <= 3) {
                console.info("Retry loading google");
                setTimeout(() => this.tryLoadLib(input, question, retry + 1), retry * 1000);
            }
            return;
        }
        console.log("Google Autocomplete loaded.");
        // Only shows search icon if the lib is loaded
        input.className = "sd-input sd-text sd-search";
        this.initMap(input, question);
    },
    initMap: function (input, question) {
        const autocomplete = new google.maps.places.Autocomplete(input, { componentRestrictions: { country: "CA" } });

        // we only need the address_component(s)
        autocomplete.setFields(["address_component", "formatted_address"]);

        // listen to place_changed events
        autocomplete.addListener("place_changed", () => {
            let place = autocomplete.getPlace();
            if (!place.address_components) {
                console.info("No address found");
                return;
            }

            // get the address components
            const streetName = this.getAddressComponent(place, "route");
            const streetNumber = this.getAddressComponent(place, "street_number");
            const postalCode = this.getAddressComponent(place, "postal_code");
            const neighborhood = this.getAddressComponent(place, "neighborhood");
            let city = this.getAddressComponent(place, "locality");
            if (!city) {
                city = this.getAddressComponent(place, "postal_town");
            }
            if (!city) {
                city = neighborhood;
            }
            const administrativeArea = this.getAddressComponentLevel(place, "administrative_area", undefined, true);
            const countryShort = this.getAddressComponent(place, "country", true);
            const country = this.getAddressComponent(place, "country");
            const formattedAddress = place.formatted_address;
            // Here we can put the question names that will use the values
            const address = {
                address: [streetNumber, streetName].filter(el => !!el).join(' '),
                streetName: streetName,
                StreetName: streetName,
                streetNumber: streetNumber,
                StreetNumber: streetNumber,
                postalCode: postalCode,
                PostalCode: postalCode,
                neighborhood: neighborhood,
                Neighborhood: neighborhood,
                city: city,
                City: city,
                administrativeArea: administrativeArea,
                AdministrativeArea: administrativeArea,
                province: administrativeArea,
                countryShort: countryShort,
                CountryShort: countryShort,
                country: country,
                Country: country,
                street: streetName + (streetNumber ? " " + streetNumber : ""),
                Street: streetName + (streetNumber ? " " + streetNumber : ""),
                formattedAddress: formattedAddress,
                FormattedAddress: formattedAddress,
            } as { [key: string]: string | undefined };

            let valueSet = false;

            // transfer all values to questions, if they exist
            Object.getOwnPropertyNames(address).forEach((fieldName) => {
                const target = !question.parent ? question.survey : question.parent as PanelModel;
                let value = address[fieldName];
                // Reset fields values if the address is not covered
                const allowedProvinces = question.getPropertyValue('provinces', []);
                if (allowedProvinces && !allowedProvinces.includes(administrativeArea || "")) {
                    value = "";
                }

                this.transferValue(value, target, fieldName, question.name);

                // prefer the value name over the question name
                if (!valueSet && question.name + fieldName == question.valueName) {
                    input.value = address[fieldName] || "";
                    question.value = address[fieldName];
                    valueSet = true;
                }
                // only use the question name if no valueName is set
                else if (!valueSet && !question.valueName && fieldName == question.name) {
                    input.value = address[fieldName] || "";
                    question.value = address[fieldName];
                    valueSet = true;
                }
            });
            if (!valueSet) {
                input.value = place.formatted_address;
                question.value = place.formatted_address;
            }
        });

        // set the lookup type to address
        autocomplete.setTypes(["address"]);
        // set strict bounds
        autocomplete.setOptions({ strictBounds: true });
    },
};

export default registerAddressAutocomplete;
