import { RotatingLines } from "react-loader-spinner";
import { styled, Theme, SxProps } from '@mui/material/styles';
import Stack, { StackProps } from "@mui/material/Stack";
import Colors from "components/CommonDashboard/Styling/Colors";

const Loader: React.FC<
    { sx?: SxProps<Theme> } & Omit<StackProps, "variant">
> = ({ sx, ...props }) => (
    <DeededLoader data-testid="circular-loader" {...props} sx={sx}>
        <RotatingLines
            ariaLabel="loading-indicator"
            width={'32'}
            strokeWidth={'3'}
            strokeColor={Colors.NEUTRAL_700}
        />
    </DeededLoader>
);

const DeededLoader = styled(Stack)({
    justifyContent: "center",
    alignItems: "center",
    padding: '2.4rem',
    backgroundColor: Colors.PRIMARY_100,
    borderRadius: '50%',
    margin: 0,
    alignSelf: 'center'
});

export default Loader;
