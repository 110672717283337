import {useFormik} from "formik";
import {Box, styled, Typography, useTheme} from "@mui/material";
import constants from "../../../styles/constants";
import React, {useEffect, useState} from "react";
import {FormContext} from "../Common/FormContext";
import * as yup from "yup";
import FormFields from "./FormFields";
import moment from "moment";
import {pricingSubmitTC} from "../../../redux/reducers/landingReducer";
import {useAppDispatch} from "../../../redux/hooks";
import {useParams} from "react-router-dom";
import DeededPopup from "../../../v2/components/DeededPopup";
import DeededButton from "../../../v2/components/DeededButton";

const phoneNumberValidation = /\+1-\d{3}-\d{3}-\d{4}/;
const postalCodeValidation = /[A-Z][0-9][A-Z]\s[0-9][A-Z][[0-9]/;
const correctDate = /(\d{2}|\d).(\d{2}|\d).20\d{2}/;
const correctEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validationSchema = yup.object().shape({
  firstName: yup.string().required("Field is required"),
  lastName: yup.string().required("Field is required"),
  email: yup
    .string()
    .email("Should match email format")
    .required("Field is required"),
  phoneNumber: yup
    .string()
    .required("Field is required")
    .test("phoneNumber", "Field doesn't match the format", (phoneNumber) => {
      return phoneNumberValidation.test(phoneNumber as string);
    }),
  streetAddress: yup.string().required("Field is required"),
  city: yup.string().required("Field is required"),
  province: yup.string().required("Field is required"),
  postalCode: yup
    .string()
    .required("Field is required")
    .test("postalCode", "Field doesn't match the format", (postalCode) => {
      return postalCodeValidation.test(postalCode as string);
    }),
  isConsentedTerms: yup
    .boolean()
    .test(
      "isConsentedTerms",
      "Please consent to the Privacy Policy and Terms of Use",
      (isConsentedTerms) => {
        return isConsentedTerms as boolean;
      },
    ),
  closingDate: yup
    .string()
    .nullable()
    .required("Field is required")
    .test("closingDate", "Field doesn't match the format", (closingDate) => {
      return correctDate.test(closingDate as string);
    }),
  preferred: yup
    .string()
    .required("Please choose how do You prefer to be contacted"),
  brokerEmailAddress: yup
    .string()
    .test(
      "brokerEmailAddress",
      "Field doesn't match the format",
      function (brokerEmailAddress) {
        if (this.parent.hasBroker) {
          if (!brokerEmailAddress) {
            return true;
          } else {
            return correctEmail.test(brokerEmailAddress as string);
          }
        } else {
          return true;
        }
      },
    ),
  brokerPhoneNumber: yup
    .string()
    .test(
      "brokerPhoneNumber",
      "Field doesn't match the format",
      function (brokerPhoneNumber) {
        if (this.parent.hasBroker) {
          if (!brokerPhoneNumber) {
            return true;
          } else {
            return phoneNumberValidation.test(brokerPhoneNumber as string);
          }
        } else {
          return true;
        }
      },
    ),
});
const EquitableBankForm = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    closingDate: null,
    streetAddress: "",
    city: "",
    province: "",
    postalCode: "",
    preferred: "",
    isConsentedTerms: false,
    hasBroker: false,
    brokerFirstName: "",
    brokerLastName: "",
    brokerEmailAddress: "",
    brokerPhoneNumber: "",
  };
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const params = useParams() as {slug: string};
  const [isShowedSuccessPopup, setIsShowedSuccessPopup] = useState(false);
  const onSubmit = (values: typeof initialValues) => {
    const payload = {
      deal_type: "Mortgage",
      deal_sub_type: "RevMrtg",
      referring_slug: params.slug,
      closing_date: moment(values.closingDate).format("M/D/YYYY"),
      client: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone: values.phoneNumber,
      },
      address: {
        address: values.streetAddress,
        city: values.city,
        state: values.province,
        code: values.postalCode,
      },
      is_broker: values.hasBroker,
      broker: {
        first_name: values.brokerFirstName,
        last_name: values.brokerLastName,
        email: values.brokerEmailAddress,
        phone: values.brokerPhoneNumber,
      },
      preferred: values.preferred,
      agreements: [],
    };
    dispatch(pricingSubmitTC(payload, setIsShowedSuccessPopup));
  };

  const {values, handleChange, errors, handleSubmit} = useFormik({
    validationSchema,
    onSubmit,
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
  });

  
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TitleTypography
          sx={{
            marginTop: "56px",
            width: "924px",
            marginBottom: "30px",
            [theme.breakpoints.down("lg")]: {
              width: "536px",
              fontSize: "18px",
            },
            [theme.breakpoints.down("sm")]: {
              width: "90%",
            },
          }}
        >
          Your next step is to initiate contact with Deeded, who you have chosen
          to help you with the closing process
        </TitleTypography>
        <TitleTypography
          sx={{
            width: "924px",
            marginBottom: "30px",
            [theme.breakpoints.down("lg")]: {
              width: "536px",
              fontSize: "18px",
            },
            [theme.breakpoints.down("sm")]: {
              width: "90%",
            },
          }}
        >
          For any questions or assistance, please feel free to contact us by
          telephone at{" "}
          <ContactsLink href={"tel://+18554564335"}>
            +1 (855) 456-4335
          </ContactsLink>{" "}
          or by email at{" "}
          <ContactsLink
            href={
              "mailto:hello@deeded.ca?subject=EQ Reverse Mortgage Onboarding"
            }
          >
            hello@deeded.ca
          </ContactsLink>
        </TitleTypography>
        <Box
          sx={{
            height: "4px",
            width: "81px",
            backgroundColor: constants.colors.red,
            marginBottom: "30px",
          }}
        />
        <TitleTypography
          sx={{
            marginBottom: "40px",
            [theme.breakpoints.down("lg")]: {
              width: "536px",
              fontSize: "18px",
            },
            [theme.breakpoints.down("sm")]: {
              width: "90%",
            },
          }}
        >
          Simply fill out the details below to get started
        </TitleTypography>
        <FormContext.Provider
          value={{
            values,
            errors,
            handleChange,
            handleSubmit,
          }}
        >
          <FormFields />
        </FormContext.Provider>
      </Box>
      {isShowedSuccessPopup && (
        <EquitableBankFormThankYouPopup
          setIsShowedSuccessPopup={setIsShowedSuccessPopup}
          isShowedSuccessPopup={isShowedSuccessPopup}
        />
      )}
    </>
  );
};
const EquitableBankFormThankYouPopup: React.FC<{
  setIsShowedSuccessPopup: (isShowedSuccessPopup: boolean) => void;
  isShowedSuccessPopup: boolean;
}> = ({setIsShowedSuccessPopup, isShowedSuccessPopup}) => {
  const theme = useTheme();
  useEffect(() => {
    document.body.style.overflow = "inherit";
    document.body.style.height = "auto";
    return () => {
      document.body.style.overflow = "inherit";
      document.body.style.height = "auto";
    };
  }, []);
  useEffect(() => {
    return () => {
      window.location.replace("https://deeded.ca/");
    };
  }, []);
  return (
    <>
      <DeededPopup
        disableScrollLock
        setOpen={setIsShowedSuccessPopup}
        open={isShowedSuccessPopup}
        sxInternalBox={{
          width: "481px",
          height: "368px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "26px",
          }}
        >
          <Box>
            <MailSvg />
          </Box>
          <Typography
            data-testid="success-modal-title"
            sx={{
              fontSize: "22px",
              fontWeight: "500",
              color: constants.colors.intakeTitle,
              marginBottom: "16px",
            }}
          >
            Thank you.
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              color: constants.colors.intakeTitle,
              textAlign: "center",
            }}
          >
            Our team will be in touch with you shortly to outline next steps
          </Typography>
        </Box>
        <DeededButton
          data-testid="success-modal-ok"
          onClick={() => {
            setIsShowedSuccessPopup(false);
          }}
          sx={{
            backgroundColor: constants.colors.redVeryPale,
            border: `1px solid ${constants.colors.redVeryPale}`,
            height: "34px",
            width: "200px",
            color: `${constants.colors.red}`,
            "&:hover": {
              colors: constants.colors.red,
              backgroundColor: `${constants.colors.pinkPale}`,
              border: `1px solid ${constants.colors.pinkPale}`,
            },
            [theme.breakpoints.down("sm")]: {
              width: "200px",
            },
          }}
        >
          Done
        </DeededButton>
      </DeededPopup>
    </>
  );
};
const MailSvg = () => {
  return (
    <svg
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6035_189413)">
        <g clipPath="url(#clip1_6035_189413)">
          <path d="M0 1.94922H112V79.9492H0V1.94922Z" fill="#F16764" />
          <g clipPath="url(#clip2_6035_189413)">
            <path
              d="M61.88 30.0675L116.32 80.0986L-4.67968 80.0986L49.7606 30.0675C53.1844 26.921 58.4562 26.921 61.88 30.0675Z"
              fill="#E9504D"
            />
          </g>
          <g clipPath="url(#clip3_6035_189413)">
            <path
              d="M61.0258 41.2884L116.32 1.94922L-4.67968 1.94921L50.6148 41.2884C53.7274 43.5028 57.9132 43.5028 61.0258 41.2884Z"
              fill="#F79290"
            />
          </g>
        </g>
        <circle cx="56.5" cy="82.5" r="27.5" fill="#35CF94" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.0688 70.9405C74.3095 72.1955 74.3105 74.2311 73.0711 75.4873L53.762 95.0572C53.1665 95.6608 52.3583 96 51.5156 96C50.6729 96 49.8647 95.6608 49.2691 95.0572L37.9289 83.5638C36.6895 82.3076 36.6905 80.2719 37.9312 79.017C39.1718 77.7621 41.1824 77.7631 42.4218 79.0193L51.5156 88.2359L68.5782 70.9428C69.8176 69.6866 71.8282 69.6856 73.0688 70.9405Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6035_189413">
          <rect width="112" height="112" fill="white" />
        </clipPath>
        <clipPath id="clip1_6035_189413">
          <rect
            y="1.94922"
            width="112"
            height="77.7313"
            rx="11.4229"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip2_6035_189413">
          <rect
            width="112"
            height="56"
            fill="white"
            transform="translate(0 24.0991)"
          />
        </clipPath>
        <clipPath id="clip3_6035_189413">
          <rect
            width="112"
            height="43.1841"
            fill="white"
            transform="translate(0 1.94922)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
const ContactsLink = styled("a")({
  color: constants.colors.red,
  textDecoration: "none",
  whiteSpace: "nowrap",
});
const ContactsSpan = styled("span")({
  color: constants.colors.red,
  whiteSpace: "nowrap",
});
const TitleTypography = styled(Typography)({
  fontSize: "24px",
  fontWeight: "500",
  color: constants.colors.equitableBankFromTitle,
  textAlign: "center",
});
export default EquitableBankForm;
