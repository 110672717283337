import {FC} from "react";
import CircularLoader from "components/Common/Loader/CircularLoader";
import {useClientOnboarding} from "../../context/ClientOnboardingProvider";
import PortalContainer from "components/CommonDashboard/Container/PortalContainer";
import FilesContent from "./FilesContent";
import MyTeam from "../../components/MyTeam";
import CustomerPortalFiles from "components/Svg/CustomerPortalFiles";

const FileView: FC = () => {
  const [{deal: {deal, loading}}] = useClientOnboarding();

  if (loading || !deal) {
    return <CircularLoader containerHeight={"70vh"} />;
  }

  return (
    <PortalContainer
      leftPanel={<FilesContent />}
      rightPanel={<MyTeam
        deal={deal}
        MainImage={<CustomerPortalFiles />}
      />}
    />
  )
};

export default FileView;
