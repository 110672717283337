import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMixpanel } from "../MixpanelContext";

const useTrackNavigation = () => {
    const mixpanel = useMixpanel();
    const history = useHistory();

    const handleNavigation = () => {
        mixpanel.track_pageview();
    };

    useEffect(() => {
        // Calling on load to get the initial page view
        handleNavigation();

        // Listener for all the other navigation that don't refresh the page
        const clearListener = history.listen(handleNavigation);

        return () => {
            clearListener();
        };
    }, []);
};

export default useTrackNavigation;
