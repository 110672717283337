import {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {getClientOnboarding} from "api";
import {
  CUSTOMER_PORTAL_PATH,
  pages,
} from "customerPortal/pages/paths";
import {AlertTypes} from "types/alertTypes";
import {SET_DEAL_DATA} from "customerPortal/context/actionTypes";
import {
  ClientIntakeStates,
  ClientOnboarding,
  OnboardingStates,
} from "types/intakeForm";
import {Deal} from "types/deal";
import {notifyError} from "utils/bugSnag";
import {useSnackbar} from "notistack";
import configuration from "../../utils/configuration";
import {useClientOnboarding} from "../context/ClientOnboardingProvider";

interface OnboardingData extends ClientOnboarding {
  deal: Deal;
}

const useOnboardingTokenVerification = () => {
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const routeParams = useParams<{onboarding_token?: string}>();
  const [,dispatchClientOnboarding] = useClientOnboarding();

  const handleGetOnboarding = (token: string) => {
    getClientOnboarding(token, {with: "deal"})
      .then((res) => {
        const {deal, ...onboarding}: OnboardingData = res.data;

        dispatchClientOnboarding({ type: SET_DEAL_DATA, payload: { deal, onboarding } });

        const allowedUrls = getAllowedPages(onboarding, token);
        if (!allowedUrls.includes(window.location.pathname)) {
          history.push(`${CUSTOMER_PORTAL_PATH}/deal/${deal.id}`);
        }
      })
      .catch(handleError);
  };

  const handleError = (error: any) => {
    console.log(error);
    if (error.response.status === 403 || error.response.status === 404) {
      return history.replace(`/not-found`);
    }
    notifyError(error);
    enqueueSnackbar("There was an error loading the data", {
      variant: AlertTypes.Error,
      autoHideDuration: configuration.autoHideErrorDuration,
    });
  };

  useEffect(() => {
    if (!routeParams?.onboarding_token) {
      return history.replace(`/not-found`);
    }

    handleGetOnboarding(routeParams.onboarding_token);
  }, []);
};

const getAllowedPages = (onboarding: ClientOnboarding, token: string) => {
  if (onboarding.state === OnboardingStates.Completed) {
    const inQNA = onboarding.intakes.length > 0 && onboarding.intakes[0].state !== ClientIntakeStates.Completed;
    if (inQNA) {
      return [pages.SURVEY.route(token)];
    }
  }
  const allowedUrls = PageMap[onboarding.state].map(page => page.route(token));
  return allowedUrls;
};

const PageMap = {
  [OnboardingStates.Created]: [pages.RETAINER],
  [OnboardingStates.Accessed]: [pages.RETAINER],
  [OnboardingStates.InRetainer]: [pages.RETAINER],
  [OnboardingStates.InIDV]: [pages.IDV],
  [OnboardingStates.Completed]: [pages.FILES],
};

export default useOnboardingTokenVerification;
