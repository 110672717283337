import React from "react";
const IDVPersonalInfo = () => {
    return (
        <svg viewBox="0 0 452 228" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1325_5244)">
                <path
                    d="M408.349 185.336H64.6758C60.2824 185.331 56.0703 183.584 52.9636 180.477C49.857 177.37 48.1095 173.158 48.1046 168.765V38.3563C48.1096 33.9628 49.8571 29.7508 52.9637 26.6441C56.0703 23.5375 60.2824 21.79 64.6758 21.785H408.349C412.743 21.79 416.955 23.5375 420.061 26.6441C423.168 29.7508 424.916 33.9628 424.921 38.3563V168.765C424.916 173.158 423.168 177.37 420.062 180.477C416.955 183.584 412.743 185.331 408.349 185.336Z"
                    fill="white" />
                <path
                    d="M408.349 185.336H64.6758C60.2824 185.331 56.0703 183.584 52.9636 180.477C49.857 177.37 48.1095 173.158 48.1046 168.765V38.3563C48.1096 33.9628 49.8571 29.7508 52.9637 26.6441C56.0703 23.5375 60.2824 21.79 64.6758 21.785H408.349C412.743 21.79 416.955 23.5375 420.061 26.6441C423.168 29.7508 424.916 33.9628 424.921 38.3563V168.765C424.916 173.158 423.168 177.37 420.062 180.477C416.955 183.584 412.743 185.331 408.349 185.336ZM64.6758 23.226C60.663 23.226 56.8146 24.8201 53.9771 27.6576C51.1396 30.495 49.5455 34.3435 49.5455 38.3563V168.765C49.5455 170.752 49.9369 172.719 50.6973 174.555C51.4576 176.391 52.5721 178.059 53.9771 179.464C55.3821 180.869 57.05 181.983 58.8857 182.743C60.7214 183.504 62.6889 183.895 64.6758 183.895H408.349C410.336 183.895 412.304 183.504 414.139 182.743C415.975 181.983 417.643 180.869 419.048 179.464C420.453 178.059 421.568 176.391 422.328 174.555C423.088 172.719 423.48 170.752 423.48 168.765V119.594C423.48 94.0355 413.327 69.524 395.254 51.4515C377.182 33.379 352.67 23.226 327.112 23.226H64.6758Z"
                    fill="#E6E6E6" />
                <path
                    d="M37.4828 213.098C37.6234 212.132 36.6116 211.185 35.223 210.983C33.8344 210.781 32.5947 211.4 32.4542 212.366C32.3136 213.333 33.3254 214.279 34.714 214.481C36.1026 214.683 37.3423 214.064 37.4828 213.098Z"
                    fill="white" />
                <path
                    d="M54.016 211.485C54.1566 210.519 53.1448 209.572 51.7562 209.37C50.3675 209.168 49.1279 209.787 48.9873 210.753C48.8468 211.719 49.8586 212.666 51.2472 212.868C52.6358 213.07 53.8755 212.451 54.016 211.485Z"
                    fill="#3F3D56" />
                <path
                    d="M32.9556 199.713C33.0961 198.747 32.0844 197.8 30.6957 197.598C29.3071 197.396 28.0675 198.016 27.9269 198.982C27.7864 199.948 28.7981 200.895 30.1868 201.097C31.5754 201.299 32.815 200.679 32.9556 199.713Z"
                    fill="#FF6584" />
                <path
                    d="M25.7715 194.231C25.7782 193.344 25.2835 192.621 24.6666 192.616C24.0497 192.612 23.5441 193.327 23.5374 194.214C23.5307 195.101 24.0254 195.823 24.6423 195.828C25.2592 195.833 25.7648 195.117 25.7715 194.231Z"
                    fill="#E6E6E6" />
                <path
                    d="M23.3352 184.019C23.3419 183.132 22.8473 182.409 22.2303 182.405C21.6134 182.4 21.1079 183.115 21.1011 184.002C21.0944 184.889 21.5891 185.611 22.206 185.616C22.823 185.621 23.3285 184.906 23.3352 184.019Z"
                    fill="#3F3D56" />
                <path
                    d="M42.2804 201.991C42.2871 201.104 41.7925 200.382 41.1755 200.377C40.5586 200.372 40.0531 201.087 40.0464 201.974C40.0396 202.861 40.5343 203.584 41.1512 203.588C41.7682 203.593 42.2737 202.878 42.2804 201.991Z"
                    fill="#E6E6E6" />
                <path
                    d="M34.2909 205.98C34.2976 205.093 33.8029 204.371 33.186 204.366C32.5691 204.361 32.0635 205.076 32.0568 205.963C32.0501 206.85 32.5448 207.573 33.1617 207.577C33.7786 207.582 34.2842 206.867 34.2909 205.98Z"
                    fill="#FF6584" />
                <path
                    d="M45.4126 213.353C45.4193 212.466 44.9246 211.744 44.3077 211.739C43.6908 211.734 43.1852 212.449 43.1785 213.336C43.1718 214.223 43.6665 214.946 44.2834 214.95C44.9003 214.955 45.4059 214.24 45.4126 213.353Z"
                    fill="#3F3D56" />
                <path
                    d="M436.906 4.84315C437.212 3.63697 436.054 2.30297 434.32 1.86357C432.586 1.42416 430.933 2.04573 430.627 3.25191C430.322 4.45809 431.479 5.79212 433.213 6.23153C434.947 6.67094 436.601 6.04933 436.906 4.84315Z"
                    fill="white" />
                <path
                    d="M415.732 4.70658C416.038 3.5004 414.88 2.16638 413.146 1.72697C411.412 1.28756 409.759 1.90917 409.453 3.11535C409.147 4.32153 410.305 5.65553 412.039 6.09493C413.773 6.53434 415.426 5.91276 415.732 4.70658Z"
                    fill="#3F3D56" />
                <path
                    d="M440.88 22.4103C441.185 21.2041 440.028 19.8701 438.294 19.4307C436.56 18.9913 434.906 19.6129 434.601 20.8191C434.295 22.0252 435.453 23.3593 437.187 23.7987C438.921 24.2381 440.574 23.6165 440.88 22.4103Z"
                    fill="#FF6584" />
                <path
                    d="M445.816 29.0345C445.941 27.911 445.409 26.9295 444.628 26.8421C443.846 26.7548 443.111 27.5948 442.986 28.7182C442.86 29.8417 443.392 30.8232 444.173 30.9106C444.955 30.9979 445.69 30.1579 445.816 29.0345Z"
                    fill="#E6E6E6" />
                <path
                    d="M447.56 42.3008C447.685 41.1774 447.153 40.1958 446.372 40.1085C445.59 40.0211 444.855 40.8611 444.729 41.9846C444.604 43.108 445.136 44.0896 445.917 44.1769C446.699 44.2643 447.434 43.4243 447.56 42.3008Z"
                    fill="#3F3D56" />
                <path
                    d="M425.909 17.0169C426.035 15.8934 425.503 14.9119 424.721 14.8246C423.94 14.7372 423.205 15.5772 423.079 16.7007C422.954 17.8241 423.485 18.8057 424.267 18.893C425.048 18.9803 425.784 18.1404 425.909 17.0169Z"
                    fill="#E6E6E6" />
                <path
                    d="M436.565 13.0138C436.691 11.8904 436.159 10.9088 435.377 10.8215C434.596 10.7342 433.86 11.5741 433.735 12.6976C433.609 13.8211 434.141 14.8026 434.923 14.8899C435.704 14.9773 436.44 14.1373 436.565 13.0138Z"
                    fill="#FF6584" />
                <path
                    d="M423.438 2.19853C423.563 1.07507 423.031 0.0935285 422.25 0.00620075C421.468 -0.081127 420.733 0.758826 420.607 1.88229C420.482 3.00576 421.014 3.9873 421.795 4.07463C422.577 4.16195 423.312 3.322 423.438 2.19853Z"
                    fill="#3F3D56" />
                <path
                    d="M185.567 100.907C185.586 113.958 180.765 126.552 172.036 136.254V136.261C170.802 137.633 169.499 138.942 168.131 140.181C166.732 141.448 165.265 142.639 163.736 143.747C162.67 144.525 161.575 145.26 160.451 145.952C159.118 146.78 157.747 147.546 156.337 148.25C155.292 148.776 154.226 149.266 153.138 149.72C146.644 152.449 139.67 153.853 132.626 153.848C128.647 153.853 124.681 153.408 120.803 152.523C119.009 152.115 117.239 151.61 115.5 151.01C114.83 150.786 114.16 150.541 113.497 150.282C109.478 148.728 105.666 146.683 102.149 144.194C94.7152 138.96 88.7545 131.9 84.8412 123.694C80.9279 115.487 79.1938 106.412 79.806 97.3408C80.4183 88.27 83.3562 79.5096 88.3368 71.9037C93.3173 64.2978 100.173 58.1027 108.242 53.9152C116.312 49.7277 125.324 47.6889 134.411 47.9953C143.497 48.3016 152.351 50.9428 160.121 55.6644C167.89 60.3859 174.312 67.0287 178.769 74.9528C183.226 82.8769 185.567 91.8153 185.567 100.907Z"
                    fill="#F2F2F2" />
                <path
                    d="M344.017 61.5525H252.515C251.752 61.5514 251.02 61.2472 250.48 60.7069C249.941 60.1665 249.638 59.4341 249.638 58.6705C249.638 57.9069 249.941 57.1745 250.48 56.6342C251.02 56.0938 251.752 55.7897 252.515 55.7886H344.017C344.782 55.7886 345.515 56.0922 346.055 56.6327C346.596 57.1732 346.899 57.9062 346.899 58.6705C346.899 59.4349 346.596 60.1679 346.055 60.7084C345.515 61.2489 344.782 61.5525 344.017 61.5525Z"
                    fill="#FF5958" />
                <path
                    d="M344.017 100.459H252.515C251.752 100.458 251.02 100.154 250.48 99.6133C249.941 99.0729 249.638 98.3405 249.638 97.5769C249.638 96.8133 249.941 96.0809 250.48 95.5405C251.02 95.0002 251.752 94.6961 252.515 94.6949H344.017C344.781 94.6961 345.513 95.0002 346.053 95.5405C346.592 96.0809 346.895 96.8133 346.895 97.5769C346.895 98.3405 346.592 99.0729 346.053 99.6133C345.513 100.154 344.781 100.458 344.017 100.459Z"
                    fill="#E6E6E6" />
                <path
                    d="M344.017 139.365H252.515C251.752 139.364 251.02 139.06 250.48 138.52C249.941 137.979 249.638 137.247 249.638 136.483C249.638 135.72 249.941 134.987 250.48 134.447C251.02 133.907 251.752 133.603 252.515 133.601H344.017C344.782 133.601 345.515 133.905 346.055 134.446C346.596 134.986 346.899 135.719 346.899 136.483C346.899 137.248 346.596 137.981 346.055 138.521C345.515 139.062 344.782 139.365 344.017 139.365Z"
                    fill="#E6E6E6" />
                <path
                    d="M120.517 76.9549C121.284 83.1481 125.576 88.2545 131.816 88.2545C134.813 88.2545 137.687 87.064 139.806 84.9449C141.925 82.8258 143.116 79.9518 143.116 76.9549C143.116 70.7144 138.03 66.2324 131.816 65.6554C125.223 65.0432 119.531 69.0037 120.517 76.9549Z"
                    fill="#2F2E41" />
                <path
                    d="M141.653 153.077C134.734 154.267 127.649 154.078 120.803 152.523C124.052 150.729 133.908 151.831 141.653 153.077Z"
                    fill="#2F2E41" />
                <path
                    d="M97.6743 172.953L103.102 159.836L107.379 162.211L104.796 175.555C105.055 176.4 105.072 177.301 104.847 178.156C104.622 179.011 104.163 179.786 103.521 180.394C102.88 181.002 102.081 181.419 101.215 181.599C100.35 181.778 99.4511 181.712 98.6208 181.409C97.7904 181.105 97.0612 180.576 96.515 179.881C95.9688 179.186 95.6273 178.352 95.529 177.474C95.4307 176.595 95.5793 175.707 95.9583 174.908C96.3373 174.109 96.9315 173.432 97.6743 172.953Z"
                    fill="#FFB6B6" />
                <path
                    d="M153.823 137.457V137.464C153.628 142.032 153.361 146.377 153.138 149.72C146.644 152.449 139.67 153.853 132.626 153.848C128.647 153.853 124.681 153.408 120.803 152.523C119.009 152.115 117.239 151.61 115.5 151.01C115.742 150.853 116.003 150.73 116.278 150.642C118.872 149.763 116.307 148.963 116.328 148.128C116.408 145.073 117.251 141.406 118.338 137.89L113.497 150.282L110.975 156.723C110.975 156.723 111.061 162.285 109.462 160.585C107.855 158.885 107.971 164.389 107.971 164.389C107.471 164.745 107.041 165.189 106.702 165.7C106.56 165.93 106.506 166.205 106.551 166.471V166.479C106.624 166.688 106.766 166.866 106.955 166.983C108.043 167.718 106.479 169.663 105.788 170.434C105.6 170.643 105.478 170.765 105.478 170.765L98.8275 170.29V170.16C98.8564 169.375 99.0365 165.239 100.067 164.252C101.198 163.164 100.643 161.442 100.643 161.442C100.643 161.442 103.597 158.827 101.616 156.702C100.823 155.844 101.176 150.765 102.149 144.194C104.274 129.698 109.368 107.939 111.573 108.249C112.027 106.042 113.083 104.003 114.622 102.358C116.162 100.713 118.126 99.5248 120.298 98.9254L127.481 96.9513L128.764 91.8862H138.88L140.638 95.2149L148.008 98.5436L148.102 98.5868L148.332 98.6876L148.419 98.9614C148.419 98.9614 148.412 98.9687 148.419 98.9687L152.518 111.844C154.363 113.962 154.291 126.34 153.823 137.457Z"
                    fill="#FF5958" />
                <path
                    d="M171.048 163.401L164.198 150.967L168.725 149.113L177.529 159.467C178.351 159.793 179.065 160.342 179.592 161.052C180.119 161.762 180.437 162.605 180.511 163.486C180.585 164.367 180.412 165.251 180.011 166.039C179.61 166.827 178.997 167.487 178.241 167.946C177.486 168.405 176.617 168.644 175.733 168.636C174.849 168.628 173.985 168.373 173.237 167.901C172.49 167.429 171.889 166.758 171.502 165.963C171.116 165.168 170.958 164.281 171.048 163.401Z"
                    fill="#FFB6B6" />
                <path
                    d="M148.009 98.4443C149.228 100.634 160.371 106.264 158.44 111.917C158.44 111.917 168.908 144.668 170.27 144.455C171.631 144.241 172.959 147.624 172.959 147.624C172.959 147.624 172.671 149.832 173.52 148.914C174.369 147.997 174.166 150.402 174.166 150.402C174.166 150.402 173.759 152.525 174.837 151.947C175.915 151.37 174.497 155.433 174.497 155.433L168.019 158.484L166.788 156.651C166.788 156.651 164.157 156.15 165.395 155.753C166.633 155.356 164.231 154.021 164.231 154.021C164.231 154.021 161.718 152.313 162.068 149.484C162.418 146.656 155.547 141.096 155.547 141.096C150.823 131.77 148.239 122.195 148.248 112.318L148.009 98.4443Z"
                    fill="#FF5958" />
                <path
                    d="M133.065 88.9255C138.599 88.9255 143.085 84.4394 143.085 78.9056C143.085 73.3717 138.599 68.8856 133.065 68.8856C127.532 68.8856 123.046 73.3717 123.046 78.9056C123.046 84.4394 127.532 88.9255 133.065 88.9255Z"
                    fill="#FFB6B6" />
                <path
                    d="M122.69 79.9972C123.007 82.1745 124.863 85.2123 124.428 85.2123C123.994 85.2123 120.904 76.2365 123.994 75.6512C126.235 75.2267 126.736 75.5991 128.831 74.6907L128.535 74.9689C129.922 75.9772 131.851 75.3557 133.433 74.6994C135.019 74.0475 136.945 73.4261 138.335 74.43C139.209 75.0601 139.578 76.1553 140.122 77.0854C140.665 78.0197 141.669 78.902 142.703 78.6152C143.106 78.4594 143.449 78.1799 143.684 77.8168C143.918 77.4537 144.031 77.0257 144.007 76.5943C144.002 75.7338 142.882 74.9152 143.259 74.1431C144.378 71.8552 143.372 70.2957 140.934 69.2192C140.048 69.0844 139.161 68.9468 138.275 68.8063C138.739 68.1628 139.302 67.5966 139.943 67.1287C139.673 66.4343 139.204 65.8345 138.596 65.4034C137.845 65.0542 137.008 64.9333 136.188 65.0557C134.354 65.1513 132.522 65.2469 130.691 65.3425C129.327 65.3182 127.974 65.5818 126.719 66.1161C125.206 66.8941 124.215 68.4108 123.494 69.9536C121.877 73.4217 122.134 76.2118 122.69 79.9972Z"
                    fill="#2F2E41" />
                <path
                    d="M373.341 73.4251H222.653C222.487 73.4251 222.328 73.3592 222.211 73.2419C222.093 73.1246 222.027 72.9654 222.027 72.7995C222.027 72.6335 222.093 72.4744 222.211 72.3571C222.328 72.2398 222.487 72.1738 222.653 72.1738H373.341C373.507 72.1738 373.666 72.2398 373.783 72.3571C373.9 72.4744 373.966 72.6335 373.966 72.7995C373.966 72.9654 373.9 73.1246 373.783 73.2419C373.666 73.3592 373.507 73.4251 373.341 73.4251Z"
                    fill="#E6E6E6" />
                <path
                    d="M373.341 112.215H222.653C222.487 112.215 222.328 112.149 222.211 112.032C222.093 111.915 222.027 111.756 222.027 111.59C222.027 111.424 222.093 111.265 222.211 111.147C222.328 111.03 222.487 110.964 222.653 110.964H373.341C373.507 110.964 373.666 111.03 373.783 111.147C373.9 111.265 373.966 111.424 373.966 111.59C373.966 111.756 373.9 111.915 373.783 112.032C373.666 112.149 373.507 112.215 373.341 112.215Z"
                    fill="#E6E6E6" />
                <path
                    d="M373.341 151.005H222.653C222.487 151.005 222.328 150.939 222.211 150.822C222.093 150.705 222.027 150.545 222.027 150.379C222.027 150.213 222.093 150.054 222.211 149.937C222.328 149.82 222.487 149.754 222.653 149.754H373.341C373.507 149.754 373.666 149.82 373.783 149.937C373.9 150.054 373.966 150.213 373.966 150.379C373.966 150.545 373.9 150.705 373.783 150.822C373.666 150.939 373.507 151.005 373.341 151.005Z"
                    fill="#E6E6E6" />
                <path
                    d="M395.769 148.525C390.253 148.525 384.918 146.555 380.725 142.969C379.326 141.772 377.836 139.691 376.296 136.784C375.313 134.911 374.762 132.842 374.685 130.728C374.607 128.615 375.004 126.511 375.847 124.571C375.035 125.398 374.136 126.135 373.167 126.77L372.677 127.096L372.648 126.508C372.63 126.126 372.62 125.745 372.62 125.376C372.62 123.201 374.265 120.994 373.507 118.984C370.309 110.511 360.168 102.091 374.866 88.9784C376.225 87.7654 374.409 85.3401 374.409 83.5048C374.409 65.5188 399.829 34.4025 407.034 50.8862C415.919 71.2148 431.227 69.0404 438.009 73.2393L438.107 73.5363L437.817 73.6536C436.333 74.2774 434.77 74.6931 433.172 74.8892C434.875 75.2366 436.614 75.3822 438.352 75.3231L438.621 75.3137L438.687 75.5756C439.333 78.1691 439.66 80.832 439.659 83.5048L439.658 83.7938C439.652 85.8239 440.074 87.8326 440.896 89.6888C441.719 91.545 442.923 93.207 444.431 94.5665C446.812 96.738 448.715 99.3818 450.019 102.33C451.322 105.277 451.997 108.464 452 111.687C452 115.5 449.409 120.505 447.235 124.033C446.684 124.931 445.936 125.693 445.049 126.262C444.161 126.83 443.156 127.19 442.11 127.315C441.118 127.44 440.112 127.346 439.161 127.039C438.21 126.731 437.338 126.219 436.608 125.538C437.637 127.607 438.978 129.505 440.584 131.167L440.88 131.472L440.512 131.687C436.973 133.751 432.949 134.838 428.851 134.836L428.611 134.835C425.607 134.804 422.627 135.373 419.846 136.508C417.064 137.644 414.537 139.323 412.413 141.447C410.249 143.675 407.663 145.449 404.805 146.665C401.947 147.88 398.875 148.513 395.769 148.525Z"
                    fill="#E6E6E6" />
                <path
                    d="M387.805 185.184C387.705 185.184 387.608 185.148 387.532 185.082C387.457 185.017 387.407 184.926 387.393 184.827C386.305 175.119 386.24 165.324 387.199 155.602C388.636 140.267 393.251 118.178 407.074 100.037C407.107 99.9932 407.148 99.9566 407.195 99.9291C407.243 99.9016 407.295 99.8837 407.349 99.8764C407.403 99.8691 407.458 99.8725 407.511 99.8865C407.564 99.9005 407.614 99.9247 407.657 99.9579C407.701 99.991 407.737 100.032 407.765 100.08C407.792 100.127 407.81 100.179 407.817 100.233C407.825 100.287 407.821 100.343 407.807 100.395C407.793 100.448 407.769 100.498 407.736 100.541C394.032 118.526 389.455 140.453 388.028 155.68C387.074 165.336 387.137 175.066 388.218 184.709C388.226 184.768 388.222 184.829 388.205 184.886C388.188 184.943 388.159 184.996 388.12 185.041C388.081 185.086 388.032 185.122 387.978 185.147C387.924 185.172 387.865 185.184 387.805 185.184Z"
                    fill="#3F3D56" />
                <path
                    d="M399.085 125.626C398.998 125.626 398.912 125.598 398.841 125.546C398.769 125.495 398.716 125.422 398.689 125.338C398.662 125.255 398.662 125.164 398.689 125.081C398.716 124.997 398.769 124.924 398.84 124.873C402.779 122.206 407.034 120.04 411.507 118.424C418.496 115.866 429.123 113.546 440.064 117.028C440.116 117.045 440.164 117.072 440.206 117.107C440.248 117.142 440.282 117.185 440.307 117.234C440.332 117.282 440.348 117.335 440.352 117.39C440.357 117.444 440.351 117.499 440.334 117.551C440.318 117.603 440.291 117.652 440.256 117.693C440.22 117.735 440.177 117.77 440.129 117.795C440.08 117.82 440.027 117.835 439.973 117.84C439.918 117.844 439.863 117.838 439.811 117.822C429.095 114.411 418.66 116.692 411.794 119.206C407.392 120.795 403.206 122.924 399.33 125.546C399.259 125.598 399.173 125.626 399.085 125.626Z"
                    fill="#3F3D56" />
                <path
                    d="M380.032 78.1701C380.025 78.0824 380.047 77.9949 380.093 77.9202C380.14 77.8455 380.209 77.7874 380.29 77.7543C380.371 77.7211 380.461 77.7146 380.547 77.7356C380.632 77.7567 380.709 77.8042 380.765 77.8714C383.708 81.608 386.175 85.6959 388.109 90.0412C391.164 96.8274 394.243 107.26 391.558 118.423C391.545 118.476 391.522 118.526 391.49 118.571C391.458 118.615 391.417 118.653 391.371 118.681C391.324 118.71 391.272 118.729 391.218 118.738C391.164 118.746 391.109 118.744 391.056 118.731C391.002 118.719 390.952 118.695 390.908 118.663C390.864 118.631 390.826 118.59 390.798 118.543C390.769 118.497 390.75 118.445 390.742 118.391C390.733 118.337 390.735 118.281 390.748 118.228C393.378 107.294 390.352 97.0508 387.35 90.383C385.448 86.1075 383.023 82.085 380.129 78.4082C380.072 78.3411 380.038 78.2577 380.032 78.1701Z"
                    fill="#3F3D56" />
                <path
                    d="M39.5873 140.196C37.4629 138.072 34.9359 136.393 32.1543 135.258C29.3728 134.123 26.3926 133.554 23.3885 133.585L23.1485 133.585C19.0514 133.587 15.0274 132.5 11.488 130.436L11.1197 130.222L11.4164 129.916C13.0223 128.255 14.3633 126.356 15.3923 124.287C14.6615 124.968 13.7898 125.481 12.839 125.788C11.8882 126.095 10.8815 126.189 9.89021 126.064C8.84361 125.94 7.83886 125.58 6.9513 125.011C6.06375 124.443 5.31644 123.68 4.76544 122.782C2.59139 119.255 -0.000106812 114.249 -0.000106812 110.436C0.00327656 107.213 0.678094 104.027 1.98134 101.079C3.28459 98.1312 5.18768 95.4874 7.56929 93.3159C9.07706 91.9564 10.2814 90.2944 11.1037 88.4382C11.9261 86.582 12.348 84.5734 12.342 82.5432L12.3408 82.2543C12.3404 79.5814 12.667 76.9185 13.3134 74.325L13.379 74.0631L13.6485 74.0725C15.3861 74.1316 17.1247 73.986 18.8283 73.6385C17.2303 73.4425 15.6671 73.0268 14.1829 72.403L13.893 72.2857L13.9914 71.9887C20.7729 67.7898 36.0807 69.9642 44.9659 49.6356C52.1705 33.1519 77.5908 64.2682 77.5908 82.2542C77.5908 84.0895 75.7746 86.5147 77.1342 87.7277C91.8323 100.841 81.6908 109.261 78.4934 117.733C77.7348 119.744 79.3797 121.951 79.3797 124.125C79.3797 124.495 79.3703 124.875 79.3517 125.258L79.3234 125.845L78.8333 125.519C77.8638 124.884 76.9652 124.147 76.1527 123.321C76.9956 125.26 77.3928 127.364 77.3152 129.478C77.2375 131.591 76.687 133.66 75.704 135.533C74.1637 138.44 72.6735 140.522 71.2749 141.719C66.7946 145.553 61.019 147.531 55.1289 147.248C49.2389 146.965 43.6794 144.442 39.5873 140.196Z"
                    fill="#E6E6E6" />
                <path
                    d="M64.195 183.934C64.2951 183.934 64.3919 183.897 64.4675 183.832C64.5431 183.766 64.5925 183.675 64.6066 183.576C65.6952 173.868 65.7603 164.073 64.8008 154.352C63.3639 139.016 58.7495 116.927 44.9264 98.7861C44.8933 98.7426 44.8519 98.706 44.8046 98.6786C44.7574 98.6511 44.7052 98.6331 44.651 98.6258C44.5968 98.6185 44.5417 98.6219 44.4889 98.6359C44.436 98.6499 44.3864 98.6741 44.3429 98.7073C44.2994 98.7404 44.2629 98.7818 44.2354 98.829C44.2079 98.8763 44.19 98.9285 44.1827 98.9827C44.1754 99.0368 44.1788 99.092 44.1928 99.1448C44.2068 99.1977 44.231 99.2473 44.2641 99.2907C57.9678 117.275 62.545 139.202 63.9718 154.429C64.9261 164.086 64.8626 173.816 63.7823 183.459C63.7739 183.518 63.7784 183.578 63.7953 183.635C63.8122 183.692 63.8412 183.745 63.8803 183.79C63.9194 183.835 63.9678 183.871 64.0221 183.896C64.0763 183.921 64.1353 183.934 64.195 183.934Z"
                    fill="#3F3D56" />
                <path
                    d="M52.9145 124.375C53.0025 124.375 53.0882 124.347 53.1594 124.296C53.2305 124.244 53.2835 124.171 53.3107 124.088C53.338 124.004 53.338 123.914 53.3108 123.83C53.2837 123.747 53.2308 123.674 53.1596 123.622C49.2209 120.956 44.9659 118.79 40.4926 117.174C33.504 114.615 22.8768 112.296 11.9363 115.778C11.8842 115.794 11.8359 115.821 11.7941 115.856C11.7523 115.891 11.7179 115.935 11.6928 115.983C11.6677 116.032 11.6524 116.085 11.6477 116.139C11.6431 116.194 11.6493 116.248 11.6659 116.301C11.6824 116.353 11.7091 116.401 11.7444 116.443C11.7796 116.484 11.8228 116.519 11.8713 116.544C11.9199 116.569 11.9729 116.584 12.0274 116.589C12.0818 116.594 12.1367 116.588 12.1888 116.571C22.9051 113.161 33.3397 115.442 40.2063 117.956C44.6077 119.544 48.7944 121.674 52.6701 124.295C52.7411 124.347 52.8267 124.375 52.9145 124.375Z"
                    fill="#3F3D56" />
                <path
                    d="M71.9682 76.9194C71.9746 76.8317 71.9531 76.7442 71.9067 76.6695C71.8603 76.5948 71.7915 76.5367 71.71 76.5035C71.6286 76.4704 71.5387 76.4638 71.4533 76.4849C71.3679 76.5059 71.2914 76.5535 71.2347 76.6207C68.2919 80.3573 65.8251 84.4452 63.8911 88.7905C60.8359 95.5767 57.757 106.009 60.4421 117.172C60.4548 117.225 60.4778 117.276 60.5099 117.32C60.542 117.364 60.5826 117.402 60.6292 117.431C60.6759 117.459 60.7278 117.478 60.7819 117.487C60.836 117.496 60.8912 117.493 60.9445 117.481C60.9977 117.468 61.0479 117.445 61.0922 117.412C61.1364 117.38 61.1739 117.34 61.2024 117.293C61.231 117.246 61.25 117.194 61.2584 117.14C61.2668 117.086 61.2645 117.031 61.2516 116.977C58.6217 106.043 61.6484 95.8001 64.6504 89.1323C66.5516 84.8568 68.9772 80.8343 71.8713 77.1574C71.928 77.0904 71.9619 77.007 71.9682 76.9194Z"
                    fill="#3F3D56" />
                <path
                    d="M333.524 198.438C353.076 198.438 368.927 182.587 368.927 163.035C368.927 143.482 353.076 127.632 333.524 127.632C313.971 127.632 298.121 143.482 298.121 163.035C298.121 182.587 313.971 198.438 333.524 198.438Z"
                    fill="#FF5958" />
                <path
                    d="M332.279 177.236C331.804 177.236 331.335 177.126 330.91 176.913C330.485 176.701 330.115 176.392 329.83 176.012L322.324 166.003C322.083 165.682 321.907 165.316 321.808 164.926C321.708 164.537 321.686 164.132 321.743 163.734C321.8 163.336 321.934 162.953 322.139 162.607C322.344 162.262 322.615 161.96 322.936 161.718C323.586 161.231 324.402 161.022 325.205 161.137C325.603 161.194 325.986 161.329 326.332 161.533C326.678 161.738 326.98 162.009 327.221 162.331L332.132 168.878L344.745 149.959C345.195 149.283 345.896 148.814 346.692 148.655C347.488 148.496 348.314 148.659 348.99 149.11C349.665 149.56 350.134 150.26 350.293 151.056C350.452 151.852 350.289 152.679 349.839 153.354L334.826 175.873C334.554 176.281 334.189 176.617 333.76 176.854C333.332 177.091 332.853 177.222 332.364 177.236C332.335 177.236 332.307 177.236 332.279 177.236Z"
                    fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1325_5244">
                    <rect width="452" height="227.859" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default IDVPersonalInfo;
