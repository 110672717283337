import React from "react";

const CustomerPortalChecklist = () => {
    return <svg height="271" width="100%" viewBox="0 0 401 271" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_301_6868)">
            <path d="M366.39 94.7973C365.97 95.1425 365.55 95.4728 365.12 95.798H259.067C258.277 95.4728 257.49 95.1392 256.707 94.7973C245.511 89.9203 235.09 83.4261 225.777 75.5221C219.593 70.2779 213.903 64.8637 209.779 59.7897C204.025 52.7091 198.726 44.993 196.261 36.2012C193.802 27.4092 194.536 17.3313 200.031 10.0406C201.732 7.79782 203.823 5.88064 206.205 4.3811C206.6 4.1309 207 3.89072 207.409 3.66554C213.998 -0.0373765 222.312 -0.938091 229.731 0.978418C240.355 3.72558 249.183 11.3966 255.482 20.3937C261.781 29.3858 265.871 39.7139 269.9 49.927C273.989 31.6976 286.372 15.3697 303.1 7.06821C319.822 -1.23332 340.599 -1.13324 356.967 7.85884C362.117 10.7053 366.769 14.3729 370.74 18.7174C371.134 19.1427 371.52 19.5781 371.899 20.0184C379.883 29.2707 384.877 41.12 385.287 53.3397C385.812 69.082 378.523 84.8945 366.39 94.7973Z" fill="#F2F2F2" />
            <path d="M263.306 95.7981H261.806C261.786 95.4629 261.761 95.1326 261.731 94.7973C261.207 88.252 260.109 81.7653 258.452 75.4121C254.648 60.9076 248.151 47.2493 239.299 35.1504C230.473 23.0128 219.347 12.7328 206.554 4.89654C206.461 4.84288 206.381 4.76857 206.32 4.67926C206.259 4.58995 206.22 4.488 206.205 4.38113C206.18 4.2414 206.196 4.09758 206.25 3.96643C206.304 3.83529 206.394 3.72222 206.51 3.64041C206.626 3.5586 206.762 3.51141 206.904 3.50435C207.045 3.49728 207.186 3.53064 207.309 3.60052C207.344 3.62043 207.377 3.64214 207.409 3.66557C214.657 8.08732 221.385 13.3109 227.466 19.2378C238.344 29.8593 247.116 42.4455 253.317 56.332C258.789 68.494 262.14 81.5036 263.226 94.7973C263.256 95.1326 263.281 95.4679 263.306 95.7981Z" fill="white" />
            <path d="M371.899 20.0182C371.889 20.0238 371.877 20.0272 371.864 20.0282C370.77 20.2284 369.67 20.4385 368.58 20.6637C359.634 22.5027 350.876 25.1644 342.419 28.615C333.984 32.0607 325.87 36.2469 318.172 41.1248C310.465 46.0002 303.197 51.5388 296.451 57.6779C289.705 63.8081 283.516 70.5265 277.959 77.7537C273.805 83.1686 270.028 88.8632 266.655 94.7971C266.466 95.1273 266.28 95.4626 266.095 95.7979H264.381C264.561 95.4626 264.746 95.1273 264.936 94.7971C269.016 87.5263 273.695 80.6086 278.924 74.1158C284.706 66.9301 291.115 60.2737 298.076 54.2251C304.992 48.2107 312.415 42.8063 320.262 38.0724C328.142 33.3194 336.426 29.2737 345.018 25.9829C353.357 22.7923 361.965 20.3605 370.74 18.7172C370.984 18.6721 371.224 18.6271 371.469 18.5821C372.404 18.4119 372.804 19.823 371.899 20.0182Z" fill="white" />
            <path d="M99.3247 98.773C101.992 93.7337 102.432 87.1702 99.1157 82.5331C97.1417 79.7728 94.0595 77.9609 90.8254 76.9389C87.5914 75.9168 84.1797 75.6164 80.7974 75.3681C77.4492 75.1222 74.0415 74.9254 70.7708 75.6834C67.5001 76.4414 64.3321 78.2931 62.7388 81.2509C60.0472 86.2475 62.4991 92.6546 60.5222 97.9752C58.708 102.858 53.5446 105.713 48.4689 106.865C43.3931 108.017 38.0954 107.894 33.0171 109.035C27.9389 110.176 22.7669 113.004 20.9205 117.874C19.5853 121.396 20.2487 125.453 22.0081 128.784C23.7674 132.114 26.5228 134.81 29.4475 137.181C33.8108 140.718 38.9155 143.747 44.5218 144.054C50.429 144.377 56.205 141.562 60.4567 137.444C64.7085 133.327 67.603 128.006 69.9156 122.555C71.3827 119.098 72.7689 115.376 75.7089 113.041C77.8693 111.326 80.6171 110.574 83.2818 109.863L86.2992 109.058C89.0335 108.342 91.5925 107.074 93.8187 105.331C96.0449 103.588 97.8913 101.407 99.2443 98.9237C99.2713 98.8736 99.2981 98.8234 99.3247 98.773Z" fill="#1D2027" />
            <path d="M75.528 264.541H69.3995L66.4834 240.878H75.5295L75.528 264.541Z" fill="#FFB8B8" />
            <path d="M65.0214 262.788H76.8415V270.237H57.5791C57.5791 269.259 57.7716 268.29 58.1456 267.386C58.5196 266.482 59.0678 265.661 59.7589 264.969C60.45 264.278 61.2704 263.729 62.1734 263.355C63.0763 262.98 64.0441 262.788 65.0214 262.788Z" fill="#1D2027" />
            <path d="M126.52 264.541H120.392L117.476 240.878H126.522L126.52 264.541Z" fill="#FFB8B8" />
            <path d="M116.014 262.788H127.834V270.237H108.571C108.571 269.259 108.764 268.29 109.138 267.386C109.512 266.482 110.06 265.661 110.751 264.969C111.442 264.278 112.263 263.729 113.166 263.355C114.068 262.98 115.036 262.788 116.014 262.788Z" fill="#1D2027" />
            <path d="M87.3095 245.733C87.224 245.733 87.1385 245.729 87.0535 245.72L62.1186 243.045C61.7875 243.01 61.4677 242.904 61.1805 242.735C60.8933 242.566 60.6454 242.338 60.4532 242.066C60.261 241.794 60.129 241.484 60.0659 241.156C60.0028 240.829 60.0101 240.492 60.0873 240.168L82.1652 156.116C82.2686 155.689 82.4893 155.298 82.8025 154.99C83.1156 154.681 83.5088 154.466 83.9376 154.369L104.418 148.654C104.829 148.564 105.256 148.585 105.656 148.715C106.056 148.846 106.414 149.081 106.692 149.396C119.861 164.263 129.784 197.219 137.301 238.759C137.358 239.072 137.35 239.394 137.279 239.704C137.207 240.015 137.073 240.307 136.885 240.564C136.697 240.821 136.459 241.037 136.185 241.199C135.911 241.361 135.606 241.465 135.291 241.506L114.86 244.117C114.28 244.192 113.694 244.048 113.215 243.712C112.737 243.376 112.401 242.873 112.275 242.302L102.335 197.943C102.243 197.534 102.014 197.17 101.687 196.91C101.359 196.651 100.952 196.512 100.534 196.517C100.116 196.521 99.7121 196.67 99.3904 196.937C99.0687 197.204 98.8484 197.574 98.7664 197.984L89.6026 243.846C89.4989 244.378 89.2133 244.857 88.7948 245.202C88.3764 245.546 87.8512 245.734 87.3095 245.733Z" fill="#1D2027" />
            <path d="M83.5169 110.314C90.2982 110.314 95.7956 104.812 95.7956 98.0241C95.7956 91.2364 90.2982 85.7339 83.5169 85.7339C76.7356 85.7339 71.2383 91.2364 71.2383 98.0241C71.2383 104.812 76.7356 110.314 83.5169 110.314Z" fill="#FFB8B8" />
            <path d="M83.1739 156.353C82.6578 156.353 82.1562 156.181 81.7474 155.866C81.3386 155.55 81.0454 155.108 80.9136 154.609L73.5269 126.832C72.9937 124.836 73.2145 122.714 74.147 120.871C75.0795 119.027 76.6582 117.593 78.5811 116.842C84.5272 114.519 90.1922 113.657 95.4187 114.279C100.064 114.834 105.794 142.361 105.415 142.948L107.248 148.452C107.352 148.764 107.39 149.095 107.358 149.422C107.326 149.75 107.225 150.067 107.063 150.353C106.901 150.639 106.68 150.888 106.416 151.083C106.151 151.279 105.849 151.416 105.528 151.487L83.6835 156.297C83.5162 156.334 83.3453 156.353 83.1739 156.353Z" fill="#CCCCCC" />
            <path d="M89.7939 184.759C89.8991 184.212 89.8833 183.649 89.7476 183.109C89.6118 182.569 89.3595 182.065 89.0083 181.634C88.6571 181.202 88.2156 180.853 87.7149 180.61C87.2142 180.368 86.6665 180.238 86.1104 180.23L81.8546 167.385L77.5049 172.908L82.2131 184.259C82.2622 185.195 82.6526 186.081 83.3103 186.748C83.968 187.415 84.8475 187.818 85.7819 187.879C86.7163 187.941 87.6408 187.657 88.3803 187.082C89.1197 186.507 89.6227 185.68 89.7939 184.759Z" fill="#FFB8B8" />
            <path d="M144.509 153.888C144.255 153.393 143.894 152.96 143.454 152.62C143.014 152.279 142.505 152.04 141.962 151.917C141.42 151.795 140.857 151.793 140.314 151.913C139.77 152.032 139.26 152.268 138.818 152.606L127.542 145.14L127.531 152.172L138.241 158.185C138.857 158.89 139.711 159.345 140.64 159.463C141.569 159.58 142.509 159.353 143.282 158.823C144.054 158.294 144.606 157.499 144.832 156.589C145.059 155.68 144.944 154.719 144.509 153.888Z" fill="#FFB8B8" />
            <path d="M80.8716 178.824C80.3784 178.824 79.8978 178.668 79.498 178.379C79.0983 178.09 78.7997 177.682 78.6449 177.214L68.6681 156.089C68.2589 154.851 68.0505 153.555 68.0508 152.251L71.0503 124.794C71.0502 123.257 71.6363 121.777 72.6889 120.658C73.7415 119.538 75.1813 118.863 76.7144 118.77C78.2474 118.677 79.7581 119.174 80.9381 120.158C82.118 121.142 82.8782 122.54 83.0635 124.066L78.4743 155.406L85.7976 174.641C85.9134 175.153 85.8543 175.689 85.6296 176.163C85.405 176.637 85.0278 177.022 84.5586 177.256L81.92 178.576C81.5944 178.739 81.2355 178.824 80.8716 178.824Z" fill="#CCCCCC" />
            <path d="M134.155 156.661C133.788 156.662 133.426 156.575 133.098 156.409L113.257 146.378C112.094 145.79 111.032 145.02 110.113 144.096L90.7475 124.649C89.6633 123.561 89.0352 122.099 88.9912 120.562C88.9473 119.026 89.491 117.53 90.5113 116.381C91.5315 115.232 92.9514 114.516 94.4811 114.379C96.0109 114.243 97.535 114.696 98.7425 115.645L119.721 138.965L136.35 149.533C136.793 149.814 137.129 150.235 137.304 150.73C137.479 151.224 137.483 151.763 137.316 152.261L136.379 155.061C136.222 155.527 135.923 155.932 135.525 156.22C135.126 156.507 134.647 156.661 134.155 156.661Z" fill="#CCCCCC" />
            <path d="M69.5503 91.67C71.636 94.4011 74.6342 96.2895 77.9966 96.9899C81.4047 97.6948 84.9518 97.1861 88.0251 95.5517C90.498 94.2143 92.4769 92.207 94.4151 90.2308C95.224 89.4061 96.0855 88.4549 96.0448 87.3298C95.9818 85.5866 93.8881 84.6335 92.0911 84.2534C86.1927 83.0217 80.0553 83.7126 74.5775 86.2248C72.1379 87.3591 69.596 89.4113 69.9623 91.9622" fill="#1D2027" />
            <path d="M392.001 94.7974H151.038C148.785 94.8006 146.625 95.6978 145.032 97.2924C143.439 98.8871 142.543 101.049 142.54 103.304V196.377C142.543 198.632 143.439 200.794 145.032 202.389C146.625 203.984 148.785 204.881 151.038 204.884H392.001C394.254 204.881 396.414 203.984 398.007 202.389C399.601 200.794 400.497 198.632 400.5 196.377V103.304C400.497 101.049 399.601 98.8871 398.007 97.2924C396.414 95.6978 394.254 94.8006 392.001 94.7974ZM399.5 196.377C399.498 198.367 398.707 200.275 397.301 201.682C395.896 203.089 393.99 203.881 392.001 203.883H151.038C149.05 203.881 147.144 203.089 145.738 201.682C144.333 200.275 143.542 198.367 143.539 196.377V103.304C143.542 101.314 144.333 99.4064 145.738 97.9993C147.144 96.5921 149.05 95.8006 151.038 95.7982H392.001C393.99 95.8006 395.896 96.5921 397.301 97.9993C398.707 99.4064 399.498 101.314 399.5 103.304V196.377Z" fill="#1D2027" />
            <path d="M194.788 178.864C189.053 178.864 183.447 177.161 178.679 173.972C173.911 170.783 170.194 166.251 168 160.947C165.805 155.644 165.231 149.809 166.35 144.179C167.468 138.549 170.23 133.377 174.285 129.318C178.34 125.26 183.507 122.495 189.131 121.376C194.756 120.256 200.586 120.83 205.884 123.027C211.182 125.224 215.711 128.944 218.897 133.717C222.083 138.489 223.784 144.101 223.784 149.841C223.775 157.535 220.717 164.912 215.281 170.353C209.846 175.794 202.476 178.855 194.788 178.864ZM194.788 121.819C189.251 121.819 183.838 123.462 179.234 126.541C174.631 129.62 171.042 133.997 168.923 139.117C166.804 144.238 166.25 149.872 167.33 155.308C168.41 160.743 171.077 165.736 174.992 169.655C178.907 173.574 183.896 176.243 189.326 177.324C194.757 178.406 200.386 177.851 205.502 175.73C210.617 173.609 214.989 170.017 218.066 165.409C221.142 160.801 222.784 155.383 222.784 149.841C222.775 142.411 219.823 135.289 214.575 130.035C209.326 124.782 202.21 121.827 194.788 121.819Z" fill="#1D2027" />
            <path d="M370.498 131.326H255.015C253.401 131.327 251.85 131.953 250.687 133.074C249.524 134.194 248.839 135.721 248.776 137.336C248.768 137.417 248.765 137.499 248.766 137.581C248.769 139.239 249.428 140.829 250.599 142.001C251.771 143.174 253.359 143.834 255.015 143.836H370.498C372.155 143.836 373.745 143.177 374.916 142.004C376.088 140.831 376.747 139.24 376.747 137.581C376.747 135.922 376.088 134.331 374.916 133.158C373.745 131.985 372.155 131.326 370.498 131.326Z" fill="#ED5A30" />
            <path d="M370.498 155.845H255.015C253.401 155.846 251.85 156.472 250.687 157.593C249.524 158.713 248.839 160.24 248.776 161.855C248.768 161.936 248.765 162.018 248.766 162.1C248.769 163.758 249.428 165.348 250.599 166.52C251.771 167.693 253.359 168.353 255.015 168.355H370.498C372.155 168.355 373.745 167.696 374.916 166.523C376.088 165.35 376.747 163.759 376.747 162.1C376.747 160.441 376.088 158.85 374.916 157.677C373.745 156.504 372.155 155.845 370.498 155.845Z" fill="#ED5A30" />
            <path d="M191.553 162.522C190.744 162.523 189.957 162.261 189.309 161.776L189.269 161.745L180.819 155.269C180.427 154.969 180.099 154.595 179.852 154.167C179.605 153.74 179.445 153.268 179.38 152.779C179.315 152.29 179.348 151.793 179.475 151.316C179.602 150.839 179.822 150.392 180.122 150C180.422 149.609 180.796 149.28 181.223 149.033C181.649 148.786 182.121 148.625 182.609 148.561C183.098 148.496 183.595 148.528 184.071 148.655C184.548 148.783 184.994 149.003 185.386 149.303L190.859 153.504L203.795 136.619C204.095 136.227 204.469 135.899 204.896 135.652C205.323 135.405 205.794 135.244 206.283 135.18C206.771 135.115 207.268 135.148 207.744 135.275C208.221 135.403 208.667 135.623 209.058 135.923L208.978 136.033L209.061 135.925C209.85 136.532 210.366 137.428 210.496 138.415C210.627 139.403 210.36 140.402 209.755 141.194L194.54 161.055C194.189 161.513 193.736 161.883 193.218 162.137C192.7 162.392 192.13 162.523 191.553 162.522Z" fill="#ED5A30" />
            <path d="M191.471 270.936H0.999924C0.867336 270.936 0.740178 270.883 0.646424 270.789C0.55267 270.695 0.5 270.568 0.5 270.435C0.5 270.303 0.55267 270.175 0.646424 270.082C0.740178 269.988 0.867336 269.935 0.999924 269.935H191.471C191.603 269.935 191.731 269.988 191.824 270.082C191.918 270.175 191.971 270.303 191.971 270.435C191.971 270.568 191.918 270.695 191.824 270.789C191.731 270.883 191.603 270.936 191.471 270.936Z" fill="#1D2027" />
            <path d="M342.077 72.2543C331.633 70.8165 321.027 71.0558 310.659 72.9633C300.321 74.855 290.341 78.3477 281.077 83.3157C275.871 86.1157 270.93 89.3832 266.315 93.078C265.561 93.6809 266.628 94.7369 267.375 94.1395C275.474 87.6913 284.552 82.5834 294.265 79.0098C303.901 75.4426 314.029 73.3859 324.292 72.9124C330.1 72.6455 335.919 72.9097 341.678 73.7018C341.87 73.7477 342.071 73.7192 342.243 73.6218C342.414 73.5245 342.542 73.3657 342.601 73.1776C342.652 72.9857 342.626 72.7811 342.528 72.6083C342.43 72.4354 342.268 72.3082 342.077 72.2543Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_301_6868">
                <rect width="400" height="270.936" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>

};

export default CustomerPortalChecklist;
