
import QuotePanel from "./QuotePanel";
import { QuoteContainer } from "./components";

const Quote = () => {
    return (
        <QuoteContainer>
            <QuotePanel />
        </QuoteContainer>
    );
};

export default Quote;
