import {useEffect, useState} from "react";
import {getDealByID} from "api";
import {Deal} from "types/deal";
import {useSnackbar} from "notistack";
import {AlertTypes} from "types/alertTypes";
import configuration from "utils/configuration";
import { useHistory } from "react-router-dom";

const useFetchDeal = (id: string) => {
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [deal, setDeal] = useState<Deal>();
  const history = useHistory();

  const refresh = () => {
    if (loading || !id) return;
    setLoading(true);
    getDealByID(+id)
      .then((res) => setDeal(res.data))
      .catch((err) => {
        console.log(err);
        if ([403, 404].includes(err?.response?.status)) {
          return history.replace('/not-found');
        }
        enqueueSnackbar("There was an error loading the data", {
          variant: AlertTypes.Error,
          autoHideDuration: configuration.autoHideErrorDuration,
        });
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    refresh();
  }, [id]);

  return [deal, loading, refresh] as const;
};

export default useFetchDeal;
