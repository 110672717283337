import {ChangeEvent, FC, useState} from "react";
import Stack from "@mui/material/Stack";
import {getDocumentFormattedSize, getDocumentLatestUpdate, getDocumentName} from "utils/intakeHelper";
import {DocumentType} from "types/deal";
import {FilesFilterTypes} from "../../hooks/useOnboardingFiles";
import {FileRow} from "./FileRow";
import DeleteFileDialog from "./DeleteFileDialog";

interface FilesListProps {
  files: DocumentType[];
  onUpload: (
    event: ChangeEvent<HTMLInputElement>,
    context?: Object | null,
  ) => Promise<void>;
  selectedFilter: FilesFilterTypes;
  setSelectedFilter: (filter: FilesFilterTypes) => void;
  onDelete: (id: number) => Promise<void>;
}

const FilesList: FC<FilesListProps> = ({files, onUpload, selectedFilter, setSelectedFilter, onDelete}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<{id: number, name: string}|null>(null);
  const handleDelete = async () => {
    if (showDeleteModal === null) return;
    await onDelete(showDeleteModal.id);
    setShowDeleteModal(null);
  }

  return (
    <Stack gap="1.6rem">
      {files.map((document, index) => (
        <FileRow
          key={document.id}
          documentName={getDocumentName(document)}
          documentType={document.original_type}
          documentId={document.id}
          fileStatus={document.status}
          description={
            document.description ||
            `${
              document.original_type || document.document_type
            } . Please contact our operations team for any questions about this document.`
          }
          documentLatestUpdate={getDocumentLatestUpdate(document)}
          originalFileName={document.original_filename}
          originalFileExtension={document.original_extension}
          originalFileSize={getDocumentFormattedSize(document)}
          onFileUploaded={onUpload}
          onFileDeleted={() => {
            if (document.status === "Temporary") {
              void onDelete(document.id);
            } else {
              setShowDeleteModal({id: document.id, name: document.document_type});
            }
          }}
          id={`file-input-${index}`}
          defaultExpanded={selectedFilter === "outstanding" || document.status === "Temporary"}
          setSelectedFilter={setSelectedFilter}
        />
      ))}

      <DeleteFileDialog
        onCancel={() => setShowDeleteModal(null)}
        onConfirm={handleDelete}
        show={showDeleteModal !== null}
        name={showDeleteModal?.name}
      />
    </Stack>
  );
};

export default FilesList;
