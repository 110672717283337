import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import configuration from "utils/configuration";
import colors from "components/CommonDashboard/Styling/Colors";
import { BodyRegular, H2, H3, SubtitleRegular } from "components/CommonDashboard/Typography";
import Button from "components/CommonDashboard/Button";
import { ArrowRightIcon, PhoneIcon } from "components/CommonDashboard/Icons";
import HeartDealOverview from "components/Svg/HeartDealOverview";
import { QuoteContainer } from "../Quote/components";
import Colors from "components/CommonDashboard/Styling/Colors";
import useScrollTopEffect from "utils/hooks/useScrollTopEffect";


const resources = [
    { name: 'How to choose a real estate lawyer', url: '/blog/how-to-choose-a-real-estate-lawyer' },
    { name: 'What happens at a real estate closing', url: '/blog/what-happens-at-a-real-estate-closing' },
    { name: 'Guide to closing on your first home in Canada', url: '/blog/first-time-homebuyers-closing-guide' },
    { name: 'How to budget for your closing costs', url: '/blog/real-estate-closing-costs' },
    { name: 'What is a mortgage closing', url: '/blog/what-is-a-mortgage-closing' },
];

const ThankYou = () => {
    useScrollTopEffect([]);

    return (
        <QuoteContainer>
            <MainContainer>
                <MessageContainer>
                    <Stack gap="2.4rem" maxWidth="65.6rem">
                        <H2 data-testid="quote-thank-you-page" color={colors.NEUTRAL_700}>
                            We're excited to work with you
                        </H2>
                        <BodyRegular mb="3.2rem">
                            We wanted to extend a heartfelt thank you for taking the time to request a quote from us. Your trust in our services means the world to us.
                            <br />
                            <br />
                            Should you have any questions or need further assistance, please feel free to schedule a call by clicking below.  We're here to help.  Every step of the way.
                        </BodyRegular>
                        <ButtonContainer>
                            <Button
                                component="a"
                                href={configuration.callScheduleUrl}
                                typeOf="CTA"
                                data-testid="quote-schedule-a-call-button"
                                label={{ text: "Schedule a call" }}
                                startIcon={<PhoneIcon color={colors.WHITE} />}
                            />
                        </ButtonContainer>
                    </Stack>
                    <Stack pb="2" justifyContent="center">
                        <HeartDealOverview />
                    </Stack>
                </MessageContainer>
                <Stack gap='2.4rem'>
                    <H3>Helpful resources</H3>
                    <Stack sx={{
                        display: 'grid',
                        gridTemplateColumns: { mobile: '1fr', md: '1fr 1fr' },
                        gap: '1.6rem 4.8rem',
                    }} >
                        {resources.map((resource, index) => {
                            return <Stack
                                key={index}
                                sx={{
                                    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.05)',
                                    padding: '1.6rem',
                                    borderRadius: '2.4rem',
                                    justifyContent: 'space-between',
                                    flexFlow: 'row',
                                    alignItems: 'center'
                                }}
                            >
                                <SubtitleRegular>{resource.name}</SubtitleRegular>
                                <Button
                                    component='a'
                                    href={configuration.webPageUrl + resource.url}
                                    target="_blank"
                                    typeOf="outline"
                                    size="small"
                                    startIcon={<ArrowRightIcon color={Colors.NEUTRAL_700} />}
                                />
                            </Stack>
                        })}
                    </Stack>
                </Stack>
            </MainContainer>
        </QuoteContainer>
    );
};

const ButtonContainer = styled(Stack)(({ theme }) => ({
    gap: '2rem',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('mobile')]: {
        alignItems: 'stretch'
    }
}));

const MessageContainer = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column-reverse',
    }
}));

const MainContainer = styled(Stack)(({ theme }) => ({
    gap: '4.8rem',
    borderRadius: '2.4rem',
    background: `${Colors.WHITE}`,
    padding: '4.8rem',
    [theme.breakpoints.down('mobile')]: {
        padding: '2.4rem',
    }
}));

export default ThankYou;
