import {FC} from "react";

const UserProfileSettings: FC = () => (
  <svg width="100%" height="342" viewBox="0 0 401 342" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_434_17772)">
      <path
        d="M186.735 312.71C189.054 313.526 191.544 313.73 193.965 313.303C196.386 312.877 198.656 311.834 200.557 310.276C205.398 306.212 206.917 299.519 208.151 293.321L211.804 274.985L204.156 280.251C198.656 284.039 193.032 287.947 189.224 293.433C185.416 298.918 183.755 306.406 186.813 312.342"
        fill="#E4E4E4" />
      <path
        d="M187.916 336.185C186.953 329.172 185.963 322.068 186.64 314.98C187.239 308.684 189.16 302.537 193.07 297.494C195.146 294.823 197.671 292.534 200.533 290.731C201.279 290.26 201.966 291.443 201.223 291.912C196.271 295.041 192.441 299.663 190.284 305.109C187.903 311.166 187.521 317.769 187.931 324.203C188.179 328.094 188.706 331.96 189.235 335.821C189.277 335.995 189.25 336.179 189.162 336.335C189.073 336.492 188.928 336.608 188.757 336.662C188.582 336.709 188.396 336.685 188.239 336.596C188.081 336.506 187.965 336.358 187.916 336.184L187.916 336.185Z"
        fill="#F2F2F2" />
      <path
        d="M194.844 324.932C195.839 326.444 197.206 327.674 198.814 328.505C200.422 329.335 202.217 329.737 204.025 329.672C208.674 329.451 212.549 326.208 216.036 323.128L226.352 314.019L219.525 313.692C214.615 313.457 209.578 313.237 204.901 314.753C200.224 316.268 195.911 319.911 195.056 324.752"
        fill="#E4E4E4" />
      <path
        d="M185.194 340.216C189.829 332.016 195.204 322.903 204.809 319.99C207.479 319.183 210.28 318.894 213.059 319.138C213.935 319.214 213.716 320.564 212.842 320.489C208.183 320.102 203.534 321.332 199.676 323.972C195.964 326.499 193.073 330.012 190.627 333.737C189.128 336.019 187.786 338.398 186.444 340.773C186.015 341.533 184.761 340.984 185.194 340.216Z"
        fill="#F2F2F2" />
      <path d="M339.024 240.824H0.5V0.353271H339.024V240.824Z" fill="white" />
      <path
        d="M28.0758 99.1187C27.201 99.1187 26.362 99.4662 25.7434 100.085C25.1249 100.703 24.7773 101.542 24.7773 102.417C24.7773 103.292 25.1249 104.131 25.7434 104.749C26.362 105.368 27.201 105.716 28.0758 105.716H142.396C143.27 105.714 144.107 105.366 144.724 104.747C145.342 104.129 145.688 103.291 145.688 102.417C145.688 101.543 145.342 100.705 144.724 100.087C144.107 99.4685 143.27 99.1203 142.396 99.1187H28.0758Z"
        fill="#F45753" />
      <path
        d="M28.0754 118.909C27.2006 118.907 26.3609 119.253 25.741 119.87C25.1212 120.488 24.7719 121.326 24.77 122.201C24.7682 123.075 25.1139 123.915 25.7312 124.535C26.3485 125.155 27.1867 125.504 28.0615 125.506H142.48C143.355 125.506 144.194 125.159 144.812 124.54C145.431 123.921 145.779 123.082 145.779 122.208C145.779 121.333 145.431 120.494 144.812 119.875C144.194 119.257 143.355 118.909 142.48 118.909H28.0754Z"
        fill="#F45753" />
      <path
        d="M28.0754 138.417C27.2006 138.415 26.3609 138.761 25.741 139.378C25.1212 139.995 24.7719 140.834 24.77 141.708C24.7682 142.583 25.1139 143.423 25.7312 144.043C26.3485 144.663 27.1867 145.012 28.0615 145.014H53.8091C54.6839 145.014 55.5229 144.666 56.1414 144.048C56.76 143.429 57.1075 142.59 57.1075 141.715C57.1075 140.841 56.76 140.002 56.1414 139.383C55.5229 138.765 54.6839 138.417 53.8091 138.417H28.0754Z"
        fill="#F45753" />
      <path d="M339.024 240.824H0.5V0.353271H339.024V240.824ZM6.06478 235.259H333.459V5.91805H6.06478V235.259Z"
            fill="#E5E5E5" />
      <path
        d="M232.506 187.985C230.934 187.983 229.424 188.605 228.31 189.715C227.196 190.826 226.568 192.333 226.565 193.906C226.562 195.478 227.183 196.988 228.292 198.103C229.402 199.218 230.908 199.847 232.481 199.851H289.135C289.914 199.852 290.687 199.699 291.407 199.401C292.128 199.104 292.782 198.667 293.334 198.116C293.886 197.565 294.323 196.91 294.622 196.19C294.92 195.47 295.074 194.698 295.074 193.918C295.074 193.138 294.92 192.366 294.622 191.646C294.323 190.926 293.886 190.272 293.334 189.721C292.782 189.17 292.128 188.733 291.407 188.435C290.687 188.137 289.914 187.984 289.135 187.985H232.506Z"
        fill="#E4E4E4" />
      <path
        d="M232.506 116.133C230.934 116.131 229.424 116.754 228.31 117.864C227.196 118.974 226.568 120.481 226.565 122.054C226.562 123.627 227.183 125.137 228.292 126.252C229.402 127.366 230.908 127.995 232.481 128H289.135C289.914 128.001 290.687 127.848 291.407 127.55C292.128 127.252 292.782 126.815 293.334 126.264C293.886 125.713 294.323 125.059 294.622 124.338C294.92 123.618 295.074 122.846 295.074 122.067C295.074 121.287 294.92 120.515 294.622 119.795C294.323 119.074 293.886 118.42 293.334 117.869C292.782 117.318 292.128 116.881 291.407 116.583C290.687 116.285 289.914 116.133 289.135 116.133H232.506Z"
        fill="#E4E4E4" />
      <path
        d="M28.0754 190.733C27.6423 190.732 27.2132 190.816 26.8126 190.981C26.4121 191.146 26.048 191.388 25.741 191.694C25.4341 192 25.1904 192.363 25.0238 192.763C24.8572 193.162 24.7709 193.591 24.77 194.024C24.7691 194.458 24.8535 194.887 25.0184 195.287C25.1834 195.688 25.4256 196.052 25.7312 196.359C26.0368 196.666 26.3999 196.909 26.7998 197.076C27.1996 197.243 27.6284 197.329 28.0615 197.33H142.48C143.355 197.33 144.194 196.982 144.812 196.364C145.431 195.745 145.779 194.906 145.779 194.031C145.779 193.157 145.431 192.318 144.812 191.699C144.194 191.08 143.355 190.733 142.48 190.733H28.0754Z"
        fill="#E4E4E4" />
      <path
        d="M28.0754 210.241C27.6423 210.24 27.2132 210.324 26.8126 210.489C26.4121 210.654 26.048 210.896 25.741 211.202C25.4341 211.508 25.1904 211.871 25.0238 212.271C24.8572 212.67 24.7709 213.099 24.77 213.532C24.7691 213.965 24.8535 214.394 25.0184 214.795C25.1834 215.196 25.4256 215.56 25.7312 215.867C26.0368 216.174 26.3999 216.417 26.7998 216.584C27.1996 216.75 27.6284 216.837 28.0615 216.838H53.8091C54.6839 216.838 55.5229 216.49 56.1414 215.872C56.76 215.253 57.1075 214.414 57.1075 213.539C57.1075 212.664 56.76 211.825 56.1414 211.207C55.5229 210.588 54.6839 210.241 53.8091 210.241H28.0754Z"
        fill="#E4E4E4" />
      <path
        d="M28.0758 170.942C27.201 170.942 26.362 171.29 25.7434 171.908C25.1249 172.527 24.7773 173.366 24.7773 174.241C24.7773 175.116 25.1249 175.955 25.7434 176.573C26.362 177.192 27.201 177.539 28.0758 177.539H142.396C143.27 177.538 144.107 177.189 144.724 176.571C145.342 175.953 145.688 175.115 145.688 174.241C145.688 173.367 145.342 172.529 144.724 171.911C144.107 171.292 143.27 170.944 142.396 170.942H28.0758Z"
        fill="#E4E4E4" />
      <path
        d="M28.0758 27.2952C27.201 27.2952 26.362 27.6427 25.7434 28.2613C25.1249 28.8798 24.7773 29.7188 24.7773 30.5936C24.7773 31.4684 25.1249 32.3074 25.7434 32.9259C26.362 33.5445 27.201 33.892 28.0758 33.892H142.396C143.27 33.8904 144.107 33.5421 144.724 32.9237C145.342 32.3053 145.688 31.4673 145.688 30.5936C145.688 29.7199 145.342 28.8819 144.724 28.2635C144.107 27.6451 143.27 27.2968 142.396 27.2952H28.0758Z"
        fill="#E4E4E4" />
      <path
        d="M28.0754 47.0857C27.6423 47.0848 27.2132 47.1692 26.8126 47.3341C26.4121 47.499 26.048 47.7412 25.741 48.0469C25.4341 48.3525 25.1904 48.7156 25.0238 49.1155C24.8572 49.5153 24.7709 49.944 24.77 50.3772C24.7691 50.8103 24.8535 51.2394 25.0184 51.64C25.1834 52.0405 25.4256 52.4046 25.7312 52.7116C26.0368 53.0185 26.3999 53.2622 26.7998 53.4288C27.1996 53.5954 27.6284 53.6817 28.0615 53.6826H142.48C142.913 53.6826 143.342 53.5973 143.742 53.4315C144.143 53.2657 144.506 53.0228 144.812 52.7165C145.119 52.4102 145.362 52.0466 145.527 51.6464C145.693 51.2462 145.779 50.8173 145.779 50.3841C145.779 49.951 145.693 49.5221 145.527 49.1219C145.362 48.7217 145.119 48.3581 144.812 48.0518C144.506 47.7455 144.143 47.5025 143.742 47.3368C143.342 47.171 142.913 47.0857 142.48 47.0857H28.0754Z"
        fill="#E4E4E4" />
      <path
        d="M28.0754 66.5933C27.2006 66.5914 26.3609 66.9372 25.741 67.5544C25.1212 68.1717 24.7719 69.0099 24.77 69.8847C24.7682 70.7595 25.1139 71.5992 25.7312 72.2191C26.3485 72.839 27.1867 73.1883 28.0615 73.1901H53.8091C54.6839 73.1901 55.5229 72.8426 56.1414 72.224C56.76 71.6055 57.1075 70.7665 57.1075 69.8917C57.1075 69.0169 56.76 68.1779 56.1414 67.5594C55.5229 66.9408 54.6839 66.5933 53.8091 66.5933H28.0754Z"
        fill="#E4E4E4" />
      <path
        d="M232.506 44.3377C230.934 44.3355 229.424 44.9579 228.31 46.0681C227.196 47.1784 226.568 48.6855 226.565 50.2584C226.562 51.8312 227.183 53.341 228.292 54.4559C229.402 55.5708 230.908 56.1996 232.481 56.2041H289.135C289.914 56.2049 290.687 56.052 291.407 55.7541C292.128 55.4563 292.782 55.0194 293.334 54.4684C293.886 53.9173 294.323 53.263 294.622 52.5428C294.92 51.8225 295.074 51.0505 295.074 50.2709C295.074 49.4912 294.92 48.7192 294.622 47.9989C294.323 47.2787 293.886 46.6244 293.334 46.0734C292.782 45.5223 292.128 45.0854 291.407 44.7876C290.687 44.4898 289.914 44.3369 289.135 44.3377H232.506Z"
        fill="#E4E4E4" />
      <path
        d="M244.441 60.855C250.318 60.855 255.082 56.0911 255.082 50.2145C255.082 44.3379 250.318 39.574 244.441 39.574C238.565 39.574 233.801 44.3379 233.801 50.2145C233.801 56.0911 238.565 60.855 244.441 60.855Z"
        fill="#CACACA" />
      <path
        d="M263.039 124.639C262.921 125.493 262.992 126.363 263.246 127.187C263.501 128.011 263.933 128.769 264.512 129.407C265.091 130.046 265.803 130.55 266.598 130.884C267.393 131.218 268.252 131.373 269.114 131.339L274.764 143.576L283.038 141.956L274.816 124.787C274.663 123.344 273.985 122.006 272.912 121.029C271.839 120.051 270.445 119.501 268.993 119.482C267.541 119.464 266.134 119.979 265.036 120.929C263.939 121.88 263.228 123.2 263.039 124.639Z"
        fill="#FFB6B6" />
      <path
        d="M320.28 170.947L297.225 159.543L280.957 131.933L269.586 137.433L284.343 169.329L321.623 190.85L320.28 170.947Z"
        fill="#3F3D56" />
      <path d="M268.232 334.168L260.984 334.167L257.536 313.271L268.233 313.272L268.232 334.168Z" fill="#FFB6B6" />
      <path
        d="M270.08 341.193L246.711 341.192V340.896C246.712 338.484 247.67 336.171 249.376 334.465C251.081 332.759 253.395 331.801 255.807 331.801H255.808L270.08 331.801L270.08 341.193Z"
        fill="#2F2E41" />
      <path d="M340.573 320.875L335.516 326.067L318.141 313.958L325.604 306.295L340.573 320.875Z" fill="#FFB6B6" />
      <path
        d="M346.895 324.453L330.59 341.193L330.378 340.986C328.65 339.303 327.662 337.003 327.63 334.591C327.598 332.179 328.525 329.853 330.208 328.124L340.167 317.9L346.895 324.453Z"
        fill="#2F2E41" />
      <path
        d="M277.191 209.81H272.179C259.148 224.049 249.201 240.828 242.963 259.094L236.975 276.626L258.317 321.31L271.634 321.548L256.413 274.108L271.747 253.773L272.52 294.651L321.876 320.521L331.754 305.929L291.592 283.968L298.431 249.197C298.431 249.197 312.192 237.722 304.179 219.782L277.191 209.81Z"
        fill="#2F2E41" />
      <path
        d="M343.162 164.305L322.332 155.182L299.407 165.341C299.407 165.341 292.915 169.034 297.053 178.492L272.179 209.81C272.179 209.81 272.225 209.823 303.485 219.782L331.689 194.945C335.972 191.173 339.259 186.405 341.26 181.06C343.261 175.716 343.914 169.961 343.162 164.305Z"
        fill="#3F3D56" />
      <path
        d="M339.666 154.913C347.685 154.913 354.185 148.412 354.185 140.394C354.185 132.375 347.685 125.875 339.666 125.875C331.647 125.875 325.147 132.375 325.147 140.394C325.147 148.412 331.647 154.913 339.666 154.913Z"
        fill="#FFB6B6" />
      <path
        d="M358.469 121.127C355.137 118.416 349.969 117.824 346.529 120.396C340.53 117.247 332.422 118.89 328.121 124.126C326.573 126.01 325.477 128.559 326.242 130.874C327.008 133.188 330.311 134.549 332.032 132.822L332.001 133.534C333.552 135.086 334.627 137.049 335.1 139.192C335.573 141.335 335.425 143.568 334.672 145.629C333.92 147.69 332.594 149.494 330.852 150.828C329.11 152.161 327.023 152.97 324.836 153.159C329.594 155.081 334.352 157.003 339.11 158.925C341.112 159.733 343.177 160.555 345.336 160.544C347.495 160.533 349.797 159.484 350.634 157.494C351.237 159.34 351.839 161.187 352.441 163.033C355.988 157.391 358.905 151.377 361.14 145.1C362.581 141.053 363.745 136.821 363.589 132.528C363.434 128.236 361.801 123.837 358.469 121.127Z"
        fill="#2F2E41" />
      <path
        d="M244.441 204.502C250.318 204.502 255.082 199.738 255.082 193.862C255.082 187.985 250.318 183.221 244.441 183.221C238.565 183.221 233.801 187.985 233.801 193.862C233.801 199.738 238.565 204.502 244.441 204.502Z"
        fill="#CACACA" />
      <path
        d="M277.545 132.707C283.422 132.707 288.186 127.943 288.186 122.066C288.186 116.19 283.422 111.426 277.545 111.426C271.669 111.426 266.905 116.19 266.905 122.066C266.905 127.943 271.669 132.707 277.545 132.707Z"
        fill="#F45753" />
      <path
        d="M277.817 118.728C277.699 119.582 277.77 120.452 278.025 121.276C278.279 122.099 278.711 122.858 279.29 123.496C279.869 124.135 280.582 124.639 281.377 124.973C282.172 125.307 283.03 125.462 283.892 125.428L289.542 137.665L297.816 136.045L289.594 118.876C289.441 117.433 288.764 116.095 287.691 115.117C286.618 114.14 285.223 113.59 283.771 113.571C282.32 113.553 280.912 114.068 279.814 115.018C278.717 115.969 278.006 117.289 277.817 118.728Z"
        fill="#FFB6B6" />
      <path
        d="M335.058 165.036L312.004 153.632L295.735 126.021L284.364 131.522L299.121 163.418L336.401 184.939L335.058 165.036Z"
        fill="#3F3D56" />
      <path
        d="M399.909 341.647H174.684C174.528 341.647 174.377 341.584 174.266 341.473C174.156 341.363 174.093 341.212 174.093 341.055C174.093 340.899 174.156 340.748 174.266 340.637C174.377 340.527 174.528 340.464 174.684 340.464H399.909C400.066 340.464 400.216 340.527 400.327 340.637C400.438 340.748 400.5 340.899 400.5 341.055C400.5 341.212 400.438 341.363 400.327 341.473C400.216 341.584 400.066 341.647 399.909 341.647Z"
        fill="#CACACA" />
    </g>
    <defs>
      <clipPath id="clip0_434_17772">
        <rect width="400" height="341.293" fill="white" transform="translate(0.5 0.353271)" />
      </clipPath>
    </defs>
  </svg>
)

export default UserProfileSettings;