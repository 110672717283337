import { FC } from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { BodyRegular, H1 } from "components/CommonDashboard/Typography";
import { CheckCircleIcon } from "components/CommonDashboard/Icons";
import CompactReview from "components/CommonDashboard/Reviews/CompactReview";

const LandingDescription: FC = () => {
    return (
        <ContentContainer>
            <CompactReview />

            <Stack gap="2.4rem" flex={1}>
                <H1> What can Deeded do for you; a seamless, secure, and virtual process</H1>
                <BodyRegular>
                    When it comes to closing, Deeded handles, well, basically everything. Let us guide you through a
                    seamless experience that ensures the funds are in your hands quickly and effortlessly.
                </BodyRegular>
                <PointsContainer>
                    <Stack flex={1} gap="2.4rem">
                        <HelpPoint label="Stress-free closings" />
                        <HelpPoint label="Transparent process with on-demand progress updates" />
                    </Stack>
                    <Stack flex={1} gap="2.4rem">
                        <HelpPoint label="A dedicated team of experts at your service" />
                        <HelpPoint label="All done virtually, like it should be" />
                    </Stack>
                </PointsContainer>
            </Stack>
        </ContentContainer>
    );
};

const HelpPoint: FC<{ label: string }> = ({ label }) => (
    <Stack gap="0.8rem" direction="row" alignItems="center" maxWidth="30rem">
        <CheckCircleIcon />
        <BodyRegular sx={{ flex: 1 }}>{label}</BodyRegular>
    </Stack>
);

const PointsContainer = styled(Stack)(({ theme }) => ({
    gap: "2.4rem",
    flexDirection: "row",
    [theme.breakpoints.down("mobile")]: {
        flexDirection: "column",
    },
}));

const ContentContainer = styled(Stack)(({ theme }) => ({
    padding: "6rem 1.2rem",
    width: "100%",
    gap: "6rem",
    maxWidth: "122.4rem",
    alignSelf: "center",
    flexDirection: "row",
    [theme.breakpoints.down("mobile")]: {
        flexDirection: "column",
    },
}));

export default LandingDescription;
