import {User} from "./user";

export interface PartnerPage {
    id: number;
    user_id: number;
    slug: string;
    page_name: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
}

export interface PartnerPageWithUser extends PartnerPage {
    user: User;
}

export enum PartnerPageTransactionType {
    MORTGAGE = 'mortgage',
    PURCHASE = 'purchase',
    SALE = 'sale',
    OWNERSHIP_TRANSFER = 'ownership-transfer',
    LEGAL_ADVICE = 'legal-advice',
}