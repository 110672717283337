import React from "react";

function CloseMenu() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="29.0245"
        height="4"
        rx="2"
        transform="matrix(0.704147 0.710055 -0.704147 0.710055 3.5625 0)"
        fill="white"
      />
      <rect
        width="29.0245"
        height="4"
        rx="2"
        transform="matrix(0.704147 -0.710055 0.704147 0.710055 1 20.7026)"
        fill="white"
      />
    </svg>
  );
}

export default CloseMenu;
