import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { keyframes, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import useUploadDocument, { AgreementType } from "utils/hooks/useUploadDocument";
import { formatBytes } from "utils/formatBytes";
import { BodyRegular, H2 } from "components/CommonDashboard/Typography";
import colors from "components/CommonDashboard/Styling/Colors";
import Button from "components/CommonDashboard/Button";
import { CloseIcon, CloudUploadIcon, FolderIcon, LoadingIcon, TrashIcon } from "components/CommonDashboard/Icons";
import Pill from "components/CommonDashboard/Pill";
import { DealConvertedByClient } from "types/deal";
import Colors from "components/CommonDashboard/Styling/Colors";

interface QuoteUploadFieldProps {
    deal: DealConvertedByClient;
    loading: boolean;
    onFileSelected: (file: AgreementType | undefined) => void;
    testID?: string;
}

const QuoteUploadField: FC<QuoteUploadFieldProps> = ({ deal, onFileSelected, loading, testID }) => {
    const fileRef = useRef<HTMLInputElement>(null);
    const [agreement, setAgreement] = useState<AgreementType>();
    const [showDelete, setShowDelete] = useState(false);

    const documentType = useMemo(() => {
        switch (deal?.deal_type) {
            case "Sale":
            case "Purchase":
                if (deal.province === "ON") {
                    return "Agreement of Purchase & Sale";
                }
                return "Purchase Contract";

            case "Mortgage":
                if ((deal.deal_type_tag?.tag === "TitleTrn") || deal.deal_type_tag?.tag === 'ILA')
                    return 'Other';
                return "Mortgage Commitment";

            default:
                return "-";
        }
    }, [deal]);

    const handleClear = () => {
        setAgreement(undefined);
        setShowDelete(false);
    };

    const { onUpload, onFileDrop } = useUploadDocument({
        documentType,
        onFileUploaded: (file) => {
            setAgreement(file);
        },
        maxSize: 5 * 1024 * 1024, // 5MB
        customFileTypes: ["application/pdf", "image/jpeg", "image/png"],
    });
    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        onDrop: (f) => f.length > 0 && onFileDrop(f[0]),
    });

    useEffect(() => {
        onFileSelected(agreement);
    }, [agreement]);

    if (loading) {
        return (
            <Container data-testid={testID}>
                <Stack bgcolor={colors.PRIMARY_50} borderRadius="2.4rem" p="4.8rem" gap="3.2rem" alignItems="center">
                    <Box bgcolor={colors.PRIMARY_100} p="2.4rem" borderRadius="8rem" height="8rem">
                        <IconWrapper>
                            <LoadingIcon size={32} color={colors.NEUTRAL_700} />
                        </IconWrapper>
                    </Box>
                    <BodyRegular color={colors.NEUTRAL_600}>Uploading</BodyRegular>
                </Stack>
            </Container>
        );
    }

    return (
        <Container data-testid={testID}>
            {agreement ? (
                <Stack
                    bgcolor={colors.PRIMARY_50}
                    borderRadius="1.6rem"
                    p="1.6rem"
                    gap="0.8rem"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Pill
                        label={"." + agreement.file.name.split(".")?.pop()?.toUpperCase()}
                        color={colors.PRIMARY_600}
                        backgroundColor={colors.PRIMARY_100}
                    />
                    <BodyRegular flex={1} color={colors.NEUTRAL_700}>
                        {agreement.file.name} - {formatBytes(agreement.file.size)}
                    </BodyRegular>
                    <Button
                        onClick={() => setShowDelete(true)}
                        typeOf="secondary"
                        endIcon={<TrashIcon color={colors.NEUTRAL_700} />}
                    />
                </Stack>
            ) : (
                <Stack
                    {...getRootProps()}
                    bgcolor={colors.PRIMARY_50}
                    borderRadius="2.4rem"
                    p="4.8rem"
                    gap="3.2rem"
                    alignItems="center"
                >
                    <Box bgcolor={colors.PRIMARY_100} p="2.4rem" borderRadius="8rem" height="8rem">
                        <CloudUploadIcon size={32} />
                    </Box>

                    <BodyRegular align="center" color={colors.NEUTRAL_600}>
                        Drag and drop a file here or click the button bellow to select a file to upload (.pdf, .jpg or
                        .png, max 5MB each).
                    </BodyRegular>

                    <Button
                        typeOf="primary"
                        onClick={() => fileRef.current?.click()}
                        startIcon={<FolderIcon color={Colors.WHITE} />}
                        label={{ text: "Select a file" }}
                    />

                    <input
                        ref={fileRef}
                        data-testid="file-select"
                        id={deal.id.toString()}
                        name={deal.id.toString()}
                        type="file"
                        style={{ display: "none" }}
                        onChange={onUpload}
                        {...getInputProps}
                    />
                </Stack>
            )}

            <Dialog open={showDelete} sx={{ ".MuiDialog-paper": { width: "60rem", borderRadius: "2.4rem" } }}>
                <Stack gap="2.4rem" p="4.8rem">
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <H2>Delete document</H2>
                        <Button
                            typeOf="secondary"
                            size="small"
                            onClick={() => setShowDelete(false)}
                            startIcon={<CloseIcon />}
                        />
                    </Stack>

                    <BodyRegular color={colors.NEUTRAL_500}>Are you sure you want to delete this document?</BodyRegular>

                    <Stack gap="1.6rem" direction="row" justifyContent="end">
                        <Button typeOf="secondary" onClick={() => setShowDelete(false)} label={{ text: "Cancel" }} />
                        <Button typeOf="primary" onClick={handleClear} label={{ text: "Yes, delete" }} />
                    </Stack>
                </Stack>
            </Dialog>
        </Container>
    );
};

const loadingAnim = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const IconWrapper = styled(Box)`
    height: 3.2rem;
    animation-name: ${loadingAnim};
    animation-timing-function: linear;
    animation-duration: 3s;
    animation-iteration-count: infinite;
`;

const Container = styled(Box)`
    padding: 3.2rem 0;
    border-top: 1px solid ${colors.PRIMARY_100};
    border-bottom: 1px solid ${colors.PRIMARY_100};
`;

export default QuoteUploadField;
