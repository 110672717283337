import { Question, ElementFactory, Serializer } from "survey-core";
import { ReactQuestionFactory, SurveyQuestionElementBase } from "survey-react-ui";
import { createElement } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import configuration from "utils/configuration";

const CUSTOM_TYPE = "captcha";

export class QuestionCaptchaModel extends Question {
    getType() {
        return CUSTOM_TYPE;
    }
}

export function registerCaptcha() {
    ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
        return new QuestionCaptchaModel(name);
    });
}

Serializer.addClass(
    CUSTOM_TYPE,
    [],
    function () {
        return new QuestionCaptchaModel("");
    },
    "question"
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionCaptcha extends SurveyQuestionElementBase {
    constructor(props) {
        super(props);
        this.state = { value: this.question.value };
    }
    get question() {
        return this.questionBase;
    }
    get value() {
        return this.question.value;
    }

    handleVerify = (data) => {
        this.question.value = data;
    };
    // Support the read-only and design modes
    get style() {
        return this.question.getPropertyValue("readOnly") ||
            this.question.isDesignMode ? { display: "none" } : undefined;
    }

    renderInput() {
        return <HCaptcha
        sitekey={configuration.hCaptchaKey}
        onVerify={this.handleVerify}
      />;
    }

    renderElement() {
        return <div style={this.style}>{this.renderInput()}</div>;
    }
}

ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
    return createElement(SurveyQuestionCaptcha, props);
});