import {FC} from "react";

const UserProfileSecurity: FC = () => (
  <svg width="100%" height="348" viewBox="0 0 401 348" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_434_18721)">
      <path
        d="M370.851 322.174L381.626 318.756C382.969 312.973 382.88 306.95 381.367 301.209C377.038 310.835 364.338 313.193 357.101 320.875C354.863 323.289 353.196 326.175 352.225 329.32C351.253 332.465 351.002 335.788 351.489 339.044L349.222 346.734C354.517 346.281 359.651 344.687 364.269 342.059C368.888 339.432 372.883 335.834 375.977 331.515C377.689 329.074 379.087 326.428 380.138 323.638C375.543 323.178 370.851 322.174 370.851 322.174Z"
        fill="#F2F2F2" />
      <path
        d="M359.337 307.954L367.457 300.09C366.08 294.315 363.314 288.964 359.399 284.5C359.817 295.046 349.502 302.821 346.451 312.924C345.524 316.082 345.32 319.409 345.853 322.657C346.386 325.905 347.643 328.992 349.531 331.688L350.932 339.582C355.469 336.816 359.352 333.099 362.315 328.688C365.277 324.276 367.248 319.275 368.091 314.028C368.535 311.08 368.606 308.088 368.302 305.122C363.984 306.76 359.337 307.954 359.337 307.954Z"
        fill="#F2F2F2" />
      <path
        d="M305.362 23.4617H1.68137C1.36551 23.455 1.06485 23.3248 0.843825 23.0991C0.622805 22.8733 0.499023 22.57 0.499023 22.254C0.499023 21.9381 0.622805 21.6347 0.843825 21.409C1.06485 21.1832 1.36551 21.0531 1.68137 21.0464H305.362C305.678 21.0531 305.979 21.1832 306.2 21.409C306.421 21.6347 306.544 21.9381 306.544 22.254C306.544 22.57 306.421 22.8733 306.2 23.0991C305.979 23.3248 305.678 23.455 305.362 23.4617Z"
        fill="#3F3D56" />
      <path
        d="M22.3533 14.8804C26.262 14.8804 29.4307 11.6412 29.4307 7.64529C29.4307 3.64944 26.262 0.410156 22.3533 0.410156C18.4445 0.410156 15.2759 3.64944 15.2759 7.64529C15.2759 11.6412 18.4445 14.8804 22.3533 14.8804Z"
        fill="#3F3D56" />
      <path
        d="M46.8025 14.8804C50.7113 14.8804 53.8799 11.6412 53.8799 7.64529C53.8799 3.64944 50.7113 0.410156 46.8025 0.410156C42.8938 0.410156 39.7251 3.64944 39.7251 7.64529C39.7251 11.6412 42.8938 14.8804 46.8025 14.8804Z"
        fill="#3F3D56" />
      <path
        d="M71.2517 14.8804C75.1605 14.8804 78.3292 11.6412 78.3292 7.64529C78.3292 3.64944 75.1605 0.410156 71.2517 0.410156C67.343 0.410156 64.1743 3.64944 64.1743 7.64529C64.1743 11.6412 67.343 14.8804 71.2517 14.8804Z"
        fill="#3F3D56" />
      <path
        d="M290.641 2.20337H273.274C272.93 2.21022 272.602 2.35178 272.361 2.59765C272.12 2.84352 271.985 3.17409 271.985 3.5184C271.985 3.8627 272.12 4.19327 272.361 4.43914C272.602 4.68501 272.93 4.82657 273.274 4.83342H290.641C290.986 4.82657 291.314 4.68501 291.555 4.43914C291.796 4.19327 291.931 3.8627 291.931 3.5184C291.931 3.17409 291.796 2.84352 291.555 2.59765C291.314 2.35178 290.986 2.21022 290.641 2.20337Z"
        fill="#3F3D56" />
      <path
        d="M290.641 7.13965H273.274C272.93 7.14646 272.602 7.28801 272.361 7.53388C272.12 7.77975 271.985 8.11035 271.985 8.45468C271.985 8.79901 272.12 9.12961 272.361 9.37548C272.602 9.62136 272.93 9.76289 273.274 9.7697H290.641C290.986 9.76289 291.313 9.62136 291.555 9.37548C291.796 9.12961 291.931 8.79901 291.931 8.45468C291.931 8.11035 291.796 7.77975 291.555 7.53388C291.313 7.28801 290.986 7.14646 290.641 7.13965Z"
        fill="#3F3D56" />
      <path
        d="M290.641 12.0693H273.274C272.93 12.0761 272.602 12.2177 272.361 12.4636C272.12 12.7094 271.985 13.04 271.985 13.3844C271.985 13.7287 272.12 14.0593 272.361 14.3052C272.602 14.551 272.93 14.6926 273.274 14.6994H290.641C290.986 14.6926 291.313 14.551 291.555 14.3052C291.796 14.0593 291.931 13.7287 291.931 13.3844C291.931 13.04 291.796 12.7094 291.555 12.4636C291.313 12.2177 290.986 12.0761 290.641 12.0693Z"
        fill="#3F3D56" />
      <path
        d="M24.0257 72.5275C28.8581 72.5275 32.7755 68.6101 32.7755 63.7777C32.7755 58.9453 28.8581 55.0278 24.0257 55.0278C19.1933 55.0278 15.2759 58.9453 15.2759 63.7777C15.2759 68.6101 19.1933 72.5275 24.0257 72.5275Z"
        fill="#E6E7E8" />
      <path
        d="M246.841 83.1529H17.8814C17.1904 83.1529 16.5276 82.8784 16.039 82.3898C15.5504 81.9012 15.2759 81.2385 15.2759 80.5474C15.2759 79.8564 15.5504 79.1937 16.039 78.705C16.5276 78.2164 17.1904 77.9419 17.8814 77.9419H246.841C247.532 77.9419 248.195 78.2164 248.684 78.705C249.172 79.1937 249.447 79.8564 249.447 80.5474C249.447 81.2385 249.172 81.9012 248.684 82.3898C248.195 82.8784 247.532 83.1529 246.841 83.1529Z"
        fill="#E6E7E8" />
      <path
        d="M186.793 93.4486H17.8815C17.1905 93.4486 16.5277 93.1741 16.0391 92.6854C15.5504 92.1968 15.2759 91.534 15.2759 90.8429C15.2759 90.1519 15.5504 89.4891 16.0391 89.0005C16.5277 88.5118 17.1905 88.2373 17.8815 88.2373H186.793C187.484 88.2373 188.146 88.5118 188.635 89.0005C189.124 89.4891 189.398 90.1519 189.398 90.8429C189.398 91.534 189.124 92.1968 188.635 92.6854C188.146 93.1741 187.484 93.4486 186.793 93.4486Z"
        fill="#E6E7E8" />
      <path
        d="M24.0257 209.099C28.8581 209.099 32.7755 205.181 32.7755 200.349C32.7755 195.517 28.8581 191.599 24.0257 191.599C19.1933 191.599 15.2759 195.517 15.2759 200.349C15.2759 205.181 19.1933 209.099 24.0257 209.099Z"
        fill="#E6E7E8" />
      <path
        d="M246.842 219.724H17.8817C17.5392 219.725 17.1999 219.658 16.8833 219.527C16.5667 219.396 16.279 219.205 16.0366 218.963C15.7942 218.721 15.6019 218.433 15.4707 218.117C15.3395 217.8 15.272 217.461 15.272 217.119C15.272 216.776 15.3395 216.437 15.4707 216.121C15.6019 215.804 15.7942 215.517 16.0366 215.275C16.279 215.033 16.5667 214.841 16.8833 214.71C17.1999 214.58 17.5392 214.513 17.8817 214.513H246.842C247.567 214.554 248.252 214.864 248.761 215.383C249.27 215.902 249.566 216.593 249.592 217.32C249.57 217.659 249.482 217.99 249.332 218.295C249.182 218.599 248.973 218.871 248.717 219.095C248.462 219.318 248.164 219.489 247.842 219.597C247.52 219.705 247.18 219.748 246.842 219.724Z"
        fill="#E6E7E8" />
      <path
        d="M186.793 230.02H17.8815C17.1905 230.02 16.5277 229.745 16.0391 229.257C15.5504 228.768 15.2759 228.105 15.2759 227.414C15.2759 226.723 15.5504 226.06 16.0391 225.572C16.5277 225.083 17.1905 224.809 17.8815 224.809H186.793C187.484 224.809 188.146 225.083 188.635 225.572C189.124 226.06 189.398 226.723 189.398 227.414C189.398 228.105 189.124 228.768 188.635 229.257C188.146 229.745 187.484 230.02 186.793 230.02Z"
        fill="#E6E7E8" />
      <path
        d="M24.0257 135.665C28.8581 135.665 32.7755 131.748 32.7755 126.915C32.7755 122.083 28.8581 118.166 24.0257 118.166C19.1933 118.166 15.2759 122.083 15.2759 126.915C15.2759 131.748 19.1933 135.665 24.0257 135.665Z"
        fill="#E6E7E8" />
      <path
        d="M246.841 146.291H17.8814C17.1904 146.291 16.5276 146.016 16.039 145.527C15.5504 145.039 15.2759 144.376 15.2759 143.685C15.2759 142.994 15.5504 142.331 16.039 141.843C16.5276 141.354 17.1904 141.08 17.8814 141.08H246.841C247.532 141.08 248.195 141.354 248.684 141.843C249.172 142.331 249.447 142.994 249.447 143.685C249.447 144.376 249.172 145.039 248.684 145.527C248.195 146.016 247.532 146.291 246.841 146.291Z"
        fill="#E6E7E8" />
      <path
        d="M214.957 156.586H17.8815C17.1905 156.586 16.5277 156.312 16.0391 155.823C15.5504 155.334 15.2759 154.672 15.2759 153.981C15.2759 153.29 15.5504 152.627 16.0391 152.138C16.5277 151.65 17.1905 151.375 17.8815 151.375H214.957C215.648 151.375 216.311 151.65 216.8 152.138C217.288 152.627 217.563 153.29 217.563 153.981C217.563 154.672 217.288 155.334 216.8 155.823C216.311 156.312 215.648 156.586 214.957 156.586Z"
        fill="#E6E7E8" />
      <path
        d="M201.581 166.882H17.8815C17.1905 166.882 16.5277 166.608 16.0391 166.119C15.5504 165.63 15.2759 164.968 15.2759 164.277C15.2759 163.585 15.5504 162.923 16.0391 162.434C16.5277 161.945 17.1905 161.671 17.8815 161.671H201.581C202.272 161.671 202.935 161.945 203.424 162.434C203.912 162.923 204.187 163.585 204.187 164.277C204.187 164.968 203.912 165.63 203.424 166.119C202.935 166.608 202.272 166.882 201.581 166.882Z"
        fill="#E6E7E8" />
      <path
        d="M205.382 282.072C241.168 282.072 270.179 253.062 270.179 217.275C270.179 181.489 241.168 152.478 205.382 152.478C169.596 152.478 140.585 181.489 140.585 217.275C140.585 253.062 169.596 282.072 205.382 282.072Z"
        fill="#F45753" />
      <path
        d="M205.293 239.883C210.405 239.883 214.55 235.739 214.55 230.626C214.55 225.514 210.405 221.37 205.293 221.37C200.181 221.37 196.036 225.514 196.036 230.626C196.036 235.739 200.181 239.883 205.293 239.883Z"
        fill="#3F3D56" />
      <path
        d="M205.293 234.899C207.653 234.899 209.565 232.986 209.565 230.626C209.565 228.267 207.653 226.354 205.293 226.354C202.934 226.354 201.021 228.267 201.021 230.626C201.021 232.986 202.934 234.899 205.293 234.899Z"
        fill="#F45753" />
      <path
        d="M222.476 251.232H188.288C184.903 251.228 181.658 249.882 179.264 247.488C176.87 245.095 175.523 241.849 175.52 238.464V222.789C175.523 219.404 176.87 216.158 179.264 213.764C181.657 211.37 184.903 210.024 188.288 210.02H222.476C225.862 210.024 229.107 211.371 231.501 213.764C233.895 216.158 235.241 219.404 235.245 222.789V238.464C235.241 241.849 233.895 245.095 231.501 247.488C229.107 249.882 225.862 251.228 222.476 251.232ZM188.288 211.357C185.257 211.36 182.352 212.566 180.209 214.709C178.065 216.852 176.86 219.758 176.856 222.789V238.464C176.86 241.495 178.065 244.4 180.209 246.543C182.352 248.686 185.258 249.892 188.288 249.895H222.476C225.507 249.892 228.413 248.686 230.556 246.543C232.699 244.4 233.905 241.495 233.908 238.464V222.789C233.905 219.758 232.699 216.852 230.556 214.709C228.413 212.566 225.507 211.36 222.476 211.357L188.288 211.357Z"
        fill="white" />
      <path
        d="M186.263 242.723H216.609C218.184 242.723 219.744 242.413 221.199 241.81C222.654 241.207 223.976 240.324 225.09 239.21C226.204 238.097 227.087 236.774 227.69 235.319C228.293 233.864 228.603 232.304 228.603 230.729V217.611C228.6 216.157 228.332 214.715 227.812 213.356C229.292 214.471 230.493 215.915 231.321 217.573C232.148 219.231 232.578 221.059 232.578 222.912V236.03C232.578 237.605 232.268 239.165 231.665 240.62C231.063 242.075 230.179 243.397 229.065 244.511C227.952 245.625 226.63 246.508 225.174 247.111C223.719 247.714 222.159 248.024 220.584 248.024H190.239C187.807 248.023 185.433 247.282 183.432 245.899C181.431 244.517 179.898 242.559 179.036 240.285C181.113 241.867 183.652 242.724 186.263 242.723Z"
        fill="#3F3D56" />
      <path d="M279.191 339.018L271.735 339.017L268.187 310.255L279.193 310.256L279.191 339.018Z" fill="#A0616A" />
      <path
        d="M279.454 346.796L256.523 346.795V346.505C256.523 344.138 257.464 341.868 259.138 340.195C260.811 338.521 263.081 337.581 265.448 337.58L269.637 334.403L277.452 337.581L279.454 337.581L279.454 346.796Z"
        fill="#2F2E41" />
      <path d="M331.952 339.018L339.409 339.017L342.957 310.255L331.951 310.256L331.952 339.018Z" fill="#A0616A" />
      <path
        d="M331.69 346.796L354.62 346.795V346.505C354.62 344.138 353.679 341.868 352.006 340.195C350.332 338.521 348.062 337.581 345.695 337.58L341.506 334.403L333.691 337.581L331.689 337.581L331.69 346.796Z"
        fill="#2F2E41" />
      <path
        d="M267.859 289.119C267.969 287.478 268.845 278.402 268.845 278.402L270.942 259.153L274.638 208.13L274.88 204.79L294.243 201.073L307.477 194.729L323.207 199.757L335.456 204.35C335.456 204.35 335.477 206.316 335.571 208.692C335.697 211.801 335.917 215.613 336.442 216.604C337.317 218.355 337.097 227.756 337.097 227.756C337.097 227.756 337.967 246.884 339.063 248.981C340.159 251.079 340.379 255.446 340.049 256.322C339.719 257.198 341.145 280.169 341.145 280.169C341.145 280.169 344.805 320.819 342.509 326.948C340.212 333.078 331.534 329.063 331.534 329.063C331.534 329.063 331.953 327.427 329.437 326.656C326.92 325.885 319.254 268.245 319.254 268.245C319.254 268.245 317.828 256.427 316.517 253.04C315.206 249.653 314 240.136 314 240.136C314 240.136 305.904 224.385 305.8 220.882C305.8 220.882 304.74 217.605 303.739 221.213C302.737 224.82 299.786 233.461 299.786 233.461L296.907 244.074L293.52 259.929L291.328 273.279C291.328 273.279 288.046 290.996 287.521 291.762C286.997 292.527 287.521 308.713 287.521 308.713C287.521 308.713 284.984 322.498 281.397 324.905C277.811 327.312 268.309 324.943 268.309 324.943C268.309 324.943 267.749 290.76 267.859 289.119Z"
        fill="#2F2E41" />
      <path
        d="M276.12 158.256L280.425 171.223L275.113 177.546L277.452 179.953L275.727 183.355L271.228 219.021L295.892 228.375L304.198 217.961L310.747 228.375L339.287 221.401L332.008 175.921V172.947L329.953 167.206V155.052L335.38 118.873L319.183 113.199L311.439 107.07L308.933 115.69L298.263 113.792L297.985 107.316L289.821 115.03L282.946 116.99L274.715 118.873L273.666 121.495L275.763 120.97L275.422 155.078L276.12 158.256Z"
        fill="#2F2E41" />
      <path
        d="M268.747 205.7L272.89 165.604L273.983 149.101L262.389 147.005C262.389 147.005 262.848 158.804 261.329 165.462C259.83 172.031 261.13 204.819 261.223 205.696C260.371 206.457 259.771 207.459 259.501 208.569C259.232 209.679 259.306 210.845 259.713 211.912C260.12 212.979 260.842 213.898 261.782 214.546C262.723 215.194 263.838 215.541 264.98 215.542C266.123 215.542 267.238 215.196 268.179 214.549C269.121 213.902 269.843 212.984 270.251 211.917C270.66 210.85 270.734 209.685 270.466 208.575C270.197 207.464 269.598 206.462 268.747 205.7Z"
        fill="#A0616A" />
      <path
        d="M257.438 173.912L260.662 174.578L271.726 176.87L272.339 176.995L273.645 171.805L275.16 170.19L278.044 155.078L278.196 154.292L282.553 144.98L285.568 116.991L274.358 119.004C274.3 119.062 274.248 119.125 274.195 119.188C272.585 121.012 271.437 123.55 269.56 126.104C263.452 134.414 258.764 154.606 258.948 165.67C258.953 165.848 258.77 166.042 258.549 166.241C257.51 167.197 256.832 168.482 256.629 169.879C256.427 171.277 256.712 172.701 257.438 173.912Z"
        fill="#2F2E41" />
      <path
        d="M340.823 205.7L336.681 165.604L335.587 149.101L347.182 147.005C347.182 147.005 346.722 158.804 348.241 165.462C349.74 172.031 348.44 204.819 348.347 205.696C349.199 206.457 349.799 207.459 350.069 208.569C350.338 209.679 350.265 210.845 349.857 211.912C349.45 212.979 348.728 213.898 347.788 214.546C346.847 215.194 345.732 215.541 344.59 215.542C343.447 215.542 342.332 215.196 341.391 214.549C340.45 213.902 339.727 212.984 339.319 211.917C338.91 210.85 338.836 209.685 339.104 208.575C339.373 207.464 339.972 206.462 340.823 205.7Z"
        fill="#A0616A" />
      <path
        d="M352.132 173.912L348.908 174.578L337.844 176.87L337.231 176.995L335.925 171.805L334.41 170.19L331.526 155.078L331.374 154.292L327.017 144.98L328.013 116.991L335.212 119.004C335.27 119.062 335.323 119.125 335.375 119.188C336.985 121.012 338.133 123.55 340.01 126.104C346.119 134.414 350.806 154.606 350.622 165.67C350.617 165.848 350.801 166.042 351.021 166.241C352.06 167.197 352.739 168.482 352.941 169.879C353.144 171.277 352.858 172.701 352.132 173.912Z"
        fill="#2F2E41" />
      <path
        d="M292.717 121.132L297.985 107.317L311.439 107.07L315.903 121.132L331.951 210.776C331.951 210.776 282.789 224.467 283.287 208.01C283.812 190.707 286.177 167.723 286.177 167.723L292.717 121.132Z"
        fill="#F45753" />
      <path
        d="M304.411 103.453C313.164 103.453 320.26 96.3567 320.26 87.6033C320.26 78.8499 313.164 71.7539 304.411 71.7539C295.658 71.7539 288.562 78.8499 288.562 87.6033C288.562 96.3567 295.658 103.453 304.411 103.453Z"
        fill="#A0616A" />
      <path
        d="M287.627 76.1051C290.207 73.2966 293.435 68.1557 303.21 65.9969C315.29 63.3293 320.998 74.2415 317.952 74.4204C326.859 77.1178 321.137 95.5511 318.583 94.0051C318.561 93.9899 318.54 93.9734 318.52 93.9559C317.147 92.9445 315.994 91.6637 315.132 90.1918C314.271 88.7199 313.718 87.0878 313.509 85.3951L313.024 81.0331C312.955 84.5821 309.327 85.0516 305.621 83.7445C305.84 82.9664 306.259 82.2588 306.835 81.6917C307.411 81.1245 308.125 80.7173 308.906 80.5103C306.323 82.8523 303.041 84.2802 299.567 84.574C299.749 83.6854 300.119 82.846 300.652 82.1122C301.185 81.3785 301.869 80.7673 302.658 80.3198L302.512 80.1761C301.065 81.3965 299.403 82.3373 297.612 82.9505C295.569 83.536 293.121 83.2192 291.722 81.6201C291.345 81.1788 291.06 80.6665 290.885 80.1133C291.187 82.924 289.302 86.1325 287.838 85.5816C286.619 85.3449 285.909 77.9748 287.627 76.1051Z"
        fill="#2F2E41" />
      <path
        d="M400.5 346.795C400.5 346.899 400.48 347.003 400.44 347.099C400.4 347.196 400.342 347.284 400.268 347.358C400.194 347.432 400.106 347.49 400.009 347.53C399.913 347.57 399.809 347.59 399.704 347.59H212.339C212.128 347.59 211.926 347.506 211.777 347.357C211.628 347.208 211.544 347.005 211.544 346.795C211.544 346.584 211.628 346.381 211.777 346.232C211.926 346.083 212.128 345.999 212.339 345.999H399.704C399.809 345.999 399.913 346.019 400.009 346.059C400.106 346.099 400.194 346.157 400.268 346.231C400.342 346.305 400.4 346.393 400.44 346.49C400.48 346.586 400.5 346.69 400.5 346.795Z"
        fill="#CCCCCC" />
      <path
        d="M205.908 182.142C199.571 182.142 194 185.011 190.846 189.317C194.218 186.863 198.289 185.557 202.459 185.591C212.273 185.591 220.258 192.466 220.258 200.916V207.94H223.707V197.467C223.707 189.016 215.723 182.142 205.908 182.142Z"
        fill="#3F3D56" />
      <path
        d="M226.077 211.535H184.687V199.357C184.687 189.532 193.971 181.538 205.382 181.538C216.793 181.538 226.077 189.532 226.077 199.357V211.535ZM186.024 210.198H224.74V199.357C224.74 190.269 216.056 182.875 205.382 182.875C194.708 182.875 186.024 190.269 186.024 199.357V210.198Z"
        fill="white" />
      <path
        d="M215.982 206.886H194.782C193.828 206.885 192.914 206.505 192.239 205.831C191.565 205.156 191.186 204.242 191.185 203.288V198.442C191.185 191.685 197.554 186.188 205.382 186.188C213.21 186.188 219.579 191.685 219.579 198.442V203.288C219.578 204.242 219.199 205.156 218.525 205.831C217.85 206.505 216.936 206.885 215.982 206.886ZM205.382 187.524C198.291 187.524 192.522 192.422 192.522 198.442V203.288C192.522 203.888 192.76 204.462 193.184 204.886C193.608 205.31 194.183 205.548 194.782 205.549H215.982C216.582 205.548 217.156 205.31 217.58 204.886C218.004 204.462 218.242 203.888 218.243 203.288V198.442C218.243 192.422 212.473 187.524 205.382 187.524Z"
        fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_434_18721">
        <rect width="400" height="347.18" fill="white" transform="translate(0.5 0.410156)" />
      </clipPath>
    </defs>
  </svg>
)

export default UserProfileSecurity;