import {useEffect, useContext, useState, useRef, ReactNode, FC} from "react";
import { PageProps } from "../types";
import BasicButton from "components/Common/Button/BasicButton";
import { pages } from "../paths";
import { ClientIntakeStates, IDVStates, OnboardingStates } from "types/intakeForm";
import { Client } from 'persona';
import { useMixpanel } from "utils/MixpanelContext";
import { resumeIDV, saveClientIDV } from "api";
import configuration from "utils/configuration";
import { CaretRight } from "components/Common/Icons/Iconography";
import constants from "styles/constants";
import useIDVStatusPolling from "customerPortal/hooks/useIDVStatusPolling";
import { AlertTypes } from "types/alertTypes";
import IDVInitial from "./IDVInitial";
import { IDVCompleted, IDVDeclined, IDVInProgress, IDVResume } from "./IDVStates";
import {useClientOnboarding} from "../../context/ClientOnboardingProvider";
import useUpdateIntakeStage from "../../hooks/useUpdateIntakeStage";
import {useSnackbar} from "notistack";
import useCustomerPortalAuthUser from "../../hooks/useCustomerPortalAuthUser";

export interface IDVStatePageProps {
    children?: ReactNode
}
interface CreateClientProps {
    sessionToken?: string,
    inquiryId?: string
}

enum PageStatus {
    Initial = 'initial',
    Completed = 'completed',
    Declined = 'declined',
    InProgress = 'in_progress',
    Resume = 'resume'
}

const PageEvents = {
    [PageStatus.Declined]: 'identity-verification_pending',
    [PageStatus.InProgress]: 'identity-verification_in_progress',
    [PageStatus.Completed]: 'identity-verification_successful',
}

const getPageByStatus = (status: string, isTimeout: boolean, idvStarted: boolean, canBeResumed: boolean | undefined): PageStatus => {
    if (!status)
        return PageStatus.Initial;
    if (status === IDVStates.Completed || status === IDVStates.Approved)
        return PageStatus.Completed;
    if (status === IDVStates.Failed ||
        status === IDVStates.Declined ||
        status === IDVStates.MarkedForReview ||
        isTimeout)
        return PageStatus.Declined;
    if (idvStarted)
        return PageStatus.InProgress;
    if (canBeResumed)
        return PageStatus.Resume;
    return PageStatus.Initial;
}

export const IDV: FC = () => {
    const [{ deal: { deal, onboarding } }] = useClientOnboarding();
    const [updateIntakeStage] = useUpdateIntakeStage();
    const {enqueueSnackbar} = useSnackbar();
    const authUser = useCustomerPortalAuthUser();

    const mixpanel = useMixpanel();
    const [hasStarted, setHasStarted] = useState(false);
    const clientRef = useRef<Client | null>(null);
    const previousPageStatusRef = useRef(PageStatus.Initial);
    const { status, isTimeout, stopTimer } = useIDVStatusPolling(onboarding, hasStarted);

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            if (clientRef.current?.isOpen) {
                mixpanel.track(`identity-verification_session_token_expired`, dealInfo);
            }
            clientRef.current && clientRef.current.destroy();
        };
    }, []);
    const dealInfo = {
        user_id: authUser?.id,
        deal_type: deal?.deal_type,
        deal_id: deal?.id
    };
    const createClient = ({ sessionToken, inquiryId }: CreateClientProps = {}) => {
        if (!onboarding) return;
        const props = sessionToken && inquiryId ? { sessionToken, inquiryId } :
            { templateId: configuration.idv.templateId, };
        const client: Client = new Client({
            ...props,
            environmentId: configuration.idv.environmentId,
            onLoad: () => {
                !hasStarted && setHasStarted(true);
                client.open();
            },
            onEvent: (name, metadata) => {
                if (name === 'start') {
                    const eventName = onboarding.user_idv ? 'restarted' : 'started';
                    metadata && metadata["inquiryId"] && saveClientIDV(onboarding.id, {
                        inquiry_id: metadata["inquiryId"],
                        state: 'start'
                    });
                    mixpanel.track(`onboarding_verification_${eventName}`, dealInfo);
                    setHasStarted(true);
                }
                if (name === 'error') {
                    enqueueSnackbar("There was an error loading the verification", {
                        variant: AlertTypes.Error,
                        autoHideDuration: configuration.autoHideErrorDuration,
                    });
                }
            },
            onCancel: ({ inquiryId, sessionToken }) => {
                mixpanel.track(`onboarding_verification_cancelled`, dealInfo);
                setHasStarted(false);
            },
            ...(authUser?.id ? { referenceId: String(authUser.id) } : {})
        })
        clientRef.current = client;
    };

    const handleResumeIDV = (setIsLoading: CallableFunction) => {
        if (!onboarding?.user_idv) return;
        if (clientRef.current) {
            !hasStarted && setHasStarted(true);
            return clientRef.current.open();
        }
        setIsLoading(true);
        resumeIDV(onboarding.id, onboarding.user_idv.id).then(({ data }) => {
            if (data?.session_token && onboarding.user_idv?.inquiry_id) {
                mixpanel.track(`identity-verification_resumed`, dealInfo);
                return createClient({ sessionToken: data.session_token, inquiryId: onboarding.user_idv.inquiry_id });
            }
            enqueueSnackbar("There was an error resuming your verification", {
                variant: AlertTypes.Error,
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        }).catch(() => {
            enqueueSnackbar("There was an error resuming your verification", {
                variant: AlertTypes.Error,
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        }).finally(() => setIsLoading(false))
    }

    const handleVerifyIdentity = () => {
        if (clientRef.current) {
            !hasStarted && setHasStarted(true);
            return clientRef.current.open();
        }
        createClient();
    }

    const handleNextButton = () => {
        let route, token;
        if (onboarding && onboarding.intakes.length > 0 && onboarding.intakes[0].state !== ClientIntakeStates.Completed) {
            route = pages.SURVEY.route;
        } else {
            route = pages.DEAL_STATUS.route;
            token = deal?.id;
        }
        stopTimer();
        updateIntakeStage(OnboardingStates.Completed, route, (token || '').toString())()
    };

    const pageStatus = getPageByStatus(status, isTimeout, hasStarted, onboarding?.user_idv?.can_be_resumed);
    useEffect(() => {
        const previousStatus = previousPageStatusRef.current;
        if (pageStatus !== previousStatus && pageStatus !== PageStatus.Initial &&
            pageStatus !== PageStatus.Resume
        ) {
            mixpanel.track(PageEvents[pageStatus], dealInfo);
            previousPageStatusRef.current = pageStatus;
        }
    }, [pageStatus]);

    const ContinueButton = <BasicButton
        typeOf="CTA"
        label={{ text: "Continue Onboarding", inputId: "IDVNextId" }}
        size="medium"
        rightIcon={CaretRight({ color: constants.colors.white })}
        onClick={handleNextButton}
    />;

    if (pageStatus === PageStatus.Initial) {
        return <IDVInitial handleVerifyIdentity={handleVerifyIdentity} />
    }
    if (pageStatus === PageStatus.Completed) {
        return <IDVCompleted>{ContinueButton}</IDVCompleted>;
    }
    if (pageStatus === PageStatus.Declined) {
        return <IDVDeclined>{ContinueButton}</IDVDeclined>;
    }
    if (pageStatus === PageStatus.Resume) {
        return <IDVResume handleResumeIDV={handleResumeIDV} />;
    }
    return <IDVInProgress />;
}

export default IDV;