import { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useSnackbar } from "notistack";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { convertLealToDealByClient, leadUploadAgreement } from "api";
import { AgreementType, DealFileType } from "utils/hooks/useUploadDocument";
import configuration from "utils/configuration";
import { useMixpanel } from "utils/MixpanelContext";
import { DealConvertedByClient } from "types/deal";
import { AlertTypes } from "types/alertTypes";
import { BodyRegular, BodySmall, H1, H2 } from "components/CommonDashboard/Typography";
import colors from "components/CommonDashboard/Styling/Colors";
import Button from "components/CommonDashboard/Button";
import QuoteUploadField from "components/Information/Quote/components/QuoteUploadField";
import { QuoteContainer } from "components/Information/Quote/components";
import Loader from "components/Information/Quote/components/Loader";
import Colors from "components/CommonDashboard/Styling/Colors";
import { StatusContainer } from "components/Information/Quote/QuotePanel";
import TextField from "components/CommonDashboard/TextField";
import useScrollTopEffect from "utils/hooks/useScrollTopEffect";

export interface AgreementFile {
    lead_id: number;
    document_type: string;
    files: DealFileType[];
}

const QuoteUpload: FC<RouteComponentProps<{ uuid: string }>> = ({
    match: {
        params: { uuid },
    },
    history,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const mixpanel = useMixpanel();
    const [agreements, setAgreements] = useState<AgreementFile[]>([]);
    const [honeypot, setHoneypot] = useState<string>("");
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [convertedDeal, setConvertedDeal] = useState<DealConvertedByClient>();

    const handleFile = (agreement: AgreementType | undefined, leadId: number) => {
        if (!agreement) {
            return void setAgreements(agreements.filter((a) => a.lead_id !== leadId));
        }

        setAgreements([
            ...agreements,
            {
                lead_id: leadId,
                document_type: agreement.document_type as string,
                files: [agreement.file],
            },
        ]);
    };

    const handleNextStep = () => history.replace("/information/my-quote/thank-you");

    const handleSubmit = async () => {
        if (!convertedDeal) return;
        if (agreements.length === 0) return void handleNextStep();

        try {
            if (honeypot.length > 0) {
                throw new Error("Validation failed");
            }

            setUploading(true);
            await leadUploadAgreement(agreements);
            mixpanel.track("quote_document_successful_upload", { dealType: convertedDeal.deal_type });
            handleNextStep();
        } catch (e) {
            console.warn(e);
            enqueueSnackbar("Failed to upload", {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        } finally {
            setUploading(false);
        }
    };

    const convertDeal = async () => {
        try {
            setLoading(true);
            const { data } = await convertLealToDealByClient(uuid);
            setConvertedDeal(data);
        } catch (e) {
            enqueueSnackbar("Unknown id provided", {
                variant: AlertTypes.Error,
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        convertDeal();
    }, [uuid]);

    useScrollTopEffect([loading, uploading]);

    if (!convertedDeal || loading) {
        return (
            <QuoteContainer>
                <H1 data-testid="title">Start Your Closing</H1>
                <StatusContainer>
                    <Loader />
                    <BodyRegular color={Colors.NEUTRAL_600}>Loading</BodyRegular>
                </StatusContainer>
            </QuoteContainer>
        );
    }

    const { title, desc } = getCopy(convertedDeal);

    return (
        <QuoteContainer>
            <H1 data-testid="title">Start Your Closing</H1>
            <Stack bgcolor={colors.WHITE} borderRadius="2.4rem" p="4.8rem" gap="4.8rem">
                <Box height={8} bgcolor={colors.PRIMARY_600} borderRadius="1rem" />

                <Stack gap="1.6rem">
                    <H2 data-testid="quote-upload-message" color={colors.NEUTRAL_700}>
                        {title}{" "}
                        <BodySmall component="span" color={colors.NEUTRAL_500}>
                            (optional)
                        </BodySmall>
                    </H2>
                    <BodyRegular color={colors.NEUTRAL_600}>{desc}</BodyRegular>
                </Stack>

                <Stack>
                    <QuoteUploadField
                        testID="first-file-field"
                        loading={uploading}
                        deal={convertedDeal}
                        onFileSelected={(f) => handleFile(f, convertedDeal.id)}
                    />

                    <TextField
                        sx={{
                            opacity: 0,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: 0,
                            width: 0,
                            zIndex: "-1",
                        }}
                        name="name"
                        value={honeypot}
                        onChange={(e) => setHoneypot(e.target.value)}
                    />
                </Stack>

                <Stack gap="2rem" direction="row" justifyContent="flex-end">
                    <Button
                        onClick={handleSubmit}
                        typeOf="CTA"
                        data-testid="quote-upload-next-step"
                        label={{ text: "Submit" }}
                    />
                </Stack>
            </Stack>
        </QuoteContainer>
    );
};

const getCopy = (deal: DealConvertedByClient) => {
    switch (deal.deal_type) {
        case "Mortgage":
            if (deal.deal_type_tag?.tag === "TitleTrn" || deal.deal_type_tag?.tag === "ILA") {
                return {
                    title: "Upload document",
                    desc: "To make things easier, if you already have a document you think may be important to review, you can upload it below before starting your transaction.",
                };
            }
            return {
                title: "Please upload your Mortgage Commitment",
                desc: "Having a copy of your Commitment will permit us to move quicker, but it can be provided later.",
            };

        case "Sale":
        case "Purchase":
            if (deal.province === "ON") {
                return {
                    title: "Please upload your Contract of Purchase & Sale",
                    desc: "Having a copy of your Contract of Purchase & Sale will permit us to move quicker, but it can be provided later.",
                };
            }

            return {
                title: "Please upload your Purchase Contract",
                desc: "Having a copy of your Purchase Contract will permit us to move quicker, but it can be provided later.",
            };

        default:
            throw new Error("Deal type not implemented.");
    }
};

export default QuoteUpload;
