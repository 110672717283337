import {UserRoleType} from "../../types/user";
import {transformLabels} from "../../utils/transformLabels";
import UserRole from "types/roles";

export const myTeamStaffDescriptions = (role: UserRoleType, name: string, dealState: string = '') => {
  switch (role) {
    case "Law Clerk":
      const role = transformLabels(UserRole.LawClerk, !["AB", "BC"].includes(dealState)).toLowerCase();
      return `${name} will be your ${role} and main point of contact for your transaction. They are available to address your inquiries pertaining to your transaction, guide you along the way, and provide information.`;
    case "Lawyer":
      return `${name} will be the lawyer representing you. Their role is to oversee the transaction, review key documents, and offer their professional expertise and support throughout the process.`;
    case "Document Support Specialist":
      return `${name} will be working with you to ensure our team and your lawyer has everything they need, such as documents, or additional information, to complete your closing.`;
    default:
      return null;
  }
};
