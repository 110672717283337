import React from "react";

type BurgerType = {
  color: string | undefined;
};
const Burger: React.FC<BurgerType> = ({color = "white"}) => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="4" rx="2" fill={color} />
      <rect y="9.59998" width="30" height="4" rx="2" fill={color} />
      <rect y="19.2" width="30" height="4" rx="2" fill={color} />
    </svg>
  );
};

export default Burger;
