import {Box, useTheme} from "@mui/material";
import bannerBackground from "../../../assets/img/equitableBankBackgroundDesktop.png";
import bannerGlad from "../../../assets/img/equitableBankBannerGladV2.png";
import React from "react";

const EquitableBankBannerPictures = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          width: "100%",
          img: {
            width: "100%",
            height: "436px",
          },
          [theme.breakpoints.down("md")]: {
            img: {
              width: "100%",
              height: "270px",
            },
          },
        }}
      >
        <img src={bannerBackground} alt={"img"} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "0px",
          right: "70px",
          width: "413px",
          height: "413px",
          img: {
            width: "413px",
            height: "413px",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        }}
      >
        <img src={bannerGlad} />
      </Box>
    </>
  );
};
export default EquitableBankBannerPictures;
