import Stack from "@mui/material/Stack";
import Colors from "components/CommonDashboard/Styling/Colors";
import { QuoteContainer } from "./Quote/components";
import { BodyRegular, H1, SubtitleRegular } from "components/CommonDashboard/Typography";

const disbursementsList = [
    { name: 'Title Search Costs', description: 'Fees for verifying the property\'s legal ownership history and any existing liens.' },
    { name: 'Registration Costs', description: 'Fees for officially recording your property\'s ownership with the provincial government.' },
    { name: 'Execution Search Fees', description: 'Government Charges for checking if there are any legal claims or judgments against you.' },
    { name: 'Transaction Levy Surcharge', description: 'A fee imposed by regulatory bodies such as the law society on some real estate transactions.' },
    { name: 'Title Insurance', description: 'Protection against potential issues with your property\'s title, ensuring you own it free and clear.' },
    { name: 'Software Transaction Fee', description: 'Fees for specialized software to create and sign the documents required for your transaction.' },
    { name: 'Other Disbursements', description: 'Fees for banking charges/wires, ID verification, and file storage.' },
];

const Disbursements = () => {
    return (
        <QuoteContainer>
            <H1 data-testid="title">Understanding Disbursements</H1>
            <Stack sx={{ padding: '4.8rem', gap: '1.6rem', borderRadius: '2.4rem', background: `${Colors.WHITE}` }}>
                <BodyRegular>
                    Disbursements are third party costs that a lawyer covers for a client in a real estate deal, which are then passed on to the client.
                </BodyRegular>
                <SubtitleRegular>
                    Your Real Estate or Mortgage transaction will be subject to some third-party disbursements such as:
                </SubtitleRegular>
                <ul style={{ marginLeft: '2rem' }}>
                    {disbursementsList.map((disbursement, index) =>
                        <li key={index} style={{marginBottom: '1rem'}}>
                            <SubtitleRegular>{disbursement.name}:</SubtitleRegular>
                            <BodyRegular>
                                {disbursement.description}
                            </BodyRegular>
                        </li>
                    )}
                </ul>
            </Stack>
        </QuoteContainer>
    );
};

export default Disbursements;
