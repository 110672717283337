import { useState } from "react";
import {withSnackbar} from "utils/withSnackbar";
import {useRouteMatch, useHistory} from "react-router-dom";
import {setRating} from "../../../redux/actions/survey";
import {useSelector, useDispatch} from "react-redux";
import {ActionType} from "../../../redux/actionTypes";
import {saveSurveyData} from "../../../api";
import "./SurveyRating.scss";
import Buttons from "../Buttons/Buttons";
import {Box} from "@mui/material";
import Rating from "@mui/material/Rating";
import configuration from "../../../utils/configuration";

const SurveyRating = ({enqueueSnackbar}) => {
  let match = useRouteMatch();
  let history = useHistory();
  const clientID = match.params.id?.split("=")?.[1]?.split("&")?.[0];
  const dispatch = useDispatch();
  const [stars, setStars] = useState();
  const {rating, answerOne, answerTwo, answerThree} = useSelector(
    (state) => state.SurveyReducer,
  );
  const handleSurverySubmit = () => {
    const payload = {
      client_id: clientID,
      rate: rating ?? 0,
      answers: [answerOne ?? "", answerTwo ?? "", answerThree ?? ""],
    };

    saveSurveyData(payload)
      .then(() => {
        dispatch({type: ActionType.SURVEY_RESET});
        if (stars === 5) {
          history.push(`/survey/google-review/${match.params.id}`);
          return;
        } else {
          history.push("/login");
          return;
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          error &&
            error.response &&
            error.response.data &&
            error.response.data.error,
          {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          },
        );
      });
  };

  return (
    <div className="step__container">
      <div className="forwarding">
        <div className="flex">
          <h2 className="title">
            How would you rate your overall experience with Deeded?
          </h2>
        </div>

        <Box component="fieldset" borderColor="transparent" height="300px">
          <Rating
            sx={{
              "& label": {
                margin: "0 !important",
                fontSize: "30px",
              },
            }}
            name="simple-controlled"
            value={rating}
            onChange={(event, newRating) => {
              setStars(newRating);
              dispatch(setRating(newRating));
            }}
          />
        </Box>
      </div>
      
      <Buttons
        backBtn={false}
        back={`/survey/question3/${match.params.id}`}
        next={stars === 5 ? `/survey/google-review/${match.params.id}` : `/`}
        submit={() => handleSurverySubmit()}
        rating={rating}
      />
    </div>
  );
};

export default withSnackbar(SurveyRating);
