import {FC} from "react";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import {H3, BodySmall} from "components/CommonDashboard/Typography";
import Button from "components/CommonDashboard/Button";
import {CloseIcon} from "components/CommonDashboard/Icons";
import Colors from "components/CommonDashboard/Styling/Colors";
import {useMixpanel} from "utils/MixpanelContext";
import {useClientOnboarding} from "../../context/ClientOnboardingProvider";
import useCustomerPortalAuthUser from "../../hooks/useCustomerPortalAuthUser";

interface DeleteFileDialogProps {
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  name?: string;
}

const DeleteFileDialog: FC<DeleteFileDialogProps> = ({show, onCancel, onConfirm, name}) => {
  const mixpanel = useMixpanel();
  const [{deal: {deal}}] = useClientOnboarding();
  const user = useCustomerPortalAuthUser();

  const handleConfirm = () => {
    mixpanel.track("delete_document_button_clicked", {
      deal_type: deal?.deal_type,
      client_type: deal?.participants.find(p => p.user_id === user?.id)?.role,
      document_type: name
    });

    onConfirm();
  };

  return (
    <Dialog open={show} sx={{
      ".MuiDialog-paper": {
        width: "48rem",
      }
    }}>
      <Stack gap="2.4rem" p="2.4rem">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <H3>Delete document</H3>
          <Button typeOf="secondary" onClick={onCancel} startIcon={<CloseIcon/>} />
        </Stack>

        <BodySmall color={Colors.BLACK}>
          Are you sure you want to delete this document?
        </BodySmall>

        <Stack gap="1.6rem" direction="row" justifyContent="end">
          <Button typeOf="secondary" onClick={onCancel} label={{text: "Cancel"}} />
          <Button typeOf="CTA" onClick={handleConfirm} label={{text: "Yes, delete"}} />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default DeleteFileDialog;
