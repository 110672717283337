import {FC} from "react";

const HeartDealOverview: FC = () => (
    <svg width="100%" height="254" viewBox="0 0 401 254" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_322_3896)">
            <g opacity="0.2">
                <path
                    d="M337.694 219.17C334.939 220.83 332.129 222.437 329.264 223.992C328.82 224.232 328.375 224.473 327.93 224.721C325.778 225.875 323.591 226.997 321.371 228.088C321.388 227.913 321.409 227.738 321.436 227.563C321.69 225.753 322.255 223.999 323.105 222.381C323.505 221.606 323.981 220.874 324.527 220.194C326.695 217.487 329.705 215.974 332.781 216.415C334.698 216.709 336.443 217.688 337.694 219.17Z"
                    fill="#3F3D56"
                />
                <path
                    d="M342.745 215.027C342.69 215.417 342.621 215.799 342.537 216.178C340.945 217.201 339.331 218.199 337.694 219.17C334.938 220.83 332.129 222.437 329.264 223.992C328.82 224.232 328.375 224.473 327.93 224.721C327.396 224.328 326.907 223.877 326.473 223.376C325.657 222.434 325.003 221.364 324.537 220.209C323.563 217.688 323.253 214.959 323.636 212.283C324.019 209.608 325.082 207.075 326.724 204.928C328.911 202.22 331.903 200.708 334.978 201.149C338.054 201.59 340.518 203.89 341.837 207.097C342.839 209.612 343.152 212.35 342.745 215.027Z"
                    fill="#3F3D56"
                />
                <path
                    d="M344.954 199.758C345.947 192.853 342.475 186.64 337.199 185.882C331.924 185.124 326.843 190.107 325.85 197.012C324.857 203.917 328.329 210.129 333.605 210.888C338.88 211.646 343.961 206.663 344.954 199.758Z"
                    fill="#3F3D56"
                />
                <path
                    d="M400.023 72.0624C399.085 78.6671 396.722 84.989 393.099 90.5903L365.985 86.4867L390.489 94.2164C389.162 95.892 387.721 97.4749 386.178 98.954C391.361 107.929 393.377 118.385 391.904 128.644C390.43 138.903 385.551 148.369 378.051 155.522C382.435 163.086 384.573 171.743 384.214 180.478C372.687 193.893 358.623 205.927 342.548 216.193C340.949 217.206 339.331 218.198 337.694 219.17C334.938 220.83 332.129 222.437 329.264 223.992C328.32 223.809 327.391 223.602 326.473 223.361C325.332 223.073 324.213 222.738 323.116 222.366C315.6 219.801 308.872 215.343 303.58 209.422C298.288 203.5 294.611 196.315 292.903 188.559C291.196 180.803 291.515 172.738 293.832 165.142C296.148 157.545 300.382 150.674 306.126 145.19C305.66 144.386 305.219 143.568 304.803 142.737C304.679 142.497 304.563 142.256 304.439 142.008C301.071 134.986 299.541 127.224 299.993 119.45L300.047 118.644C300.08 118.211 300.12 117.773 300.164 117.34C300.23 116.728 300.306 116.112 300.394 115.496V115.437C301.107 110.467 302.631 105.647 304.905 101.17L337.963 117.817L307.821 96.2135C309.68 93.4589 311.83 90.9127 314.235 88.6187C311.092 83.125 309.111 77.0443 308.415 70.7537C307.718 64.463 308.321 58.0962 310.186 52.0481C312.051 45.9999 315.138 40.3992 319.256 35.593C323.374 30.7868 328.435 26.8776 334.126 24.1076C339.817 21.3376 346.016 19.7659 352.339 19.4898C358.662 19.2137 364.974 20.2392 370.885 22.5027C376.796 24.7661 382.178 28.2193 386.7 32.6483C391.221 37.0773 394.784 42.3876 397.169 48.2502L360.044 68.1083L399.17 54.4529C400.585 60.2132 400.874 66.1924 400.023 72.0624Z"
                    fill="#F06A6A"
                />
                <path
                    opacity="0.1"
                    d="M384.203 180.463C372.676 193.878 358.612 205.912 342.537 216.178C340.946 217.201 339.331 218.198 337.694 219.17C334.938 220.829 332.129 222.436 329.264 223.991C328.32 223.809 327.391 223.601 326.473 223.361C325.332 223.073 324.213 222.738 323.116 222.366C315.6 219.801 308.872 215.343 303.58 209.422C298.288 203.5 294.611 196.315 292.903 188.559C291.196 180.803 291.515 172.738 293.832 165.142C296.148 157.545 300.382 150.674 306.126 145.19C305.66 144.385 305.219 143.568 304.803 142.737C304.679 142.496 304.563 142.256 304.439 142.008C301.071 134.986 299.541 127.224 299.993 119.45L300.047 118.644C300.08 118.211 300.12 117.773 300.164 117.34C300.23 116.727 300.306 116.111 300.394 115.496V115.437C300.678 114.453 304.439 115.28 310.168 117.259L311.378 117.689C312.646 118.145 313.998 118.648 315.419 119.198C318.521 120.394 321.946 121.804 325.562 123.364L325.798 123.466L326.301 123.684C350.609 134.224 382.585 151.134 378 155.5C382.4 163.062 384.551 171.722 384.203 180.463Z"
                    fill="black"
                />
            </g>
            <g opacity="0.2">
                <path
                    d="M116.722 239.622C115.191 239.093 113.671 238.549 112.163 237.99C108.649 236.697 105.191 235.331 101.787 233.89C102.477 233.33 103.261 232.896 104.101 232.607C107.137 231.565 110.472 232.56 113.187 234.958C114.652 236.273 115.852 237.856 116.722 239.622Z"
                    fill="#3F3D56"
                />
                <path
                    d="M113.187 234.957C112.98 236.009 112.636 237.028 112.163 237.99C108.65 236.697 105.191 235.33 101.787 233.89C98.8256 232.643 95.9101 231.342 93.0408 229.987C92.9154 229.117 92.8593 228.238 92.8731 227.359C92.8854 226.443 92.983 225.531 93.1647 224.633C93.8936 221.051 95.9563 218.197 98.9957 217.155C102.035 216.113 105.366 217.107 108.081 219.509C108.738 220.092 109.341 220.733 109.881 221.426C111.078 222.967 112 224.703 112.607 226.557C113.527 229.259 113.727 232.155 113.187 234.957Z"
                    fill="#3F3D56"
                />
                <path
                    d="M102.062 227.047C107.305 225.389 109.758 218.36 107.541 211.345C105.324 204.331 99.2766 199.988 94.0335 201.645C88.7904 203.302 86.3373 210.332 88.5543 217.346C90.7714 224.361 96.819 228.704 102.062 227.047Z"
                    fill="#3F3D56"
                />
                <path
                    d="M96.204 210.869C101.428 209.22 103.873 202.217 101.667 195.228C99.4596 188.239 93.4358 183.911 88.212 185.561C82.9882 187.211 80.5425 194.213 82.7494 201.202C84.9564 208.191 90.9802 212.519 96.204 210.869Z"
                    fill="#3F3D56"
                />
                <path
                    d="M109.863 221.426C108.85 222.019 107.81 222.574 106.743 223.091C105.024 223.927 103.256 224.657 101.448 225.278C99.7988 225.847 98.1185 226.323 96.4154 226.703C95.2322 226.97 94.0478 227.188 92.8622 227.355C91.3898 227.567 89.9212 227.705 88.4561 227.771C70.8466 219.024 55.0375 208.325 41.5461 196.105C41.3529 195.588 41.1816 195.066 40.9958 194.538C37.5373 184.168 37.5962 172.947 41.1634 162.614C32.1419 156.556 25.4047 147.656 22.0233 137.329C20.3411 132.275 19.4799 126.985 19.4723 121.658H57.6395L19.844 115.616C20.2638 112.141 21.0505 108.721 22.191 105.412C13.1689 99.3565 6.43142 90.4575 3.05083 80.131C-5.42592 54.5805 7.90146 26.9235 32.8179 18.3447C49.4981 12.6085 67.1403 16.887 79.5275 28.0023L54.6912 64.3583L84.2615 32.9003C88.2625 37.6771 91.3196 43.1706 93.2703 49.0885C95.4945 55.7728 96.2746 62.853 95.559 69.8612L68.7256 78.8628L94.8556 74.5151C94.4202 76.7197 93.8346 78.8919 93.1027 81.0166C102.124 87.0727 108.861 95.9715 112.243 106.297C113.881 111.217 114.742 116.361 114.794 121.545V121.585C114.794 121.986 114.794 122.387 114.794 122.788C114.794 122.959 114.794 123.127 114.794 123.298C114.666 128.388 113.756 133.428 112.093 138.24C115.76 140.697 119.071 143.646 121.933 147.005C122.006 147.085 122.075 147.165 122.141 147.249C126.177 152.044 129.258 157.568 131.219 163.521C138.679 186.007 129.236 210.132 109.863 221.426Z"
                    fill="#F06A6A"
                />
                <path
                    opacity="0.1"
                    d="M109.863 221.426C108.85 222.019 107.81 222.574 106.743 223.092C105.024 223.927 103.256 224.657 101.448 225.278C99.7986 225.847 98.1183 226.323 96.4152 226.703C95.232 226.97 94.0476 227.188 92.862 227.355C91.3896 227.567 89.921 227.705 88.4559 227.771C70.8465 219.024 55.0373 208.325 41.5459 196.105C41.3527 195.588 41.1815 195.067 40.9956 194.538C37.5371 184.168 37.596 172.947 41.1632 162.614C32.1417 156.556 25.4045 147.656 22.0231 137.329C20.7148 133.386 75.2743 130.204 100.125 133.255L100.705 133.328C108.248 134.301 112.88 135.879 112.075 138.215C115.741 140.671 119.052 143.62 121.915 146.979C121.988 147.06 122.057 147.14 122.122 147.224C126.159 152.019 129.24 157.542 131.201 163.496C138.679 186.007 129.236 210.132 109.863 221.426Z"
                    fill="black"
                />
            </g>
            <path
                opacity="0.1"
                d="M139.791 84.8213C139.989 84.5391 140.098 84.204 140.104 83.8592C140.104 81.7637 135.837 80.0691 130.574 80.0691C125.312 80.0691 121.052 81.7783 121.052 83.8592C121.059 84.2564 121.196 84.6403 121.441 84.9525C115.585 85.6049 111.638 86.833 111.638 88.2324C111.638 90.3243 120.385 92.0189 131.154 92.0189C141.923 92.0189 150.669 90.3243 150.669 88.2324C150.665 86.731 146.234 85.4409 139.791 84.8213Z"
                fill="#F06A6A"
            />
            <path
                opacity="0.1"
                d="M291.002 7.94369C291.317 7.49304 291.491 6.95902 291.501 6.40942C291.501 3.08941 284.73 0.39624 276.377 0.39624C268.025 0.39624 261.253 3.08941 261.253 6.40942C261.269 7.03198 261.487 7.6324 261.877 8.11861C252.576 9.15725 246.312 11.1033 246.312 13.3337C246.312 16.6537 260.16 19.3469 277.288 19.3469C294.417 19.3469 308.265 16.6537 308.265 13.3337C308.265 10.9685 301.228 8.92402 291.002 7.94369Z"
                fill="#F06A6A"
            />
            <path
                opacity="0.1"
                d="M169.645 7.94369C169.96 7.49304 170.134 6.95902 170.145 6.40942C170.145 3.08941 163.373 0.39624 155.02 0.39624C146.668 0.39624 139.896 3.08941 139.896 6.40942C139.912 7.03198 140.13 7.6324 140.52 8.11861C131.219 9.15725 124.955 11.1033 124.955 13.3337C124.955 16.6537 138.821 19.3469 155.932 19.3469C173.042 19.3469 186.909 16.6537 186.909 13.3337C186.909 10.9685 179.871 8.92402 169.645 7.94369Z"
                fill="#F06A6A"
            />
            <path
                opacity="0.1"
                d="M131.303 39.3361C132.156 38.8441 133.3 39.3142 133.883 40.105C134.466 40.8959 134.634 41.9272 134.779 42.8857C135.104 45.0395 135.337 47.514 133.887 49.1394C136.259 41.9345 143.092 36.4133 150.651 35.6079"
                stroke="#6C63FF"
                strokeWidth="3"
                strokeMiterlimit="10"
            />
            <path
                opacity="0.1"
                d="M226.592 3.61423C227.138 3.29718 227.878 3.61424 228.254 4.10622C228.587 4.64714 228.784 5.26142 228.826 5.8956C229.034 7.28045 229.19 8.86939 228.254 9.90438C229.054 7.59144 230.488 5.54974 232.392 4.01178C234.296 2.47383 236.593 1.50143 239.023 1.20532"
                stroke="#6C63FF"
                strokeWidth="3"
                strokeMiterlimit="10"
            />
            <path
                opacity="0.1"
                d="M289.274 71.0346C289.821 70.7176 290.561 71.0346 290.936 71.5266C291.27 72.0675 291.466 72.6818 291.508 73.316C291.716 74.7009 291.873 76.2898 290.936 77.3248C291.737 75.0119 293.17 72.9702 295.074 71.4322C296.978 69.8942 299.276 68.9218 301.705 68.6257"
                stroke="#6C63FF"
                strokeWidth="3"
                strokeMiterlimit="10"
            />
            <path
                d="M199.911 24.0808C198.352 25.3855 197.251 27.0582 195.538 28.2354C193.476 29.6567 190.768 30.196 188.25 30.9577C185.731 31.7194 183.147 32.9147 182.134 34.9883C181.274 36.7631 181.77 38.8222 181.041 40.6371C180.257 42.5649 178.224 43.917 176.668 45.4731C173.592 48.5417 172.225 52.7618 173.023 56.6977C173.574 59.42 175.079 61.9346 175.972 64.5914C177.065 67.8421 177.218 71.2496 177.371 74.6206C177.655 80.9508 177.936 87.343 176.511 93.5712C176.147 95.1346 175.163 118.907 173.257 118.859C178.315 118.426 183.403 118.378 188.486 118.327L264.653 117.598C261.144 113.007 258.902 85.8707 258.159 80.4589C256.876 71.1293 259.981 61.7889 260.346 52.3974C260.397 51.1875 260.397 49.9447 259.897 48.8077C259.373 47.616 258.323 46.5992 257.973 45.3638C257.47 43.5963 258.48 41.785 258.513 39.9702C258.578 36.3258 254.868 33.5306 251.927 30.8301C249.092 28.2317 246.734 25.2798 243.808 22.747C240.881 20.2141 237.204 18.0676 233.02 17.6777C229.584 17.3606 226.169 18.2535 222.878 19.1354C218.953 20.185 215.225 21.5115 211.059 21.4459C206.464 21.3658 203.632 21.7484 199.911 24.0808Z"
                fill="#2F2E41"
            />
            <g opacity="0.1">
                <path
                    d="M252.375 38.4359C252.481 38.8346 252.636 39.2189 252.835 39.5802C252.785 38.7602 252.587 37.9561 252.248 37.2078C252.232 37.6211 252.275 38.0346 252.375 38.4359Z"
                    fill="black"
                />
                <path
                    d="M178.508 96.9531L178.085 96.9896C178.227 96.9925 178.369 96.9803 178.508 96.9531Z"
                    fill="black"
                />
            </g>
            <path
                d="M257.602 228.281C257.137 228.641 256.582 228.867 255.998 228.933C246.679 230.847 237.277 232.479 227.776 232.997C219.394 233.456 210.987 233.044 202.601 232.632C194.215 232.22 185.837 231.805 177.652 230.11C181.043 223.282 183.179 215.898 183.957 208.314C184.301 204.985 184.385 201.634 184.208 198.292C184.181 197.89 184.132 197.489 184.062 197.093C183.672 194.695 182.71 191.222 183.585 189.487C184.707 187.256 189.019 187.184 191.125 186.768C194.704 186.065 198.301 185.478 201.912 184.986C216.246 183.036 230.724 182.592 245.167 182.158C247.106 182.1 249.416 182.231 250.426 183.889C250.991 184.818 250.962 185.977 250.932 187.067C250.867 189.426 250.89 191.785 251.002 194.144C251.508 204.605 253.747 214.909 257.627 224.637C258.108 225.828 258.549 227.388 257.602 228.281Z"
                fill="#FBBEBE"
            />
            <path
                d="M200.895 98.502C199.372 100.379 197.225 101.64 195.064 102.748C192.483 104.081 189.824 105.259 187.101 106.275C186.551 107.11 186.988 108.218 187.466 109.107C191.741 117.409 197.462 125.66 206.092 129.228C210.52 131.05 215.378 131.505 220.152 131.932C221.639 132.063 223.155 132.194 224.605 131.837C226.056 131.48 227.404 130.616 228.614 129.701C236.521 123.718 241.728 114.84 243.09 105.018C243.206 104.147 243.206 103.079 242.459 102.613C242.004 102.332 241.424 102.387 240.888 102.391C237.393 102.427 234.296 99.8395 232.681 96.7454C231.067 93.6514 230.637 90.0836 230.24 86.6105C230.24 86.534 209.693 86.1003 207.838 86.0857C204.944 86.0602 203.989 86.3991 203.709 89.3656C203.414 92.6383 203.049 95.8344 200.895 98.502Z"
                fill="#FBBEBE"
            />
            <path
                opacity="0.1"
                d="M200.895 98.502C199.372 100.379 197.225 101.64 195.064 102.748C192.483 104.081 189.824 105.259 187.101 106.275C186.551 107.11 186.988 108.218 187.466 109.107C191.741 117.409 197.462 125.66 206.092 129.228C210.52 131.05 215.378 131.505 220.152 131.932C221.639 132.063 223.155 132.194 224.605 131.837C226.056 131.48 227.404 130.616 228.614 129.701C236.521 123.718 241.728 114.84 243.09 105.018C243.206 104.147 243.206 103.079 242.459 102.613C242.004 102.332 241.424 102.387 240.888 102.391C237.393 102.427 234.296 99.8395 232.681 96.7454C231.067 93.6514 230.637 90.0836 230.24 86.6105C230.24 86.534 209.693 86.1003 207.838 86.0857C204.944 86.0602 203.989 86.3991 203.709 89.3656C203.414 92.6383 203.049 95.8344 200.895 98.502Z"
                fill="black"
            />
            <path
                d="M217.226 94.6208C232.723 94.6208 245.287 82.0572 245.287 66.5593C245.287 51.0614 232.723 38.4978 217.226 38.4978C201.728 38.4978 189.164 51.0614 189.164 66.5593C189.164 82.0572 201.728 94.6208 217.226 94.6208Z"
                fill="#FBBEBE"
            />
            <path
                d="M237.805 99.8286C238.115 103.764 195.815 107.449 194.219 102.595C193.271 103.713 191.533 103.805 190.148 103.324C188.763 102.842 187.597 101.917 186.285 101.235C184.099 100.095 181.635 99.6719 179.211 99.2601C175.265 98.5931 170.68 98.1048 167.757 100.842C166.748 101.786 166.059 103.028 165.046 103.976C163.275 105.623 160.771 106.137 158.486 106.891C155.188 107.985 152.149 109.734 149.033 111.265C141.757 114.847 134 117.354 126.004 118.706C124.302 118.994 122.589 119.227 120.902 119.632C118.533 120.2 116.164 121.174 114.55 122.999C116.438 125.412 119.149 126.728 122.061 127.679L150.159 136.862C154.386 138.244 158.679 139.654 162.378 142.129C168.541 146.239 172.55 152.996 174.744 160.07C176.937 167.143 177.502 174.607 178.049 181.994C178.286 185.205 178.501 188.554 177.24 191.528C183.785 192.299 190.403 192.19 196.919 191.203C203.191 190.248 209.394 188.477 215.739 188.536C222.663 188.605 229.372 190.85 236.198 192.063C243.024 193.277 250.557 193.313 256.308 189.439C255.083 187.789 254.897 185.431 254.85 183.383L254.704 173.678C254.639 172.506 254.717 171.331 254.937 170.179C255.426 168.102 256.8 166.36 257.678 164.417C259.373 160.667 259.136 156.382 259.23 152.267C259.325 148.153 259.901 143.732 262.682 140.722C264.504 138.757 267.055 137.642 269.642 136.954C274.067 135.773 278.695 135.696 283.265 135.372C298.164 134.304 312.74 130.508 326.268 124.173C325.459 122.464 323.579 121.494 321.862 120.7C317.802 118.823 313.502 116.899 309.049 117.318C306.895 117.522 304.763 118.273 302.616 117.999C300.583 117.737 298.783 116.585 296.807 116.035C295.309 115.62 293.742 115.558 292.204 115.324C288.655 114.781 285.324 113.32 282.04 111.873L273.746 108.229C271.559 107.263 269.282 106.232 267.78 104.366C267.292 103.757 266.898 103.072 266.366 102.5C265.451 101.52 264.179 100.94 262.908 100.536C261.31 99.9795 259.606 99.7926 257.926 99.9889C256.773 100.218 255.635 100.516 254.518 100.882C249.037 102.361 243.381 100.823 237.805 99.8286Z"
                fill="#F06A6A"
            />
            <path
                d="M269.901 246.605C254.964 250.196 239.749 252.512 224.419 253.529H224.394H185.906H185.884C178.996 253.06 172.208 252.343 165.52 251.379C167.493 245.503 170.214 239.906 173.614 234.724C174.158 233.991 174.583 233.177 174.875 232.312C175.035 231.506 175.117 230.687 175.119 229.866C175.296 227.351 176.272 224.959 177.903 223.037C178.661 223.45 179.426 223.847 180.199 224.228C188.952 228.576 198.485 231.135 208.239 231.754C213.369 232.017 218.513 231.913 223.629 231.444C235.167 230.515 247.164 228.372 256.457 221.568C256.847 221.284 257.233 220.989 257.616 220.686C262.131 224.695 264.744 230.839 266.614 236.583C267.725 239.943 268.753 243.296 269.901 246.605Z"
                fill="#2F2E41"
            />
            <path
                opacity="0.1"
                d="M217.073 223.419C216.891 224.008 216.846 224.63 216.942 225.238C217.038 225.847 217.272 226.425 217.627 226.929C217.863 226.402 217.933 225.816 217.828 225.248C217.722 224.68 217.446 224.158 217.036 223.751L217.073 223.419Z"
                fill="black"
            />
            <path
                d="M201.129 105.612C202.543 104.84 204.07 104.154 205.681 104.133C208.1 104.111 210.265 105.59 212.058 107.223C213.716 108.72 215.215 110.383 216.534 112.187C217.19 113.079 217.816 114.06 217.915 115.164C218.013 116.268 217.408 117.511 216.329 117.773C215.648 117.888 214.948 117.747 214.365 117.376C211.872 116.006 210.025 112.868 207.197 113.174C205.258 113.382 203.946 115.186 202.885 116.819C201.942 118.276 200.987 119.774 200.564 121.458C200.141 123.141 200.345 125.102 201.519 126.37C202.43 127.365 203.76 127.828 205.003 128.346C207.752 129.483 210.268 131.116 212.426 133.163C213.472 134.162 214.467 135.35 214.613 136.808C214.759 138.266 213.655 139.869 212.211 139.767C211.561 139.665 210.947 139.398 210.429 138.991C207.82 137.267 205.156 135.459 202.095 134.825C198.979 134.18 195.761 134.825 192.619 135.306C189.174 135.821 185.712 136.188 182.233 136.407C181.2 136.525 180.155 136.457 179.146 136.206C175.706 135.142 174.704 130.765 175.105 127.602C175.531 124.22 178.02 123.451 180.622 121.771C187.94 116.983 193.37 109.84 201.129 105.612Z"
                fill="#FBBEBE"
            />
            <path
                d="M228.086 105.612C226.668 104.84 225.145 104.154 223.531 104.133C221.111 104.111 218.946 105.59 217.153 107.223C215.497 108.72 213.998 110.384 212.681 112.187C212.029 113.079 211.402 114.06 211.304 115.164C211.206 116.268 211.807 117.511 212.886 117.773C213.568 117.888 214.269 117.747 214.854 117.376C217.346 116.006 219.194 112.868 222.018 113.174C223.961 113.382 225.273 115.186 226.33 116.819C227.273 118.276 228.232 119.774 228.651 121.458C229.07 123.141 228.87 125.102 227.696 126.37C226.789 127.365 225.455 127.828 224.212 128.346C221.463 129.484 218.945 131.117 216.785 133.163C215.739 134.162 214.748 135.35 214.598 136.808C214.449 138.266 215.557 139.869 217 139.767C217.651 139.666 218.265 139.398 218.782 138.991C221.391 137.267 224.055 135.459 227.117 134.825C230.233 134.18 233.451 134.825 236.592 135.306C240.029 135.846 243.494 136.221 246.968 136.429C248.001 136.547 249.048 136.479 250.058 136.228C253.495 135.164 254.497 130.787 254.099 127.624C253.669 124.242 251.184 123.473 248.578 121.793C241.275 116.983 235.849 109.84 228.086 105.612Z"
                fill="#FBBEBE"
            />
            <path
                d="M197.823 56.4209C199.456 53.8407 201.395 51.0418 204.044 50.743C207.743 50.3239 210.633 54.9486 214.314 55.5462C219.052 56.3188 223.709 50.2546 228.163 52.4704C230.349 53.5637 231.982 56.5958 234.358 56.5375C236.001 56.5011 237.339 54.9595 238.429 53.4471C240.267 50.8971 241.895 48.201 243.294 45.3858C238.771 40.1999 232.193 39.0155 226.052 38.0716L215.728 36.4863C213.243 36.1036 210.713 35.7246 208.243 36.2531C206.009 36.7305 203.924 37.9331 201.901 39.1977C199.489 40.7137 193.815 43.1482 192.335 45.9689C191.304 47.9259 192.721 48.837 194.051 50.8086C195.303 52.6793 196.56 54.5501 197.823 56.4209Z"
                fill="#2F2E41"
            />
            <path
                opacity="0.1"
                d="M182.185 119.76C177.707 120.654 173.133 120.98 168.574 120.729C166.784 120.634 164.987 120.449 163.205 120.616C161.055 120.82 158.989 121.531 156.893 122.048C146.037 124.72 134.692 122.11 123.61 120.645C121.653 120.387 119.652 120.164 117.713 120.543C115.938 120.89 114.298 121.72 112.728 122.613C110.183 124.061 107.747 125.691 105.439 127.493C103.023 129.381 100.585 131.786 100.417 134.844C100.271 137.475 101.875 139.898 103.817 141.688C109.065 146.546 116.857 148.007 123.883 146.691C126.554 146.192 129.16 145.332 131.86 145.033C134.626 144.727 137.422 145.033 140.202 144.902C145.851 144.651 151.259 142.686 156.744 141.32C165.309 139.187 174.169 138.493 182.961 139.264C182.885 139.297 182.802 139.313 182.719 139.311C182.635 139.308 182.554 139.287 182.48 139.249C182.405 139.211 182.341 139.157 182.29 139.091C182.239 139.025 182.203 138.948 182.185 138.867C181.54 137.089 181.507 135.153 181.518 133.262C181.547 128.674 181.762 124.33 182.185 119.76Z"
                fill="black"
            />
            <path
                d="M181.457 120.124C176.978 121.018 172.405 121.344 167.845 121.093C166.056 120.999 164.259 120.813 162.477 120.98C160.327 121.185 158.26 121.895 156.165 122.413C145.308 125.084 133.964 122.475 122.881 121.01C120.924 120.751 118.923 120.529 116.984 120.908C115.21 121.254 113.57 122.085 111.999 122.978C109.454 124.425 107.018 126.056 104.71 127.857C102.294 129.745 99.8561 132.15 99.6884 135.208C99.5426 137.839 101.146 140.263 103.089 142.052C108.336 146.91 116.128 148.371 123.154 147.056C125.826 146.556 128.431 145.696 131.132 145.398C133.898 145.091 136.693 145.398 139.474 145.266C145.122 145.015 150.531 143.051 156.015 141.684C164.58 139.551 173.44 138.857 182.233 139.629C182.156 139.662 182.074 139.677 181.99 139.675C181.907 139.672 181.825 139.651 181.751 139.613C181.677 139.575 181.612 139.521 181.561 139.455C181.51 139.389 181.475 139.313 181.457 139.231C180.812 137.453 180.779 135.518 180.79 133.626C180.819 129.038 181.034 124.694 181.457 120.124Z"
                fill="#F06A6A"
            />
            <path
                opacity="0.1"
                d="M247.875 117.81C253.428 119.074 266.796 119.632 272.488 119.442C275.087 119.362 277.678 119.129 280.276 119.136C284.73 119.136 289.176 119.865 293.618 119.57C299.358 119.176 304.996 117.066 310.721 117.609C313.535 117.879 316.242 118.775 318.925 119.672C321.607 120.568 324.53 121.582 326.418 123.761C328.167 125.78 328.805 128.499 329.384 131.108C329.708 132.566 330.033 134.071 329.748 135.532C329.205 138.306 326.651 140.182 324.198 141.582C321.283 143.244 318.083 144.672 314.723 144.596C312.729 144.552 310.791 143.976 308.892 143.378C303.451 141.669 297.89 139.734 292.204 140.16C288.228 140.467 284.256 141.932 280.346 141.141C278.046 140.674 275.972 139.457 273.786 138.648C268.458 136.698 254.934 137.216 249.285 137.755C249.613 134.581 249.573 131.381 249.901 128.203C250.156 125.773 250.4 123.254 249.62 120.937C249.132 119.548 248.968 118.794 247.875 117.81Z"
                fill="black"
            />
            <path
                d="M248.239 118.539C253.793 119.803 267.161 120.361 272.853 120.171C275.452 120.091 278.043 119.858 280.641 119.865C285.095 119.865 289.541 120.594 293.983 120.299C299.723 119.905 305.361 117.795 311.086 118.338C313.899 118.608 316.607 119.504 319.289 120.401C321.972 121.297 324.894 122.311 326.782 124.49C328.532 126.509 329.169 129.228 329.749 131.837C330.073 133.295 330.397 134.8 330.113 136.261C329.57 139.035 327.015 140.911 324.563 142.311C321.647 143.973 318.448 145.401 315.088 145.325C313.094 145.281 311.155 144.705 309.257 144.107C303.816 142.398 298.254 140.463 292.569 140.889C288.593 141.196 284.621 142.661 280.71 141.87C278.411 141.403 276.337 140.186 274.151 139.377C268.823 137.427 255.298 137.945 249.65 138.484C249.978 135.31 249.938 132.11 250.266 128.932C250.521 126.502 250.765 123.983 249.985 121.666C249.497 120.277 249.333 119.523 248.239 118.539Z"
                fill="#F06A6A"
            />
            <path
                d="M190.334 63.0426C189.481 62.8203 189.153 61.7416 188.384 61.3079C187.616 60.8742 186.653 61.2168 186.099 61.8619C185.585 62.5445 185.306 63.3758 185.305 64.2307C185.237 66.0741 185.739 67.8937 186.743 69.4409C187.748 70.988 189.206 72.1874 190.917 72.8751C190.155 69.5577 189.861 66.15 190.043 62.7511L190.334 63.0426Z"
                fill="#FBBEBE"
            />
            <path
                d="M244.114 63.0426C244.966 62.8203 245.291 61.7416 246.06 61.3079C246.829 60.8742 247.791 61.2168 248.345 61.8619C248.861 62.5436 249.14 63.3755 249.139 64.2307C249.208 66.0741 248.705 67.8937 247.701 69.4409C246.696 70.988 245.239 72.1874 243.527 72.8751C244.289 69.5577 244.583 66.15 244.401 62.7511L244.114 63.0426Z"
                fill="#FBBEBE"
            />
            <g opacity="0.1">
                <path
                    d="M209.183 143.856C209.877 146.396 210.215 149.021 210.185 151.655C210.185 156.236 209.285 160.234 207.944 162.38C207.831 163.401 207.703 164.414 207.579 165.409C209.471 163.951 210.859 159.162 210.859 153.477C210.844 149.592 210.196 146.126 209.183 143.856Z"
                    fill="black"
                />
            </g>
            <g opacity="0.1">
                <path
                    d="M219.449 143.856C218.753 146.396 218.414 149.021 218.443 151.655C218.443 156.236 219.347 160.234 220.688 162.38C220.801 163.401 220.925 164.414 221.052 165.409C219.161 163.951 217.772 159.162 217.772 153.477C217.769 149.592 218.418 146.126 219.449 143.856Z"
                    fill="black"
                />
            </g>
            <path
                opacity="0.1"
                d="M238.399 51.6248C237.306 53.1372 235.972 54.6788 234.329 54.7153C231.949 54.7736 230.32 51.7488 228.133 50.6482C223.698 48.4324 219.051 54.4966 214.285 53.724C210.604 53.1263 207.725 48.5016 204.015 48.9207C201.365 49.2196 199.427 52.0184 197.794 54.5986C196.531 52.7303 195.268 50.8595 194.007 48.9863C193.279 47.893 192.488 47.1095 192.134 46.3004C191.427 48.0352 192.754 48.9535 194.007 50.8085C195.261 52.6635 196.529 54.5513 197.794 56.4208C199.427 53.8406 201.365 51.0417 204.015 50.7429C207.714 50.3238 210.604 54.9485 214.285 55.5462C219.022 56.3188 223.68 50.2546 228.133 52.4703C230.32 53.5636 231.952 56.5957 234.329 56.5374C235.972 56.501 237.31 54.9594 238.399 53.447C240.238 50.8971 241.865 48.201 243.264 45.3857C243.068 45.1598 242.864 44.9411 242.656 44.7297C241.399 47.1242 239.977 49.4282 238.399 51.6248Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_322_3896">
                <rect width="400" height="253.144" fill="white" transform="translate(0.5 0.39624)" />
            </clipPath>
        </defs>
    </svg>
);

export default HeartDealOverview;