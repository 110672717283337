import { FC, Fragment, ReactNode, useMemo } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { H2 } from "components/CommonDashboard/Typography";
import { Deal } from "types/deal";
import MyTeamStaffUser from "./MyTeamStaffUser";
import { UserStaff } from "../../types/user";
import { myTeamStaffDescriptions } from "./myTeamStaffDescriptions";
import useCustomerPortalAuthUser from "../hooks/useCustomerPortalAuthUser";

interface MyTeamProps {
  deal: Deal;
  MainImage: ReactNode;
}

const MyTeam: FC<MyTeamProps> = ({ deal, MainImage }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const authUser = useCustomerPortalAuthUser();
  const orderedStaff = useMemo(() => {
    const lawClerks = [] as UserStaff[];
    const lawyers = [] as UserStaff[];
    const dss = [] as UserStaff[];

    deal.staff.forEach((a) => {
      switch (a.role) {
        case "Law Clerk":
          lawClerks.push(a);
          break;
        case "Lawyer":
          lawyers.push(a);
          break;
        case "Document Support Specialist":
          dss.push(a);
          break;
        default:
          break;
      }
    });

    return [...lawClerks, ...dss, ...lawyers];
  }, [deal.staff]);

  return (
    <Fragment>
      {!isMobile && (
        <Box maxWidth="40rem">
          {MainImage}
        </Box>
      )}
      <Stack gap="2.4rem" flexDirection="column">
        <H2> My Deeded Team</H2>

        <Stack gap="1.6rem">
          {orderedStaff.map((s) => {
            const desc = myTeamStaffDescriptions(s.role, s.first_name, deal.address.state);
            return <MyTeamStaffUser key={s.id.toString()} staff={s} deal={deal} description={desc} authUser={authUser} />
          })}
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default MyTeam;
