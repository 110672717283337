import React from "react";
import {withSnackbar} from "utils/withSnackbar";
import {useRouteMatch} from "react-router-dom";
import "./SurveyQuestion.scss";
import {useSelector, useDispatch} from "react-redux";
import {setAnswerThree} from "../../../redux/actions/survey";
import Buttons from "../Buttons/Buttons";

const SurveyQuestion3 = () => {
  let match = useRouteMatch();
  const dispatch = useDispatch();
  const {answerThree} = useSelector((state) => state.SurveyReducer);
  return (
    <div className="step__container">
      <div className="forwarding">
        <div className="flex">
          <h2 className="title">
            When referring us to others, what would you say?
          </h2>
        </div>

        <div className="address__container">
          <div className="address__wrapper" style={{}}>
            <label htmlFor="address">
              <textarea
                style={{minHeight: "350px"}}
                value={answerThree ?? ""}
                onChange={(e) => {
                  if (
                    e.target.value.length > 300 ||
                    e.target.value.split(" ").some((el) => el.length > 25)
                  )
                    return false;
                  dispatch(setAnswerThree(e.target.value));
                }}
                name="address"
                placeholder="Your answer"
              ></textarea>
            </label>
          </div>
        </div>
      </div>
      <Buttons
        backBtn={false}
        back={`/survey/question2/${match.params.id}`}
        next={`/survey/rating/${match.params.id}`}
      />
    </div>
  );
};

export default withSnackbar(SurveyQuestion3);
