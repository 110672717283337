import PortalContainer from "components/CommonDashboard/Container/PortalContainer";
import useFetchDeal from "customerPortal/hooks/useFetchDeal";
import { useParams } from "react-router-dom";
import CircularLoader from "components/Common/Loader/CircularLoader";
import CustomerPortalChecklist from "components/Svg/CustomerPortalChecklist";
import MyTeam from "customerPortal/components/MyTeam";
import DealOverview from "./DealOverview";


const NextSteps = () => {
    const routeParams = useParams<{ deal_id: string }>();
    const [deal, loading, refresh] = useFetchDeal(routeParams.deal_id);

    if (loading || !deal) {
        return <CircularLoader containerHeight={"70vh"} />;
    }
    return <PortalContainer
        leftPanel={<DealOverview refresh={refresh} deal={deal} />}
        rightPanel={<MyTeam
          deal={deal}
          MainImage={<CustomerPortalChecklist />}
        />}
    />
}

export default NextSteps;

