import React, {Component} from "react";
import {createCustomerAccount, sendPasswordForConfirm, verifyResetPasswordToken} from "../../../api";
import {Link, withRouter} from "react-router-dom";
import {withSnackbar} from "utils/withSnackbar";
import ArrowBackSVG from "../../Svg/ArrowBack";

import "./RequestResetPassword.scss";

import {userLogIn, userMtfLogin} from "../../../redux/actions/auth";
import {connect} from "react-redux";
import storage from "utils/storage";
import {ONBOARDING_PATH} from "customerPortal/pages/paths";
import MfaForm from "../MfaForm";
import configuration from "../../../utils/configuration";
import CircularLoader from "../../Common/Loader/CircularLoader";
import {Box} from "@mui/material";

export class RequestResetPassword extends Component {
  state = {
    password: "",
    passwordError: false,
    confirm_password: "",
    minchar: false,
    spaces: false,
    digit: false,
    uppercase: false,
    lowercase: false,
    togglePassword: false,
    not_match: false,
    showMfa: false,
    mfaCode: "",
    mfaError: false,
    loginToken: "",
    verifyingToken: true,
    resetToken: "",
    loadingPass: false,
  };

  componentDidMount() {
    const {location} = this.props;
    const query = new URLSearchParams(location.search);
    const clientId = query.get("client_id");
    if (!clientId) {
      void this.handleVerifyToken();
    } else {
      this.setState({verifyingToken: false})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      Object.keys(this.props.currentUser).length === 0 ||
      !this.state.showMfa
    ) {
      return;
    }

    if (this.props.loginError) {
      this.props.enqueueSnackbar(this.props.loginError, {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    }

    if (storage.targetLink) {
      const link = storage.targetLink;
      return this.props.history.replace(link);
    }
    this.props.history.push("/");
  }

  handleVerifyToken = async () => {
    this.setState({verifyingToken: true});

    let resetToken = this.props.location.search.split("=")[1];
    if (!resetToken) {
      this.props.enqueueSnackbar("Missing reset password token.", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      this.props.history.push("/");
    }

    try {
      await verifyResetPasswordToken(resetToken);
      this.setState({verifyingToken: false, resetToken});
    } catch (e) {
      this.props.enqueueSnackbar(e.response.data.error, {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      this.props.history.push("/");
    }
  };

  handleSubmitMfa = () => {
    const {mfaCode, loginToken} = this.state;

    if (mfaCode.length !== 6) {
      this.props.enqueueSnackbar("Invalid authentication code.", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }

    this.props.onMtfLogin({code: mfaCode, login_token: loginToken});
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      showMfa,
      digit,
      minchar,
      spaces,
      uppercase,
      lowercase,
      confirm_password,
      password,
      loadingPass,
    } = this.state;
    const {loading} = this.props;

    if (loadingPass || loading) {
      return;
    }

    if (showMfa) {
      return void this.handleSubmitMfa();
    }

    if (!digit || !minchar || !uppercase || !lowercase || !spaces) {
      this.props.enqueueSnackbar("Valid password required", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }
    if (confirm_password !== password) {
      this.props.enqueueSnackbar("Passwords don't match", {
        variant: "error",
        autoHideDuration: configuration.autoHideErrorDuration,
      });
      return;
    }
    const {location} = this.props;
    const query = new URLSearchParams(location.search);
    const clientId = query.get("client_id");
    const handlePasswordConfirmation = (res, link) => {
      if (res.status === 201) {
        this.setState({
          showMfa: true,
          loginToken: res.data.login_token,
        });
      } else {
        if (!storage.token) {
          storage.userId = res.data.user.id;
          storage.token = res.data.access_token;
          this.props.onUserLogin(res.data.user);
        }
        this.props.history.push(link || "/");
      }
    };

    this.setState({loadingPass: true});
    if (clientId) {
      return createCustomerAccount(clientId, this.state.password)
        .then((res) =>
          handlePasswordConfirmation(
            res,
            `${ONBOARDING_PATH}/${clientId}`,
          ),
        )
        .catch((error) =>
          this.props.enqueueSnackbar(error.response.data.error, {
            variant: "error",
            autoHideDuration: configuration.autoHideErrorDuration,
          }),
        )
        .finally(() => this.setState({loadingPass: false}));
    }
    sendPasswordForConfirm(this.state.password, this.state.resetToken)
      .then((res) => handlePasswordConfirmation(res))
      .catch((error) =>
        this.props.enqueueSnackbar(error.response.data.error, {
          variant: "error",
          autoHideDuration: configuration.autoHideErrorDuration,
        }),
      )
      .finally(() => this.setState({loadingPass: false}));
  };
  handleFormInputs = (e, key) => {
    const {name, value} = e.target;
    if (key === "password") {
      value.length >= 12
        ? this.setState({minchar: true})
        : this.setState({minchar: false});
      /[A-Z]/.test(e.target.value)
        ? this.setState({uppercase: true})
        : this.setState({uppercase: false});
      /[a-z]/.test(e.target.value)
        ? this.setState({lowercase: true})
        : this.setState({lowercase: false});
      /\d/.test(e.target.value)
        ? this.setState({digit: true})
        : this.setState({digit: false});
      !/^\s+|\s+$/g.test(value) && value.length > 0
        ? this.setState({spaces: true})
        : this.setState({spaces: false});
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        [key]: value,
      }),
      () => {
        if (key === "confirm_password") {
          let {password, confirm_password} = this.state;
          if (password !== confirm_password) {
            this.setState({not_match: true});
            return;
          } else {
            this.setState({not_match: false});
            return;
          }
        }
      },
    );
  };

  handlePasswordInput = (e) => {
    if (e.target.value.length <= 0) {
      this.setState({
        [[e.target.name] + "Error"]: true,
      });
    }
  };

  handleMfaChange = (value) => {
    this.setState({
      mfaCode: value,
    });
  };

  handleMfaBlur = (e) => {
    if (e.target.value.length <= 0) {
      this.setState({
        mfaError: true,
      });
    }
  };

  render() {
    const {
      passwordError,
      minchar,
      spaces,
      digit,
      uppercase,
      lowercase,
      togglePassword,
      not_match,
      showMfa,
      mfaCode,
      mfaError,
      verifyingToken,
      loadingPass,
    } = this.state;
    const {loading} = this.props;
    if (verifyingToken) {
      return (
        <div className="wrapper__login loading">
          <Box zIndex={9}>
            <CircularLoader containerHeight="70vh" />
          </Box>
        </div>
      );
    }

    return (
      <div className="wrapper__login">
        <Link to="/login">
          <ArrowBackSVG color={"#ffffff"} />
        </Link>
        <form
          className="login-form"
          onSubmit={this.handleSubmit}
          onChange={() => this.setState({passwordError: false})}
        >
          {!showMfa && (
            <>
              <p className="login-form__title"></p>
              <p className="login-form__subtitle">Create your new password.</p>
              <div className="login-form__input" style={{marginBottom: "1rem"}}>
                <label
                  htmlFor="password"
                  className="login-form__icon--password__reset"
                >
                  <input
                    data-testid="reset-pass-password-field"
                    type={togglePassword ? "text" : "password"}
                    name="password"
                    className={
                      passwordError
                        ? "login-form__password input-error"
                        : "login-form__password"
                    }
                    placeholder="Password"
                    onChange={(e) => this.handleFormInputs(e, "password")}
                    // onBlur={this.handlePasswordInput}
                  />
                  <i
                    onClick={() =>
                      this.setState({togglePassword: !togglePassword})
                    }
                    className={`fas ${
                      togglePassword ? "fa-eye" : "fa-eye-slash"
                    }`}
                  ></i>
                </label>
                <span className={passwordError ? "error" : ""}>
                  invalid password
                </span>
              </div>
              <div className="login-form__input">
                <label
                  htmlFor="password"
                  className="login-form__icon--password__reset"
                >
                  <input
                    data-testid="reset-pass-confirm-password-field"
                    type={togglePassword ? "text" : "password"}
                    name="password"
                    className={
                      passwordError
                        ? "login-form__password input-error"
                        : "login-form__password"
                    }
                    placeholder="Confirm Password"
                    onChange={(e) => {
                      this.handleFormInputs(e, "confirm_password");
                    }}
                    onBlur={this.handlePasswordInput}
                  />
                  <i
                    onClick={() =>
                      this.setState({togglePassword: !togglePassword})
                    }
                    className={`fas ${
                      togglePassword ? "fa-eye" : "fa-eye-slash"
                    }`}
                  ></i>
                </label>
                <span className={passwordError || not_match ? "error" : ""}>
                  passwords not match
                </span>
              </div>
              <ul className="reset__requirements">
                <li className={uppercase ? "approved" : ""}>
                  one uppercase character
                </li>
                <li className={lowercase ? "approved" : ""}>
                  one lowercase character
                </li>
                <li className={digit ? "approved" : ""}>one number</li>
                <li className={minchar ? "approved" : ""}>
                  12 characters minimum
                </li>
                <li className={spaces ? "approved" : ""}>
                  no spaces at the beginning or end
                </li>
              </ul>
            </>
          )}

          {showMfa && (
            <MfaForm
              hasError={mfaError}
              onChange={this.handleMfaChange}
              onBlur={this.handleMfaBlur}
              value={mfaCode}
            />
          )}

          <button
            type="submit"
            className={
              loadingPass || loading ? "login-form__login loading" : "login-form__login"
            }
            data-testid="reset-pass-login-button"
            disabled={loading || loadingPass}
          >
            {showMfa ? "Verify" : "Send"}
          </button>

          <p
            className="loading"
            style={{visibility: loading || loadingPass ? "visible" : "hidden"}}
          >
            Loading...
          </p>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.AuthReducer.authLoading,
    currentUser: state.AuthReducer.currentUser,
    loginError: state.AuthReducer.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUserLogin: (user) => dispatch(userLogIn(user)),
    onMtfLogin: (user) => dispatch(userMtfLogin(user)),
  };
};

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(RequestResetPassword)),
);
