import {AxiosResponse} from "axios";
import * as yup from "yup";
import {useFormik} from "formik";
import {updateProfile} from "api";
import {useSnackbar} from "notistack";
import {User} from "types/user";
import configuration from "utils/configuration";
import {useCustomerPortal} from "../context/CustomerPortalProvider";
import {SET_AUTH_USER} from "../context/actionTypes";
import {AlertTypes} from "../../types/alertTypes";

const schema = yup.object().shape({
  first_name: yup.string().required("Name is required"),
  last_name: yup.string().required("Name is required"),
  middle_name: yup.string().nullable(),
  email: yup.string().email().required("Email is required"),
  phone: yup
    .string()
    .required("Phone is required")
    .min(12, "Phone number should have 10 digits"),
  timezone: yup.string().required("Province is required"),
  communication_method: yup.string(),
});

const useUserSettingsForm = () => {
  const [{authUser}, dispatch] = useCustomerPortal();
  const {enqueueSnackbar} = useSnackbar();

  const onSubmit = async (values: Partial<User>) => {
    try {
      const updateProfileResult = await updateProfile(values);
      dispatch({type: SET_AUTH_USER, payload: updateProfileResult.data});
      enqueueSnackbar("Successfully updated", {
        variant: AlertTypes.Success,
        autoHideDuration: 1500,
      });
    } catch (e) {
      const error = e as {response: AxiosResponse<{error?: string}>};
      enqueueSnackbar(error.response.data.error ?? "Couldn't update profile", {
        variant: AlertTypes.Error,
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    }
  };

  const {handleSubmit, isSubmitting, values, errors, handleChange, setValues, handleBlur} =
    useFormik<Partial<User>>({
      validationSchema: schema,
      initialValues: authUser.user || {},
      onSubmit,
      validateOnChange: false,
      validateOnBlur: true,
    });

  const handleComm = (type: "Email" | "Text", value: boolean) => {
    let methods = values.communication_method?.split("&") || [];

    if (value) {
      methods.push(type);
    } else {
      methods = methods.filter((t) => t !== type);
    }
    const uniq = methods.filter((value, i, array) => array.indexOf(value) === i);
    values.communication_method = uniq.filter((v) => !!v).sort().reverse().join("&") as never;

    setValues({...values}, true);
  };

  const controlFor = (name: keyof User) => ({
    name,
    value: values[name] as string,
    onChange: handleChange,
    onBlur: handleBlur,
    error: errors[name],
    disabled: isSubmitting,
  });

  return {values, controlFor, handleComm, handleSubmit, isSubmitting} as const;
};

export default useUserSettingsForm;
