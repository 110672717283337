import React, {useContext} from "react";
import {getFormattedPostalCode} from "../../../utils/getFormattedPostalCode";
import {Box, styled, Typography, useTheme} from "@mui/material";
import DeededInput from "../../../v2/components/DeededInput";
import {FormContext} from "./FormContext";

const EquitableBankPostalCodeField: React.FC<{label: string; name: string}> = ({
  label,
  name,
}) => {
  const {values, errors, handleChange} = useContext(FormContext);
  const theme = useTheme();
  const handleChangePostalCode = (e: {
    target: {value: string; name: string};
  }) => {
    const {value, name} = e.target;
    const formatted = getFormattedPostalCode(value as string);
    handleChange({
      target: {
        name: name,
        value: formatted,
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          width: "30%",
          [theme.breakpoints.down("sm")]: {
            width: "48%",
          },
          "& .MuiFormControl-root .MuiOutlinedInput-root": {
            height: "36px",
          },
        }}
      >
        <LabelTypography>{label}</LabelTypography>
        <DeededInput
          error={errors?.postalCode}
          value={values.postalCode ?? ""}
          name={"postalCode"}
          data-selenium-id={"postal-code-input"}
          onChange={handleChangePostalCode}
          placeholder={"A4A 4A4"}
          sx={{
            width: "100%",
          }}
        />
      </Box>
    </>
  );
};
const LabelTypography = styled(Typography)({
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: "8px",
});
export default EquitableBankPostalCodeField;
