import {useEffect} from "react";
import {Route, Switch} from "react-router-dom";
import Retainer from "../Retainer";
import IDV from "../IDV";
import Survey from "../Survey";
import CircularLoader from "components/Common/Loader/CircularLoader";
import FileView from "../FileView";
import {pages} from "../paths";
import {useMixpanel} from "utils/MixpanelContext";
import {useClientOnboarding} from "../../context/ClientOnboardingProvider";
import useOnboardingTokenVerification from "../../hooks/useOnboardingTokenVerification";

export const OnboardingBase = () => {
  const [state] = useClientOnboarding();
  const mixpanel = useMixpanel();

  useOnboardingTokenVerification();

  useEffect(() => {
    if (state.deal.onboarding?.state) {
      mixpanel.track(`intake_state_${state.deal.onboarding.state}`, {
        transactionType: state.deal.deal?.deal_type,
      });
    }
  }, [state.deal.onboarding?.state]);

  if (state.deal.loading || !state.deal.onboarding) {
    return <CircularLoader containerHeight={"70vh"} />;
  }

  return (
    <Switch>
      <Route path={pages.RETAINER.route()} exact component={Retainer} />
      <Route path={pages.IDV.route()} exact component={IDV} />
      <Route path={pages.SURVEY.route()} exact component={Survey} />
      <Route path={pages.FILES.route()} exact component={FileView} />
    </Switch>
  );
};
