import {useClientOnboarding} from "../context/ClientOnboardingProvider";
import {State} from "../../types/intakeForm";
import {updateClientOnboarding} from "../../api";
import {SET_ONBOARDING_DATA, SET_ONBOARDING_LOADING} from "../context/actionTypes";
import {AlertTypes} from "../../types/alertTypes";
import {useHistory, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import configuration from "../../utils/configuration";

const useUpdateIntakeStage = () => {
  const history = useHistory();
  const routeParams = useParams<{onboarding_token?: string}>();
  const {enqueueSnackbar} = useSnackbar();
  const [state, dispatch] = useClientOnboarding();

  const updateIntakeStage = (newState: State, urlFn: (token: string) => string, token?: string) => () => {

    if (!state.deal.onboarding || !state.deal || !routeParams?.onboarding_token) return;

    const url = urlFn(token || routeParams.onboarding_token);
    if (state.deal.onboarding.state === newState) {
      return history.push(url);
    }

    dispatch({
      type: SET_ONBOARDING_LOADING,
      payload: true,
    });

    const onboarding = state.deal.onboarding;
    updateClientOnboarding(onboarding.id, {state: newState})
      .then(({data}) => {
        dispatch({
          type: SET_ONBOARDING_DATA,
          payload: {onboarding: {...onboarding, data}},
        });
        history.push(url);
      })
      .catch(() => {
        enqueueSnackbar("There was an error submitting the data", {
          variant: AlertTypes.Error,
          autoHideDuration: configuration.autoHideErrorDuration,
        });
        dispatch({
          type: SET_ONBOARDING_LOADING,
          payload: false,
        });
      });
  };

  return [updateIntakeStage];
};

export default useUpdateIntakeStage;
