import {FC} from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import Colors from "components/CommonDashboard/Styling/Colors";
import {BodyRegular, H3} from "components/CommonDashboard/Typography";
import Button from "components/CommonDashboard/Button";
import {CheckCircleIcon, CloseIcon} from "components/CommonDashboard/Icons";

interface SecurityMFASuccessModalProps {
  show: boolean;
  onClose: () => void;
}

const SecurityMFASuccessModal: FC<SecurityMFASuccessModalProps> = ({
  show,
  onClose,
}) => {
  return (
    <StyledDialog open={show}>
      <ModalBody>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <H3>Set up complete</H3>
          <Button
            typeOf="secondary"
            onClick={onClose}
            startIcon={<CloseIcon />}
          />
        </Stack>

        <Stack gap="0.8rem">
          <Circle>
            <CheckCircleIcon />
          </Circle>
          <BodyRegular color={Colors.DARK_GREY}>
            Your MFA is now enabled, You will be asked to enter your
            authentication code next time you sign in
          </BodyRegular>
          <BodyRegular color={Colors.DARK_GREY}>
            Contact your administrator to disable your MFA at any time
          </BodyRegular>
        </Stack>

        <Stack direction="row" justifyContent="end">
          <Button onClick={onClose} typeOf="CTA" label={{text: "Done"}} />
        </Stack>
      </ModalBody>
    </StyledDialog>
  );
};

const Circle = styled(Box)({
  display: "flex",
  width: "8rem",
  minWidth: "8rem",
  height: "8rem",
  borderRadius: "50%",
  marginTop: "0.4rem",
  background: Colors.CORAL_20,
  alignItems: "center",
  justifyContent: "center",
});

const ModalBody = styled(Stack)({
  maxWidth: "60rem",
  background: Colors.WHITE,
  gap: "2.4rem",
});

const StyledDialog = styled(Dialog)({
  ".MuiPaper-root": {
    borderRadius: "1.6rem",
    padding: "2.4rem",
  },
});

export default SecurityMFASuccessModal;
