import {FC} from "react";
import {styled} from "@mui/material/styles";
import Stack, {StackProps} from "@mui/material/Stack";
import {BodySmall} from "../Typography";
import Colors from "../Styling/Colors";
import {Box} from "@mui/material";

interface PasswordHintProps {
  active: boolean;
  hint: string;
}

const PasswordHint: FC<PasswordHintProps> = ({ active, hint}) => {
  return (
    <Stack direction="row" alignItems="center" gap="0.8rem">
      <Box bgcolor={active ? Colors.SUCCESS : Colors.WARM_GREY} height="0.8rem" width="0.8rem" borderRadius="50%" />
      <BodySmall color={active ? Colors.BLACK : Colors.DARK_GREY}>{hint}</BodySmall>
    </Stack>
  );
};

export default PasswordHint;
