import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Quote from "./Quote/Quote";
import WhatIsNext from "./WhatIsNext/WhatIsNext";
import ThankYou from "./ThankYou/ThankYou";
import NotFound from "components/Dashboard/404/NotFound";
import Disbursements from "./Disbursements";
import QuoteUpload from "components/Information/Quote/QuoteUpload";


const Information = () => {
    const location = useLocation();
    return (
        <Switch>
            <Route path="/information/my-quote/what-is-next" exact component={WhatIsNext} />
            <Route path="/information/my-quote/new" exact component={Quote} />
            <Route path="/information/my-quote/thank-you" exact component={ThankYou} />
            <Route path="/information/my-quote/disbursements" exact component={Disbursements} />
            <Route path="/information/my-quote/upload/:uuid" component={QuoteUpload} />
            <Route path="/information/my-quote" component={() => <Redirect to={{ pathname: "/information/my-quote/new", search: location.search }} />} />
            <Route component={NotFound} />
        </Switch>
    );
};


export default Information;
