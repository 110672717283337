import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";

interface TestState {
  value: string | null;
}

const initialState: TestState = {
  value: null,
};

export const testSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    setTest: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    clearTest: (state) => {
      state.value = null;
    },
  },
});

export const {setTest, clearTest} = testSlice.actions;

export const selectSuccess = (state: RootState) => state.test.value;

export default testSlice.reducer;
