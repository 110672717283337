import { useEffect, useState } from "react";
import { getDeals } from "api";
import { Deal } from "types/deal";
import { useSnackbar } from "notistack";
import { AlertTypes } from "types/alertTypes";
import configuration from "utils/configuration";
import { useCustomerPortal } from "customerPortal/context/CustomerPortalProvider";

const useGetDeals = (
    status: boolean = true,
    page: number | undefined,
    filter?: { onboarding: boolean } | { showClosed: boolean },
    query: string = '',
    sortBy = 'id',
    initialState?: {
        perPage: number,
        total: number,
        deals: Deal[] | null
    }
) => {
    const { enqueueSnackbar } = useSnackbar();
    const [state,] = useCustomerPortal();
    const [loading, setLoading] = useState(false);
    const [deals, setDeals] = useState<Deal[] | null>(initialState?.deals || null);
    const [total, setTotal] = useState<number>(initialState?.total || 0);
    const [perPage, setPerPage] = useState(initialState?.perPage || 20);

    useEffect(() => {
        if (loading || !state.authUser.user) return;
        if (!page) {
            setTotal(initialState?.total || 0);
            setDeals(initialState?.deals || []);
            setPerPage(initialState?.perPage || 1);
            return;
        }

        setLoading(true);
        getDeals(status, page, query, sortBy, 'desc', null, filter)
            .then((res) => {
                setTotal(res?.data?.total || 0);
                setDeals(res?.data?.data || []);
                setPerPage(res?.data?.per_page || 1);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setDeals([]);
                enqueueSnackbar("There was an error loading the data", {
                    variant: AlertTypes.Error,
                    autoHideDuration: configuration.autoHideErrorDuration,
                });
            })
            .finally(() => setLoading(false));
    }, [page, query, state.authUser.user, initialState]);

    return { deals, loading, total, perPage } as const;
};

export default useGetDeals;
