import React, {LegacyRef, useContext, useRef} from "react";
import MobileDetect from "mobile-detect";
import moment from "moment";
import {Box, styled, Typography, useTheme} from "@mui/material";
import DeededDatePicker from "v2/components/DeededDatePicker";
import {FormContext} from "./FormContext";
import useWindowWidth from "utils/hooks/useWindowWidth";
import constants from "styles/constants";
import CalendarDealInfoCalendarSvg from "../../Svg/CalendarDealInfoCalendarSvg";

const EquitableBankClosingDateField: React.FC<{
  label: string;
  name: string;
}> = ({label, name}) => {
  const md = new MobileDetect(window.navigator.userAgent);
  const windowWidth = useWindowWidth();
  const theme = useTheme();
  const { sm } = theme.breakpoints.values;
  const {handleChange, values, errors} = useContext(FormContext);
  const inputRef = useRef(null) as unknown as {
    current: {showPicker: () => void};
  };
  const isDateValid = moment(values.closingDate).isValid();
  const isMobile =
    windowWidth < sm && !!md.mobile() && md.mobile() !== "UnknownPhone";
  return (
    <>
      <Box
        sx={{
          width: "30%",
          "& .MuiFormControl-root": {
            width: "100%",
            height: "36px",
            "& .MuiOutlinedInput-root": {
              height: "36px !important",
              "& .MuiInputAdornment-root .MuiButtonBase-root": {
                top: "-2px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&:focus": {
                  backgroundColor: "transparent",
                },
                "&:active": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
        }}
      >
        <LabelTypography>{label}</LabelTypography>
        {isMobile && (
          <>
            <NativeDatePickerLabel
              htmlFor={"native-date-picker"}
              onClick={() => {
                inputRef?.current?.showPicker();
              }}
            >
              <Box
                sx={{
                  marginRight: "8px",
                  marginTop: "3px",
                  svg: {
                    stroke: constants.colors.sortingColor,
                  },
                }}
              >
                <CalendarDealInfoCalendarSvg />
              </Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: isDateValid
                    ? constants.colors.sortingColor
                    : constants.colors.grayBorder,
                }}
              >
                {isDateValid
                  ? moment(values.closingDate).format("MM/DD/YYYY")
                  : "mm/dd/yyyy"}
              </Typography>
            </NativeDatePickerLabel>
            <NativeDatePicker
              ref={
                inputRef as unknown as LegacyRef<HTMLInputElement> | undefined
              }
              id={"native-date-picker"}
              placeholder={"MM/DD/YYYY"}
              value={
                moment(values.closingDate, "MM/DD/YYYY").format(
                  "YYYY-MM-DD",
                ) as unknown as string
              }
              onChange={(e) => {
                handleChange({
                  target: {
                    name,
                    value: moment(e.target.value, "YYYY-MM-DD").format(
                      "MM/DD/YYYY",
                    ),
                  },
                });
              }}
              name={name}
              type={"date"}
            />
          </>
        )}
        {!isMobile && (
          <DeededDatePicker
            hasError={errors[name as keyof typeof errors]}
            data-selenium-id="start-date-picker"
            dateStart={values.closingDate ? new Date(values.closingDate) : null}
            onChangeEvent={handleChange as (someToChange: unknown) => void}
            name={name}
          />
        )}
      </Box>
    </>
  );
};
const NativeDatePickerLabel = styled("label")({
  paddingLeft: "8px",
  width: "100%",
  border: `1px solid ${constants.colors.grayBorder}`,
  height: "36px",
  borderRadius: "5px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});
const NativeDatePicker = styled("input")({
  width: "0% !important",
  height: "0px",
  marginLeft: "50px",
  opacity: "0",
  position: "relative",
  "&::-webkit-calendar-picker-indicator": {
    position: "absolute",
    left: "20px",
    top: "10px",
  },
});
const LabelTypography = styled(Typography)({
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: "8px",
});
export default EquitableBankClosingDateField;
