import { useMemo, useState, useEffect } from "react";
import { Stack } from "@mui/material";
import BasicButton from "components/Common/Button/BasicButton";
import { CaretRight } from "components/Common/Icons/Iconography";
import constants from "styles/constants";
import { Body, H1 } from "components/Common/Typography/index";
import { useHistory, useLocation } from "react-router-dom";
import { getExistingIntakeByToken, intakeRequestAccessed } from "api";
import { Wrapper } from "components/Common/Wrapper/Wrapper"
import { useMixpanel } from "utils/MixpanelContext";

interface DetailsProps {

}

const Details = ({ }: DetailsProps) => {
    const { search } = useLocation();
    const history = useHistory();
    const mixpanel = useMixpanel();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const [page, setPage] = useState<string | null>(null);
    const [transactionType, setTransactionType] = useState(``);
    const clientId = query.get("client_id");
    if (!clientId) return history.replace("/");

    useEffect(() => {
        getExistingIntakeByToken(clientId).then((res) => {
            const { clientHasAccount, deal_type } = res.data;
            setTransactionType(deal_type)

            mixpanel.track(`intake_welcome`, { transactionType });

            if (!clientHasAccount) {
                setPage(`/reset-password` + window.location.search)
            } else {
                setPage(`/login`)
            }
        }).catch((error) => {
            error?.response?.status === 404 && history.push("/not-found");
        });
        intakeRequestAccessed(clientId);
    }, []);

    return <Wrapper stageName={'Registration'}>
        <Stack alignItems={'center'}>
            <Stack direction={'column'}
                justifyContent={'center'}
                height={'calc(100vh - 240px)'}
                maxWidth={'70rem'}
                alignItems={'center'}
                spacing={{ xs: '4rem', sm: '4rem', md: '4rem', lg: '8rem' }}
                sx={{
                    marginLeft: '2rem',
                    marginRight: '2rem',
                }}>
                <H1 textAlign={'center'}>WELCOME TO DEEDED!</H1>
                <Body data-testid="registration-body-text" sx={{ display: "flex", margin: "0 1rem", flexFlow: "nowrap column" }}>
                    We're thrilled to make the process of closing your {transactionType} smooth and easy. To get started, we'll need some information about your transaction.
                    In the following pages we'll setup your account and then collect the necessary information to process your closing quickly and transparently.
                </Body>
                <BasicButton
                    typeOf="CTA"
                    disabled={!page}
                    label={{ text: page ? "Get Started" : "Loading...", inputId: "ButtonId" }}
                    size="medium"
                    rightIcon={CaretRight({ color: constants.colors.white })}
                    onClick={() => page && history.push(page)}
                    testId="registration-get-started-btn"
                />
            </Stack>
        </Stack>
    </Wrapper>
}

export default Details;