import {ActionType} from "../actionTypes";
import {postEnteredField} from "../../api";

const mortgageState = {
  ownersNumber: null,
  mortgageFullName: null,
  corporateRefinance: null,
  birthDay: null,
  currentAddress: null,
  phoneNumber: null,
  //email: "",
  employmentStatus: null,
  employerName: null,
  employerAddress: null,
  supousalStatus: null,
  citizenshipStatus: null,
  cards: null,
  signingAppointment: null,
  typeProperty: null,
  ownershipProperty: null,
  condominium: null,
  newMortgage: null,
  scheduledClosingDate: null,
  debtsList: null,
  currentMortgageStatement: null,
  homeInsurancePolicy: null,
  propertyTaxBillStatement: null,
  voidCheque: null,
  tankWellFuel: null,
  additionalInformation: null,
  sourceOfFunds: null,
  has_mortgage_referring: false,
  separationAgreement: null
};

export const MortgageReducer = (state = mortgageState, action) => {
  switch (action.type) {
    case "SET_HAS_MORTGAGE_REFERRING_MORTGAGE": {
      return {
        ...state,
        has_mortgage_referring: action.payload,
      };
    }
    case ActionType.SET_TMP_DATA_MORTGAGE_INTAKE_REQUEST: {
      const newState = action.payload;
      if (newState === false) {
        return {
          ...state,
        };
      }
      return {
        ...newState,
      };
    }
    case "SET_MORTGAGE_EMAIL": {
      const actionPayload = action.payload;

      return {
        ...state,
        email: action.payload,
      };
    }
    case ActionType.MORTGAGE_RESET:
      return {
        ...state,
        ownersNumber: null,
        mortgageFullName: null,
        corporateRefinance: null,
        birthDay: null,
        currentAddress: null,
        phoneNumber: null,
        employmentStatus: null,
        employerName: null,
        employerAddress: null,
        supousalStatus: null,
        citizenshipStatus: null,
        cards: null,
        signingAppointment: null,
        typeProperty: null,
        ownershipProperty: null,
        condominium: null,
        newMortgage: null,
        scheduledClosingDate: null,
        debtsList: null,
        currentMortgageStatement: null,
        homeInsurancePolicy: null,
        propertyTaxBillStatement: null,
        voidCheque: null,
        additionalInformation: null,
        sourceOfFunds: null,
        tankWellFuel: null,
        separationAgreement: null
      };
    case ActionType.MORTGAGE_SET_OWNERS_NUMBER:
      return {
        ...state,
        ownersNumber: action.payload,
        mortgageFullName: null,
        corporateRefinance: null,
        birthDay: null,
        currentAddress: null,
        phoneNumber: null,
        employmentStatus: null,
        employerName: null,
        employerAddress: null,
        supousalStatus: null,
        citizenshipStatus: null,
        cards: null,
        signingAppointment: null,
        typeProperty: null,
        ownershipProperty: null,
        condominium: null,
        newMortgage: null,
        scheduledClosingDate: null,
        debtsList: null,
        currentMortgageStatement: null,
        homeInsurancePolicy: null,
        propertyTaxBillStatement: null,
        voidCheque: null,
        additionalInformation: null,
        sourceOfFunds: null,
        tankWellFuel: null,
        separationAgreement: null
      };
    case ActionType.MORTGAGE_SET_MORTGAGE_FULLNAME:
      return {
        ...state,
        mortgageFullName: action.payload,
      };
    case ActionType.MORTGAGE_SET_CORPORATE_REFINANCE:
      return {
        ...state,
        corporateRefinance: action.payload,
      };
    case ActionType.MORTGAGE_SET_BIRTH_DAY:
      return {
        ...state,
        birthDay: action.payload,
      };
    case ActionType.MORTGAGE_SET_CURRENT_ADDRESS:
      return {
        ...state,
        currentAddress: action.payload,
      };
    case ActionType.MORTGAGE_SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case ActionType.MORTGAGE_SET_EMPLOYMENT_STATUS:
      return {
        ...state,
        employmentStatus: action.payload,
      };
    case ActionType.MORTGAGE_SET_EMPLOYER_NAME:
      return {
        ...state,
        employerName: action.payload,
      };
    case ActionType.MORTGAGE_SET_EMPLOYER_ADDRESS:
      return {
        ...state,
        employerAddress: action.payload,
      };
    case ActionType.MORTGAGE_SET_SUPOUSAL_STATUS:
      return {
        ...state,
        supousalStatus: action.payload,
      };
    case ActionType.MORTGAGE_SET_CITIZENSHIP_STATUS:
      return {
        ...state,
        citizenshipStatus: action.payload,
      };

    case ActionType.MORTGAGE_SET_IDENTIFICATION:
      return {
        ...state,
        cards: action.payload,
      };
    case ActionType.MORTGAGE_SET_SIGNING_APPOINTMENT:
      return {
        ...state,
        signingAppointment: action.payload,
      };
    case ActionType.MORTGAGE_SET_TYPE_PROPERTY:
      return {
        ...state,
        typeProperty: action.payload,
      };
    case ActionType.MORTGAGE_SET_OWNERSHIP_PROPERTY:
      return {
        ...state,
        ownershipProperty: action.payload,
      };
    case ActionType.MORTGAGE_SET_CONDOMINIUM:
      return {
        ...state,
        condominium: action.payload,
      };
    case ActionType.MORTGAGE_SET_NEW_MORTGAGE:
      return {
        ...state,
        newMortgage: action.payload,
      };
    case ActionType.MORTGAGE_SET_SCHEDULED_CLOSING_DATE:
      return {
        ...state,
        scheduledClosingDate: action.payload,
      };
    case ActionType.MORTGAGE_SET_DEBT_LIST:
      return {
        ...state,
        debtsList: action.payload,
      };
    case ActionType.MORTGAGE_SET_CURRENT_MORTGAGE_STATEMENT:
      return {
        ...state,
        currentMortgageStatement: action.payload,
      };
    case ActionType.MORTGAGE_SET_HOME_INSURANCE_POLICY:
      return {
        ...state,
        homeInsurancePolicy: action.payload,
      };
    case ActionType.MORTGAGE_SET_PROPERTY_TAX_BILL_STATEMENT:
      return {
        ...state,
        propertyTaxBillStatement: action.payload,
      };
    case ActionType.MORTGAGE_SET_VOID_CHEQUE:
      return {
        ...state,
        voidCheque: action.payload,
      };
    case ActionType.MORTGAGE_SET_ADDITIONAL_INFO:
      return {
        ...state,
        additionalInformation: action.payload,
      };
    case ActionType.MORTGAGE_SET_SOURCE_OF_FOUNDS_INFO: {
      return {
        ...state,
        sourceOfFunds: action.payload,
      };
    }
    case ActionType.MORTGAGE_SET_TANK_WELL_FUEL: {
      return {
        ...state,
        tankWellFuel: action.payload,
      };
    }
    case ActionType.MORTGAGE_SET_SEPARATION_AGREEMENT:
      return {
        ...state,
        separationAgreement: action.payload,
      };
    default:
      return state;
  }
};

export const setHasMortgageReferringMortgageAC = (payload) => ({
  type: "SET_HAS_MORTGAGE_REFERRING_MORTGAGE",
  payload,
});
export const postEnteredMortgageFieldsTC =
  (client_id) => async (dispatch, getState) => {
    const reducer = getState().MortgageReducer;
    await postEnteredField(client_id, {
      ...reducer,
      cards: null,
      debtsList: null,
      currentMortgageStatement: null,
      homeInsurancePolicy: null,
      propertyTaxBillStatement: null,
      voidCheque: null,
      separationAgreement: null,
      condominium: reducer.condominium ? { ...reducer.condominium, documents: [] } : null,
    });
  };
