import { FC } from "react";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";
import constants from "styles/constants";

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
  }
}

const ThemeProvider: FC = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

const theme = createTheme({
  typography: {
    fontFamily: `Montserrat`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Montserrat",
          fontStyle: "normal"
        },
      },
    },
  },
  palette: {
    primary: {
      main: constants.colors.red,
    },
  }, breakpoints: {
    values: {
      xs: 0,    //default breakpoints
      sm: 600,
      md: 900,  
      lg: 1200,  
      xl: 1536,
      mobile: 780 // custom breakpoints
    },
  },
});

export default ThemeProvider;
