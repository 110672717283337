import React, {ChangeEvent, FC, FocusEventHandler} from "react";
import {Typography} from "@mui/material";
import constants from "../../styles/constants";

interface MfaFormProps {
  hasError: boolean;
  onChange: (value: string) => void;
  onBlur: FocusEventHandler<HTMLInputElement>;
  value: string;
}

const MfaForm: FC<MfaFormProps> = ({hasError, onChange, onBlur, value}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    const isOnlyDigits = /^\d+$/.test(value);
    const isAllowedChangeValue =
      value.length === 0 || (isOnlyDigits && value.length <= 6);
    if (isAllowedChangeValue) {
      onChange(e.target.value);
    }
  };

  return (
    <>
      <Typography
        sx={{
          color: constants.colors.white,
          fontSize: "16px",
          fontWeight: "500",
          marginBottom: "20px",
        }}
      >
        Authentication Code
      </Typography>
      <div className="login-form__input">
        <label htmlFor="password" className="login-form__icon--password">
          <input type="hidden" value="something" />
        </label>
        <input
          autoComplete="off"
          type="password"
          name="authentication code"
          className={
            hasError
              ? "login-form__password input-error"
              : "login-form__password"
          }
          placeholder="Authentication Code"
          onChange={handleChange}
          value={value}
          onBlur={onBlur}
        />
      </div>
      <Typography
        sx={{
          marginTop: "-10px",
          width: "250px",
          color: constants.colors.white,
        }}
      >
        Open the Google Authenticator on your phone to view your authentication
        code
      </Typography>
    </>
  );
};

export default MfaForm;
