import React, { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { useMixpanel } from "utils/MixpanelContext";
import { Header } from "components/Common/Wrapper/Header/Header";
import Footer from "components/CommonDashboard/Footer/Footer";
import CircularLoader from "components/Common/Loader/CircularLoader";
import LandingTitleBlock from "./components/LandingTitleBlock";
import LandingDescription from "./components/LandingDescription";
import usePartnerPage from "./hooks/usePartnerPage";
import bg from "assets/img/partner-page-bg.png";
import {styled} from "@mui/material/styles";

const Landing: FC = () => {
    const mixpanel = useMixpanel();
    const [page, loading] = usePartnerPage();

    useEffect(() => {
        if (page) {
            const mixPayload = {
                partner_slug: page.slug,
                partner_email: page.user.email,
                partner_full_name: page.user.full_name,
                partner_role: page.user.role,
            };
            mixpanel.track(`partner_referral_page_open`, mixPayload);
        }
    }, [page]);

    if (loading) {
        return <CircularLoader containerHeight="70vh" />;
    }

    return (
        <Stack>
            <Container>
                <Header title="" fixed={false} maxWidth="122.4rem" transparent />
                <LandingTitleBlock displayName={page?.page_name || ""} slug={page?.slug || ''} />
                <LandingDescription />
            </Container>
            <Footer />
        </Stack>
    );
};

const Container = styled(Stack)(() => ({
    gap: "6rem",
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
}));

export default Landing;
