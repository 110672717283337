import {combineReducers} from "redux";
import StaffCardReducer, {StaffCardReducerState} from "./staffCardReducer";
import EmailTemplateReducer, {
  EmailTemplateInitialStateType,
} from "./emailTemplateReducer";
import ProfReducer, {ProfReducerState} from "./profReducer";
import {
  userManagerReducer,
  UserManagerStateInterface,
} from "./userManagerReducer";
import {
  ConfigNotificationsReducer,
  ConfigNotificationsReducerState,
} from "./configNotificationsReducer";

export type ConfigReducerState = {
  ProfReducer: ProfReducerState;
  EmailTemplateReducer: EmailTemplateInitialStateType;
  StaffCardReducer: StaffCardReducerState;
  userManagerReducer: UserManagerStateInterface;
  ConfigNotificationsReducer: ConfigNotificationsReducerState;
};
const ConfigReducer = combineReducers({
  StaffCardReducer,
  EmailTemplateReducer,
  ProfReducer,
  userManagerReducer,
  ConfigNotificationsReducer,
});

export default ConfigReducer;
