import { useState, useEffect } from 'react';
import { getClientOnboarding, resumeIDV } from 'api';
import { ClientOnboarding, IDVStates } from "types/intakeForm";

const useIDVStatusPolling = (clientOnboarding: ClientOnboarding | null, isIDVOpen: boolean) => {
    const [status, setStatus] = useState(clientOnboarding?.user_idv?.state || '');
    const [isTimeout, setIsTimeout] = useState(false);
    const maxTimeout = 1000 * 60 * 20; // in miliseconds = 20min
    const pollingInterval = 1000 * 20; // in miliseconds = 20s
    let elapsedTime = 0;
    let interval: NodeJS.Timeout;

    const stopTimer = () => {
        clearInterval(interval);
    }
    const poll = async () => {
        if (elapsedTime >= maxTimeout || !clientOnboarding) {
            setIsTimeout(true);
            clearInterval(interval);
            return;
        }

        const { data: onboarding }: { data: ClientOnboarding } = await getClientOnboarding(clientOnboarding.id, {});

        if (onboarding.user_idv?.state !== status) {
            setStatus(onboarding.user_idv?.state || '');
        }

        if (onboarding.user_idv?.state === IDVStates.Completed) {
            clearInterval(interval);
            return;
        }

        elapsedTime += pollingInterval;
    };

    useEffect(() => {
        if (isIDVOpen) {
            interval = setInterval(poll, pollingInterval);
        }
        if (!isIDVOpen && interval) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isIDVOpen, clientOnboarding]);

    return { status, isTimeout, stopTimer };
};
export default useIDVStatusPolling;