import React from "react";

const TransactionsPlace = () => {
    return (
        <svg width="100%" height="254" viewBox="0 0 401 254" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_510_22926)">
                <path d="M292.367 62.293C292.367 86.4992 254.53 157.885 232.836 196.826C231.364 199.468 229.214 201.669 226.606 203.201C223.999 204.733 221.03 205.541 218.006 205.541C214.981 205.541 212.012 204.733 209.405 203.201C206.797 201.669 204.647 199.468 203.175 196.826C181.481 157.885 143.645 86.4992 143.645 62.293C143.645 28.3585 176.937 0.849121 218.006 0.849121C259.074 0.849121 292.367 28.3585 292.367 62.293Z" fill="#F45753" />
                <path d="M218.355 102.092C240.721 102.092 258.852 83.9607 258.852 61.5948C258.852 39.2288 240.721 21.0977 218.355 21.0977C195.989 21.0977 177.858 39.2288 177.858 61.5948C177.858 83.9607 195.989 102.092 218.355 102.092Z" fill="white" />
                <path d="M49.3631 98.2374C62.7594 98.2374 73.6192 87.3775 73.6192 73.9812C73.6192 60.5849 62.7594 49.7251 49.3631 49.7251C35.9668 49.7251 25.1069 60.5849 25.1069 73.9812C25.1069 87.3775 35.9668 98.2374 49.3631 98.2374Z" fill="#FF6584" />
                <path d="M218.006 253.151C250.433 253.151 276.721 251.122 276.721 248.619C276.721 246.115 250.433 244.086 218.006 244.086C185.578 244.086 159.291 246.115 159.291 248.619C159.291 251.122 185.578 253.151 218.006 253.151Z" fill="#F45753" />
                <path opacity="0.4" d="M44.4245 95.5515C45.659 87.9441 40.4927 80.7762 32.8852 79.5417C25.2778 78.3072 18.11 83.4735 16.8754 91.0809C15.6409 98.6884 20.8072 105.856 28.4146 107.091C36.0221 108.325 43.19 103.159 44.4245 95.5515Z" fill="#F45753" />
                <path d="M357.133 165.302C247.24 124.107 113.208 164.269 35.8236 246.422C35.3837 246.883 34.9438 247.351 34.5179 247.819H33.3379C33.7429 247.351 34.1618 246.883 34.5737 246.422C113.564 158.02 245.815 122.787 357.133 165.302Z" fill="#3F3D56" />
                <path d="M387.164 178.967C377.45 173.786 367.42 169.222 357.133 165.302C367.447 169.158 377.481 173.724 387.164 178.967Z" fill="#3F3D56" />
                <path opacity="0.4" d="M327.019 137.866C335.472 137.866 342.324 131.013 342.324 122.561C342.324 114.108 335.472 107.255 327.019 107.255C318.566 107.255 311.714 114.108 311.714 122.561C311.714 131.013 318.566 137.866 327.019 137.866Z" fill="#F45753" />
                <path d="M328.723 240.286C329.906 244.658 333.957 247.366 333.957 247.366C333.957 247.366 336.091 242.985 334.908 238.613C333.725 234.241 329.674 231.533 329.674 231.533C329.674 231.533 327.54 235.914 328.723 240.286Z" fill="#3F3D56" />
                <path d="M330.461 239.346C333.707 242.505 334.103 247.362 334.103 247.362C334.103 247.362 329.238 247.096 325.992 243.937C322.747 240.778 322.35 235.921 322.35 235.921C322.35 235.921 327.216 236.187 330.461 239.346Z" fill="#F45753" />
                <path d="M2.8459 179.494V186.239L2.51074 186.602V179.424C2.62246 179.445 2.7342 179.473 2.8459 179.494Z" fill="#3F3D56" />
                <path d="M24.3373 246.419C24.2396 246.579 24.1348 246.74 24.0301 246.901H2.51074V179.829L2.8459 179.494L5.70164 182.741V177.993H14.7786V193.06L23.9463 203.471L24.0301 203.569C24.1418 203.918 24.2465 204.267 24.3513 204.616C24.352 204.621 24.3544 204.626 24.3582 204.63C25.7332 209.03 26.8614 213.503 27.7377 218.029C29.7346 228.705 29.4343 238.494 24.3373 246.419Z" fill="#CCCCCC" />
                <path d="M10.067 216.568H4.28955V221.383H10.067V216.568Z" fill="#3F3D56" />
                <path d="M10.067 225.234H4.28955V230.049H10.067V225.234Z" fill="#3F3D56" />
                <path d="M188.035 207.421L185.146 202.606L212.108 179.497C232.04 169.902 256.067 172.671 281.437 179.497V186.237L256.402 213.199L227.514 218.976L188.035 207.421Z" fill="#3F3D56" />
                <path d="M293.371 193.058C298.159 187.732 298.825 182.668 293.371 177.992H284.295V182.743L281.437 179.496L258.328 202.606L254.475 206.457L249.661 225.716L258.328 246.899H302.621V203.569L293.371 193.058Z" fill="#CCCCCC" />
                <path d="M185.147 202.606H258.328V246.9H185.147C176.293 237.321 180.892 219.862 185.147 202.606Z" fill="#F2F2F2" />
                <path d="M248.217 216.568H232.811V230.049H248.217V216.568Z" fill="#3F3D56" />
                <path d="M226.552 216.087H213.071V246.9H226.552V216.087Z" fill="#CCCCCC" />
                <path d="M197.664 220.901V216.087H191.887V220.901H197.183H197.664Z" fill="#3F3D56" />
                <path d="M197.183 224.753H191.887V229.568H197.664V224.753H197.183Z" fill="#3F3D56" />
                <path d="M207.293 216.087H201.516V220.901H207.293V216.087Z" fill="#3F3D56" />
                <path d="M207.293 224.753H201.516V229.567H207.293V224.753Z" fill="#3F3D56" />
                <path d="M279.03 221.383V216.568H273.252V221.383H278.549H279.03Z" fill="#3F3D56" />
                <path d="M278.549 225.234H273.252V230.049H279.03V225.234H278.549Z" fill="#3F3D56" />
                <path d="M288.659 216.568H282.882V221.383H288.659V216.568Z" fill="#3F3D56" />
                <path d="M288.659 225.234H282.882V230.049H288.659V225.234Z" fill="#3F3D56" />
                <path d="M57.7308 126.537L52.9165 122.685L79.8778 98.6118H148.244V119.796L103.951 149.647L57.7308 126.537Z" fill="#3F3D56" />
                <path d="M160.708 112.116V99.0929H151.632V102.282L148.244 98.6118L126.097 122.685L117.431 128.462L103.994 226.635L124.258 246.9H171.354C178.57 210.6 184.292 173.311 171.354 123.647L160.708 112.116Z" fill="#CCCCCC" />
                <path d="M52.9164 122.685H126.097V246.9H52.9164C44.9083 198.643 40.9973 153.892 52.9164 122.685Z" fill="#F2F2F2" />
                <path d="M94.8025 216.568H81.3218V246.9H94.8025V216.568Z" fill="#CCCCCC" />
                <path d="M66.8782 225.235V220.42H61.1011V225.235H66.3971H66.8782Z" fill="#3F3D56" />
                <path d="M66.3971 229.086H61.1011V233.901H66.8782V229.086H66.3971Z" fill="#3F3D56" />
                <path d="M76.5074 220.42H70.73V225.234H76.5074V220.42Z" fill="#3F3D56" />
                <path d="M105.876 229.086H100.58V233.901H106.357V229.086H105.876Z" fill="#3F3D56" />
                <path d="M115.986 220.42H110.209V225.234H115.986V220.42Z" fill="#3F3D56" />
                <path d="M115.986 229.086H110.209V233.9H115.986V229.086Z" fill="#3F3D56" />
                <path d="M146.8 128.944V124.129H141.022V128.944H146.319H146.8Z" fill="#3F3D56" />
                <path d="M146.319 132.796H141.022V137.61H146.8V132.796H146.319Z" fill="#3F3D56" />
                <path d="M156.429 124.129H150.651V128.943H156.429V124.129Z" fill="#3F3D56" />
                <path d="M156.429 132.795H150.651V137.61H156.429V132.795Z" fill="#3F3D56" />
                <path d="M146.8 155.906V151.091H141.022V155.906H146.319H146.8Z" fill="#3F3D56" />
                <path d="M146.319 159.757H141.022V164.571H146.8V159.757H146.319Z" fill="#3F3D56" />
                <path d="M156.429 151.09H150.651V155.905H156.429V151.09Z" fill="#3F3D56" />
                <path d="M156.429 159.757H150.651V164.571H156.429V159.757Z" fill="#3F3D56" />
                <path d="M146.8 182.867V178.052H141.022V182.867H146.319H146.8Z" fill="#3F3D56" />
                <path d="M146.319 186.718H141.022V191.533H146.8V186.718H146.319Z" fill="#3F3D56" />
                <path d="M156.429 178.052H150.651V182.866H156.429V178.052Z" fill="#3F3D56" />
                <path d="M156.429 186.718H150.651V191.533H156.429V186.718Z" fill="#3F3D56" />
                <path d="M146.8 209.828V205.013H141.022V209.828H146.319H146.8Z" fill="#3F3D56" />
                <path d="M146.319 213.68H141.022V218.494H146.8V213.68H146.319Z" fill="#3F3D56" />
                <path d="M156.429 205.013H150.651V209.828H156.429V205.013Z" fill="#3F3D56" />
                <path d="M156.429 213.68H150.651V218.494H156.429V213.68Z" fill="#3F3D56" />
                <path d="M61.1011 128.944H116.95V140.499C99.0485 135.558 80.381 135.913 61.1011 140.499V128.944Z" fill="#3F3D56" />
                <path d="M61.1011 152.053C78.532 157.189 97.1482 157.189 116.95 152.053V163.608H61.1011V152.053Z" fill="#3F3D56" />
                <path d="M61.1011 175.163H116.95V186.718C97.9166 180.64 79.2808 180.354 61.1011 186.718V175.163Z" fill="#3F3D56" />
                <path d="M61.1011 198.273C79.315 205.293 97.948 205 116.95 198.273V209.828H61.1011V198.273Z" fill="#3F3D56" />
                <path d="M361.26 178.199H400.5V197.107L396.995 198.203L385.334 201.842L367.041 197.605L348.768 193.37L339.119 191.135L334.301 189.353C338.309 183.853 345.598 179.528 361.26 178.199Z" fill="#3F3D56" />
                <path d="M400.5 189.353V246.901H334.301C334.28 246.865 334.266 246.833 334.245 246.797V246.794C334.229 246.754 334.211 246.715 334.189 246.678C333.156 244.598 332.23 242.523 331.41 240.454C324.463 222.944 325.217 205.896 334.301 189.353H400.5Z" fill="#F2F2F2" />
                <path d="M376.188 216.568H362.707V246.9H376.188V216.568Z" fill="#CCCCCC" />
                <path d="M348.263 225.235V220.42H342.486V225.235H347.782H348.263Z" fill="#3F3D56" />
                <path d="M347.782 229.086H342.486V233.901H348.263V229.086H347.782Z" fill="#3F3D56" />
                <path d="M357.893 220.42H352.115V225.234H357.893V220.42Z" fill="#3F3D56" />
                <path d="M357.893 229.086H352.115V233.9H357.893V229.086Z" fill="#3F3D56" />
                <path d="M387.742 225.235V220.42H381.965V225.235H387.261H387.742Z" fill="#3F3D56" />
                <path d="M387.261 229.086H381.965V233.901H387.742V229.086H387.261Z" fill="#3F3D56" />
                <path d="M397.372 220.42H391.594V225.234H397.372V220.42Z" fill="#3F3D56" />
                <path d="M397.372 229.086H391.594V233.9H397.372V229.086Z" fill="#3F3D56" />
                <path d="M348.77 155.855H357.069C365.254 155.855 373.104 159.107 378.892 164.895C384.68 170.683 387.932 178.533 387.932 186.718H348.77V155.855Z" fill="#3F3D56" />
                <path d="M97.4087 72.0684H105.708C113.893 72.0684 121.743 75.3199 127.531 81.1078C133.319 86.8957 136.57 94.7457 136.57 102.931H97.4087V72.0684Z" fill="#3F3D56" />
                <path d="M230.072 149.571H238.371C246.556 149.571 254.406 152.823 260.194 158.611C265.982 164.399 269.233 172.249 269.233 180.434H230.072V149.571Z" fill="#3F3D56" />
                <path d="M387.932 198.273H348.77V209.828H387.932V198.273Z" fill="#3F3D56" />
                <path d="M400.5 246.422H2.51123V247.819H400.5V246.422Z" fill="#3F3D56" />
                <path d="M39.2173 191.259H37.8208V246.419H39.2173V191.259Z" fill="#3F3D56" />
                <path d="M52.105 198.288C52.1939 217.576 38.6237 233.275 38.6237 233.275C38.6237 233.275 21.2454 217.368 24.8206 198.414C28.4161 179.352 39.6223 163.508 38.3018 163.427C38.3018 163.427 52.0161 179 52.105 198.288Z" fill="#F45753" />
                <path d="M178.863 191.259H177.466V246.419H178.863V191.259Z" fill="#3F3D56" />
                <path d="M191.75 198.288C193.009 217.536 178.269 233.275 178.269 233.275C178.269 233.275 164.555 217.702 164.466 198.414C164.377 179.126 177.947 163.427 177.947 163.427C176.624 168.714 190.732 182.715 191.75 198.288Z" fill="#F45753" />
                <path d="M88.1367 101.595L148.794 98.6279L174.24 119.867L169.741 123.929L129.447 123.022L88.1367 101.595Z" fill="#3F3D56" />
                <path d="M27.6121 200.161L24.3444 203.115L23.9464 203.471L23.1156 204.225L2.5109 203.764V179.424C1.8406 179.277 1.1703 179.138 0.5 179.005L2.16877 178.921L2.5109 179.208L5.70179 181.868L14.7787 189.451L24.917 197.913L27.6121 200.161Z" fill="#3F3D56" />
                <path d="M220.8 181.891L281.457 178.924L306.903 200.163L302.404 204.225L262.11 203.318L220.8 181.891Z" fill="#3F3D56" />
            </g>
            <defs>
                <clipPath id="clip0_510_22926">
                    <rect width="400" height="252.302" fill="white" transform="translate(0.5 0.849121)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default TransactionsPlace;
