import React, {useEffect, useState} from "react";
import {Link, Route, Switch, useRouteMatch} from "react-router-dom";
import axios from "axios";
import {withSnackbar} from "utils/withSnackbar";
import {baseURL} from "../../../api/index";
import DeededSVG from "../../Svg/Deeded";
import CloseMenuSVG from "../../Svg/CloseMenu";
import BurgerSVG from "../../Svg/Burger";
import SurveyAccordionMenu from "../SurveyAccordionMenu/SurveyAccordionMenu";
import SurveyHome from "../SurveyHome/SurveyHome";
import SurveyQuestion1 from "../SurveyQuestions/SurveyQuestion1";
import SurveyQuestion2 from "../SurveyQuestions/SurveyQuestion2";
import SurveyQuestion3 from "../SurveyQuestions/SurveyQuestion3";
import SurveyRating from "../SurveyRating";
import GoogleReview from "../GoogleReview/GoogleReview";
import {setSurveyProvinceAC} from "../../../redux/reducers/surveyReducer";
import {useDispatch} from "react-redux";
import configuration from "../../../utils/configuration";

const Survey = ({history, enqueueSnackbar}) => {
  let match = useRouteMatch();

  const clientID = match.params.id?.split("=")?.[1]?.split("&")?.[0];
  const [client, setClient] = useState(null);
  const [clientStatus, setClientStatus] = useState(null);
  const [sidebarClose, setSidebarClose] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setSidebarClose(true);
      return;
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 768) {
        setSidebarClose(true);
        return;
      }
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const handleSidebarClose = () => {
    setSidebarClose((sidebarClose) => !sidebarClose);
  };

  const {pathname} = history.location;
  let hideTrigger =
    match.params.page === "create-client-account" ||
    match.params.page === "thank-you";
  useEffect(() => {
    // GET CLIENT DEAL DATA
    if (clientID) {
      axios
        .get(`${baseURL}/deals/survey/get-client?client_id=${clientID}`)
        .then((res) => {
          setClient(res.data);

          dispatch(setSurveyProvinceAC(res.data?.province));
        })
        .catch((error) => {
          setClientStatus(error?.response?.status);
          if (error.response.data.error === "Not found.") {
            console.error(error.response.data.error);
          } else {
            enqueueSnackbar(error.response.data.error, {
              variant: "error",
              autoHideDuration: configuration.autoHideErrorDuration,
            });
          }
        });
    }
  }, [clientID, enqueueSnackbar]);

  return (
    <div className="survey__wrapper">
      <div className={sidebarClose ? "sidebar closed" : "sidebar"}>
        <div className="sidebar__title">
          <Link
            to={`/survey/home/${match.params.id}`}
            onClick={(e) => (hideTrigger ? e.preventDefault() : null)}
          >
            <DeededSVG color={"white"} />
          </Link>
          <div className="triggers" onClick={handleSidebarClose}>
            <CloseMenuSVG />
          </div>
        </div>
        <SurveyAccordionMenu />
      </div>

      <div className="main">
        <div
          className="sidebar__triggers notify"
          style={{
            display: hideTrigger ? "none" : "none",
            visibility: hideTrigger ? "visible" : "hidden",
          }}
          onClick={handleSidebarClose}
        >
          <BurgerSVG
            onClick={handleSidebarClose}
            color={pathname !== "/survey/:id" ? "#000" : "#fff"}
          />
        </div>

        <Switch>
          <Route
            exact
            path={"/survey/home/:id"}
            component={() => <SurveyHome clientStatus={clientStatus} />}
          />
          <Route
            exact
            path="/survey/question1/:id"
            component={() => <SurveyQuestion1 />}
          />
          <Route
            exact
            path="/survey/question2/:id"
            component={() => <SurveyQuestion2 />}
          />
          <Route
            exact
            path="/survey/question3/:id"
            component={() => <SurveyQuestion3 />}
          />
          <Route
            exact
            path="/survey/rating/:id"
            component={() => <SurveyRating />}
          />
          <Route
            exact
            path="/survey/google-review/:id"
            component={() => <GoogleReview clientData={client} />}
          />
        </Switch>
      </div>
    </div>
  );
};

export default withSnackbar(Survey);
