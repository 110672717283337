import {FC, useEffect, useState} from "react";
import SecurityMFAPasswordModal, {QrData} from "./SecurityMFAPasswordModal";
import {useCustomerPortal} from "../../context/CustomerPortalProvider";
import SecurityMFAEnableModal from "./SecurityMFAEnableModal";
import SecurityMFASuccessModal from "./SecurityMFASuccessModal";

interface UserSecurityMFAProps {
  startMFAConfig?: boolean;
  onFinish: () => void;
}

const SecurityMFA: FC<UserSecurityMFAProps> = ({startMFAConfig, onFinish}) => {
  const [{authUser},] = useCustomerPortal();
  const [showMFAPassword, setShowMFAPassword] = useState(false);
  const [showEnableMFA, setShowEnableMFA] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [qrData, setQrData] = useState<QrData>();

  const handleCancelPassword = () => {
    setShowMFAPassword(false);
    onFinish();
  };
  const handleSuccessPassword = (data: QrData) => {
    setQrData(data);
    setShowMFAPassword(false);
    setShowEnableMFA(true);
  };

  const handleEnableCancel = () => {
    setShowEnableMFA(false);
    onFinish();
  };
  const handleEnableSuccess = () => {
    setShowEnableMFA(false);
    setShowSuccess(true);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccess(false);
    onFinish();
  };

  useEffect(() => {
    if (startMFAConfig && !Boolean(authUser.user?.google_verify)) {
      setShowMFAPassword(true);
    }
  }, [startMFAConfig]);

  return (
    <>
      <SecurityMFAPasswordModal
        show={showMFAPassword}
        onSuccess={handleSuccessPassword}
        onCancel={handleCancelPassword}
      />
      <SecurityMFAEnableModal
        show={showEnableMFA}
        qrData={qrData}
        onCancel={handleEnableCancel}
        onSuccess={handleEnableSuccess}
      />
      <SecurityMFASuccessModal
        show={showSuccess}
        onClose={handleCloseSuccessModal}
      />
    </>
  );
};

export default SecurityMFA;
