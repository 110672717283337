import {FC, MouseEvent} from "react";
import {alpha, styled} from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {BodySmall} from "components/CommonDashboard/Typography";
import Colors from "components/CommonDashboard/Styling/Colors";
import ToggleButton from "components/CommonDashboard/ToggleButton";
import ToggleButtonGroup from "components/CommonDashboard/ToggleButtonGroup";
import {BODY_SMALL} from "components/CommonDashboard/Styling/Styles";
import {useMixpanel} from "utils/MixpanelContext";
import {
  FilesFilterTypes,
  FilesFilterTotals,
  filesFilterTypesList,
} from "../../hooks/useOnboardingFiles";
import {useClientOnboarding} from "../../context/ClientOnboardingProvider";
import useCustomerPortalAuthUser from "../../hooks/useCustomerPortalAuthUser";

interface FilesFilterProps {
  totals: FilesFilterTotals;
  setSelectedFilter: (filter: FilesFilterTypes) => void;
  selectedFilter: FilesFilterTypes;
}

const FilesFilter: FC<FilesFilterProps> = ({
  totals,
  setSelectedFilter,
  selectedFilter,
}) => {
  const mixpanel = useMixpanel();
  const [{deal: {deal}}] = useClientOnboarding();
  const user = useCustomerPortalAuthUser();

  const handleSelect = (e: MouseEvent<HTMLElement>, value: FilesFilterTypes) => {
    if (!value || !deal) return;

    mixpanel.track(`${value === "inReview" ? "in_review" : value}_filter_clicked`, {
      deal_type: deal.deal_type,
      client_type: deal.participants.find(p => p.user_id === user?.id)?.role
    });

    setSelectedFilter(value);
  };

  return (
    <FilterWrapper>
      <BodySmall data-testid="all-documents-total" color={Colors.DARK_GREY} noWrap overflow="visible" mr="2.4rem">{totals.all} documents</BodySmall>
      <ToggleButtonGroup exclusive value={selectedFilter} onChange={handleSelect}>
        {(Object.keys(filesFilterTypesList) as FilesFilterTypes[]).map(
          (filter) => (
            <ToggleButton
              key={filter}
              label={{text: filesFilterTypesList[filter]}}
              value={filter}
              RightRender={<Counter inverted={filter === selectedFilter} data-testid="files-filter-count">{totals[filter]}</Counter>}
              testId={`files-filter-${filter}`}
            />
          ),
        )}
      </ToggleButtonGroup>
    </FilterWrapper>
  );
};

const FilterWrapper = styled(Stack)(({theme}) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflowX: 'auto',
  flexWrap: 'nowrap',
  [theme.breakpoints.down('md')]: {
    flexDirection: "column-reverse",
    alignItems: "start",
    "> p": {
      marginTop: "0.8rem"
    }
  }
}));

const Counter = styled(({inverted, ...props}: {inverted?: boolean}) => (
  <Box {...props} />
))<{
  inverted?: boolean;
}>(({inverted}) => ({
  ...BODY_SMALL,
  fontSize: "1.2rem",
  textAlign: "center",
  borderRadius: "50%",
  background: alpha(Colors.LIGHT_GREY, inverted ? 0.2 : 1),
  width: "2.4rem",
}));

export default FilesFilter;
