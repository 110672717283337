import {FC, useState} from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import {AxiosResponse} from "axios";
import {useSnackbar} from "notistack";
import {authVerifyQR, getProfile} from "api";
import Colors from "components/CommonDashboard/Styling/Colors";
import {BodyRegular, H3, Overline} from "components/CommonDashboard/Typography";
import TextField from "components/CommonDashboard/TextField";
import Button from "components/CommonDashboard/Button";
import {
  CloseIcon,
  KeyIcon,
  MobileDownloadIcon,
  QrCodeIcon,
} from "components/CommonDashboard/Icons";
import {AlertTypes} from "types/alertTypes";
import configuration from "utils/configuration";
import {QrData} from "./SecurityMFAPasswordModal";
import {useCustomerPortal} from "../../context/CustomerPortalProvider";
import {SET_AUTH_USER} from "../../context/actionTypes";

interface SecurityMFAEnableModalProps {
  show: boolean;
  qrData: QrData | undefined;
  onCancel: () => void;
  onSuccess: () => void;
}

const SecurityMFAEnableModal: FC<SecurityMFAEnableModalProps> = ({
  show,
  qrData,
  onCancel,
  onSuccess,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [, dispatch] = useCustomerPortal();

  const [loading, setLoading] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");

  const handleCancel = () => {
    setVerifyCode("");
    onCancel();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await authVerifyQR(verifyCode);
      const profile = await getProfile();
      dispatch({type: SET_AUTH_USER, payload: profile.data});
      onSuccess();
    } catch (e) {
      const error = e as {response: AxiosResponse<{error?: string}>};
      enqueueSnackbar(error.response.data.error ?? "Couldn't send code", {
        variant: AlertTypes.Error,
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    } finally {
      setVerifyCode("");
      setLoading(false);
    }
  };

  return (
    <StyledDialog open={show}>
      <ModalBody>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <H3>
            Enable Multi-Factor
            <br />
            Authentication
          </H3>
          <Button
            typeOf="secondary"
            onClick={handleCancel}
            startIcon={<CloseIcon />}
          />
        </Stack>

        <Stack alignItems="start" direction="row" gap="2.4rem" mb="0.8rem">
          <Circle>
            <MobileDownloadIcon />
          </Circle>
          <Stack gap="0.8rem">
            <Overline>Step 1</Overline>
            <BodyRegular color={Colors.DARK_GREY}>
              Enter your Deeded password to enable Multi-Factor Authentication
            </BodyRegular>
          </Stack>
        </Stack>

        <Stack direction="row" gap="2.4rem" mb="0.8rem">
          <Circle>
            <QrCodeIcon />
          </Circle>
          <Stack>
            <Overline>Step 2</Overline>
            <BodyRegular color={Colors.DARK_GREY}>
              Open your Google Authenticator on your phone and scan the
              following QR Code
            </BodyRegular>
            <Box width="18rem" height="18rem" ml="-1.3rem">
              <img width="100%" src={qrData?.qr} alt="QR Code" />
            </Box>
            <BodyRegular mb="1.6rem" color={Colors.DARK_GREY}>
              If you prefer to manually enter a code, you can enter the code
              provided below
            </BodyRegular>
            <BodyRegular color={Colors.BLACK}>
              <strong>{qrData?.code}</strong>
            </BodyRegular>
          </Stack>
        </Stack>

        <Stack direction="row" gap="2.4rem" mb="0.8rem">
          <Circle>
            <KeyIcon />
          </Circle>
          <Stack gap="0.8rem">
            <Overline>Step 3</Overline>
            <BodyRegular color={Colors.DARK_GREY}>
              Enter the MFA code displayed in the app to confirm and enable MFA
              for your account
            </BodyRegular>
            <TextField
              value={verifyCode}
              onChange={({target: {value}}) => {
                if (/^(\s*|\d+)$/.test(value) && value.length <= 6) {
                  setVerifyCode(value);
                }
              }}
              disabled={loading}
              outlined
              label="6-digit MFA number"
            />
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="end" gap="1.6rem">
          <Button
            onClick={handleCancel}
            typeOf="secondary"
            label={{text: "Cancel"}}
            disabled={loading}
          />
          <Button
            onClick={handleSubmit}
            typeOf="CTA"
            label={{text: "Enable MFA"}}
            disabled={loading}
          />
        </Stack>
      </ModalBody>
    </StyledDialog>
  );
};

const Circle = styled(Box)({
  display: "flex",
  width: "8rem",
  minWidth: "8rem",
  height: "8rem",
  borderRadius: "50%",
  marginTop: "0.4rem",
  background: Colors.CORAL_20,
  alignItems: "center",
  justifyContent: "center",
});

const ModalBody = styled(Stack)({
  maxWidth: "60rem",
  background: Colors.WHITE,
  gap: "2.4rem",
});

const StyledDialog = styled(Dialog)({
  ".MuiPaper-root": {
    borderRadius: "1.6rem",
    padding: "2.4rem",
  },
});

export default SecurityMFAEnableModal;
