import { useSnackbar } from "notistack";
import { useEffect, useState, ChangeEvent } from "react";
import { returnConvertedFromHeicFile } from "utils/returnConvertedFromHeicFile";
import configuration from "../configuration";


export type DealFileType = {
    name: string,
    size: number,
    type: string,
    data: string | ArrayBuffer | null
};

export type AgreementType = {
    file: DealFileType,
    document_type: String
}

type HandleFileFunction = (file: AgreementType, context: any) => void;

type UploadDocumentProps = {
    documentType: string,
    onFileUploaded: HandleFileFunction,
    customFileTypes?: string[],
    maxSize?: number,
};

const validFilesList = [
    "application/pdf",
    "text/plain",
    "image/jpeg",
    "image/gif",
    "image/png",
    "application/msword",
    "application/rtf",
    "application/x-zip-compressed",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "image/heic",
];

const isValidFile = (doc: File, types: string[]) =>
    !!types.find((fileType) => fileType === doc.type);

const useUploadDocument = ({ documentType, onFileUploaded, customFileTypes, maxSize }: UploadDocumentProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [document, setDocument] = useState<DealFileType | null>(null);
    const [fileContext, setFileContext] = useState<Object | null>(null);

    const onUpload = async (event: ChangeEvent<HTMLInputElement>, context: Object | null = null) => {
        const input = event.target as HTMLInputElement;
        if (!input.files?.length) return;
        let file = await returnConvertedFromHeicFile(input.files[0]);
        await process(file, context);
    };

    const onFileDrop = async (file: File) => {
        await process(file);
    };

    const process = async (file: File, context: Object | null = null) => {
        let reader = new FileReader();
        if (!isValidFile(file, customFileTypes || validFilesList)) {
            enqueueSnackbar("Not valid file format", {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            });
            return;
        }
        if (maxSize && file.size > maxSize) {
            return void enqueueSnackbar("File size exceeds the maximum allowed limit", {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        }
        reader.readAsDataURL(file);

        reader.onload = () => {
            if (context) setFileContext(context);
            setDocument({
                name: file.name,
                size: file.size,
                type: file.type,
                data: reader.result,
            });
        };
        reader.onerror = () => {
            enqueueSnackbar("Error occurred reading file", {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        };
    };

    useEffect(() => {
        if (document) {
            onFileUploaded({
                document_type: documentType,
                file: document
            }, fileContext);
            setDocument(null);
        }
    }, [document, fileContext]);

    return {
        onUpload,
        onFileDrop,
    };
}

export default useUploadDocument;