import { ActionType } from "../actionTypes";
import { postEnteredField } from "../../api";

const lenderState = {
  ownersNumber: null,
  lenderFullName: null,
  corporateRefinance: null,
  birthDay: null,
  currentAddress: null,
  phoneNumber: null,
  //email: "",
  employmentStatus: null,
  employerName: null,
  employerAddress: null,
  supousalStatus: null,
  citizenshipStatus: null,
  cards: null,
  signingAppointment: null,
  ownershipProperty: null,
  condominium: null,
  newMortgage: null,
  scheduledClosingDate: null,
  debtsList: null,
  currentMortgageStatement: null,
  homeInsurancePolicy: null,
  propertyTaxBillStatement: null,
  voidCheque: null,
  additionalInformation: null,
  has_mortgage_referring: false,
  sourceOfFunds: null,
  separationAgreement: null
};

export const LenderReducer = (state = lenderState, action) => {
  switch (action.type) {
    case ActionType.LENDER_SET_SOUCE_OF_FOUNDS_INFO: {
      return {
        ...state,
        sourceOfFunds: action.payload,
      };
    }
    case "SET_HAS_MORTGAGE_REFERRING": {
      return {
        ...state,
        has_mortgage_referring: action.payload,
      };
    }
    case ActionType.SET_TMP_DATA_LENDER_INTAKE_REQUEST: {
      const newState = action.payload;
      if (newState === false) {
        return {
          ...state,
        };
      }
      return {
        ...newState,
      };
    }
    case "SET_LENDER_EMAIL": {
      const actionPayload = action.payload;

      return {
        ...state,
        email: action.payload,
      };
    }
    case ActionType.LENDER_RESET: {
      return {
        ...state,
        ownersNumber: null,
        lenderFullName: null,
        corporateRefinance: null,
        birthDay: null,
        currentAddress: null,
        phoneNumber: null,
        employmentStatus: null,
        employerName: null,
        employerAddress: null,
        supousalStatus: null,
        citizenshipStatus: null,
        cards: null,
        signingAppointment: null,
        ownershipProperty: null,
        condominium: null,
        newMortgage: null,
        scheduledClosingDate: null,
        debtsList: null,
        currentMortgageStatement: null,
        homeInsurancePolicy: null,
        propertyTaxBillStatement: null,
        voidCheque: null,
        additionalInformation: null,
        sourceOfFunds: null,
        separationAgreement: null
      };
    }
    case ActionType.LENDER_SET_OWNERS_NUMBER:
      return {
        ...state,
        ownersNumber: action.payload,
        lenderFullName: null,
        corporateRefinance: null,
        birthDay: null,
        currentAddress: null,
        phoneNumber: null,
        employmentStatus: null,
        employerName: null,
        employerAddress: null,
        supousalStatus: null,
        citizenshipStatus: null,
        cards: null,
        signingAppointment: null,
        ownershipProperty: null,
        condominium: null,
        newMortgage: null,
        scheduledClosingDate: null,
        debtsList: null,
        currentMortgageStatement: null,
        homeInsurancePolicy: null,
        propertyTaxBillStatement: null,
        voidCheque: null,
        additionalInformation: null,
        sourceOfFunds: null,
        separationAgreement: null
      };
    case ActionType.LENDER_SET_LENDER_FULLNAME:
      return {
        ...state,
        lenderFullName: action.payload,
      };
    case ActionType.LENDER_SET_CORPORATE_REFINANCE:
      return {
        ...state,
        corporateRefinance: action.payload,
      };
    case ActionType.LENDER_SET_BIRTH_DAY:
      return {
        ...state,
        birthDay: action.payload,
      };
    case ActionType.LENDER_SET_CURRENT_ADDRESS:
      return {
        ...state,
        currentAddress: action.payload,
      };
    case ActionType.LENDER_SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case ActionType.LENDER_SET_EMPLOYMENT_STATUS:
      return {
        ...state,
        employmentStatus: action.payload,
      };
    case ActionType.LENDER_SET_EMPLOYER_NAME:
      return {
        ...state,
        employerName: action.payload,
      };
    case ActionType.LENDER_SET_EMPLOYER_ADDRESS:
      return {
        ...state,
        employerAddress: action.payload,
      };
    case ActionType.LENDER_SET_SUPOUSAL_STATUS:
      return {
        ...state,
        supousalStatus: action.payload,
      };
    case ActionType.LENDER_SET_CITIZENSHIP_STATUS:
      return {
        ...state,
        citizenshipStatus: action.payload,
      };

    case ActionType.LENDER_SET_IDENTIFICATION:
      return {
        ...state,
        cards: action.payload,
      };
    case ActionType.LENDER_SET_SIGNING_APPOINTMENT:
      return {
        ...state,
        signingAppointment: action.payload,
      };
    case ActionType.LENDER_SET_OWNERSHIP_PROPERTY:
      return {
        ...state,
        ownershipProperty: action.payload,
      };
    case ActionType.LENDER_SET_CONDOMINIUM:
      return {
        ...state,
        condominium: action.payload,
      };
    case ActionType.LENDER_SET_NEW_MORTGAGE:
      return {
        ...state,
        newMortgage: action.payload,
      };
    case ActionType.LENDER_SET_SCHEDULED_CLOSING_DATE:
      return {
        ...state,
        scheduledClosingDate: action.payload,
      };
    case ActionType.LENDER_SET_DEBT_LIST:
      return {
        ...state,
        debtsList: action.payload,
      };
    case ActionType.LENDER_SET_CURRENT_MORTGAGE_STATEMENT:
      return {
        ...state,
        currentMortgageStatement: action.payload,
      };
    case ActionType.LENDER_SET_HOME_INSURANCE_POLICY:
      return {
        ...state,
        homeInsurancePolicy: action.payload,
      };
    case ActionType.LENDER_SET_PROPERTY_TAX_BILL_STATEMENT:
      return {
        ...state,
        propertyTaxBillStatement: action.payload,
      };
    case ActionType.LENDER_SET_VOID_CHEQUE:
      return {
        ...state,
        voidCheque: action.payload,
      };
    case ActionType.LENDER_SET_ADDITIONAL_INFO:
      return {
        ...state,
        additionalInformation: action.payload,
      };
    case ActionType.LENDER_SET_SEPARATION_AGREEMENT:
      return {
        ...state,
        separationAgreement: action.payload,
      };
    default:
      return state;
  }
};

export const setHasMortgageReferringLenderAC = (payload) => ({
  type: "SET_HAS_MORTGAGE_REFERRING",
  payload,
});
export const postEnteredLenderFieldsTC =
  (client_id) => async (dispatch, getState) => {
    await postEnteredField(client_id, {
      ...getState().LenderReducer,
      cards: null,
      debtsList: null,
      currentMortgageStatement: null,
      homeInsurancePolicy: null,
      propertyTaxBillStatement: null,
      voidCheque: null,
      separationAgreement: null,
    });
  };
