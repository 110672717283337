import {useCustomerPortal} from "../context/CustomerPortalProvider";
import {useMemo} from "react";

const useCustomerPortalAuthUser = () => {
  const [state] = useCustomerPortal();

  return useMemo(() => state?.authUser?.user, [state?.authUser?.user])
};

export default useCustomerPortalAuthUser;
