import {ActionType} from "../actionTypes";
import {postEnteredField} from "../../api";

const purchaseState = {
  tmpDataIntakeRequest: null,
  ownersNumber: null,
  purchaserFullName: null,
  corporatePurchase: null,
  birthDay: null,
  currentAddress: null,
  phoneNumber: null,
  // email: "",
  employmentStatus: null,
  employerName: null,
  employerAddress: null,
  supousalStatus: null,
  citizenshipStatus: null,
  ownedProperty: null,
  residedAddress: null,
  physicallyPresent: null,
  cards: null,
  signingAppointment: null,
  titleProperty: null,
  typeProperty: null,
  rentedOut: null,
  tankWellFuel: null,
  primaryUse: null,
  takingMortgage: null,
  takingMortgageWithHasReferring: null,
  lenderInformation: null,
  homeInsurancePolicy: null,
  additionalInformation: null,
  has_mortgage_referring: false,
  sourceOfFunds: null,
  separationAgreement: null
};

export const PurchaseReducer = (state = purchaseState, action) => {
  switch (action.type) {
    case "SET_TAKING_MORTGAGE_WITH_HAS_REFERRING": {
      return {
        ...state,
        takingMortgageWithHasReferring: action.payload,
      };
    }
    case ActionType.PURCHASE_SET_MORTGAGE_REFERRING: {
      return {
        ...state,
        has_mortgage_referring: action.payload,
      };
    }
    case ActionType.SET_TMP_DATA_INTAKE_REQUEST: {
      const newState = action.payload;
      if (newState === false) {
        return {
          ...state,
        };
      }
      return {
        ...newState,
      };
    }
    case ActionType.PURCHASE_RESET: {
      return {
        ...state,
        ownersNumber: null,
        purchaserFullName: null,
        corporatePurchase: null,
        birthDay: null,
        currentAddress: null,
        phoneNumber: null,
        employmentStatus: null,
        employerName: null,
        employerAddress: null,
        supousalStatus: null,
        citizenshipStatus: null,
        ownedProperty: null,
        residedAddress: null,
        physicallyPresent: null,
        cards: null,
        signingAppointment: null,
        titleProperty: null,
        typeProperty: null,
        rentedOut: null,
        tankWellFuel: null,
        primaryUse: null,
        takingMortgage: null,
        lenderInformation: null,
        homeInsurancePolicy: null,
        additionalInformation: null,
        sourceOfFunds: null,
        separationAgreement: null
      };
    }
    case ActionType.PURCHASE_SET_OWNERS_NUMBER:
      return {
        ...state,
        ownersNumber: action.payload,
        purchaserFullName: null,
        corporatePurchase: null,
        birthDay: null,
        currentAddress: null,
        phoneNumber: null,
        employmentStatus: null,
        employerName: null,
        employerAddress: null,
        supousalStatus: null,
        citizenshipStatus: null,
        ownedProperty: null,
        residedAddress: null,
        physicallyPresent: null,
        cards: null,
        signingAppointment: null,
        titleProperty: null,
        typeProperty: null,
        rentedOut: null,
        tankWellFuel: null,
        primaryUse: null,
        takingMortgage: null,
        lenderInformation: null,
        homeInsurancePolicy: null,
        additionalInformation: null,
        sourceOfFunds: null,
        separationAgreement: null
      };
    case ActionType.PURCHASE_SET_PURCHASER_FULLNAME:
      return {
        ...state,
        purchaserFullName: action.payload,
      };
    case ActionType.PURCHASE_SET_CORPORATE_PURCHASE:
      return {
        ...state,
        corporatePurchase: action.payload,
      };
    case ActionType.PURCHASE_SET_BIRTH_DAY:
      return {
        ...state,
        birthDay: action.payload,
      };
    case ActionType.PURCHASE_SET_CURRENT_ADDRESS:
      return {
        ...state,
        currentAddress: action.payload,
      };
    case ActionType.PURCHASE_SET_EMAIL: {
      const actionPayload = action.payload;

      return {
        ...state,
        email: action.payload,
      };
    }
    case ActionType.PURCHASE_SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case ActionType.PURCHASE_SET_EMPLOYMENT_STATUS:
      return {
        ...state,
        employmentStatus: action.payload,
      };
    case ActionType.PURCHASE_SET_EMPLOYER_NAME:
      return {
        ...state,
        employerName: action.payload,
      };
    case ActionType.PURCHASE_SET_EMPLOYER_ADDRESS:
      return {
        ...state,
        employerAddress: action.payload,
      };
    case ActionType.PURCHASE_SET_SUPOUSAL_STATUS:
      return {
        ...state,
        supousalStatus: action.payload,
      };
    case ActionType.PURCHASE_SET_CITIZENSHIP_STATUS:
      return {
        ...state,
        citizenshipStatus: action.payload,
      };
    case ActionType.PURCHASE_SET_OWNED_PROPERTY:
      return {
        ...state,
        ownedProperty: action.payload,
      };
    case ActionType.PURCHASE_SET_RESIDED_ADDRESS:
      return {
        ...state,
        residedAddress: action.payload,
      };
    case ActionType.PURCHASE_SET_PHYSICALLY_PRESENT:
      return {
        ...state,
        physicallyPresent: action.payload,
      };
    case ActionType.PURCHASE_SET_IDENTIFICATION: {
      return {
        ...state,
        cards: action.payload,
      };
    }

    case ActionType.PURCHASE_SET_SIGNING_APPOINTMENT:
      return {
        ...state,
        signingAppointment: action.payload,
      };
    case ActionType.PURCHASE_SET_TITLE_PROPERTY:
      return {
        ...state,
        titleProperty: action.payload,
      };
    case ActionType.PURCHASE_SET_TYPE_PROPERTY:
      return {
        ...state,
        typeProperty: action.payload,
      };
    case ActionType.PURCHASE_SET_RENTED_OUT:
      return {
        ...state,
        rentedOut: action.payload,
      };
    case ActionType.PURCHASE_SET_TANK_WELL:
      return {
        ...state,
        tankWellFuel: action.payload,
      };
    case ActionType.PURCHASE_SET_PRIMARY_USE:
      return {
        ...state,
        primaryUse: action.payload,
      };
    case ActionType.PURCHASE_SET_TAKING_MORTGAGE:
      return {
        ...state,
        takingMortgage: action.payload,
      };
    case ActionType.PURCHASE_SET_LENDER_INFORMATION:
      return {
        ...state,
        lenderInformation: action.payload,
      };
    case ActionType.PURCHASE_SET_HOME_INSURANCE_POLICY:
      return {
        ...state,
        homeInsurancePolicy: action.payload,
      };
    case ActionType.PURCHASE_SET_ADDITIONAL_INFO:
      return {
        ...state,
        additionalInformation: action.payload,
      };
    case ActionType.PURCHASE_SET_SOURCE_OF_FOUNDS_INFO:
      return {
        ...state,
        sourceOfFunds: action.payload,
      };
    case ActionType.PURCHASE_SET_SEPARATION_AGREEMENT:
      return {
        ...state,
        separationAgreement: action.payload,
      };
    default:
      return state;
  }
};

export const setTakingMortgageWithHasReferring = (payload) => ({
  type: "SET_TAKING_MORTGAGE_WITH_HAS_REFERRING",
  payload,
});

export const setHasMortgageReferringAC = (payload) => ({
  type: ActionType.PURCHASE_SET_MORTGAGE_REFERRING,
  payload,
});

export const postEnteredFieldsTC =
  (client_id) => async (dispatch, getState) => {
    await postEnteredField(client_id, {
      ...getState().PurchaseReducer,
      cards: null,
      separationAgreement: null,
    });
  };
