import React from "react";
import {Link} from "react-router-dom";
import "./Buttons.scss";
import * as H from "history";

type LocationCallback = (
  location: H.Location<unknown>,
) => H.LocationDescriptor<unknown>;

const Back = ({
  back,
  notAllowed = false,
}: {
  back: string;
  notAllowed?: boolean;
}) => {
  if (
    (notAllowed && back.includes("/survey/employer-address/")) ||
    (notAllowed && back.includes("/survey/name-of-employer/"))
  ) {
    back = `/survey/employment-status/client_id=${back.split("client_id=")[1]}`;
  }
  if (notAllowed && back.includes("/survey/lender-information/")) {
    back = `/survey/taking-of-mortgage/client_id=${
      back.split("client_id=")[1]
    }`;
  }
  return (
    <Link to={back} onClick={() => window.scrollTo(0, 0)} className="btn back">
      Back
    </Link>
  );
};

const Next = ({
  next,
  nextStep,
  tabIndex,
}: {
  next: string | LocationCallback;
  tabIndex: boolean;
  nextStep: () => void;
  rating: unknown;
}) => {
  return (
    <Link to={tabIndex ? next : ""} onClick={nextStep} className="btn next">
      Next
    </Link>
  );
};
const Submit = ({
  next,
  submit,
  tabIndex,
}: {
  next: string | LocationCallback;
  tabIndex: boolean;
  submit?: () => void;
  rating: unknown;
}) => {
  return (
    <Link to={tabIndex ? next ?? "" : ""} onClick={submit} className="btn next">
      Submit
    </Link>
  );
};

const Buttons = ({
  submit,
  rating,
  skip,
  back,
  next,
  nextStep,
  handleOnSkip,
  notAllowed,
  backBtn = true,
  tabIndex = true,
}: {
  submit?: () => void;
  rating?: number;
  skip?: string;
  back: string;
  next: string | LocationCallback;
  nextStep: () => void;
  handleOnSkip?: React.MouseEventHandler<HTMLAnchorElement>;
  notAllowed?: boolean;
  backBtn?: boolean;
  tabIndex?: boolean;
}) => {
  return (
    <div className="buttons__wrapper">
      {backBtn && <Back back={back} notAllowed={notAllowed} />}
      {skip && (
        <Link to={tabIndex ? skip : ""} className="skip" onClick={handleOnSkip}>
          skip
        </Link>
      )}
      {rating && rating <= 5 ? (
        <Submit rating={rating} submit={submit} tabIndex={tabIndex} next={next} />
      ) : (
        <Next
          rating={rating}
          next={next}
          nextStep={nextStep}
          tabIndex={tabIndex}
        />
      )}
    </div>
  );
};

export default Buttons;
