// @ts-nocheck
// TODO: Do something with this nightmare!
import {postEnteredField} from "../../api";

type Address = {
  fullName: string;
  address: string;
  city: string;
  state: string;
  code: string;
  id: number;
};

export interface SaleReducerState {
  ownersNumber: number | null;
  sellerFullName: {
    data: [{prefix: string; firstName: string; lastName: string; id: number}];
  } | null;
  corporateSale: number | null;
  isCurrenltyReside: {
    data: [{isReside: number; fullName: string}];
  } | null;
  willBeResidentCadana: {
    data: [{isResidentCanada: number; fullName: string}];
  } | null;
  birthDay: {
    data: [{date: string; fullName: string}];
  } | null;
  forwardingAddress: {
    data: Address[];
  } | null;
  phoneNumber: {
    data: [{phone: string; fullName: string; id: number}];
  } | null;
  employmentStatus: {
    data: [
      {employment: string; occupation: string; fullName: string; id: number},
    ];
  } | null;
  employerName: {
    data: [{employer: string; fullName: string; id: number}];
  } | null;
  employerAddress: {data: Address[]} | null;
  supousalStatus: {
    data: [{status: string; supouseName: string; fullName: string; id: number}];
  } | null;
  cards: {
    checked: boolean;
    usersId: {
      data: [
        {
          files: {
            primaryFront: null;
            primaryBack: null;
            secondaryFront: null;
            secondaryBack: null;
          };
          fullName: string;
        },
      ];
    };
  } | null;
  signingAppointment: {
    preferred_time: null;
    internet: number;
    client_comment: string;
  } | null;
  isRentalProperty: number | null;
  sellingCondominium: {company: string; fees: string} | null;
  isMortgageLinesCredit: number | null;
  currentMortgageLender: {name: string; account: string} | null;
  branchAddressLender: {
    address: string;
    unit: string;
    city: string;
    state: string;
    code: string;
  } | null;
  currentMortgageStatement: unknown | null;
  additionalMortgageStatement: {
    data: [{lender: string; account: string; amount: string; file: string}];
  } | null;
  rentalContracts: {
    is_rental_contract: number;
    rental_agreement: null;
  } | null;
  propertyTaxBillStatement: unknown | null;
  voidCheque: unknown | null;
  additionalInformation: string | null;
  separationAgreement: unknown | null;
}

type SaleActionType =
  | "SALE_RESET"
  | "SALE_SET_OWNERS_NUMBER"
  | "SALE_SET_SELLER_FULLNAME"
  | "SALE_SET_CORPORATE_SALE"
  | "SALE_SET_CURRENTLY_RESIDE"
  | "SALE_SET_RESIDENT_CANADA"
  | "SALE_SET_BIRTH_DAY"
  | "SALE_SET_FORWARDING_ADDRESS"
  | "SALE_SET_PHONE_NUMBER"
  | "SALE_SET_EMPLOYMENT_STATUS"
  | "SALE_SET_EMPLOYER_NAME"
  | "SALE_SET_EMPLOYER_ADDRESS"
  | "SALE_SET_SUPOUSAL_STATUS"
  | "SALE_SET_IDENTIFICATION"
  | "SALE_SET_RENTAL_PROPERY"
  | "SALE_SET_MORTGAGE_LINES_CREDIT"
  | "SALE_SET_SELLING_CONDOMINIUM"
  | "SALE_SET_CURRENT_MORTGAGE_LENDER"
  | "SALE_SET_BRANCH_ADDRESS_LENDER"
  | "SALE_SET_SIGNING_APPOINTMENT"
  | "SALE_SET_TYPE_PROPERTY"
  | "SALE_SET_RENTAL_CONTRACTS"
  | "SALE_SET_CURRENT_MORTGAGE_STATEMENT"
  | "SALE_SET_PROPERTY_TAX_BILL_STATEMENT"
  | "SALE_SET_VOID_CHEQUE"
  | "SALE_SET_ADDITIONAL_MORTGAGES_STETEMENT"
  | "SALE_SET_ADDITIONAL_INFO"
  | "SALE_SET_SEPARATION_AGREEMENT"
  | "SALE_SET_TANK_WELL_FUEL"
  | "SET_SALE_EMAIL"
  | "SET_TMP_DATA_SALE_INTAKE_REQUEST";

export type SaleAction = {
  type: SaleActionType;
  payload?: unknown;
};

const saleState: SaleReducerState = {
  ownersNumber: null,
  sellerFullName: null,
  corporateSale: null,
  isCurrenltyReside: null,
  willBeResidentCadana: null,
  birthDay: null,
  //email:"",
  forwardingAddress: null,
  phoneNumber: null,
  employmentStatus: null,
  employerName: null,
  employerAddress: null,
  supousalStatus: null,
  cards: null,
  signingAppointment: null,
  typeProperty: null,
  isRentalProperty: null,
  sellingCondominium: null,
  isMortgageLinesCredit: null,
  currentMortgageLender: null,
  branchAddressLender: null,
  currentMortgageStatement: null,
  additionalMortgageStatement: null,
  rentalContracts: null,
  propertyTaxBillStatement: null,
  voidCheque: null,
  additionalInformation: null,
  separationAgreement: null,
  tankWellFuel: null,
};

export const SaleReducer = (
  state = saleState,
  action: SaleAction,
): SaleReducerState => {
  switch (action.type) {
    case "SET_TMP_DATA_SALE_INTAKE_REQUEST": {
      const newState = action.payload;
      if (newState === false) {
        return {
          ...state,
        };
      }
      return {
        ...newState,
      };
    }
    case "SET_SALE_EMAIL": {
      const actionPayload = action.payload;

      return {
        ...state,
        email: action.payload,
      };
    }
    case "SALE_SET_TANK_WELL_FUEL": {
      return {
        ...state,
        tankWellFuel: action.payload,
      };
    }
    case "SALE_RESET":
      return {
        ownersNumber: null,
        sellerFullName: null,
        corporateSale: null,
        isCurrenltyReside: null,
        willBeResidentCadana: null,
        birthDay: null,
        forwardingAddress: null,
        phoneNumber: null,
        employmentStatus: null,
        employerName: null,
        employerAddress: null,
        supousalStatus: null,
        cards: null,
        signingAppointment: null,
        typeProperty: null,
        isRentalProperty: null,
        sellingCondominium: null,
        isMortgageLinesCredit: null,
        currentMortgageLender: null,
        branchAddressLender: null,
        currentMortgageStatement: null,
        additionalMortgageStatement: null,
        rentalContracts: null,
        propertyTaxBillStatement: null,
        voidCheque: null,
        tankWellFuel: null,
        additionalInformation: null,
        separationAgreement: null,
      };
    case "SALE_SET_OWNERS_NUMBER":
      return {
        ownersNumber: action.payload as number,
        sellerFullName: null,
        corporateSale: null,
        isCurrenltyReside: null,
        willBeResidentCadana: null,
        birthDay: null,
        forwardingAddress: null,
        phoneNumber: null,
        employmentStatus: null,
        employerName: null,
        employerAddress: null,
        supousalStatus: null,
        cards: null,
        signingAppointment: null,
        typeProperty: null,
        isRentalProperty: null,
        sellingCondominium: null,
        isMortgageLinesCredit: null,
        currentMortgageLender: null,
        branchAddressLender: null,
        currentMortgageStatement: null,
        additionalMortgageStatement: null,
        rentalContracts: null,
        propertyTaxBillStatement: null,
        voidCheque: null,
        tankWellFuel: null,
        additionalInformation: null,
        separationAgreement: null,
      };
    case "SALE_SET_SELLER_FULLNAME":
      return {
        ...state,
        sellerFullName: action.payload,
      };
    case "SALE_SET_CORPORATE_SALE":
      return {
        ...state,
        corporateSale: action.payload,
      };
    case "SALE_SET_CURRENTLY_RESIDE":
      return {
        ...state,
        isCurrenltyReside: action.payload,
      };
    case "SALE_SET_RESIDENT_CANADA":
      return {
        ...state,
        willBeResidentCadana: action.payload,
      };
    case "SALE_SET_BIRTH_DAY":
      return {
        ...state,
        birthDay: action.payload,
      };
    case "SALE_SET_FORWARDING_ADDRESS":
      return {
        ...state,
        forwardingAddress: action.payload,
      };
    case "SALE_SET_PHONE_NUMBER":
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case "SALE_SET_EMPLOYMENT_STATUS":
      return {
        ...state,
        employmentStatus: action.payload,
      };
    case "SALE_SET_EMPLOYER_NAME":
      return {
        ...state,
        employerName: action.payload,
      };
    case "SALE_SET_EMPLOYER_ADDRESS":
      return {
        ...state,
        employerAddress: action.payload,
      };
    case "SALE_SET_SUPOUSAL_STATUS":
      return {
        ...state,
        supousalStatus: action.payload,
      };
    case "SALE_SET_IDENTIFICATION":
      return {
        ...state,
        cards: action.payload,
      };
    case "SALE_SET_RENTAL_PROPERY":
      return {
        ...state,
        isRentalProperty: action.payload,
      };
    case "SALE_SET_MORTGAGE_LINES_CREDIT":
      return {
        ...state,
        isMortgageLinesCredit: action.payload,
      };
    case "SALE_SET_SELLING_CONDOMINIUM":
      return {
        ...state,
        sellingCondominium: action.payload,
      };
    case "SALE_SET_CURRENT_MORTGAGE_LENDER":
      return {
        ...state,
        currentMortgageLender: action.payload,
      };
    case "SALE_SET_BRANCH_ADDRESS_LENDER":
      return {
        ...state,
        branchAddressLender: action.payload,
      };
    case "SALE_SET_SIGNING_APPOINTMENT":
      return {
        ...state,
        signingAppointment: action.payload,
      };
    case "SALE_SET_TYPE_PROPERTY":
      return {
        ...state,
        typeProperty: action.payload,
      };
    case "SALE_SET_RENTAL_CONTRACTS":
      return {
        ...state,
        rentalContracts: action.payload,
      };
    case "SALE_SET_CURRENT_MORTGAGE_STATEMENT":
      return {
        ...state,
        currentMortgageStatement: action.payload,
      };
    case "SALE_SET_PROPERTY_TAX_BILL_STATEMENT":
      return {
        ...state,
        propertyTaxBillStatement: action.payload,
      };
    case "SALE_SET_VOID_CHEQUE":
      return {
        ...state,
        voidCheque: action.payload,
      };
    case "SALE_SET_ADDITIONAL_MORTGAGES_STETEMENT":
      return {
        ...state,
        additionalMortgageStatement: action.payload,
      };
    case "SALE_SET_ADDITIONAL_INFO":
      return {
        ...state,
        additionalInformation: action.payload,
      };
    case "SALE_SET_SEPARATION_AGREEMENT":
      return {
        ...state,
        separationAgreement: action.payload,
      };
    default:
      return state;
  }
};

export const postEnteredFieldsSaleTC =
  (client_id: string) => async (dispatch, getState) => {

    await postEnteredField(client_id, {
      ...getState().SaleReducer,

      cards: null,
      additionalMortgageStatement: null,
      rentalContracts: null,
      separationAgreement: null,
    });
  };
