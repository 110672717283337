export interface SurveyReducerState {
  answerOne: unknown | null;
  answerTwo: unknown | null;
  answerThree: unknown | null;
  rating: unknown | null;
  surveyProvince: string | null;
}

type SurveyActionType =
  | "SURVEY_RESET"
  | "SURVEY_SET_LAST_NAME"
  | "SURVEY_SET_ANSWER_ONE"
  | "SURVEY_SET_ANSWER_TWO"
  | "SURVEY_SET_ANSWER_THREE"
  | "SURVEY_SET_RATING"
  | "SET_SURVEY_PROVINCE";

export type SurveyAction = {
  type: SurveyActionType;
  payload?: unknown;
};

const surveyState: SurveyReducerState = {
  surveyProvince: null,
  answerOne: null,
  answerTwo: null,
  answerThree: null,
  rating: null,
};

export const SurveyReducer = (
  state = surveyState,
  action: SurveyAction,
): SurveyReducerState => {
  switch (action.type) {
    case "SET_SURVEY_PROVINCE": {
      return {
        ...state,
        surveyProvince: action.payload as string | null,
      };
    }
    case "SURVEY_RESET":
      return {
        answerOne: null,
        answerTwo: null,
        answerThree: null,
        rating: null,
        surveyProvince: state.surveyProvince,
      };

    case "SURVEY_SET_ANSWER_ONE":
      return {
        ...state,
        answerOne: action.payload,
      };
    case "SURVEY_SET_ANSWER_TWO":
      return {
        ...state,
        answerTwo: action.payload,
      };
    case "SURVEY_SET_ANSWER_THREE":
      return {
        ...state,
        answerThree: action.payload,
      };
    case "SURVEY_SET_RATING":
      return {
        ...state,
        rating: action.payload,
      };
    default:
      return state;
  }
};
export const setSurveyProvinceAC = (payload: string | null) => ({
  type: "SET_SURVEY_PROVINCE",
  payload,
});
