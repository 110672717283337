import {ChangeEvent, FC, useRef, useState} from "react";
import Stack from "@mui/material/Stack";
import {styled, useTheme} from "@mui/material/styles";
import {BodyRegular, BodySmall, H3} from "components/CommonDashboard/Typography";
import Accordion from "components/CommonDashboard/Accordion";
import Colors from "components/CommonDashboard/Styling/Colors";
import Button from "components/CommonDashboard/Button";
import {UploadIcon, TrashIcon, AlertIcon, ProgressIcon, ArrowRightIcon, CloseCircleIcon} from "components/CommonDashboard/Icons";
import Pill from "components/CommonDashboard/Pill";
import {DocumentUpdateType, StatusType} from "types/deal";
import {useMixpanel} from "utils/MixpanelContext";
import FileStatusPill from "../../components/FileStatusPill";
import {useClientOnboarding} from "../../context/ClientOnboardingProvider";
import useCustomerPortalAuthUser from "../../hooks/useCustomerPortalAuthUser";
import {FilesFilterTypes} from "../../hooks/useOnboardingFiles";

interface FileRowProps {
  documentName: string;
  documentType: string;
  documentId: number;
  fileStatus?: StatusType | "Temporary";
  description: string;
  onFileUploaded: (
    event: ChangeEvent<HTMLInputElement>,
    context?: Object | null,
  ) => Promise<void>;
  onFileDeleted: () => void;
  id: string;
  documentLatestUpdate: DocumentUpdateType | null;
  originalFileName: string | null;
  originalFileExtension: string | null;
  originalFileSize: string;
  defaultExpanded?: boolean;
  setSelectedFilter: (filter: FilesFilterTypes) => void;
}

export const FileRow: FC<FileRowProps> = ({
  documentName,
  documentType,
  documentId,
  fileStatus = "Pending",
  description,
  onFileUploaded,
  onFileDeleted,
  id,
  documentLatestUpdate,
  originalFileName,
  originalFileExtension,
  originalFileSize,
  defaultExpanded = false,
  setSelectedFilter,
}) => {
  const mixpanel = useMixpanel();
  const [{deal: {deal}}] = useClientOnboarding();
  const user = useCustomerPortalAuthUser();
  const theme = useTheme();
  const fileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const showRejection = fileStatus === "Rejected" && Boolean(documentLatestUpdate?.rejection_reason);

  const handleUploadClick = () => {
    mixpanel.track("upload_button_clicked", {
      deal_type: deal?.deal_type,
      client_type: deal?.participants.find(p => p.user_id === user?.id)?.role,
      document_type: documentType
    });

    fileRef.current?.click();
  }

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      title={
        <AccordionHeader data-testid="document-accordion">
          <H3 data-testid="document-name">{documentName}</H3>
          <FileStatusPill status={fileStatus === "Temporary" ? "Missing" : fileStatus as StatusType} />
        </AccordionHeader>
      }
      sx={{
        [theme.breakpoints.down("mobile")]: {
          ".MuiAccordionSummary-expandIconWrapper": {
            position: "relative",
            alignSelf: "center",
          },
        },
      }}
    >
      <Stack flexDirection="column" gap="1rem">
        {showRejection && (
          <FileRowErrorWrapper>
            <Stack
              height="3.2rem"
              width="3.2rem"
              bgcolor={Colors.WHITE}
              borderRadius="50%"
              alignItems="center"
              justifyContent="center"
            >
              <AlertIcon size="small" />
            </Stack>
            <BodySmall color={Colors.BLACK}>Rejected file: {documentLatestUpdate?.rejection_reason}</BodySmall>
          </FileRowErrorWrapper>
        )}

        <BodyRegular mb="2.4rem" color={Colors.DARK_GREY}>
          {description}
        </BodyRegular>

        {fileStatus === "Temporary" ? (
          <Stack direction="row" gap="1rem" alignItems="center">
            <Button
              label={{
                text: 'View outstanding documents'
              }}
              onClick={() => {
                setSelectedFilter("outstanding");
                onFileDeleted();
              }}
              disabled={loading}
              typeOf="primary"
              endIcon={<ArrowRightIcon color={Colors.WHITE} />}
            />
            <Button
              label={{
                text: 'Dismiss message'
              }}
              onClick={onFileDeleted}
              disabled={loading}
              typeOf="secondary"
              endIcon={<CloseCircleIcon color={Colors.BLACK} />}
            />
          </Stack>
        ) : (
          <FileRowFooterWrapper>
            {["Pending", "Approved", "Rejected"].includes(fileStatus) ? (
              <FileDetails>
                <Stack direction="row" gap="1rem" alignItems="center">
                  {originalFileExtension && (
                    <Pill
                      label={'.' + originalFileExtension.toUpperCase()}
                      color={Colors.ERROR}
                      backgroundColor={Colors.ERROR_50}
                    />
                  )}

                  <BodySmall color={fileStatus === "Rejected" ? Colors.ERROR : Colors.BLACK}>
                    {`${originalFileName} - ${originalFileSize}`}
                  </BodySmall>
                </Stack>

                {fileStatus === "Pending" && (
                  <Button
                    onClick={onFileDeleted}
                    disabled={loading}
                    typeOf="secondary"
                    endIcon={<TrashIcon size="small" color={Colors.DARK_GREY} />}
                  />
                )}
              </FileDetails>
            ) : (
              <BodySmall color={Colors.DARK_GREY}>
                Please upload your files (.pdf, .jpg or .png, max 5MB each)
              </BodySmall>
            )}

            {["Missing", "Rejected"].includes(fileStatus) && (
              <>
                <Button
                  data-testid="upload-button"
                  onClick={handleUploadClick}
                  label={{
                    inputId: `${id}-button`,
                    text: loading ? "Uploading" : "Upload",
                  }}
                  disabled={loading}
                  typeOf="CTA"
                  size="small"
                  endIcon={loading? <ProgressIcon color={Colors.WHITE} size="small" /> : <UploadIcon color={Colors.WHITE} size="small" />}
                  sx={{

                    '&.Mui-disabled': {
                      color: Colors.WHITE,
                      backgroundColor: Colors.PRIMARY_HOVER,
                      '& svg path': {
                        fill: Colors.WHITE,
                      }
                    }
                  }}
                />
                <input
                  ref={fileRef}
                  id={id}
                  name={id}
                  type="file"
                  style={{display: "none"}}
                  onChange={(e) => {
                    onFileUploaded(e, {documentName: documentType, setLoading, id: documentId});
                  }}
                />
              </>
            )}
          </FileRowFooterWrapper>
        )}
      </Stack>
    </Accordion>
  );
};

const AccordionHeader = styled(Stack)(({theme}) => ({
  flex: 1,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: "2rem",
  [theme.breakpoints.down('md')]: {
    flexDirection: "column-reverse",
    alignItems: "start",
    gap: "1rem",
  }
}));

const FileRowFooterWrapper = styled(Stack)(({theme}) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1.6rem",
  borderRadius: "0.8rem",
  backgroundColor: Colors.CORAL_10,
  [theme.breakpoints.down('md')]: {
    flexDirection: "column",
    alignItems: "start",
    gap: "1rem",
  }
}));

const FileDetails = styled(Stack)(({theme}) => ({
  flexDirection: "row",
  gap: "1rem",
  alignItems: "center",
  [theme.breakpoints.down('sm')]: {
    flexDirection: "column",
    alignItems: "start",
    gap: "1rem",
  }
}));

const FileRowErrorWrapper = styled(Stack)({
  gap: "1.6rem",
  flexDirection: "row",
  alignItems: "center",
  padding: "2.4rem",
  borderRadius: "1.6rem",
  backgroundColor: Colors.CORAL_20,
  marginBottom: '2.4rem',
});
