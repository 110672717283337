import React, { FC, forwardRef, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { CustomContentProps, SnackbarContent, SnackbarProvider, useSnackbar } from "notistack";
import { IconButton, Stack, styled } from "@mui/material";
import useTypedSelector from "utils/hooks/useTypedSelector";
import configuration from "utils/configuration";
import { clearErrorAC, clearSuccessAC } from "redux/reducers/ErrorReducer";
import Colors from "components/CommonDashboard/Styling/Colors";
import {
    AlertIcon,
    CheckCircleIcon,
    CloseIcon,
    ExclamationCircleIcon,
    InfoIcon,
} from "components/CommonDashboard/Icons";
import { BodySmall, SubtitleSmall } from "components/CommonDashboard/Typography";

const AUTO_HIDE_DURATION = 2000;

const NotificationsInnerProvider: React.FC = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar();
    const error = useTypedSelector((state) => state.ErrorReducer.value);
    const successMessage = useTypedSelector((state) => state.ErrorReducer.successMessage);
    const info = useTypedSelector((state) => state.ErrorReducer.info);
    const dispatch = useDispatch();
    useEffect(() => {
        if (info !== null) {
            enqueueSnackbar(info, {
                variant: "info",
                autoHideDuration: AUTO_HIDE_DURATION,
            });
            const t = setTimeout(() => {
                dispatch(clearErrorAC());
            });
            return () => clearTimeout(t);
        }
    }, [info, dispatch, enqueueSnackbar]);
    useEffect(() => {
        if (error !== null) {
            enqueueSnackbar(error, {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            });
            const t = setTimeout(() => {
                dispatch(clearErrorAC());
            });
            return () => clearTimeout(t);
        }
    }, [dispatch, enqueueSnackbar, error]);

    useEffect(() => {
        if (successMessage !== null) {
            enqueueSnackbar(successMessage, {
                variant: "success",
                autoHideDuration: AUTO_HIDE_DURATION,
            });
            const t = setTimeout(() => {
                dispatch(clearSuccessAC());
            });
            return () => clearTimeout(t);
        }
    }, [dispatch, enqueueSnackbar, successMessage]);

    return <>{children}</>;
};

const NotificationsProvider: FC = ({ children }) => {
    return (
        <SnackbarProvider
            iconVariant={{
                error: <ExclamationCircleIcon color={Colors.DANGER_600} />,
                success: <CheckCircleIcon color={Colors.SUCCESS_600} />,
                warning: <AlertIcon color={Colors.WARNING_600} />,
                info: <InfoIcon color={Colors.INFORMATION_600} />,
                default: <InfoIcon color={Colors.INFORMATION_600} />,
            }}
            maxSnack={3}
            style={{}}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            Components={{
                default: CustomMessage,
                error: CustomMessage,
                info: CustomMessage,
                warning: CustomMessage,
                success: CustomMessage,
            }}
        >
            <NotificationsInnerProvider>{children}</NotificationsInnerProvider>
        </SnackbarProvider>
    );
};

const CustomMessage = forwardRef<HTMLDivElement, CustomContentProps>(
    ({ id, message, iconVariant, variant, style, ...props }, ref) => {
        const { closeSnackbar } = useSnackbar();

        const title = useMemo(() => {
            switch (variant) {
                case "success":
                case "error":
                case "info":
                case "warning":
                    return variant;
                case "default":
                default:
                    return "Info";
            }
        }, [variant]);

        return (
            <SnackbarContent ref={ref} role="alert" style={style}>
                <NotificationWrapper direction="row">
                    {iconVariant[variant]}
                    <Stack flex={1} gap=".8rem">
                        <SubtitleSmall color={Colors.WHITE} height="2.4rem" textTransform="capitalize">
                            {title}
                        </SubtitleSmall>
                        <BodySmall id="notistack-snackbar" color={Colors.WHITE}>{message}</BodySmall>
                    </Stack>
                    <CloseButton size="small" onClick={() => closeSnackbar(id)}>
                        <CloseIcon color={Colors.NEUTRAL_300} />
                    </CloseButton>
                </NotificationWrapper>
            </SnackbarContent>
        );
    },
);

const NotificationWrapper = styled(Stack)({
    width: "320px",
    position: "relative",
    backgroundColor: Colors.NEUTRAL_600,
    borderRadius: "2.4rem",
    padding: "2.4rem",
    gap: "2.4rem",
});

const CloseButton = styled(IconButton)({
    height: "2.4rem",
    padding: "0",
    position: "absolute",
    top: "2.4rem",
    right: "2.4rem",
});

export default NotificationsProvider;
