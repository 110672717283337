import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";


export const InfoModalBox = styled(Box)<BoxProps>(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15), 0.0rem 0.5rem 2rem rgba(0, 0, 0, 0.25)',
    borderRadius: '0.6rem',
    display: 'flex',
    flexDirection: 'column',
    padding: '4rem',
    gap: '4rem',
    maxWidth: '78rem'
}));