import { FC, Fragment } from "react";
import { OnboardingStepStatus as Status } from "customerPortal/pages/types";
import Colors from "components/CommonDashboard/Styling/Colors";
import { CheckCircleIcon, ClockIcon, ProgressIcon } from "components/CommonDashboard/Icons";
import Pill from "components/CommonDashboard/Pill";

interface StatusPillProps {
    status: Status,
    testId?: string,
};

const StatusPill: FC<StatusPillProps> = ({ status, testId }) => {
    const getProps = () => {
        switch (status) {
            case Status.InProgress:
                return {
                    label: "In progress",
                    backgroundColor: Colors.INFORMATION_50,
                    color: Colors.INFORMATION,
                    icon: ProgressIcon
                };
            case Status.Completed:
                return {
                    label: "Completed",
                    backgroundColor: Colors.SUCCESS_50,
                    color: Colors.SUCCESS,
                    icon: CheckCircleIcon
                };
            case Status.NotStarted:
            case Status.Disabled:
            default:
                return {
                    label: "Not started",
                    backgroundColor: Colors.GREY,
                    color: Colors.DARK_GREY,
                    icon: ClockIcon
                };
        }
    }
    return <Pill {...getProps()} testId={testId}/>;
}


export default StatusPill;