import ToggleButton from "components/CommonDashboard/ToggleButton";
import ToggleButtonGroup from "components/CommonDashboard/ToggleButtonGroup";
import { MouseEvent } from "react";
import Box from "@mui/material/Box";
import { BODY_SMALL } from "components/CommonDashboard/Styling/Styles";
import { alpha, styled } from "@mui/material/styles";
import Colors from "components/CommonDashboard/Styling/Colors";

export enum DealFilter {
    Active = 'Active',
    Closed = 'Closed'
}
interface DealToggleFiltersInterface {
    selectedFilter: keyof typeof DealFilter,
    handleFilter: (e: MouseEvent<HTMLElement>, value: keyof typeof DealFilter) => void,
    totalActive: number,
    totalClosed: number
}

export const DealToggleFilters = ({ selectedFilter, handleFilter, totalActive, totalClosed }: DealToggleFiltersInterface) => {

    const Filters = [{ name: DealFilter.Active, total: totalActive }, { name: DealFilter.Closed, total: totalClosed }]

    if (!totalClosed) return <></>;
    return <ToggleButtonGroup exclusive value={selectedFilter} onChange={handleFilter}>
        {Filters.map((filter) => <ToggleButton
            key={filter.name}
            testId={`deal-list-filter-${filter.name.toLowerCase()}`}
            label={{ text: filter.name }}
            value={filter.name}
            RightRender={<Counter inverted={filter.name === selectedFilter}>{filter.total}</Counter>}
        />)}
    </ToggleButtonGroup>;

}


const Counter = styled(({ inverted, ...props }: { inverted?: boolean }) => (
    <Box {...props} />
))<{
    inverted?: boolean;
}>(({ inverted }) => ({
    ...BODY_SMALL,
    fontSize: "1.2rem",
    textAlign: "center",
    borderRadius: "50%",
    background: alpha(Colors.LIGHT_GREY, inverted ? 0.2 : 1),
    width: "2.4rem",
}));

