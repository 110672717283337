import React, {useContext} from "react";
import {Box, MenuItem, styled, Typography, useTheme} from "@mui/material";
import DeededSelect from "../../../v2/components/DeededSelect";
import {FormContext} from "./FormContext";

const EquitableBankProvinceField: React.FC<{label: string; name: string}> = ({
  label,
  name,
}) => {
  const {errors, values, handleChange} = useContext(FormContext);
  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          width:"30%",
          [theme.breakpoints.down("sm")]:{
            width:"48%"
          },
        }}
      >
        <LabelTypography>{label}</LabelTypography>
        <DeededSelect
          error={errors[name as keyof typeof errors]}
          name={name ? name : "province"}
          width={"100%"}
          height={"37px"}
          data-selenium-id={"province-select"}
          sx={{
            width: "100%",
            height: "36px",
            "& .MuiSelect-select": {
              textOverflow: "unset",
              overflow: "hidden",
              whiteSpace: "nowrap",
              borderRight: "40px solid transparent",
            },
          }}
          value={values[name as keyof typeof values]}
          onChange={(e) => {
            handleChange({
              target: {
                name: name,
                value: e?.target.value,
              },
            });
          }}
        >
          <MenuItem value={"AB"}>Alberta (AB)</MenuItem>
          <MenuItem value={"BC"}>British Columbia (BC)</MenuItem>
          <MenuItem value={"MB"}>Manitoba (MB)</MenuItem>
          <MenuItem value={"NB"}>New Brunswick (NB)</MenuItem>
          <MenuItem value={"NL"}>
            Newfoundland & Labrador (NL)
          </MenuItem>
          <MenuItem value={"NT"}>
            Northwest Territories (NT)
          </MenuItem>
          <MenuItem value={"NS"}>Nova Scotia (NS)</MenuItem>
          <MenuItem value={"NU"}>Nunavut (NU)</MenuItem>
          <MenuItem value={"ON"}>Ontario (ON)</MenuItem>
          <MenuItem value={"PE"}>
            Prince Edward Island (PE)
          </MenuItem>
          <MenuItem value={"QC"}>Quebec (QC)</MenuItem>
          <MenuItem value={"SK"}>Saskatchewan (SK)</MenuItem>
          <MenuItem value={"YT"}>Yukon(YT)</MenuItem>
        </DeededSelect>
      </Box>
    </>
  );
};
const LabelTypography = styled(Typography)({
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: "8px",
});
export default EquitableBankProvinceField