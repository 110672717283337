import { FC } from "react";
import { format } from "date-fns";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled";
import Colors from "components/CommonDashboard/Styling/Colors";
import {
    ArrowLeftIcon,
    CalendarIcon,
    HousePinIcon,
    ReceiptIcon,
} from "components/CommonDashboard/Icons";
import {
    BodySmall,
    H2,
    SubtitleSmall,
} from "components/CommonDashboard/Typography";
import Button from "components/CommonDashboard/Button";
import { Deal } from "types/deal";
import { convertDateStringToDateObject } from "utils/date";

interface DealInfoHeaderProps {
    deal: Deal | null;
    onBack?: () => void;
}

const DealInfoHeader: FC<DealInfoHeaderProps> = ({ deal, onBack }) => {
    if (!deal) {
        return null;
    }

    return (
        <>
            {onBack && (
                <Box>
                    <Button onClick={onBack} typeOf="secondary" startIcon={<ArrowLeftIcon color={Colors.DARK_GREY} />} />
                </Box>
            )}
            <DealInfoContainer>
                <Stack sx={{ gap: '2.4rem', alignItems: 'flex-start' }}>
                    <Stack gap='0.8rem' sx={{ backgroundColor: Colors.CORAL_50, padding: '.4rem .8rem', borderRadius: '3.6rem' }} flexDirection={'row'} alignItems='center'>
                        <HousePinIcon color={Colors.PRIMARY} />
                        <BodySmall color={Colors.PRIMARY_600}>Your <b data-testid='header-deal-type-and-address'>{deal.deal_type.toLowerCase()}</b> of</BodySmall>
                    </Stack>
                    <H2>{deal.address.address} {deal.address.state}</H2>
                </Stack>
                <Stack sx={{ backgroundColor: Colors.PRIMARY_100, padding: '1.6rem 2.4rem', borderRadius: '1.6rem', gap: '2.4rem', alignSelf: 'stretch' }}>
                    <Stack flexDirection={'row'} gap={'0.8rem'} paddingLeft={'.8rem'}>
                        <CalendarIcon color={Colors.NEUTRAL_500} />
                        <SubtitleSmall color={Colors.NEUTRAL_700} flexDirection='row'>Closing date: </SubtitleSmall>
                        <BodySmall color={Colors.NEUTRAL_500}>{format(convertDateStringToDateObject(deal.closing_date), 'MMMM dd, yyyy')}</BodySmall>
                    </Stack>
                    <Stack flexDirection={'row'} gap={'0.8rem'} paddingLeft={'.8rem'}>
                        <ReceiptIcon color={Colors.NEUTRAL_500} />
                        <SubtitleSmall color={Colors.NEUTRAL_700} flexDirection='row'>Transaction: </SubtitleSmall>
                        <BodySmall color={Colors.NEUTRAL_500}>{deal.deal_number}</BodySmall>
                    </Stack>
                </Stack>
            </DealInfoContainer>
        </>
    );
}

const DealInfoContainer = styled(Stack)(({ theme }) => ({
    flexFlow: 'row wrap',
    gap: '2.4rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('mobile')]: {
        flexFlow: 'column nowrap',
        alignItems: 'flex-start',
    }
}));

export default DealInfoHeader;
