import React, {FormEvent} from "react";

export const FormContext = React.createContext({
    values: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      streetAddress: "",
      city: "",
      province: "",
      postalCode: "",
      isConsentedTerms: false,
      closingDate: null,
      preferred: "",
      hasBroker: false,
      brokerFirstName: "",
      brokerLastName: "",
      brokerEmailAddress: "",
      brokerPhoneNumber: "",
      type: "",
    },
    errors: {} as {
      phoneNumber?: string;
      firstName?: string;
      lastName?: string;
      email?: string;
      streetAddress?: string;
      city?: string;
      province?: string;
      postalCode?: string;
      isConsentedTerms?: string;
      closingDate?: string;
      preferred?: string;
      type?: string;
    },
    handleChange: (someToChange: unknown) => {},
    handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => {},
  });