import React from "react";
import {OnboardingBase} from "./index";
import {ClientOnboardingProvider} from "../../context/ClientOnboardingProvider";

const OnboardingBaseWrapper = () => (
  <ClientOnboardingProvider>
    <OnboardingBase />
  </ClientOnboardingProvider>
)

export default OnboardingBaseWrapper
