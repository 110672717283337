import React from "react";
import {Link} from "react-router-dom";
import "./Login.scss";
import {version} from "../../../../package.json";
import MfaForm from "../MfaForm";

/**
 *
 * @param {Object} props
 * @param {(event: React.FormEvent<HTMLFormElement>) => void} props.onFormSubmit
 * @param {(event: React.FormEvent<HTMLFormElement>) => void)} props.onFormChange
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} props.onEmailInputChange Runs on every key press.
 * @param {(event: React.FocusEvent<HTMLInputElement>) => void} props.onEmailInputBlur
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} props.onPasswordInputChange Runs on every key press.
 * @param {(event: React.FocusEvent<HTMLInputElement>) => void} props.onPasswordInputBlur
 * @param {boolean} props.hasUserEmailError
 * @param {boolean} props.hasUserPasswordError
 * @param {boolean} props.isLoading
 */

export function LoginDisplay({
  onFormSubmit,
  onFormChange,
  onEmailInputChange,
  onEmailInputBlur,
  onPasswordInputChange,
  onPasswordInputBlur,
  hasUserEmailError,
  hasUserPasswordError,
  isLoading,
  sixDigitsCode,
  setSixDigitsCode,
  isMtfAuthShowed,
}) {
  return (
    <div className="wrapper__login">
      <form
        className="login-form"
        onSubmit={onFormSubmit}
        onChange={onFormChange}
      >
        <p className="login-form__title"></p>
        {!isMtfAuthShowed && (
          <>
            <div className="login-form__input">
              <label
                htmlFor="email"
                className="login-form__icon--email"
              ></label>
              <input type="hidden" value="something" />
              <input
                autoComplete="off"
                name="userEmail"
                type="email"
                className={
                  hasUserEmailError
                    ? "login-form__email input-error"
                    : "login-form__email"
                }
                placeholder="Email"
                onChange={onEmailInputChange}
                onBlur={onEmailInputBlur}
              />
              <span className={hasUserEmailError ? "error" : null}>
                invalid email
              </span>
            </div>
            <div className="login-form__input">
              <label htmlFor="password" className="login-form__icon--password">
                <input type="hidden" value="something" />
              </label>
              <input
                autoComplete="off"
                type="password"
                name="password"
                className={
                  hasUserPasswordError
                    ? "login-form__password input-error"
                    : "login-form__password"
                }
                placeholder="Password"
                onChange={onPasswordInputChange}
                onBlur={onPasswordInputBlur}
              />
              <span className={hasUserPasswordError ? "error" : null}>
                invalid password
              </span>
            </div>
          </>
        )}
        {isMtfAuthShowed && (
          <MfaForm
            hasError={hasUserPasswordError}
            onChange={setSixDigitsCode}
            onBlur={onPasswordInputBlur}
            value={sixDigitsCode}
          />
        )}
        <button
          disabled={isLoading}
          type="submit"
          className="login-form__login"
        >
          {isMtfAuthShowed ? "Verify" : "Log In"}
        </button>
        <Link to="/request-password-reset" className="login-form__forgot-pass">
          Forgot your Password?
        </Link>
      </form>

      <div className="app-version">V{version}</div>
    </div>
  );
}
