import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from "react";
import {
  authUserReducer,
  AuthUserState,
  initialAuthUser,
} from "./reducer/authUser";

interface CustomerPortalProviderProps {
  children?: ReactNode;
}

interface State {
  authUser: AuthUserState;
}

const initialState = {
  authUser: initialAuthUser,
};

export const CustomerPortalContext = createContext([initialState, () => {}] as [
  State,
  Dispatch<Record<string, any>>,
]);

export const useCustomerPortal = () => useContext(CustomerPortalContext);

export const CustomerPortalProvider = ({
  children,
}: CustomerPortalProviderProps) => {
  const [state, dispatch] = useReducer(customerPortalReducer, initialState);

  return (
    <CustomerPortalContext.Provider value={[state, dispatch]}>
      {children}
    </CustomerPortalContext.Provider>
  );
};

export const customerPortalReducer = (
  state: State,
  action: Record<string, any>,
) => ({
  authUser: authUserReducer(state.authUser, action),
});
