import { SET_AUTH_USER, CLEAN_AUTH_USER } from "../actionTypes";
import { User } from "types/user";

export type AuthUserState = {
    user: User | null
}

export const initialAuthUser: AuthUserState = {
    user: null
}

export const authUserReducer = (state: AuthUserState, action: Record<string, any>): AuthUserState => {
    const { type, payload } = action;
    switch (type) {
        case SET_AUTH_USER:
            return { ...state, user: payload };
        case CLEAN_AUTH_USER:
            return { ...initialAuthUser };
        default:
            return state;
    }
}