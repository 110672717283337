import { Box, Link, Stack, styled } from "@mui/material";
import { IDVStatePageProps } from ".";
import IDVPersonalInfo from "components/Svg/IDVPersonalInfo";
import { Body, H1, LargeBold } from "components/Common/Typography";
import { BadgeIcon, CheckIcon, MobileIcon, SelfieIcon, TaskIcon, XIcon } from "components/Common/Icons/Iconography";
import Colors from "components/Common/componentStyling/Colors";
import BasicButton from "components/Common/Button/BasicButton";
import configuration from "../../../utils/configuration";

interface IDVInitialStateProps extends IDVStatePageProps {
    handleVerifyIdentity: () => void
}

const faqLink = `${configuration.docsBaseUrl}/docs/FAQ/Identity%20Verification`;
const IDVInitial = ({ handleVerifyIdentity }: IDVInitialStateProps) => {
    return (
        <>
            <Stack justifyContent={'center'} alignItems={'center'} gap={'2.4rem'} maxWidth={'70rem'}>
                <IDVPersonalInfoBox>
                    <IDVPersonalInfo />
                </IDVPersonalInfoBox>
                <Stack alignItems={'center'} gap={'4rem'}>
                    <Body sx={{ color: Colors.GRAY_800 }}>
                        To ensure a secure and seamless experience and comply with regulations, you’ll need to verify your identity. Let’s get started on this quick and efficient journey to ensure the highest level of security for your transaction
                    </Body>
                    <Stack alignItems={'center'} gap={'2.4rem'}>
                        <IntroBox>
                            <Stack justifyContent={'center'} alignItems={'center'} gap='0.8rem' alignContent={'center'} >
                                <Stack padding={'2.41rem'} style={{ backgroundColor: Colors.RED_20, borderRadius: '50%' }}>
                                    <MobileIcon color={Colors.BLACK} size={32} />
                                </Stack>
                            </Stack>
                            <Stack alignItems={'flex-start'} gap={'2.4rem'} flex={'1 0 0'}>
                                <LargeBold>Before you start</LargeBold>
                                <Body sx={{ color: Colors.GRAY_700 }}>
                                    To prepare for the digital ID verification process, please have an acceptable piece of ID ready. Additionally, we recommend using your smartphone, as it makes the verification process more convenient and straightforward.
                                </Body>
                            </Stack>
                        </IntroBox>
                        <StepsBox>
                            <Stack alignItems={'stretch'} flex={'1 0 0'} >
                                <OneStepBox>
                                    <SubtitleBox>
                                        <NumberTitle>1.</NumberTitle>
                                        <Stack padding={'2.41rem'} style={{ backgroundColor: Colors.RED_20, borderRadius: '50%', marginLeft: '-0.5rem' }}>
                                            <BadgeIcon color={Colors.BLACK} size={32} />
                                        </Stack>
                                    </SubtitleBox>
                                    <LargeBold>Prepare a piece of acceptable ID</LargeBold>
                                    <Body sx={{ color: Colors.GRAY_700 }}>Have one of these ready to go:</Body>
                                    <Stack alignItems={'flex-start'} gap={'1.6rem'}>
                                        <SecondStepItem>
                                            <CheckIcon size={16} color={Colors.GREEN_500} /> <Body sx={{ color: Colors.GRAY_700 }}>Driver’s license</Body>
                                        </SecondStepItem>
                                        <SecondStepItem>
                                            <CheckIcon size={16} color={Colors.GREEN_500} /> <Body sx={{ color: Colors.GRAY_700 }}>Passport</Body>
                                        </SecondStepItem>
                                        <SecondStepItem>
                                            <CheckIcon size={16} color={Colors.GREEN_500} /> <Body sx={{ color: Colors.GRAY_700 }}>Provincial ID</Body>
                                        </SecondStepItem>
                                        <SecondStepItem>
                                            <CheckIcon size={16} color={Colors.GREEN_500} /> <Body sx={{ color: Colors.GRAY_700 }}>Permanent Resident Card</Body>
                                        </SecondStepItem>
                                        <SecondStepItem>
                                            <CheckIcon size={16} color={Colors.GREEN_500} /> <Body sx={{ color: Colors.GRAY_700 }}>Indian Status Card</Body>
                                        </SecondStepItem>
                                        <SecondStepItem>
                                            <XIcon size={16} color={Colors.ORANGE_500} /> <Body sx={{ color: Colors.GRAY_700 }}>Sorry, a health card cannot be used as ID</Body>
                                        </SecondStepItem>
                                        <SecondStepItem>
                                            <XIcon size={16} color={Colors.ORANGE_500} /> <Body sx={{ color: Colors.GRAY_700 }}>Your ID must be valid and not expired</Body>
                                        </SecondStepItem>
                                    </Stack>
                                </OneStepBox>
                            </Stack>
                            <Stack alignItems={'stretch'} gap={'2.4rem'} flex={'1 0 0'}>
                                <OneStepBox>
                                    <SubtitleBox>
                                        <NumberTitle>2.</NumberTitle>
                                        <Stack padding={'2.41rem'} style={{ backgroundColor: Colors.RED_20, borderRadius: '50%', marginLeft: '-0.5rem' }}>
                                            <SelfieIcon color={Colors.BLACK} size={32} />
                                        </Stack>
                                    </SubtitleBox>
                                    <LargeBold>Take a selfie</LargeBold>
                                    <Body sx={{ color: Colors.GRAY_700 }}>Our system will prompt you to take a selfie that we compare to your ID.</Body>
                                </OneStepBox>
                                <OneStepBox>
                                    <SubtitleBox>
                                        <NumberTitle>3.</NumberTitle>
                                        <Stack padding={'2.41rem'} style={{ backgroundColor: Colors.RED_20, borderRadius: '50%', marginLeft: '-0.5rem' }}>
                                            <TaskIcon color={Colors.BLACK} size={32} />
                                        </Stack>
                                    </SubtitleBox>
                                    <LargeBold>All set!</LargeBold>
                                    <Body sx={{ color: Colors.GRAY_700 }}>If there are any issues or glitches, we’ll make sure to reach out.</Body>
                                </OneStepBox>
                            </Stack>
                        </StepsBox>
                    </Stack>
                    <Body sx={{ color: Colors.GRAY_800 }}>Stuck or have questions? Check out our <StyledLink target="_blank" href={faqLink}>Frequently Asked Questions</StyledLink>, or reach out to us at idvhelp@deeded.ca</Body>
                    <BasicButton
                        typeOf="CTA"
                        label={{ text: "Verify Identity", inputId: "idvVerifyIdentityId" }}
                        onClick={handleVerifyIdentity}
                    />
                </Stack>
            </Stack >
            <BoxShadow />
        </>
    );
}


const IDVPersonalInfoBox = styled(Box)(({ theme }) => ({
    width: '45.2rem',
    [theme.breakpoints.down("mobile")]: {
        width: '33.5rem',
    },
}));

const BoxShadow = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: Colors.RED_10,
    zIndex: '-999',
    top: '27rem',
    [theme.breakpoints.down("mobile")]: {
        top: '23.5rem',
    },
}));

const IntroBox = styled(Stack)(({ theme }) => ({
    backgroundColor: Colors.WHITE,
    borderRadius: '2.4rem',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '2.4rem',
    padding: '2.4rem',
    alignSelf: 'stretch',
    [theme.breakpoints.down("mobile")]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const StepsBox = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    alignItems: 'stretch',
    gap: '2.4rem',
    [theme.breakpoints.down("mobile")]: {
        flexDirection: 'column',
    },
}));

const OneStepBox = styled(Stack)(({ theme }) => ({
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2.4rem',
    flex: '1 0 0',
    alignSelf: 'stretch',
    padding: "2.4rem",
    backgroundColor: Colors.WHITE,
    borderRadius: '2.4rem'
}));

const SubtitleBox = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center'
}));

const NumberTitle = styled(H1)(({ theme }) => ({
    height: 'fit-content',
    zIndex: 2,
    color: Colors.RED_500
}));

const SecondStepItem = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    gap: '0.8rem'
}));

const StyledLink = styled(Link)(({ theme }) => ({
    color: "inherit",
    textDecorationColor: "inherit"
}));


export default IDVInitial;