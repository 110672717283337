import {Box, styled, Typography} from "@mui/material";
import React from "react";
import logo from "../../assets/img/deeded.svg";
import backgroundImg from "../../assets/img/updatePageBackground.jpg";

const Update = () => {
  return (
    <UpdatePage>
      <InfoBlock>
        <Box sx={{marginBottom: "40px"}}>
          <img src={logo} alt="" />
        </Box>
        <Text>We are updating system!</Text>
        <Text>Please, check back shortly</Text>
      </InfoBlock>
    </UpdatePage>
  );
};

const UpdatePage = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100vh",
  backgroundImage: `url(${backgroundImg})`,
  backgroundSize: "cover",
});

const InfoBlock = styled(Box)({
  width: "65%",
  textAlign: "center",
  padding: "68px 20px 42px 20px",
  background: "rgba(255, 255, 255, 0.85)",
});

const Text = styled(Typography)(({theme}) => ({
  fontFamily: "Montserrat",
  fontWeight: "500",
  fontSize: "26px",
  lineHeight: "48px",
  marginBottom: "26px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "130%",
  },
}));
export default Update;
