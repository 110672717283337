import React, {FormEvent, useEffect} from "react";
import {Box, useTheme} from "@mui/material";
import EquitableBankBanner from "./EquitableBankBanner";
import EquitableBankForm from "./EquitableBankForm";
import {useGetSlugForPartnersBankQuery} from "../../../redux/reducers/landingApi";
import {useParams} from "react-router-dom";
import DeededLoader from "../../Svg/DeededLoader";
import NotFound from "../../Dashboard/404/NotFound";

const EquitableBank = () => {
  const params = useParams() as {slug: string};
  const {data, isLoading, refetch} = useGetSlugForPartnersBankQuery(
    params.slug,
  );
  const theme = useTheme();
  useEffect(() => {
    refetch();
  }, [refetch]);
  return (
    <>
      {isLoading ? (
        <DeededLoader />
      ) : (
        <>
          {!!data ? (
            <Box
              sx={{
                overflowY: "scroll",
                paddingBottom: "80px",
                [theme.breakpoints.down("md")]: {
                  paddingBottom: "56px",
                },
                "&::-webkit-scrollbar": {
                  width: "0px",
                },
              }}
            >
              <EquitableBankBanner />
              <EquitableBankForm />
            </Box>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
};

export default EquitableBank;
