import { OnboardingStepStatus as StepStatus } from "../types";
import { AlertIcon, MailIcon, TruckIcon } from "components/CommonDashboard/Icons";
import { getStatusProps } from "./OnboardingSteps";
import { OnboardingTask, OnboardingTaskStatus } from "types/intakeForm";
import { useState } from "react";
import Button from "components/CommonDashboard/Button";
import { updateOnboardingTask } from "api";
import Colors from "components/CommonDashboard/Styling/Colors";
import BaseModal from "components/CommonDashboard/Modal";
import { BodyRegular } from "components/CommonDashboard/Typography";
import { LINK } from "components/CommonDashboard/Styling/Styles";
import { useMixpanel } from "utils/MixpanelContext";
import { Deal } from "types/deal";

export default {
    title: "Streamline your move with MoveBliss!",
    icon: <TruckIcon />,
    link: () => '',
    description: "MoveBliss is your personal moving concierge app. It simplifies connecting your utilities, address changes, arranging your move, and much more.  It's complimentary for you as a valued Deeded client.  Sign up now and experience the ease of planning your entire move in one place!",
    testId: "move-with-movebliss-step",
    getStatus: ({ task, intState }: getStatusProps) => {
        if (!intState)
            return StepStatus.NotShow;
        switch (task?.status || "") {
            case OnboardingTaskStatus.Completed:
                return StepStatus.Completed;
            case OnboardingTaskStatus.InProgress:
                return StepStatus.InProgress;
            case OnboardingTaskStatus.NotStarted:
                return StepStatus.Disabled;
            default:
                return StepStatus.NotShow;
        }
    },
    customFooter: ({ task, refreshDeal, deal }: { task?: OnboardingTask, refreshDeal: () => void, deal: Deal }) => {
        const [open, setOpen] = useState(false);
        const [loading, setLoading] = useState(false);
        const [response, setResponse] = useState({ result: '', url: '' });
        const mixpanel = useMixpanel();
        if (!task) return <></>;

        const onCloseModalClick = () => {
            setOpen(false);
            setLoading(false);
            refreshDeal();
        }

        return <>
            <Button
                sx={(theme) => ({
                    marginTop: "2.4rem",
                    [theme.breakpoints.down("mobile")]: { width: "100%" },
                })}
                loading={loading}
                startIcon={<MailIcon color={Colors.WHITE} />}
                typeOf="CTA"
                label={{ text: "Help with my move" }}
                onClick={async (e) => {
                    setLoading(true);
                    updateOnboardingTask(task.id, OnboardingTaskStatus.Completed).then((result) => {
                        setResponse({ result: result?.data?.success ? 'success' : 'conflict', url: result?.data?.web_url || '' });
                    }).catch((error) => {
                        if (error.response?.status === 500) {
                            setResponse({ result: 'error', url: '' })
                        }
                    }).finally(() => {
                        setOpen(true);
                        mixpanel.track(`dashboard_subscribe_movebliss`, {
                            transactionType: deal.deal_type,
                            dealId: deal.id
                        });
                    });
                }}
            />
            <BaseModal
                open={open}
                onCloseClick={onCloseModalClick}
                title={response.result === 'success' ? "We are excited to help your move!" : "Alert"}
                icon={response.result === 'success' ? <TruckIcon /> : <AlertIcon />}
                buttonProps={[{
                    typeOf: 'primary',
                    label: { text: "Got it" },
                    onClick: onCloseModalClick
                }]}
            >
                {response.result === 'success' && <BodyRegular>
                    Please, check your email for the invite from MoveBliss and follow the instructions there to start your moving journey
                </BodyRegular>}
                {response.result === 'error' && <BodyRegular>
                    We have encountered a problem subscribing you to MoveBliss. Please try again later of contact us at <a href="mailto: hello@deeded.ca" style={LINK}>hello@deeded.ca</a>
                </BodyRegular>}
                {response.result === 'conflict' && <>
                    <BodyRegular>Your email is already being used by MoveBliss.</BodyRegular>
                    <BodyRegular>Access <a href={decodeURI(response.url)} style={LINK} target="_blank">MoveBliss</a> website to Log in.</BodyRegular>
                </>}
            </BaseModal>
        </>;
    },
}