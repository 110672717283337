import React, { FC, useEffect } from "react";
import { Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import Login from "./Auth/Login/Login";
import Details from "../customerPortal/pages/Registration";
import ResetPassword from "./Auth/ResetPassword/ResetPassword";
import RequestResetPassword from "./Auth/RequetsResetPassword/RequestResetPassword";
import Dashboard from "./Dashboard/Dashboard";
import NotFound from "./Dashboard/404/NotFound";
import { FullStoryAPI } from "react-fullstory";
import "./App.scss";
import * as url from "utils/url";
import storage from "utils/storage";

import Survey from "./Survey/Survey/Survey";
import useUser from "utils/hooks/useUser";
import { User } from "../types/user";
import Landing from "./Landing/Landing";
import Update from "./Update/Update";
import reloadAxios from "utils/axiosManager";
import TermsOfUseApp from "./Dashboard/TermsOfUse";
import Information from "./Information/Information";
import EquitableBank from "./Partners/EquitableBank";
import Simplii from "./Partners/Simplii";
import CustomerPortal from "customerPortal/pages";
import configuration from "utils/configuration";
import CookiePopup from "./Dashboard/CookiePopup";
import AppEnvironment from "./System/AppEnvironment";
import useTrackNavigation from "../utils/hooks/useTrackNavigation";

const App: FC = () => {
    const { user: currentUser } = useUser();
    const history = useHistory();
    const location = useLocation();
    useTrackNavigation();

    useEffect(() => {
        if (Object.entries(currentUser).length !== 0) {
            const { id, email, full_name } = currentUser as User;

            // Identify method
            FullStoryAPI("identify", "" + id, {
                displayName: full_name,
                email,
            });
        }
    }, [currentUser]);
    reloadAxios(history);

    useEffect(() => {
        const ignoredPaths = [
            "/login",
            "/customer/registration",
            "/reset-password",
            "/request-password-reset"
        ];
        if (!ignoredPaths.includes(location.pathname)) {
            const theLocation = location.pathname + location.search;
            storage.targetLink = theLocation;
        }
    }, [location]);

    let PartnerPage;
    if (history.location.pathname.indexOf("simplii") !== -1) {
        PartnerPage = Simplii;
    } else if (history.location.pathname.indexOf("eqb") !== -1) {
        PartnerPage = EquitableBank;
    }

    const { intake } = useQueryParamsKeys(history.location);
    return (
        <>
            {configuration.cookieConsentEnabled && <CookiePopup />}
            <AppEnvironment />
            <Switch>
                {intake === "Survey" && <Route path="/survey/:page/:id" component={Survey} />}
                <Route path={"/partner/:slug"} exact component={PartnerPage} />
                <Route path="/partners/:slug" exact component={Landing} />
                <Route path="/update" component={Update} />
                <Route path="/information" component={Information} />
                <Route path="/" exact render={() => <Redirect to="/dashboard" />} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/login" component={Login} />
                <Route path="/customer/registration" component={Details} />
                <Route path="/request-password-reset" component={ResetPassword} />
                <Route path="/reset-password" component={RequestResetPassword} />
                <Route path="/accept_terms_of_use" component={() => <TermsOfUseApp />} />
                <CustomerPortal />
                <Route component={NotFound} />
            </Switch>
        </>
    );
};

const useQueryParamsKeys = (location: { pathname: string; search: string }) => {
    const intake = location.pathname.includes("/survey")
        ? url.makeQueryParamsObject(location.pathname)?.intake ?? ""
        : "";

    const { state } = url.makeQueryParamsObject(location.search.slice(1));

    return {
        intake,
        state,
    };
};

export default App;
