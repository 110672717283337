import { useCallback, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "../index.scss";
import questions from "../QuoteQuestions.json";
import { registerInputCustomFormat } from "components/Common/SurveyJs/QuestionNumberFormat";
import { registerCaptcha } from "components/Common/SurveyJs/QuestionCaptcha";
import registerAddressAutocomplete from "components/Common/SurveyJs/widgets/addressAutocomplete";
import { useMixpanel } from "utils/MixpanelContext";
import AlertPanel from "components/CommonDashboard/AlertPanel";
import { UserParticipantBase } from "types/user";
import { QuoteTypes } from "types/quote";
import { upperFirst } from "lodash";
import { registerHoneypot } from "components/Common/SurveyJs/QuestionHoneypot";
import useScrollTopEffect from "utils/hooks/useScrollTopEffect";
import { registerCustomFunctions } from "components/Common/SurveyJs/CustomFunctions";

export interface quoteSurveyDataInterface {
    dealType: QuoteTypes,
    formCaptcha: string,
    closingDate: string,
    province: string,
    address: string,
    city: string,
    postalCode: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    referralChannel: string,
    'referralChannel-Comment': string,
    referrerName: string,
    sellingPrice: number,
    purchasePrice: number,
    mortgageAmount: number,
    mortgageValue: number,
    hasMortgage: boolean,
    isFirstTimeHomeBuyer: boolean,
    // Honeypot prop
    nameH: string,
}

registerCustomFunctions();
registerInputCustomFormat();
registerCaptcha();
registerAddressAutocomplete();
registerHoneypot();

interface SurveyQuotePropsInterface {
    submitForm: (data: Partial<quoteSurveyDataInterface>) => void,
    referral: string | null,
    dealType: string | null
    userInfo?: UserParticipantBase
}

enum Pages {
    PROPERTY_INFORMATION = 'propertyInformation',
    TRANSACTION_TYPE = 'transactionType',
    TRANSACTION_INFORMATION = 'transactionInformation',
    YOUR_INFORMATION = 'yourInformation'
}

export const SurveyQuote = ({
    submitForm,
    referral,
    dealType,
    userInfo,
}: SurveyQuotePropsInterface) => {
    const [survey] = useState(new Model(questions));
    const [currPage, setCurrPage] = useState('');
    const mixpanel = useMixpanel();

    const handleData = useCallback((sender, options) => {
        const surveyData = sender.data as Partial<quoteSurveyDataInterface>;
        submitForm(surveyData);
    }, [mixpanel]);

    const sendPageEvent = useCallback(function (sender, options) {
        setCurrPage(options.page.name);
        mixpanel.track_pageview({
            page: `quote${upperFirst(options.page.name)}`,
            referral,
            deal_type: survey.data?.dealType || dealTypeMap[dealType as keyof typeof dealTypeMap]
        });
    }, [mixpanel]);

    useEffect(() => {
        if (dealType && dealType in dealTypeMap) {
            setCurrPage(Pages.PROPERTY_INFORMATION);
            survey.data = { dealType: dealTypeMap[dealType as keyof typeof dealTypeMap] };
            survey.currentPageNo = 1;
        }

        if (userInfo) {
            survey.mergeData({
                firstName: userInfo.first_name,
                lastName: userInfo.last_name,
                email: userInfo.email,
                phone: userInfo.phone,
            });
        }
    }, [dealType, userInfo]);
    
    useScrollTopEffect([currPage]);

    survey.onComplete.add(handleData);
    survey.onAfterRenderPage.add(sendPageEvent);

    return (<Stack sx={{ backgroundColor: 'white', borderRadius: '2.4rem' }}>
        <Survey model={survey} />
        <InfoAlerts currentPage={currPage} />
    </Stack>);
}


const InfoAlerts = ({ currentPage }: { currentPage: string }) => {
    return <>
        {(currentPage == Pages.PROPERTY_INFORMATION) && <AlertPanel
            message={`Any guidance provided to potential clients at this stage, is provided solely for 
        purposes of helping you and Deeded determine whether your transaction is one that can be
        handled by Deeded. It is not intended to be and should not be considered legal advice.`
            }
            sx={{ margin: '0 4.8rem 4.8rem' }}
            showIcon={false}
        />}
    </>
}

const dealTypeMap = {
    purchase: 'Purchase',
    mortgage: 'Mortgage',
    sale: 'Sale',
    'ownership-transfer': 'TitleTrn',
    'legal-advice': 'ILA'
};
