import React, {FC, ReactNode} from "react";
import {styled, alpha} from "@mui/material/styles";
import MuiToggleButton, {
  ToggleButtonProps as MuiButtonProps,
} from "@mui/material/ToggleButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Colors from "../Styling/Colors";
import {BUTTON_SMALL_TEXT, BODY_SMALL} from "../Styling/Styles";


export type ToggleButtonProps = {
  /**
   * Button text.
   */
  label?: { text: string, inputId?: string };
  /**
   * The size of the component.
   */
  size?: "medium" | "small";
  /**
   * Id for tests
   */
  testId?: string;
  /**
   * Toggle left optional component
   */
  LeftRender?: ReactNode;
  /**
   * Toggle right optional component
   */
  RightRender?: ReactNode;
} & Omit<MuiButtonProps, 'touchRippleRef' | 'TouchRippleProps' | 'onFocusVisible' | 'focusVisibleClassName' | 'focusRipple' |
  'disableTouchRipple' | 'disableRipple' | 'centerRipple' | 'action' | 'variant' | 'fullWidth' | 'children' | 'disableElevation' | 'disableFocusRipple'>;

const ToggleButton: FC<ToggleButtonProps> = ({ label, size = "medium", testId, ...props }) => {
  return (
    <CustomToggle size={size} label={label} data-testid={testId} {...props}>
      <Box display={'inline-flex'} gap='1rem' alignItems='center'>
        {props.LeftRender}
        {label?.text && <ButtonText noWrap size={size}>{label.text}</ButtonText>}
        {props.RightRender}
      </Box>
    </CustomToggle>
  )
};

const CustomToggle = styled(MuiToggleButton, {
  shouldForwardProp: (prop) => !(["size", "label", "LeftRender", "RightRender"] as PropertyKey[]).includes(prop)
})<ToggleButtonProps>(({RightRender, LeftRender}) => ({
  borderRadius: "5rem !important",
  border: `.1rem solid ${Colors.LIGHT_GREY} !important`,
  textTransform: "none",
  minWidth: 0,
  color: Colors.DARK_GREY,
  padding: `0.8rem ${RightRender ? '0.8rem' : '1.6rem'} 0.8rem ${LeftRender ? '0.8rem' : '1.6rem'}`,
  '&:hover': {
    background: Colors.WHITE,
    color: Colors.DARK_GREY,
    opacity: '.6'
  },
  '&.Mui-selected': {
    background: Colors.BLACK,
    color: Colors.WHITE,
    border: `.1rem solid ${Colors.BLACK} !important`,
    '&:hover': {
      background: Colors.BLACK,
      color: Colors.WHITE,
    },
  },
}));

const getTextStyle = (type: "medium" | "small") => {
  switch (type) {
    case "medium":
      return BODY_SMALL;
    case "small":
    default:
      return BUTTON_SMALL_TEXT;
  }
}

export const ButtonText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "size"
})(({ size }: { size: "medium" | "small"}) => ({
  ...(getTextStyle(size)),
  color: 'inherit',
}));

export default ToggleButton;