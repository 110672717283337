import { useState } from "react";
import { Stack, styled } from "@mui/material";
import { IDVStatePageProps } from ".";
import { Body, H4 } from "components/Common/Typography";
import BasicButton from "components/Common/Button/BasicButton";
import Colors from "components/Common/componentStyling/Colors";
import { MAX_BODY_INFORMATION } from "components/Common/componentStyling/Styles";
import CircularLoader from "components/Common/Loader/CircularLoader";

interface IDVResumeStateProps extends IDVStatePageProps {
    handleResumeIDV: (callback: CallableFunction) => void
}

export const IDVResume = ({ handleResumeIDV }: IDVResumeStateProps) => {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <IDVWrapper>
            <H4 width={'100%'} sx={{ color: Colors.GRAY_800 }}>Welcome back! </H4>
            <Body sx={{ maxWidth: MAX_BODY_INFORMATION, color: Colors.GRAY_800 }}>
                Since you've already started the verification process, click below to pick up where you left off.
            </Body>
            <BasicButton
                typeOf="CTA"
                label={{ text: isLoading ? "Loading..." : "Resume Verification", inputId: "idvResumeVerificationId" }}
                disabled={isLoading}
                onClick={() => handleResumeIDV(setIsLoading)}
            />
        </IDVWrapper>
    );
}

export const IDVInProgress = ({ }: IDVStatePageProps) => {
    return <IDVWrapper>
        <H4 width={'100%'} sx={{ color: Colors.GRAY_800 }}>Identity Validation in Progress</H4>
        <Body sx={{ maxWidth: MAX_BODY_INFORMATION }}>
            Please wait while we verify your identity.  This may take a few minutes.
        </Body>
        <CircularLoader containerHeight="9.4vh" strokeWidth={2} />
    </IDVWrapper>
}

export const IDVCompleted = ({ children, }: IDVStatePageProps) => {
    return <IDVWrapper>
        <H4 >Congratulations, we've verified your identity.</H4>
        <Body alignSelf={'self-start'} sx={{ maxWidth: MAX_BODY_INFORMATION, color: Colors.GRAY_800 }}>
            Please click below to proceed
        </Body>
        {children}
    </IDVWrapper>
}

export const IDVDeclined = ({ children, }: IDVStatePageProps) => {
    return <IDVWrapper>
        <H4 alignSelf={'self-start'}>Your identity verification is still pending.</H4>
        <Body sx={{ maxWidth: MAX_BODY_INFORMATION, color: Colors.GRAY_800 }}>
            It seems like we've hit a little snag while making sure everything's tip-top with your identity verification.
            No worries, though! Our team is on the case, and you can expect an  email from us soon to iron out these technical quirks.
        </Body>
        {children}
    </IDVWrapper>
}


const IDVWrapper = styled(Stack)(({ theme }) => ({
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4rem',
    marginTop: 'calc((50vh - 12rem)/2)',
}));
