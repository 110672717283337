import React from "react";
import {connect} from "react-redux";
import {NavLink, useRouteMatch} from "react-router-dom";
import {Accordion, AccordionItem} from "react-sanfona";
import {useSelector} from "react-redux";
import "./SurveyAccordionMenu.scss";
// Intake links
import {surveyLinks} from "../SurveyLinks";
const SurveyAccordionMenu = ({PurchaseReducer}) => {
  let match = useRouteMatch();
  const {rating} = useSelector((state) => state.SurveyReducer);
  // TODO: Clean this up and make sure nothing breaks.
  return (
    <Accordion>
      {surveyLinks.map((item, index) => {
        if (item.title === "Google review" && rating !== 5) {
          return false;
        }
        return (
          <AccordionItem
            key={item.title}
            title={item.title}
            expanded={item.subtitle.some((el) => el.page === match.params.page)}
          >
            {<div className="item__order">{`0${index + 1}`}</div>}

            {item.subtitle.map((el, idx) => {
              return (
                <NavLink
                  className={"passed no-arrow"}
                  to={`${el.path}/${match.params.id}`}
                  key={idx}
                  onClick={(e) => e.preventDefault()}
                >
                  {el.title}
                </NavLink>
              );
            })}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

const mapStateToProps = ({PurchaseReducer}) => {
  return {
    PurchaseReducer,
  };
};

export default connect(mapStateToProps, null)(SurveyAccordionMenu);
