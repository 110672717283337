import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./reset.scss";
import App from "./components/App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "redux/store";
import FullStory from "react-fullstory";
import { MixpanelProvider } from "utils/MixpanelContext";
import { UploadedDocsProvider } from "context/UploadDocumentsProvider";
import configuration from "./utils/configuration";
import NotificationsProvider from "context/NotificationsProvider";
import ThemeProvider from "context/ThemeProvider";
import { ErrorBoundaryBugSnag } from "utils/bugSnag";

ReactDOM.render(
  <MixpanelProvider>
    <Provider store={store}>
      <UploadedDocsProvider>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ErrorBoundaryBugSnag>
              <ThemeProvider>
                <NotificationsProvider>
                  {configuration.fullstory.enabled && (
                    // @ts-ignore
                    <FullStory org={configuration.fullstory.orgId!} />
                  )}
                  <App />
                </NotificationsProvider>
              </ThemeProvider>
            </ErrorBoundaryBugSnag>
          </BrowserRouter>
        </PersistGate>
      </UploadedDocsProvider>
    </Provider>
  </MixpanelProvider >,
  document.getElementById("root"),
);
