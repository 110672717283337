export const surveyLinks = [
  {
    offset: 0,
    title: "Questions",
    subtitle: [
      {
        title: "WHAT SHOULD WE KEEP DOING?",
        path: "/survey/question1",
        page: "question1",
        data: "question1",
      },
      {
        title: "WHAT COULD WE DO DIFFERENTLY?",
        path: "/survey/question2",
        page: "question2",
        data: "question2",
      },
      {
        title: "WHEN REFERRING US TO OTHERS, WHAT WOULD YOU SAY?",
        path: "/survey/question3",
        page: "question3",
        data: "question3",
      },
    ],
  },
  {
    offset: 1,
    title: "Rating",
    subtitle: [
      {
        title: "YOUR RATE",
        path: "/survey/rating",
        page: "rating",
        data: "rating",
      },
    ],
  },
  // {
  //   offset: 2,
  //   title: "GOOGLE REVIEW",
  //   subtitle: [
  //     {
  //       title: "REVIEW US ON GOOGLE",
  //       path: "/survey/google-review",
  //       page: "google-review",
  //       data: "google-review",
  //     },
  //   ],
  // },
];
