import {FC} from "react";
import Colors from "components/CommonDashboard/Styling/Colors";
import {
  CheckCircleIcon,
  ClockIcon,
  CloseCircleIcon,
  HelpIcon,
} from "components/CommonDashboard/Icons";
import Pill from "components/CommonDashboard/Pill";
import {StatusType} from "types/deal";

interface FileStatusPillProps {
  status: StatusType;
}

const FileStatusPill: FC<FileStatusPillProps> = ({status}) => {
  const getProps = () => {
    switch (status) {
      case "Pending":
        return {
          label: "In review",
          backgroundColor: Colors.WARNING_50,
          color: Colors.WARNING,
          icon: ClockIcon,
          testId: "document-status-pill",
        };
      case "Approved":
        return {
          label: "Approved",
          backgroundColor: Colors.SUCCESS_50,
          color: Colors.SUCCESS,
          icon: CheckCircleIcon,
          testId: "document-status-pill",
        };
      case "Rejected":
        return {
          label: "Rejected",
          backgroundColor: Colors.ERROR_50,
          color: Colors.ERROR,
          icon: CloseCircleIcon,
          testId: "document-status-pill",
        };
      case "Missing":
      default:
        return {
          label: "Outstanding",
          backgroundColor: Colors.INFORMATION_50,
          color: Colors.INFORMATION,
          icon: HelpIcon,
          testId: "document-status-pill",
        };
    }
  };
  return <Pill {...getProps()} />;
};

export default FileStatusPill;
