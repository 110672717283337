import { FC } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { IconProps, IconWrapper, MeetingIcon, PaymentIcon, PhoneIcon, SiteIcon } from "components/CommonDashboard/Icons";
import Colors from "components/CommonDashboard/Styling/Colors";
import { BodyRegular, H2, H3 } from "components/CommonDashboard/Typography";

interface BottomPanelProps {
}

const BottomPanel: FC<BottomPanelProps> = () => {
    return <RootContainer>
        <H2>Why Choose Deeded</H2>
        <InfoContainer>
            {Benefits.map((item, idx) => <BenefitItem key={idx}>
                <IconWrapper>{item.icon({ color: Colors.NEUTRAL_700 })}</IconWrapper>
                <H3>{item.title}</H3>
                <BodyRegular>{item.content}</BodyRegular>
            </BenefitItem>)}
        </InfoContainer>
    </RootContainer>;
}

const RootContainer = styled(Stack)(({ theme }) => ({
    backgroundColor: Colors.WHITE,
    borderRadius: '2.4rem',
    gap: '2.4rem',
    width: '100%',
    maxWidth: '120rem',
    alignSelf: 'center',
    padding: '4.8rem',
    [theme.breakpoints.down('mobile')]: {
        gap: '1.6rem',
        padding: '4.8rem 1.6rem',
    }
}));

const InfoContainer = styled(Stack)(({ theme }) => ({
    gap: '2.4rem',
    flexDirection: 'row',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
        padding: 0,
        gap: 0,
    }
}));

const BenefitItem = styled(Stack)(({ theme }) => ({
    gap: '1.6rem',
    alignItems: 'flex-start',
    [theme.breakpoints.down('mobile')]: {
        padding: '2.4rem',
    }
}));

const Benefits: Array<{ icon: FC<IconProps>, title: string, content: string }> = [
    { icon: SiteIcon, title: 'Convenient Closings', content: 'Experience the ultimate convenience of seamless closings—handle your entire transaction online from anywhere, saving you time and stress.' },
    { icon: MeetingIcon, title: 'Expert Support', content: 'Our team and lawyers are here to support you every step of the way, with personalized guidance ensuring a smooth and hassle-free experience.' },
    { icon: PaymentIcon, title: 'Transparent Pricing', content: 'Enjoy peace of mind with transparent pricing, ensuring no hidden fees or surprises—know exactly what you\'re paying for upfront.' },
];
export default BottomPanel;