import { User } from "types/user"
import { State } from "types/intakeForm";
export type { State } from "types/intakeForm";

export interface PageProps {
    updateIntakeStage?: (s: State, u: string) => () => void,
    setAlert: (alert: object) => void,
    authUser: User | null
}

export enum OnboardingStepStatus {
    Disabled = 'Disabled',
    NotStarted = 'Not Started',
    InProgress = 'In Progress',
    Completed = 'Completed',
    NotShow = 'Hide',
}
