import { Question, ElementFactory, Serializer } from "survey-core";
import { ReactQuestionFactory, SurveyQuestionElementBase } from "survey-react-ui";
import { createElement, CSSProperties } from "react";

const CUSTOM_TYPE = "honeypot";

export class HoneypotModel extends Question {
    constructor(name: string) {
        super(name);
    }

    getType() {
        return CUSTOM_TYPE;
    }
}

export function registerHoneypot() {
    ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
        return new HoneypotModel(name);
    });
}

Serializer.addClass(
    CUSTOM_TYPE,
    [],
    function () {
        return new HoneypotModel("");
    },
    "question",
);

export class SurveyQuestionHoneypot extends SurveyQuestionElementBase {
    constructor(props: any) {
        super(props);
        this.state = { value: this.question.value };
    }

    get question() {
        return this.questionBase;
    }

    get value() {
        return this.question.value;
    }

    get style() {
        return {
            opacity: 0,
            position: "absolute",
            top: 0,
            left: 0,
            height: 0,
            width: 0,
            zIndex: "-1",
        } as CSSProperties;
    }

    handleInputChange = (data: string) => {
        this.question.value = data;
    };

    renderElement() {
        return (
            <div style={this.style}>
                <input
                    id={this.question.inputId}
                    value={this.value}
                    onChange={(e) => this.handleInputChange(e.target.value)}
                    autoComplete="nope"
                    tabIndex={-1}
                    type="text"
                />
            </div>
        );
    }
}

ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
    return createElement(SurveyQuestionHoneypot, props);
});
