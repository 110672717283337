import React, {useContext} from "react";
import {FormContext} from "./FormContext";
import PhoneNumberField from "./PhoneNumberField";
import {Box, styled, Typography, useTheme} from "@mui/material";
import DeededInput from "v2/components/DeededInput";

import PostalCodeField from "./PostalCodeField";
import ProvinceField from "./ProvinceField";
import ClosingDateField from "./ClosingDateField";

const FormField: React.FC<{
  label: string;
  name: string;
}> = ({label, name}) => {
  const {handleChange, values, errors} = useContext(FormContext);
  const theme = useTheme();
  const isFullWidthMobileField =
    name === "email" ||
    name === "city" ||
    name === "streetAddress" ||
    name === "brokerEmailAddress";
  const hasMarginBottom =
    name === "email" ||
    name === "streetAddress" ||
    name === "brokerEmailAddress";
  if (name === "closingDate") {
    return (
      <>
        <ClosingDateField label={label} name={name} />
      </>
    );
  }
  if (name === "postalCode") {
    return (
      <>
        <PostalCodeField label={label} name={name} />
      </>
    );
  } else if (name === "province") {
    return (
      <>
        <ProvinceField label={label} name={name} />
      </>
    );
  } else if (name === "phoneNumber" || name === "brokerPhoneNumber") {
    return (
      <>
        <PhoneNumberField label={label} name={name} />
      </>
    );
  } else {
    return (
      <>
        <Box
          sx={{
            width: "48%",
            "& .MuiFormControl-root": {
              width: "100%",
            },
            ".MuiFormControl-root .MuiOutlinedInput-root": {
              height: "36px",
              width: "100%",
            },
            [theme.breakpoints.down("sm")]: {
              width: isFullWidthMobileField ? "100%" : "48%",
              marginBottom: hasMarginBottom ? "18px" : "0px",
            },
          }}
        >
          <LabelTypography>{label}</LabelTypography>
          <DeededInput
            error={errors[name as keyof typeof errors]}
            name={name}
            value={values[name as keyof typeof values] as string}
            onChange={handleChange}
          />
        </Box>
      </>
    );
  }
};

const LabelTypography = styled(Typography)({
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: "8px",
});
export default FormField;
