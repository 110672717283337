import React, {useContext} from "react";
import {Box, styled, Typography, useTheme} from "@mui/material";
import NumberFormat from "react-number-format";
import DeededInput from "../../../v2/components/DeededInput";
import {FormContext} from "./FormContext";

const EquitableBankPhoneNumberField: React.FC<{
  label: string;
  name: string;
}> = ({label, name}) => {
  const {values, errors, handleChange} = useContext(FormContext);
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          width: "48%",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
        }}
      >
        <LabelTypography>{label}</LabelTypography>
        {/*@ts-ignore*/}
        <NumberFormat
          name={name}
          error={errors?.[name as keyof typeof errors]}
          customInput={DeededInput}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              height: "36px",
            },
          }}
          type={"tel"}
          format="+1-###-###-####"
          value={(values[name as keyof typeof values] as string) ?? ""}
          onChange={(e: {target: {value: string}}) => {
            handleChange({
              target: {
                name: name,
                value: e.target.value.replace(/ /g, ""),
              },
            });
          }}
        />
      </Box>
    </>
  );
};
const LabelTypography = styled(Typography)({
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: "8px",
});
export default EquitableBankPhoneNumberField;
