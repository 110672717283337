import { FC } from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { webLink } from "utils/openLink";
import { PartnerPageTransactionType } from "types/partnerPage";
import { H1, H2, H3 } from "components/CommonDashboard/Typography";
import {
    ArrowRightIcon,
    IconWrapper,
    MeetingIcon,
    PurchaseIcon,
    SignatureIcon,
    SoldIcon,
    TransferIcon,
} from "components/CommonDashboard/Icons";
import Button from "components/CommonDashboard/Button";
import Colors from "components/CommonDashboard/Styling/Colors";

interface LandingTitleBlockProps {
    displayName: string;
    slug: string;
}

const LandingTitleBlock: FC<LandingTitleBlockProps> = ({ displayName, slug }) => {
    return (
        <ContentContainer>
            <TitleWrapper>
                <H1 data-testid="title">{displayName} is partnered with Deeded to help you close your transaction seamlessly</H1>
            </TitleWrapper>
            <Stack gap="2.4rem" flex={1}>
                <H2>How can we help you today?</H2>
                {transactionTypes.map((t) => (
                    <LinkContainer key={t.name}>
                        <Stack flex={1} direction="row" gap="1.6rem" alignItems="center">
                            <IconWrapper>{t.Icon}</IconWrapper>
                            <H3>{t.name}</H3>
                        </Stack>
                        <Button
                            testId={`${t.type}-quote-btn`}
                            component="a"
                            href={`/information/my-quote/new?referral=${slug}&deal_type=${t.type}`}
                            endIcon={<ArrowRightIcon color={Colors.WHITE} />}
                            typeOf="CTA"
                            label={{ text: "Choose" }}
                            sx={{ alignSelf: "flex-end" }}
                        />
                    </LinkContainer>
                ))}
            </Stack>
        </ContentContainer>
    );
};

const transactionTypes = [
    {
        name: "I am adding a mortgage/line of credit or Refinancing my mortgage",
        Icon: <SignatureIcon />,
        type: PartnerPageTransactionType.MORTGAGE,
    },
    {
        name: "I am purchasing a property",
        Icon: <PurchaseIcon />,
        type: PartnerPageTransactionType.PURCHASE,
    },
    {
        name: "I am selling a property",
        Icon: <SoldIcon />,
        type: PartnerPageTransactionType.SALE,
    },
    {
        name: "I am adding or removing person(s) from my Property Title",
        Icon: <TransferIcon />,
        type: PartnerPageTransactionType.OWNERSHIP_TRANSFER,
    },
    {
        name: "I am looking for independent legal advice",
        Icon: <MeetingIcon />,
        type: PartnerPageTransactionType.LEGAL_ADVICE,
    },
];

const TitleWrapper = styled(Stack)(({ theme }) => ({
    maxWidth: "40rem",
    [theme.breakpoints.down("mobile")]: {
        maxWidth: "100%",
    },
}));

const ContentContainer = styled(Stack)(({ theme }) => ({
    padding: "0 1.2rem",
    width: "100%",
    gap: "4.8rem",
    maxWidth: "122.4rem",
    alignSelf: "center",
    flexDirection: "row",
    [theme.breakpoints.down("mobile")]: {
        flexDirection: "column",
        minWidth: "30rem",
    },
}));

const LinkContainer = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    borderRadius: "2.4rem",
    padding: "2.4rem",
    gap: "2.4rem",
    background: Colors.WHITE,
    boxShadow: "0px 4px 16px 0px #0000000D",
    flexDirection: "row",
    [theme.breakpoints.down("mobile")]: {
        flexDirection: "column",
        alignItems: "stretch",
    },
}));

export default LandingTitleBlock;
