import {FC} from "react";
import {styled} from "@mui/material/styles";
import MuiToggleButtonGroup, { ToggleButtonGroupProps } from "@mui/material/ToggleButtonGroup";

const ToggleButtonGroup: FC<ToggleButtonGroupProps> = ({ children, ...props }) => {
  return (
    <CustomToggleGroup {...props}>
      {children}
    </CustomToggleGroup>
  )
};

const CustomToggleGroup = styled(MuiToggleButtonGroup)<ToggleButtonGroupProps>(() => ({
  gap: '1.2rem',
}));

export default ToggleButtonGroup;