import {FC} from "react";
import Stack from "@mui/material/Stack";
import Colors from "components/CommonDashboard/Styling/Colors";
import {BodyRegular, H2, H3} from "components/CommonDashboard/Typography";
import CustomerDealListEmptyImage from "components/Svg/CustomerDealListEmptyImage";
import {styled} from "@mui/material/styles";

const DealListEmptyState: FC = () => {
  return <ContentContainer>
        <CustomerDealListEmptyImage />
        <Stack gap="2.4rem" p="2.4rem" mt="2.4rem" sx={{backgroundColor: Colors.WHITE, borderRadius: '1.6rem'}}>
          <H3>We were unable to identify any active transactions at this time.</H3>
          <BodyRegular color={Colors.DARK_GREY}>
            If you believe this is an error please contact us at{" "}
            <BodyRegular
              component="a"
              href="mailto:hello@deeded.ca"
              color={Colors.PRIMARY}
            >
              hello@deeded.ca
            </BodyRegular>
            .
          </BodyRegular>
        </Stack>
      </ContentContainer>
};

const ContentContainer = styled(Stack)(({theme}) => ({
  height: "100%",
  maxWidth: "60rem",
  justifyContent: "center",
  gap: "2.4rem",
  alignSelf: "center",
  [theme.breakpoints.down("mobile")]: {
    paddingTop: "10rem",
  },
}));

export default DealListEmptyState;
