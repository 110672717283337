import {FC, useState} from "react";
import {AxiosResponse} from "axios";
import {useSnackbar} from "notistack";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import {styled} from "@mui/material/styles";
import {authGenerateQR, sendEmailForReset} from "api";
import Colors from "components/CommonDashboard/Styling/Colors";
import {BodyRegular, H3} from "components/CommonDashboard/Typography";
import TextField from "components/CommonDashboard/TextField";
import Button from "components/CommonDashboard/Button";
import {CloseIcon} from "components/CommonDashboard/Icons";
import {AlertTypes} from "types/alertTypes";
import configuration from "utils/configuration";
import useCustomerPortalAuthUser from "../../hooks/useCustomerPortalAuthUser";

interface SecurityMFAPasswordModalProps {
  show: boolean;
  onCancel: () => void;
  onSuccess: (data: QrData) => void;
}

export interface QrData {
  qr: string;
  code: string;
}

const SecurityMFAPasswordModal: FC<SecurityMFAPasswordModalProps> = ({
  show,
  onCancel,
  onSuccess,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const user = useCustomerPortalAuthUser();

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setPassword("");
    onCancel();
  };

  const handleForgotPass = async () => {
    if (!user) return;

    try {
      setLoading(true);
      await sendEmailForReset(user?.email);
      enqueueSnackbar("We have sent you email for new password", {
        variant: AlertTypes.Success,
        autoHideDuration: 3000,
      });
      handleCancel();
    } catch (e) {
      const error = e as {response: AxiosResponse<{error?: string}>};
      enqueueSnackbar(
        error.response.data.error ?? "Couldn't request password reset",
        {
          variant: AlertTypes.Error,
          autoHideDuration: configuration.autoHideErrorDuration,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!password)
      return enqueueSnackbar("The password field is required.", {
        variant: AlertTypes.Error,
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    try {
      setLoading(true);
      const data = await authGenerateQR(password);
      onSuccess(data.data);
    } catch (e) {
      const error = e as {response: AxiosResponse<{error?: string}>};
      enqueueSnackbar(error.response.data.error ?? "Couldn't send password", {
        variant: AlertTypes.Error,
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    } finally {
      setPassword("");
      setLoading(false);
    }
  };

  return (
    <StyledDialog open={show}>
      <ModalBody>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <H3>
            Enable Multi-Factor
            <br />
            Authentication
          </H3>
          <Button
            typeOf="secondary"
            onClick={handleCancel}
            startIcon={<CloseIcon />}
          />
        </Stack>

        <BodyRegular color={Colors.DARK_GREY}>
          Enter your Deeded password to enable Multi-Factor Authentication
        </BodyRegular>
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
          outlined
          type="password"
          label="Password"
        />

        <Stack direction="row" justifyContent="end" gap="1.6rem">
          <Button
            onClick={handleForgotPass}
            typeOf="secondary"
            label={{text: "Forgot password"}}
            loading={loading}
            disabled={loading}
          />
          <Button
            onClick={handleSubmit}
            typeOf="CTA"
            label={{text: "Submit"}}
            loading={loading}
            disabled={loading}
          />
        </Stack>
      </ModalBody>
    </StyledDialog>
  );
};

const ModalBody = styled(Stack)({
  maxWidth: "60rem",
  background: Colors.WHITE,
  gap: "2.4rem",
});

const StyledDialog = styled(Dialog)({
  ".MuiPaper-root": {
    borderRadius: "1.6rem",
    padding: "2.4rem",
  },
});

export default SecurityMFAPasswordModal;
