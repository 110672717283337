import {ActionType} from "../actionTypes";

// SET ANSWER ONE
export const setAnswerOne = (answerOne) => {
  return {
    type: ActionType.SURVEY_SET_ANSWER_ONE,
    payload: answerOne,
  };
};
// SET ANSWER TWO
export const setAnswerTwo = (answerTwo) => {
  return {
    type: ActionType.SURVEY_SET_ANSWER_TWO,
    payload: answerTwo,
  };
};
// SET ANSWER THREE
export const setAnswerThree = (answerThree) => {
  return {
    type: ActionType.SURVEY_SET_ANSWER_THREE,
    payload: answerThree,
  };
};
// SET RATING
export const setRating = (rating) => {
  return {
    type: ActionType.SURVEY_SET_RATING,
    payload: rating,
  };
};
