import { useEffect, FC } from "react";
import { Deal } from "types/deal";
import { useHistory } from "react-router-dom";
import PortalContainer from "components/CommonDashboard/Container/PortalContainer";
import SideImage from "customerPortal/components/SideImage";
import TransactionsPlace from "components/Svg/TransactionsPlace";
import { pages } from "../paths";
import DealList from "./DealList";

interface InitialDealsProps {
    deals: Deal[] | null
    total: number,
    perPage: number,
}
interface DealsProps {
    activeDeals: InitialDealsProps,
    closeDeals: InitialDealsProps,
}
const DealsView: FC<DealsProps> = ({ activeDeals, closeDeals }) => {
    const history = useHistory();

    useEffect(() => {
        if (activeDeals.deals && closeDeals.deals && activeDeals.total === 1 && !closeDeals.total) {
            return history.replace(pages.DEAL_STATUS.route(activeDeals.deals[0].id));
        }
    }, [activeDeals.deals])

    return (
        <PortalContainer
            leftPanel={<DealList initialActiveDeals={activeDeals} initialCloseDeals={closeDeals} />}
            rightPanel={<SideImage MainImage={<TransactionsPlace />} />}
            hideRightPanelOnMobile
        />
    )
}

export default DealsView;