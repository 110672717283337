import {Box, Typography, useTheme} from "@mui/material";
import constants from "../../../styles/constants";
import {colors} from "./Simplii";
import SimpliiBannerPictures from "./SimpliiBannerPictures";
import React from "react";

const SimpliiBanner = () => {
  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          backgroundColor: colors.banner,
          position: "relative",
          height: "508px",
          padding: "24px 70px 0px 70px",
          [theme.breakpoints.down("md")]:{
            height: "326px",
          },
          [theme.breakpoints.down("sm")]:{
            padding: "24px 10px 0px 10px",
          }
        }}
      >
        <Box sx={{
          [theme.breakpoints.down("md")]:{
            display:"flex",
            justifyContent:"center",
            svg:{
              margin:"0 auto",
            }
          },
        }}>
          <SimpliiDeededSvg />
        </Box>
        <Box
          sx={{
            height: "436px",
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("lg")]:{
              justifyContent:"center"
            },
            [theme.breakpoints.down("md")]:{
              height: "281px",
            },
          }}
        >
          <SimpliiBannerPictures />
          <Typography
            sx={{
              fontSize: "36px",
              fontWeight: "700",
              color: constants.colors.sortingColor,
              width: "650px",
              marginLeft: "111px",
              zIndex: 1,
              [theme.breakpoints.down("lg")]:{
                marginLeft:"0px",
                textAlign:"center"
              },
              [theme.breakpoints.down("md")]:{
              fontSize:"24px"
            }
            }}
          >
            Simplii, this is a temporary page
          </Typography>
        </Box>
      </Box>
    </>
  );
};
const SimpliiDeededSvg = () => {
  return (
    <svg
      width="136"
      height="24"
      viewBox="0 0 136 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0V24H9.96597C14.1309 24 17.2175 23.0045 19.2256 21.0135C21.2337 18.9985 22.2378 16.0001 22.2378 12.0181C22.2378 8.01219 21.2337 5.0138 19.2256 3.02282C17.2175 1.00783 14.1309 0 9.96597 0H0ZM23.8548 0V24H40.8491V19.5021H29.8792V14.2487H39.3989V9.71504H29.8792V4.49796H40.8491V0H23.8548ZM43.3521 0V24H60.3463V19.5021H49.3765V14.2487H58.8961V9.71504H49.3765V4.49796H60.3463V0H43.3521ZM62.849 0V24H72.8155C76.9803 24 80.0666 23.0045 82.0745 21.0135C84.0827 18.9985 85.0867 16.0001 85.0867 12.0181C85.0867 8.01219 84.0827 5.0138 82.0745 3.02282C80.0666 1.00783 76.9803 0 72.8155 0H62.849ZM113.762 0V24H123.728C127.893 24 130.98 23.0045 132.988 21.0135C134.996 18.9985 136 16.0001 136 12.0181C136 8.01219 134.996 5.0138 132.988 3.02282C130.98 1.00783 127.893 0 123.728 0H113.762ZM86.7045 0.100874V11.9904V23.8799H98.9919H111.279V11.9904V0.100874H98.9919H86.7045ZM89.5712 2.87547H94.0771H98.582V11.9904V21.1058H94.0771H89.5712V11.9904V2.87547ZM99.4013 2.87547H103.906H108.412V5.64949V8.42351H103.906H99.4013V5.64949V2.87547ZM6.02431 4.49796H9.25963C11.5156 4.49796 13.2135 5.10958 14.3539 6.33295C15.4943 7.53233 16.0647 9.42747 16.0647 12.0181C16.0647 14.5609 15.4943 16.4437 14.3539 17.6671C13.2135 18.8905 11.5156 19.5021 9.25963 19.5021H6.02431V4.49796ZM68.8734 4.49796H72.1087C74.3646 4.49796 76.063 5.10958 77.2034 6.33295C78.3438 7.53233 78.9136 9.42747 78.9136 12.0181C78.9136 14.5609 78.3438 16.4437 77.2034 17.6671C76.063 18.8905 74.3646 19.5021 72.1087 19.5021H68.8734V4.49796ZM119.787 4.49796H123.022C125.278 4.49796 126.976 5.10958 128.116 6.33295C129.257 7.53233 129.827 9.42747 129.827 12.0181C129.827 14.5609 129.257 16.4437 128.116 17.6671C126.976 18.8905 125.278 19.5021 123.022 19.5021H119.787V4.49796ZM99.4013 9.21635H103.906H108.412V11.9904V14.765H103.906H99.4013V11.9904V9.21635ZM99.4013 15.5572H103.906H108.412V18.3312V21.1058H103.906H99.4013V18.3312V15.5572Z"
        fill="#F16764"
      />
    </svg>
  );
};
export default SimpliiBanner