import React, {useEffect} from "react";
import "./SurveyHome.scss";
import {Link, useRouteMatch, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";

const SurveyHome: React.FC<{
  clientStatus: null | number;
  surveyReset: () => void;
}> = ({clientStatus}) => {
  const dispatch = useDispatch();

  let match = useRouteMatch<{id: string | undefined}>();
  let history = useHistory();

  useEffect(() => {
    dispatch({type: "SURVEY_RESET"});
    // Redirect to 404 if intake was passed and client id is not valid.
    if (clientStatus === 404) {
      history.push("/404");
    }
  }, [clientStatus, dispatch, history]);

  return (
    <div className="home__wrapper">
      <div className="home__card">
        <div className="home__card--title">Deeded Client Feedback - {}</div>
        <div className="home__card--subtitle">
          It's been a pleasure serving you.
        </div>
        <div className="home__card--subtitle">
          We are constantly seeking ways to improve our service to ensure a
          simple and smooth closing experience.
        </div>
        <div className="home__card--subtitle">
          Your feedback is highly valuable to us and we greatly appreciate you
          taking the time to answer the following questions.{" "}
        </div>
        <Link
          to={`/survey/question1/${match.params?.id}`}
          className="home__card--btn"
        >
          get started
        </Link>
      </div>
    </div>
  );
};

export default SurveyHome;
