import { FC } from "react";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import configuration from "utils/configuration";
import { Caption } from "../CommonDashboard/Typography";
import Colors from "../CommonDashboard/Styling/Colors";

const env = configuration.frontendUrl.includes("dev")
    ? "dev"
    : configuration.frontendUrl.includes("stage")
        ? "stage"
        : null;

const AppEnvironment: FC = () => {
    const color = env === "dev" ? Colors.INFORMATION_700 : Colors.SUCCESS_700;

    if (!env) {
        return null;
    }

    return (
        <StyledBox bgcolor={color}>
            <Caption color={Colors.WHITE}>{env}</Caption>
        </StyledBox>
    );
};

const StyledBox = styled(Box)(() => ({
    position: "fixed",
    width: "9rem",
    top: "1rem",
    left: "-2.5rem",
    padding: "0.2rem",
    transform: "rotate(-45deg)",
    zIndex: 9999,
    textTransform: "capitalize",
    textAlign: 'center',
}));

export default AppEnvironment;
