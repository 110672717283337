/**
 * Turns a search string into query parameter object
 */
export const makeQueryParamsObject = (
  path: string | undefined,
): {[index: string]: string} => {
  return (
    path?.split("&").reduce((search, el) => {
      const keyVal = el.split("=");
      return {...search, [keyVal[0]]: keyVal[1]};
    }, {}) ?? {}
  );
};
