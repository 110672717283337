import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useEffect, useMemo } from "react";
import { useMixpanel } from "utils/MixpanelContext";
import { convertLealToDealByClient } from "api";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import constants from "styles/constants";
import { Wrapper } from "components/Common/Wrapper/Wrapper";


const WhatIsNext = () => {
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const mixpanel = useMixpanel();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!query.get('lead')) return;
        mixpanel.track(`what_happens_next_page`, {
            deal_uuid: query.get('lead')
        });
        convertLealToDealByClient(query.get('lead')).catch(e => {
            enqueueSnackbar(`Uknown id provided`, {
                variant: "info",
            });
        })
    }, []);

    return <Wrapper stageName="What’s Next">
        <Title variant="h3"><b>We're on it!</b></Title>
        <Paragraph>Thanks for moving forward with Deeded! We’ve received your confirmation, and after a quick
            review of the information you provided, we’ll send you an intake form or contact you by phone.</Paragraph>
        <Paragraph>You’ll hear from us again soon!</Paragraph>
    </Wrapper>
}

const Paragraph = styled(Typography)(({ theme }) => ({
    fontSize: '18px',
    color: constants.colors.gray800,
    maxWidth: '700px'
}));


const Title = styled(Typography)(({ theme }) => ({
    color: constants.colors.gray800,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
        paddingTop: "2vh"
    }
}));
export default WhatIsNext;