import {FC} from "react";
import PortalContainer from "components/CommonDashboard/Container/PortalContainer";
import UserProfileSettings from "components/Svg/UserProfileSettings";
import SideImage from "../../components/SideImage";
import SettingsContent from "./SettingsContent";

const UserSettings: FC = () => {
    return <PortalContainer
        leftPanel={<SettingsContent />}
        rightPanel={<SideImage MainImage={<UserProfileSettings />} />}
        hideRightPanelOnMobile
    />
}

export default UserSettings;
