import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { BodyRegular, H1, H2, H3, LinkLarge } from "components/CommonDashboard/Typography";
import colors from "components/CommonDashboard/Styling/Colors";
import Button from "components/CommonDashboard/Button";
import { CheckCircleIcon } from "components/CommonDashboard/Icons";
import Accordion from "components/CommonDashboard/Accordion";
import { QuoteResult } from "types/quote";
import { useMixpanel } from "utils/MixpanelContext";

interface QuoteSuccessProps {
    clientName?: string;
    quote: QuoteResult;
    onNextStep: () => void;
}

const QuoteSuccess: FC<QuoteSuccessProps> = ({ quote, clientName, onNextStep }) => {
    const mixpanel = useMixpanel();

    useEffect(() => mixpanel.track_pageview({ page: 'QuotePrice', dealType: quote.deal_type }), []);

    return (
        <Stack bgcolor={colors.WHITE} borderRadius="2.4rem" p="4.8rem" gap="4.8rem">
            <Box height={8} bgcolor={colors.PRIMARY_600} borderRadius="1rem" />

            <Stack gap="1.6rem">
                <H2 data-testid="quote-success-message" color={colors.NEUTRAL_700}>
                    {clientName}, here's your quote
                </H2>
                <BodyRegular color={colors.NEUTRAL_600}>
                    Wasn't that easy? Here's your quote based on the information you shared with us.
                </BodyRegular>
            </Stack>

            <Stack gap="1.6rem">
                <H1 color={colors.SUCCESS_600} data-testid="quote-response-value">
                    {quote.quote}
                </H1>
                <BodyRegular color={colors.NEUTRAL_600}>
                    All prices are subject to taxes and <LinkLarge component='a' href="/information/my-quote/disbursements" target="_blank" >disbursements</LinkLarge>.
                </BodyRegular>
            </Stack>
            <BodyRegular>
                Additional fees may apply for items not disclosed in the quote form or matters beyond the scope of a
                standard real estate sale transaction
            </BodyRegular>

            <Accordion
                defaultExpanded
                sx={{ border: `1px solid ${colors.NEUTRAL_300}` }}
                title={<H3 color={colors.PRIMARY_600}>What is included in your fees</H3>}
            >
                <Stack pl=".6rem" gap=".3rem">
                    {(quote.inclusions || []).map((point, i) => (
                        <BodyRegular component="li" key={i} color={colors.NEUTRAL_600}>
                            {point}
                        </BodyRegular>
                    ))}
                </Stack>
            </Accordion>

            <Stack gap="2.4rem" alignItems="flex-start">
                <H2 color={colors.NEUTRAL_700}>Ready to start your closing?</H2>
                <Button
                    onClick={onNextStep}
                    typeOf="CTA"
                    size="large"
                    data-testid="quote-back-to-home-page-button"
                    label={{ text: "Start your closing" }}
                    endIcon={<CheckCircleIcon color={colors.WHITE} />}
                />
            </Stack>
        </Stack >
    );
};

export default QuoteSuccess;
