import {Model} from "survey-core";
import {Survey} from "survey-react-ui";
import {useCallback, useEffect, useState} from "react";
import { renderToString } from 'react-dom/server';
import {Modal, Stack} from "@mui/material";
import BasicButton from "components/Common/Button/BasicButton";
import {H3, Large} from "components/Common/Typography";
import {InfoModalBox} from "customerPortal/components/InfoModalBox";
import "./portalSurvey.scss";
import {useMixpanel} from "utils/MixpanelContext";
import constants from "styles/constants";
import {registerInputCustomFormat} from "components/Common/SurveyJs/QuestionNumberFormat";
import {ClientIntakeStates} from "types/intakeForm";
import {updateClientIntake} from "api";
import {useSnackbar} from "notistack";
import {AlertTypes} from "../../../types/alertTypes";
import configuration from "../../../utils/configuration";

interface SurveyQuestionsProps {
  questions: unknown;
  intakeId: number;
  savedData: any;
  handleSubmit: (data: any) => void;
  transactionType?: string;
}

registerInputCustomFormat();

export const SurveyQuestions = ({
  questions,
  intakeId,
  savedData,
  handleSubmit,
  transactionType,
}: SurveyQuestionsProps) => {
  const {enqueueSnackbar} = useSnackbar();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPartialSaving, setIsPartialSaving] = useState(false);
  const [partialData, setPartialData] = useState<{
    data: Object;
    pageNo: number;
  } | null>(null);
  const mixpanel = useMixpanel();

  const survey = new Model(questions);
  survey.sendResultOnPageNext = true;
  survey.completedHtml = renderToString(<h3>Thank you for completing the onboarding questionnaire</h3>);

  if (savedData || partialData) {
    survey.data = partialData?.data || savedData?.data || {};
    survey.currentPageNo = partialData?.pageNo || savedData?.pageNo || 0;
  }
  const handleData = async (sender: Model, options: any) => {
    await updateClientIntake(intakeId, {
      content: {
        data: survey.data,
        pageNo: survey.currentPageNo,
      },
      state: ClientIntakeStates.Completed,
    }).catch(() => {
      enqueueSnackbar("There was an error saving your survey.", {
        variant: AlertTypes.Error,
        autoHideDuration: configuration.autoHideErrorDuration,
      });
    });
    handleSubmit(sender.data);
  };

  const handleManualSavePartialData = (data: object, pageNo: number) => {
    setPartialData({data, pageNo});
    setIsPartialSaving(true);
    mixpanel.track(`intake_user_saved`, {
      transactionType,
      name: survey.currentPage.name,
    });
    updateClientIntake(intakeId, {
      content: {data, pageNo},
      state: ClientIntakeStates.InProgress,
    })
      .then(() => setIsModalOpen(true))
      .catch(() =>
        enqueueSnackbar(
          "There was an error saving your partial data. If the issue persists, please contact us.",
          {
            variant: AlertTypes.Error,
            autoHideDuration: configuration.autoHideErrorDuration,
          },
        ),
      )
      .finally(() => setIsPartialSaving(false));
  };

  const handleOnAfterRenderPage = useCallback(
    function (sender, options) {
      const page = options.page;
      mixpanel.track(`intake_${page.name.toLowerCase()}`, {transactionType});
    },
    [mixpanel],
  );

  document.documentElement.style.setProperty(
    "--sjs-primary-backcolor",
    constants.colors.black3,
  );

  survey.onPartialSend.add((survey) =>
    updateClientIntake(intakeId, {
      content: {data: survey.data, pageNo: survey.currentPageNo},
      state: ClientIntakeStates.InProgress,
    }),
  );
  survey.onAfterRenderPage.add(handleOnAfterRenderPage);
  survey.onComplete.add(handleData);
  survey.showProgressBar = "top";
  survey.showTOC = false;
  survey.showTitle = false;
  survey.showPageTitles = false;

  return (
    <>
      <Survey model={survey} />
      <Stack mb={4} sx={{marginTop: "2rem"}}>
        <BasicButton
          testId="survey-save-for-later-button"
          disabled={isPartialSaving}
          label={{
            inputId: "intakeSavePartialId",
            text: isPartialSaving ? "Loading..." : "Save for Later",
          }}
          onClick={() =>
            handleManualSavePartialData(survey.data, survey.currentPageNo)
          }
          size="small"
          typeOf="secondary"
        />
      </Stack>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <InfoModalBox>
          <H3 sx={{textAlign: "center"}}>Your Progress Has Been Saved</H3>
          <Large>
            You can close this browser window and return later using the same
            link.
          </Large>
        </InfoModalBox>
      </Modal>
    </>
  );
};
